import { Box, Grid, GridSize, IconButton, Skeleton, TableCell } from "@mui/material";
import TrialUsageWarning from "../../../common/components/TrialUsageWarning.tsx";
import {
    DotLegalButton,
    DotLegalEmptyState,
    DotLegalMultiSelect,
    DotLegalPaper,
    DotLegalTableWithControls,
    DotLegalTooltip,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import TrialExpiredRowWrapper from "../../../common/components/TrialExpiredRowWrapper.tsx";
import DotLegalLink from "../../../common/components/dotLegalLink/DotLegalLink.tsx";
import { LegalEntityAuditTabs } from "../../../legalEntity/LegalEntity.types.ts";
import SystemLinks from "../../../system/SystemLinks.tsx";
import ClassificationRisk from "../../../common/components/questionnaireComponents/classiificationRisk/ClassificationRisk.tsx";
import { LegalEntityMasterType, LegalEntityPageEnum } from "../../../legalEntity/legalEntities/LegalEntities.types.ts";
import QuestionnaireStatus from "../../../common/components/questionnaireComponents/questionnaireStatus/QuestionnaireStatus.tsx";
import QuestionnaireRisk from "../../../common/components/questionnaireComponents/questionnaireRisk/QuestionnaireRisk.tsx";
import QuestionnaireEvaluation from "../../../common/components/questionnaireComponents/questionnaireEvaluation/QuestionnaireEvaluation.tsx";
import { NoteSVG, WhiteLockIcon } from "../../../common/icons.tsx";
import LegalEntityAuditTableOverflowMenu from "../../../legalEntity/legalEntityAuditTab/legalEntityAuditTableOverflowMenu/LegalEntityAuditTableOverflowMenu.tsx";
import { LegalEntityAuditStatus } from "../../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types.ts";
import PlanDialogButtonWrapper from "../../../plan/planDialogButtonWrapper/planDialogButtonWrapper.tsx";
import React from "react";
import {
    LegalEntityAuditGroupAuditModel,
    LegalEntityAuditGroupAuditTableModel,
    LegalEntityAuditGroupOverviewModel,
} from "../LegalEntityAuditGroupOverview.types.ts";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider.tsx";
import { useTranslation } from "../../../localization/useTranslation.ts";
import { setFirstLetterToLowerCase } from "../../../common/stringOperations.ts";
import { LegalEntityContactType } from "../../../legalEntity/legalEntityContactTab/LegalEntityContactTab.types.ts";
import { getQuestionnaireResponseScore } from "../../../common/questionnaireResponseScore.ts";
import { EvaluationStatus } from "../../../common/components/questionnaireComponents/evaluateQuestionnaireDialog/EvaluateQuestionnaireDialog.types.ts";
import { useLegalEntityAuditGroupAuditsTab } from "./LegalEntityAuditGroupAuditsTab.hooks.ts";
import { useUrlProvider } from "../../../useUrlProvider.ts";
import QuestionnaireReportOverflowButtons from "../../../common/components/questionnaireComponents/questionnaireReportButtons/QuestionnaireReportOverflowButtons.tsx";
import QuestionnaireReportButtons from "../../../common/components/questionnaireComponents/questionnaireReportButtons/QuestionnaireReportButtons.tsx";
import ExternalAuditEmptyStateIcon from "../../externalAuditsEmptyState.svg?react";

export interface LegalEntityAuditGroupAuditsTabProps {
    isLoading: boolean;
    auditsData: LegalEntityAuditGroupOverviewModel | undefined;
    refetchData: () => void;
}

function LegalEntityAuditGroupAuditsTab(props: LegalEntityAuditGroupAuditsTabProps) {
    const { vendorManagementFeatures } = usePlanContext();
    const { translateString, translateLegalEntityAuditStatus, translateDate } = useTranslation();
    const { getLegalEntityUrl, getAuditGroupManagementReportUrl } = useUrlProvider();
    const {
        rows,
        showNote,
        setShowNote,
        saveNote,
        filters,
        searchedType,
        setSearchedType,
        searchedRiskLevel,
        setSearchedRisklevel,
        searchedClassifications,
        setSearchedClassifications,
        searchedAssets,
        setSearchedAssets,
        searchedStatus,
        setSearchedStatus,
        searchedEvaluations,
        setSearchedEvaluations,
        getReportPdfUrl,
        isDownloadingPdf,
        onDownloadReportAsPdf,
        onDownloadManagementReportClick,
        isDownloadingManagementReport,
        auditInformationsIsLoading,
    } = useLegalEntityAuditGroupAuditsTab(props);

    function getHeaders() {
        let headers = Array<ITableHeader<LegalEntityAuditGroupAuditTableModel>>();
        headers.push({ property: "legalEntityName", text: translateString("legalEntity"), align: "left", showOnMobile: true });
        headers.push({ property: "uniqueIdentifier", text: translateString("id"), align: "left", showOnMobile: true });
        headers.push({ property: "legalEntityTypes", text: translateString("type"), align: "left", showOnMobile: true });
        headers.push({ property: "contact", text: translateString("contact"), align: "left", showOnMobile: true });
        headers.push({ property: "assets", text: translateString("assets"), align: "left", showOnMobile: true });
        headers.push({ property: "classifications", text: translateString("classifications"), align: "left", showOnMobile: true });
        headers.push({ property: "status", text: translateString("status"), align: "left", showOnMobile: false });
        headers.push({ property: "riskLevel", text: translateString("riskLevel"), align: "left", showOnMobile: false });
        headers.push({ property: "score", text: translateString("score"), align: "left", showOnMobile: true });
        headers.push({ property: "evaluation", text: translateString("evaluation"), align: "left", showOnMobile: true });
        headers.push({ property: "report", text: translateString("legalEntityAuditReport"), align: "left", showOnMobile: true });
        headers.push({ property: "reminderSent", text: translateString("headerReminderSent"), align: "left", showOnMobile: true });

        headers.push({ property: "evaluationName", text: "", align: "left", showOnMobile: true, hideHeader: true });

        headers.push({ property: "note", text: translateString("note"), align: "left", showOnMobile: true });
        headers.push({ property: "assetsString", text: translateString("assets"), align: "left", showOnMobile: false, hideHeader: true });
        headers.push({ property: "reminderSentString", text: "", align: "left", showOnMobile: false, hideHeader: true });

        return headers;
    }

    function getReportButtonMenuItems(row: LegalEntityAuditGroupAuditTableModel) {
        if (row.scoreEnabled) {
            return [
                {
                    menuItem: row.hasRecommendations ? translateString("downloadWithScoreAndRecommendations") : translateString("downloadWithScore"),
                    onClick: () => onDownloadReportAsPdf({ legalEntityAuditId: row.id, ...row }, false, row.hasRecommendations),
                },
                {
                    menuItem: row.hasRecommendations ? translateString("downloadWithoutScoreAndRecommendations") : translateString("downloadWithoutScore"),
                    onClick: () => onDownloadReportAsPdf({ legalEntityAuditId: row.id, ...row }, true, false),
                },
            ];
        } else {
            return [
                {
                    menuItem: translateString("downloadWithRecommendations"),
                    onClick: () => onDownloadReportAsPdf({ legalEntityAuditId: row.id, ...row }, false, true),
                },
                {
                    menuItem: translateString("downloadWithoutRecommendations"),
                    onClick: () => onDownloadReportAsPdf({ legalEntityAuditId: row.id, ...row }, false, false),
                },
            ];
        }
    }

    function getReportButtons(row: LegalEntityAuditGroupAuditTableModel) {
        const reportUrl = getReportPdfUrl(row.questionnaireResponseId, row.deadline, row.sender, row.legalEntityName, false, row.hasRecommendations);
        const showButtons = row.status !== LegalEntityAuditStatus.Opened && row.status !== LegalEntityAuditStatus.Sent && !row.closed;
        const downloadingPdf = isDownloadingPdf === row.id;

        if (!row.partOfCurrentPlan) {
            return undefined;
        }

        if (row.scoreEnabled || row.hasRecommendations) {
            return (
                <QuestionnaireReportOverflowButtons
                    reportPdfUrl={reportUrl}
                    reportPdfMenuItems={getReportButtonMenuItems(row)}
                    showButtons={showButtons}
                    isDownloadingPdf={downloadingPdf}
                />
            );
        }

        return (
            <QuestionnaireReportButtons
                isDownloadingPdf={downloadingPdf}
                onDownloadReportPdf={() => onDownloadReportAsPdf({ legalEntityAuditId: row.id, ...row }, true, row.hasRecommendations)}
                reportPdfUrl={reportUrl}
                showButtons={showButtons}
            />
        );
    }

    function getSearchFields() {
        const tableGridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize; xl: boolean } = {
            item: true,
            xs: 12,
            sm: 6,
            md: 4,
            lg: 2,
            xl: true,
        };

        return (
            <React.Fragment>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedType}
                        chosenOptions={searchedType}
                        label={translateString("type")}
                        placeholder={translateString("type")}
                        isLoading={props.isLoading}
                        options={filters.typeSelectOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>

                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedAssets}
                        chosenOptions={searchedAssets}
                        label={translateString("assets")}
                        placeholder={translateString("assets")}
                        isLoading={props.isLoading}
                        options={filters.assetsOptions}
                        noMargin
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>

                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedClassifications}
                        chosenOptions={searchedClassifications}
                        label={translateString("classifications")}
                        placeholder={translateString("classifications")}
                        isLoading={props.isLoading}
                        options={filters.classificationOptions}
                        noMargin
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>

                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedStatus}
                        chosenOptions={searchedStatus}
                        label={translateString("status")}
                        placeholder={translateString("status")}
                        isLoading={props.isLoading}
                        options={filters.statusOptions}
                        noMargin
                        noOptionsLabel={translateString("status")}
                    />
                </Grid>

                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedRisklevel}
                        chosenOptions={searchedRiskLevel}
                        label={translateString("riskLevel")}
                        placeholder={translateString("riskLevel")}
                        isLoading={props.isLoading}
                        options={filters.riskLevelOptions}
                        noMargin
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        options={filters.evaluationOptions}
                        noMargin
                        chosenOptions={searchedEvaluations}
                        onChange={setSearchedEvaluations}
                        label={translateString("evaluation")}
                        noOptionsLabel={translateString("noOptions")}
                    ></DotLegalMultiSelect>
                </Grid>
            </React.Fragment>
        );
    }

    return (
        <DotLegalPaper>
            {!vendorManagementFeatures.auditGroups && (
                <TrialUsageWarning
                    headerKey={translateString("auditGroupsLockedWarningHeader")}
                    textKey={translateString("auditGroupsLockedWarning")}
                    marginBottom={2}
                />
            )}
            {props.isLoading || (props.auditsData && props.auditsData.audits.length > 0) ? (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <DotLegalTableWithControls
                        headers={getHeaders()}
                        getUserSpecificPageLength={() => 30}
                        extraControls={getSearchFields()}
                        hideRowsPerPage
                        defaultOrderBy={"legalEntityName"}
                        defaultOrder={"asc"}
                        isLoading={props.isLoading}
                        labelRowsPerPage={translateString("showEntites")}
                        labelSearch={translateString("search")}
                        noOptionsLabel={translateString("noOptions")}
                        paginationLabelOf={translateString("labelOf")}
                        data={mapToTableData(rows)}
                        clickableRows={false}
                        emptyText={translateString("noData")}
                        renderRow={(row, i) => {
                            return (
                                <TrialExpiredRowWrapper
                                    key={row.id + i}
                                    tooltip={""}
                                    onNonExpiredClick={() => {}}
                                    expired={!row.partOfCurrentPlan}
                                    planUpgradeText={translateString("auditGroupLocked")}
                                >
                                    <TableCell>
                                        <Box key={row.id} component={"span"} sx={{ fontWeight: "bold" }}>
                                            <DotLegalLink linkColor={"primary"} to={`${getLegalEntityUrl(row.legalEntityId, LegalEntityAuditTabs.Audits)}`}>
                                                {row.legalEntityName}
                                            </DotLegalLink>
                                        </Box>
                                    </TableCell>
                                    <TableCell>{row.uniqueIdentifier}</TableCell>
                                    <TableCell>{row.legalEntityTypeString}</TableCell>
                                    <TableCell>
                                        <DotLegalTooltip text={row.contactEmail}>
                                            <Box>
                                                {row.contact} {row.contactTypes.length > 0 && <>({row.contactTypesString})</>}
                                            </Box>
                                        </DotLegalTooltip>
                                    </TableCell>
                                    <TableCell>
                                        <SystemLinks systems={row.assets} />
                                    </TableCell>
                                    <TableCell sx={{ minWidth: 350 }}>
                                        {auditInformationsIsLoading ? (
                                            <Skeleton width={"100%"} height={18} />
                                        ) : (
                                            row.classifications &&
                                            row.classifications.length > 0 && (
                                                <ClassificationRisk
                                                    legalEntityId={row.id}
                                                    legalEntityPage={LegalEntityPageEnum.Vendor}
                                                    classifications={row.classifications}
                                                />
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <QuestionnaireStatus
                                            showQuestionsStatistics={row.showQuestionsStatistics}
                                            numberOfQuestionsAnswered={row.numberOfQuestionsAnswered}
                                            totalNumberOfQuestions={row.totalNumberOfQuestions}
                                            status={row.status}
                                            lastestRecipientUpdate={row.lastestResponseChange}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <QuestionnaireRisk
                                            riskLevel={row.riskLevel}
                                            riskName={row.riskName}
                                            scoreEnabled={row.scoreEnabled}
                                            status={row.status}
                                        />
                                    </TableCell>
                                    <TableCell>{row.scoreString}</TableCell>
                                    <TableCell>
                                        <QuestionnaireEvaluation evaluation={row.evaluation} evaluationStatus={row.evaluationStatus} />
                                    </TableCell>
                                    <TableCell>{getReportButtons(row)}</TableCell>
                                    <TableCell>
                                        {row.remindersPaused ? translateString("remindersPaused") : row.reminderSent ? translateDate(row.reminderSent) : ""}
                                    </TableCell>
                                    <TableCell>
                                        {row.note && (
                                            <IconButton
                                                onClick={() => {
                                                    setShowNote(row.id);
                                                }}
                                            >
                                                {NoteSVG}
                                            </IconButton>
                                        )}
                                    </TableCell>

                                    <TableCell
                                        align={"right"}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        {!props.isLoading && props.auditsData && (
                                            <LegalEntityAuditTableOverflowMenu
                                                key={row.id}
                                                auditId={row.id}
                                                isClosed={row.closed}
                                                status={row.status}
                                                canBeDeleted={row.closed || row.status === LegalEntityAuditStatus.Submitted}
                                                questionnaireName={row.questionnaireName}
                                                deadline={row.deadline}
                                                auditNote={row.note}
                                                responsibleId={props.auditsData.responsibleId}
                                                contactId={row.contactId}
                                                contactName={row.contact}
                                                legalEntityId={row.legalEntityId}
                                                responseExternalId={row.responseExternalId}
                                                evaluation={row.evaluation}
                                                evaluationStatus={row.evaluationStatus}
                                                onAuditClosed={props.refetchData}
                                                onAuditReopened={props.refetchData}
                                                onAuditDeleted={props.refetchData}
                                                onResponsibleChanged={props.refetchData}
                                                onContactChanged={props.refetchData}
                                                onReminderSent={props.refetchData}
                                                onAuditEvaluated={props.refetchData}
                                                onCloseNoteDialog={() => setShowNote(undefined)}
                                                saveNote={(note) => saveNote(note, row.id)}
                                                showNote={showNote === row.id}
                                                disableChangeResponsible
                                                onRemindersToggled={props.refetchData}
                                                remindersPaused={row.remindersPaused}
                                                questionnaireResponseId={row.questionnaireResponseId}
                                                questionnaireId={row.questionnaireId}
                                                legalEntityName={row.legalEntityName}
                                                partOfCurrentPlan={row.partOfCurrentPlan}
                                            />
                                        )}
                                    </TableCell>
                                </TrialExpiredRowWrapper>
                            );
                        }}
                    />
                    <Box sx={{ alignSelf: "flex-end" }}>
                        {props.auditsData && props.auditsData.sent && (
                            <Box sx={{ display: "flex", gap: 1 }}>
                                <PlanDialogButtonWrapper text={translateString("auditGroupLocked")} enabled={!vendorManagementFeatures.auditGroups}>
                                    <DotLegalButton
                                        ariaHaspopup
                                        onClick={() => {
                                            window.open(getAuditGroupManagementReportUrl(props.auditsData!.id), "_blank");
                                        }}
                                        buttonType={"primary"}
                                        disabled={!vendorManagementFeatures.auditGroups}
                                        endIcon={!vendorManagementFeatures.auditGroups ? WhiteLockIcon : undefined}
                                        toolTip={!vendorManagementFeatures.auditGroups ? translateString("auditGroupLocked") : undefined}
                                    >
                                        {translateString("viewManagementReportInBrowser")}
                                    </DotLegalButton>
                                </PlanDialogButtonWrapper>
                                <PlanDialogButtonWrapper text={translateString("auditGroupLocked")} enabled={!vendorManagementFeatures.auditGroups}>
                                    <DotLegalButton
                                        ariaHaspopup
                                        onClick={onDownloadManagementReportClick}
                                        isLoading={isDownloadingManagementReport}
                                        buttonType={"primary"}
                                        disabled={!vendorManagementFeatures.auditGroups || isDownloadingManagementReport}
                                        endIcon={!vendorManagementFeatures.auditGroups ? WhiteLockIcon : undefined}
                                        toolTip={!vendorManagementFeatures.auditGroups ? translateString("auditGroupLocked") : undefined}
                                    >
                                        {translateString("downloadManagementReport")}
                                    </DotLegalButton>
                                </PlanDialogButtonWrapper>
                            </Box>
                        )}
                    </Box>
                </Box>
            ) : (
                <DotLegalEmptyState icon={<ExternalAuditEmptyStateIcon />} text={"Du har ingen udsendte audits på denne auditgruppe"} />
            )}
        </DotLegalPaper>
    );

    function mapToTableData(audits: Array<LegalEntityAuditGroupAuditModel> | undefined): Array<LegalEntityAuditGroupAuditTableModel> {
        if (audits) {
            return audits.map((x) => {
                return {
                    ...x,
                    assetsString: x.assets.flatMap((x) => x.name).join(", "),
                    contactTypesString: x.contactTypes.map((x) => translateString(setFirstLetterToLowerCase(LegalEntityContactType[x].toString()))).join(", "),
                    auditStatusString: translateLegalEntityAuditStatus(x.status.toString()),
                    scoreString: getQuestionnaireResponseScore(
                        x.status === LegalEntityAuditStatus.Submitted,
                        x.scoreEnabled,
                        x.score,
                        translateString("scoreNotEnabled")
                    ),
                    legalEntityTypeString: x.legalEntityTypes
                        ?.map((type) => translateString(setFirstLetterToLowerCase(LegalEntityMasterType[type].toString())))
                        .join(", "),
                    reminderSentString: x.reminderSent ? translateDate(x.reminderSent) : "",
                    evaluationName: x.evaluationStatus ? translateString(setFirstLetterToLowerCase(EvaluationStatus[x.evaluationStatus].toString())) : "",
                };
            });
        }

        return [];
    }
}

export default LegalEntityAuditGroupAuditsTab;
