import { useState } from "react";
import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { LegalEntityAuditGroupRow, LegalEntityAuditGroupRowTableModel, LegalEntityAuditGroupViewModel } from "./LegalEntityAuditGroups.types";
import { useStateUrlParamsArray } from "../../common/hooks/useStateUrlParams";
import { BaseViewModel } from "../../system/systemOverview/SystemOverview.types";
import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useTranslation } from "../../localization/useTranslation";

export function useLegalEntityAuditGroupsHooks() {
    const { translateDate } = useTranslation();
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [searchedAuditTemplate, setSearchedAuditTemplate] = useStateUrlParamsArray("auditTemplates", []);
    const [searchedDeadline, setSearchedDeadline] = useStateUrlParamsArray("deadlines", []);
    const [searchedResponsibles, setSearchedResponsible] = useStateUrlParamsArray("responsibles", []);
    const [auditGroupToEdit, setAuditGroupToEdit] = useState<LegalEntityAuditGroupRowTableModel | undefined>(undefined);

    const url = `/legalEntityAuditGroups`;
    let { isLoading, data, refetch } = useQuery(url, () => get<LegalEntityAuditGroupViewModel>(url));

    let auditTemplateFilterOptions: Array<DotLegalSelectOption> = [];
    let deadlineFilterOptions: Array<DotLegalSelectOption> = [];
    let responsibleFilterOptions: Array<DotLegalSelectOption> = [];
    let rows: Array<LegalEntityAuditGroupRow> = [];

    if (data) {
        createDeadlineFilterOptions(data.rows);
        createAuditTemplateFilterOptions(data.rows.flatMap((x) => x.questionnaire));
        createResponsibleFilterOptions(data.rows.flatMap((x) => x.responsible));

        sortSelectOptions();
        rows = getFilteredRows(data.rows);
    }

    const refetchAuditGroups = () => {
        refetch();
    };

    return {
        showCreateDialog,
        setShowCreateDialog,
        rows: rows,
        isLoading,
        hasData: isLoading || (data && data.rows.length > 0),
        searchedAuditTemplate,
        searchedDeadline,
        searchedResponsibles,
        setSearchedAuditTemplate,
        setSearchedDeadline,
        setSearchedResponsible,
        auditTemplateFilterOptions,
        deadlineFilterOptions,
        responsibleFilterOptions,
        auditGroupToEdit,
        setAuditGroupToEdit,
        refetchAuditGroups,
    };

    function createDeadlineFilterOptions(rows: Array<LegalEntityAuditGroupRow>) {
        for (let row of rows) {
            const rowDeadline = new Date(row.deadline);
            if (!deadlineFilterOptions.some((x) => x.id === rowDeadline.getTime().toString())) {
                deadlineFilterOptions.push({
                    id: rowDeadline.getTime().toString(),
                    name: translateDate(rowDeadline),
                });
            }
        }
    }

    function createResponsibleFilterOptions(responsibles: Array<BaseViewModel>) {
        for (let responsible of responsibles) {
            if (!responsibleFilterOptions.some((x) => x.id === responsible.id)) {
                responsibleFilterOptions.push({
                    id: responsible.id,
                    name: responsible.name,
                });
            }
        }
    }

    function createAuditTemplateFilterOptions(auditTemplates: Array<BaseViewModel>) {
        for (let template of auditTemplates) {
            if (!auditTemplateFilterOptions.some((x) => x.id === template.id)) {
                auditTemplateFilterOptions.push({
                    id: template.id,
                    name: template.name,
                });
            }
        }
    }

    function sortSelectOptions() {
        auditTemplateFilterOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
        deadlineFilterOptions.sort((a, b) => (a.id > b.id ? 1 : -1));
        responsibleFilterOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    function getFilteredRows(rows: Array<LegalEntityAuditGroupRow>) {
        let tempData = [...rows];

        if (searchedResponsibles.length > 0 && searchedResponsibles[0] !== "") {
            tempData = tempData.filter((row) => searchedResponsibles.indexOf(row.responsible.id.toString()) >= 0);
        }

        if (searchedAuditTemplate.length > 0 && searchedAuditTemplate[0] !== "") {
            tempData = tempData.filter((row) => searchedAuditTemplate.indexOf(row.questionnaire.id.toString()) >= 0);
        }

        if (searchedDeadline.length > 0 && searchedDeadline[0] !== "") {
            tempData = tempData.filter((row) => searchedDeadline.indexOf(new Date(row.deadline).getTime().toString()) >= 0);
        }

        return tempData;
    }
}
