import { DocumentStatus } from "../../documents/Documents.types";
import { SelectableDocumentItem } from "../ProcessingActivity.types";

export function filterDocumentDataByCompanyId(documentsData: Array<SelectableDocumentItem> | undefined, companyId: string | null | undefined) {
    let filteredDocumentData = Array<SelectableDocumentItem>();

    if (documentsData && companyId) filteredDocumentData = documentsData.filter((x) => x.legalEntityId === companyId);

    return filteredDocumentData;
}

export function getSelectableTiaDocumentItems(
    documentsData: Array<SelectableDocumentItem> | undefined,
    translateString: (key: string, interpolation?: any) => string,
    hasAgreement: boolean,
    containsNotAccessibleDocuments: boolean
) {
    let agreements = (documentsData ?? []).map((x) => {
        return {
            id: x.id,
            name: x.name,
            fontStyle: x.status !== DocumentStatus.active ? "italic" : undefined,
            hoverText: x.documentTypeName,
        };
    });

    var yesNoOptions = getYesNoOptions(translateString, agreements.length, hasAgreement, containsNotAccessibleDocuments);

    return yesNoOptions.concat(agreements);
}

export function getSelectableDocumentItems(
    documentsData: Array<SelectableDocumentItem> | undefined,
    translateString: (key: string, interpolation?: any) => string,
    hasAgreement: boolean,
    agreementIds: Array<string>,
    containsNotAccessibleDocuments?: boolean
) {
    var agreements = (documentsData ?? []).map((x) => {
        return {
            id: x.id,
            name: x.name,
            fontStyle: x.status !== DocumentStatus.active ? "italic" : undefined,
            hoverText: x.documentTypeName,
        };
    });

    if (agreementIds.length > 0) {
        return agreements;
    }

    var yesNoOptions = getYesNoOptions(translateString, agreements.length, hasAgreement, containsNotAccessibleDocuments ?? false);

    return yesNoOptions.concat(agreements);
}

export function getSelectedDocument(agreementId: string | undefined | null, hasAgreement: boolean, containsNotAccessibleTia: boolean) {
    if (agreementId) {
        return agreementId;
    } else if (containsNotAccessibleTia) {
        return "true";
    } else {
        return hasAgreement.toString();
    }
}

export function getSelectedDocuments(agreementIds: Array<string>, hasAgreement: boolean, containsNotAccessibleDocuments?: boolean) {
    if (agreementIds.length > 0) {
        return agreementIds;
    } else if (containsNotAccessibleDocuments) {
        return ["true"];
    } else {
        return [hasAgreement.toString()];
    }
}

function getYesNoOptions(translateString: any, numberOfSelectableAgreements: number, hasAgreement: boolean, containsNotAccessibleDocuments: boolean) {
    if (containsNotAccessibleDocuments) {
        return [
            {
                name: translateString("yes"),
                id: "true",
            },
        ];
    }

    var options = [{ name: translateString("no"), id: "false" }];

    if (numberOfSelectableAgreements === 0 || hasAgreement) {
        options.push({
            name: translateString("yes"),
            id: "true",
        });
    }

    return options;
}
