import { Box, Grid, TableCell, TableRow } from "@mui/material";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import { DotLegalButton, DotLegalMultiSelect, DotLegalOverflowMenu, DotLegalTableWithControls, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { useLegalEntityDocumentTabDataMapping } from "./LegalEntityDocumentTab.hooks";
import NoSharings from "../../processingActivity/processingActivityOverview/processingActivitySharingsTab/noSharings.svg?react";
import { LegalEntityDocumentRow } from "./LegalEntityDocumentTab.types";
import AddLegalEntityDocumentDialog from "./AddLegalEntityDocumentDialog/AddLegalEntityDocumentDialog";
import { DeleteLegalEntityDocumentDialog } from "./deleteLegalEntityDocumentDialog/DeleteLegalEntityDocumentDialog";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import React from "react";
import EditLegalEntityDocumentDialog from "./editLegalEntityDocumentDIalog/EditLegalEntityDocumentDialog";
import { DocumentStatus } from "../../documents/Documents.types";

export interface ILegalEntityDocumentTabProps {
    legalEntityId: string | undefined;
    isGroupEntity: boolean;
}

function LegalEntityDocumentTab(props: ILegalEntityDocumentTabProps) {
    const { translateString, translateDate } = useTranslation();
    const { permissions } = useUserContext();

    const {
        hasDocuments,
        isLoading,
        documents,
        documentTypeOptions,
        searchedDocumentType,
        setSearchedDocumentType,
        getMenuItems,
        showAddEditDialog,
        setShowAddEditDialog,
        selectedDocument,
        setSelectedDocument,
        refetchDocuments,
        showDeleteDocumentDialog,
        setShowDeleteDocumentDialog,
        handleRowClick,
        showEditDialog,
        setShowEditDialog,
    } = useLegalEntityDocumentTabDataMapping(props);

    function getAdditionalFilters() {
        return (
            <React.Fragment>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <DotLegalMultiSelect
                        onChange={setSearchedDocumentType}
                        chosenOptions={searchedDocumentType}
                        label={translateString("type")}
                        placeholder={translateString("type")}
                        isLoading={isLoading}
                        options={documentTypeOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    function formatGroupEntities(row: LegalEntityDocumentRow) {
        if (row.allGroupEntities) {
            return translateString("entireGroup");
        }

        return row.groupEntities.join(", ");
    }

    return (
        <DotLegalPaper>
            {hasDocuments ? (
                <DotLegalTableWithControls
                    extraControls={getAdditionalFilters()}
                    headers={GetDocumentHeader(translateString, props.isGroupEntity)}
                    getUserSpecificPageLength={() => 30}
                    hideRowsPerPage
                    defaultOrderBy={"name"}
                    defaultOrder={"asc"}
                    isLoading={isLoading}
                    data={documents}
                    emptyText={translateString("noSharingsMatchesQuery")}
                    labelRowsPerPage={translateString("showEntites")}
                    labelSearch={translateString("search")}
                    noOptionsLabel={translateString("noOptions")}
                    paginationLabelOf={translateString("labelOf")}
                    renderRow={(row, i) => (
                        <TableRow key={i} onClick={() => handleRowClick(row.documentId, row.dataId, row.fileName, row.link)}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.fileName ?? row.link}</TableCell>
                            <TableCell>{row.documentType.name}</TableCell>
                            <TableCell>{translateString(DocumentStatus[row.status].toLowerCase())}</TableCell>
                            <TableCell>{row.owner.name}</TableCell>
                            <TableCell>{row.version}</TableCell>
                            {!props.isGroupEntity && <TableCell>{formatGroupEntities(row)}</TableCell>}
                            <TableCell>{translateDate(row.created)}</TableCell>
                            <TableCell align={"right"}>
                                <DotLegalOverflowMenu items={getMenuItems(row)} />
                            </TableCell>
                        </TableRow>
                    )}
                />
            ) : (
                <DotLegalEmptyState icon={<NoSharings />} text={translateString("noDocumentsAttachedToLegalEntity")} />
            )}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {permissions.companyDocumentationPermissions.create && (
                    <DotLegalButton
                        buttonType={"primary"}
                        onClick={() => {
                            setSelectedDocument(undefined);
                            setShowAddEditDialog(true);
                        }}
                    >
                        {translateString("createNew")}
                    </DotLegalButton>
                )}
            </Box>

            {showAddEditDialog && (
                <AddLegalEntityDocumentDialog
                    onCloseDialog={() => {
                        setShowAddEditDialog(false);
                        refetchDocuments();
                    }}
                    isGroupEntity={props.isGroupEntity}
                />
            )}

            {showDeleteDocumentDialog && (
                <DeleteLegalEntityDocumentDialog
                    selectedDocument={selectedDocument!}
                    onCloseDialog={() => {
                        setShowDeleteDocumentDialog(false);
                    }}
                    onSaveCorrect={() => {
                        setShowDeleteDocumentDialog(false);
                        refetchDocuments();
                    }}
                    legalEntityId={props.legalEntityId}
                />
            )}

            {showEditDialog && (
                <EditLegalEntityDocumentDialog
                    onCloseDialog={() => {
                        refetchDocuments();
                        setShowEditDialog(false);
                    }}
                    selectedDocument={selectedDocument!}
                    isExtended
                />
            )}
        </DotLegalPaper>
    );
}

export default LegalEntityDocumentTab;

function GetDocumentHeader(translateString: any, isGroupEntity: boolean) {
    const headers = Array<ITableHeader<LegalEntityDocumentRow>>();
    headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
    headers.push({ property: "fileName", text: translateString("document"), align: "left", showOnMobile: true });
    headers.push({ property: "documentType", text: translateString("type"), align: "left", showOnMobile: true });
    headers.push({ property: "status", text: translateString("status"), align: "left", showOnMobile: true });
    headers.push({ property: "owner", text: translateString("owner"), align: "left", showOnMobile: true });
    headers.push({ property: "version", text: translateString("version"), align: "left", showOnMobile: true });

    if (!isGroupEntity) {
        headers.push({ property: "groupEntities", text: translateString("groupEntities"), align: "left", showOnMobile: true });
    }

    headers.push({ property: "created", text: translateString("created"), align: "left", showOnMobile: true });

    return headers;
}
