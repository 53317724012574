import {
    DotLegalActionBar,
    DotLegalButton,
    DotLegalEmptyState,
    DotLegalMultiSelect,
    DotLegalOverflowMenu,
    DotLegalPageHeader,
    DotLegalTableWithControls,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import { Box, Grid, GridSize, styled, TableCell } from "@mui/material";
import React from "react";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import { useTranslation } from "../../localization/useTranslation";
import { usePoliciesDataMapping } from "./Policies.hooks";
import { PolicyRowModel } from "./Policies.types";
import PoliciesIcon from "./policiesIcon.svg?react";
import ProcessingActivitiesIcon from "../../system/systems/processingActivity.svg?react";
import { usePoliciesStyle } from "./Policies.styles";
import PolicyDialog from "../policyDialog/PolicyDialog";
import DeletePolicyDialog from "../deletePolicyDialog/DeletePolicyDialog";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { useNavigate } from "react-router-dom";
import { useUrlProvider } from "../../useUrlProvider";
import Rocket from "../../processingActivity/processingActivities/rocket.svg?react";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { WhiteLockIcon } from "../../common/icons";
import PlanDialogButtonWrapper from "../../plan/planDialogButtonWrapper/planDialogButtonWrapper";
import SystemSvg from "../../processingActivity/processingActivities/systems.svg?react";
import TrialUsageWarning from "../../common/components/TrialUsageWarning.tsx";
import TrialExpiredRowWrapper from "../../common/components/TrialExpiredRowWrapper.tsx";
import { EntityType } from "../../common/Common.types.ts";
import { useAddTaskMenuItem } from "../../common/components/addTaskMenuItem/AddTaskMenuItemContext.hooks.ts";

function Policies() {
    const styles = usePoliciesStyle();
    const navigate = useNavigate();

    const { translateString } = useTranslation();
    const { getPolicyUrl } = useUrlProvider();
    const { permissions, customerName } = useUserContext();
    const { platformFeatures } = usePlanContext();
    const addTaskMenuItem = useAddTaskMenuItem();
    const policyPermissions = permissions.policyPermissions;

    const {
        policyData,
        isLoading,
        hasPolicyData,
        showPolicyDialog,
        setShowPolicyDialog,
        setShowDeleteDialog,
        showDeleteDialog,
        selectedPolicy,
        setSelectedPolicy,
        getDefaultPolicy,
        searchedPolicyTypes,
        setSearchedPolicyTypes,
        policyTypeOptions,
        onRefetch,
        selectableResponsibles,
        isResponsiblesLoading,
        policyLimit,
    } = usePoliciesDataMapping();

    function getHeaders() {
        let headers = Array<ITableHeader<PolicyRowModel>>();
        headers.push({ property: "name", text: translateString("policy"), align: "left", showOnMobile: true });
        headers.push({ property: "type", text: translateString("type"), align: "left", showOnMobile: true });
        headers.push({ property: "responsibleId", text: translateString("responsible"), align: "left", showOnMobile: true });
        if (permissions.processingActivityPermissions.read) {
            headers.push({ property: "numberOfProcessingActivities", text: translateString("processingActivities"), align: "left", showOnMobile: true });
        }

        if (permissions.systemPermissions.read) {
            headers.push({ property: "numberOfSystems", text: translateString("systems"), align: "left", showOnMobile: true });
        }

        return headers;
    }

    const getMenuItems = (row: PolicyRowModel) => {
        let menuItems = [];

        if (policyPermissions.edit && !policyLimit?.limitExceeded) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    setSelectedPolicy({ id: row.id, name: row.name, type: row.type, responsibleId: row.responsibleId, tags: row.tags });
                    setShowPolicyDialog(true);
                },
            });
        }

        if (policyPermissions.delete) {
            menuItems.push({
                menuItem: translateString("delete"),
                onClick: () => {
                    setSelectedPolicy({ id: row.id, name: row.name, type: row.type, responsibleId: row.responsibleId, tags: row.tags });
                    setShowDeleteDialog(true);
                },
            });
        }

        if (!policyLimit?.limitExceeded) {
            addTaskMenuItem.addCreateTaskMenuItem(menuItems, {
                fromEntityId: row.id,
                fromEntityType: EntityType.Policy,
                toEntityType: EntityType.Task,
            });
        }

        return menuItems;
    };

    const CounterWrapper = styled(Box)(({ theme }) => ({
        display: "flex",
        alignItems: "center",
    }));

    const CounterTextBox = styled("span")(({ theme }) => ({
        marginLeft: theme.spacing(1),
        fontSize: 18,
        fontWeight: theme.typography.fontWeightBold,
    }));

    function getSearchField() {
        const gridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize } = {
            item: true,
            xs: 6,
            sm: 4,
            md: 2,
            lg: 2,
        };
        const noOptionsLabel = translateString("noOptions");

        return (
            <React.Fragment>
                <Grid {...gridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedPolicyTypes}
                        chosenOptions={searchedPolicyTypes}
                        label={translateString("type")}
                        placeholder={translateString("type")}
                        isLoading={isLoading}
                        options={policyTypeOptions}
                        noMargin
                        noOptionsLabel={noOptionsLabel}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <DotLegalPageHeader userContext={{ customerName }} breadCrumbs={[{ name: translateString("policiesAndProcedures") }]} />
            <DotLegalActionBar
                canCreate={policyPermissions.create}
                showDialog={showPolicyDialog}
                icon={<PoliciesIcon />}
                primaryButton={
                    <PlanDialogButtonWrapper
                        text={translateString("policyLimitReached", { count: platformFeatures.maxNumberOfPolicies })}
                        enabled={policyLimit?.limitReached ?? false}
                    >
                        <DotLegalButton
                            buttonType="primary"
                            size="medium"
                            onClick={() => {
                                setSelectedPolicy(getDefaultPolicy());
                                setShowPolicyDialog(true);
                            }}
                            disabled={policyLimit?.limitReached}
                            endIcon={policyLimit?.limitReached ? WhiteLockIcon : undefined}
                            toolTip={
                                policyLimit?.limitReached ? translateString("policyLimitReached", { count: platformFeatures.maxNumberOfPolicies }) : undefined
                            }
                        >
                            {translateString("createPolicy")}
                        </DotLegalButton>
                    </PlanDialogButtonWrapper>
                }
            >
                <Box></Box>
            </DotLegalActionBar>

            <DotLegalPaper>
                {hasPolicyData ? (
                    <>
                        {policyLimit?.limitExceeded && (
                            <TrialUsageWarning
                                textKey={"policiesLockedWarningBodyText"}
                                headerKey={"policiesLockedWarningHeader"}
                                values={{ count: platformFeatures.maxNumberOfPolicies }}
                                marginBottom={3}
                            />
                        )}
                        <DotLegalTableWithControls
                            headers={getHeaders()}
                            getUserSpecificPageLength={() => 30}
                            extraControls={getSearchField()}
                            hideRowsPerPage
                            defaultOrderBy={"name"}
                            defaultOrder={"asc"}
                            isLoading={isLoading}
                            emptyText={translateString("noPoliciesMatchesQuery")}
                            labelRowsPerPage={translateString("showEntites")}
                            labelSearch={translateString("search")}
                            noOptionsLabel={translateString("noOptions")}
                            paginationLabelOf={translateString("labelOf")}
                            data={policyData}
                            renderRow={(row) => (
                                <TrialExpiredRowWrapper
                                    key={row.id}
                                    tooltip={translateString("policyLockedMaxReached", { count: platformFeatures.maxNumberOfPolicies })}
                                    onNonExpiredClick={() => navigate(getPolicyUrl(row.id))}
                                    expired={policyLimit !== undefined && policyLimit.limitExceeded}
                                    planUpgradeText={translateString("policyLimitReached", { count: platformFeatures.maxNumberOfPolicies })}
                                >
                                    <TableCell sx={styles.bold}>{row.name}</TableCell>
                                    <TableCell>{row.typeString}</TableCell>
                                    <TableCell>{row.responsible}</TableCell>
                                    {permissions.processingActivityPermissions.read && (
                                        <TableCell>
                                            <CounterWrapper>
                                                <ProcessingActivitiesIcon />
                                                <CounterTextBox>{row.numberOfProcessingActivities}</CounterTextBox>
                                            </CounterWrapper>
                                        </TableCell>
                                    )}
                                    {permissions.systemPermissions.read && (
                                        <TableCell>
                                            <CounterWrapper>
                                                <SystemSvg />
                                                <CounterTextBox>{row.numberOfSystems}</CounterTextBox>
                                            </CounterWrapper>
                                        </TableCell>
                                    )}
                                    <TableCell align={"right"}>{policyPermissions.edit && <DotLegalOverflowMenu items={getMenuItems(row)} />}</TableCell>
                                </TrialExpiredRowWrapper>
                            )}
                        />
                    </>
                ) : (
                    <DotLegalEmptyState icon={<Rocket />} text={translateString("noPolicies")} />
                )}
            </DotLegalPaper>

            {showDeleteDialog && (
                <DeletePolicyDialog
                    closeDialog={() => setShowDeleteDialog(false)}
                    onDeleted={() => onRefetch()}
                    selectedPolicy={selectedPolicy}
                ></DeletePolicyDialog>
            )}

            {showPolicyDialog && (
                <PolicyDialog
                    onDialogClose={() => setShowPolicyDialog(false)}
                    onSave={() => onRefetch()}
                    selectedPolicy={selectedPolicy}
                    selectableResponsibles={selectableResponsibles}
                    isResponsiblesLoading={isResponsiblesLoading}
                ></PolicyDialog>
            )}
        </React.Fragment>
    );
}

export default Policies;
