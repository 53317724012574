import React from "react";
import { useTranslation } from "../../localization/useTranslation.ts";
import { DotLegalDialog, DotLegalSearchField, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { arrow } from "../../common/icons.tsx";
import { Box } from "@mui/material";
import DeleteAnnualWheelActivityTemplateDialog from "../deleteAnnualWheelActivityTemplateDialog/DeleteAnnualWheelActivityTemplateDialog.tsx";
import DotLegalTabs from "../../common/components/dotLegalTabs/DotLegalTabs.tsx";
import GdprTemplates from "../annualWheelDialog/templates/gdprTemplates/GdprTemplates.tsx";
import ISOTemplates from "../annualWheelDialog/templates/isoTemplates/IsoTemplates.tsx";
import ISO27002Templates from "../annualWheelDialog/templates/isoTemplates/iso27002Templates/Iso27002Templates.tsx";
import Nis2Templates from "../annualWheelDialog/templates/nis2Templates/Nis2Templates.tsx";
import { TabContent } from "../../common/components/dotLegalTabs/DotLegalTabs.types.ts";
import { getPlanTab } from "../../plan/planTab/PlanTab.tsx";
import CustomTemplates from "../annualWheelDialog/templates/customTemplates/CustomTemplates.tsx";
import { AnnualWheelFilterOption } from "../annualWheelDialog/AnnualWheelDialog.types.ts";
import { usePlanContext } from "../../auth/planProvider/PlanProvider.tsx";
import CustomTemplateAddEditDialog from "../customTemplateAddEditDialog/CustomTemplateAddEditDialog.tsx";
import { useTemplateAnnualWheelActivityDialog } from "./TemplateAnnualWheelActivityDialog.hooks.ts";
import { SelectedAnnualWheelActivityTemplate } from "../annualWheelAddEditDialog/AnnualWheelAddEditDialog.types.ts";
import { AnnualWheelActivityTemplateSaveModel } from "../customTemplateAddEditDialog/CustomTemplateAddEditDialog.types.ts";

export interface ITemplateAnnualWheelActivityDialogProps {
    onDialogClose: () => void;
    onNextClick: (selectedTemplate: SelectedAnnualWheelActivityTemplate) => void;
    onCustomTemplateAdded: (customTemplateModel: AnnualWheelActivityTemplateSaveModel) => void;
}

function TemplateAnnualWheelActivityDialog(props: ITemplateAnnualWheelActivityDialogProps) {
    const { translateString } = useTranslation();
    const { informationSecurityFeatures } = usePlanContext();
    const {
        isLoading,
        selectedActivity,
        searchInput,
        setSearchInput,
        gdprActivities,
        selectedTab,
        onChangeTab,
        customActiviites,
        refetch,
        hasCustomActivities,
        customTemplateIdForDeletion,
        setCustomTemplateIdForDeletion,
        copyCustomTemplate,
        nis2Templates,
        isoTemplates,
        onSelectActivity,
        selectedFilterOption,
        setSelectedFilterOption,
        onCustomTemplateEdit,
        onCustomTemplateCreate,
        onCustomTemplateDelete,
        showAddEditTemplateDialog,
        onAddEditCanceled,
        onCustomTemplateAdded,
        onCustomTemplateUpdated,
        showTemplateDialog,
        templateModel,
    } = useTemplateAnnualWheelActivityDialog(props);

    function getTabsContent() {
        let tabIndex = 0;
        const content = Array<TabContent>();

        content.push({
            content: getGDPRContent(),
            index: tabIndex,
            label: translateString("gdrpActvitities"),
        });

        content.push(getPlanTab(translateString("nis2Activtities"), getNIS2Content(), ++tabIndex, true, translateString("upgradeInfoSecPlan")));

        content.push(
            getPlanTab("ISO 27001", getISO27001Content(), ++tabIndex, informationSecurityFeatures.isoActivitiesCatalogue, translateString("upgradeInfoSecPlan"))
        );

        content.push(
            getPlanTab("ISO 27002", getISO27002Content(), ++tabIndex, informationSecurityFeatures.isoActivitiesCatalogue, translateString("upgradeInfoSecPlan"))
        );

        content.push({
            content: getCustomContent(),
            index: ++tabIndex,
            label: translateString("myActivities"),
        });

        return content;
    }

    function getDialogTabs() {
        return (
            <DotLegalTabs
                tabStyle={"secondary"}
                selectedTab={selectedTab}
                setSelectedTab={(x) => onChangeTab(x)}
                content={getTabsContent()}
                fullWidth
                ignoreTextTransformation
            ></DotLegalTabs>
        );
    }

    function getGDPRContent() {
        return (
            <GdprTemplates
                templates={gdprActivities}
                onSelectActivity={onSelectActivity}
                searchField={getSearchField()}
                selectedActivity={selectedActivity}
                additionalFilterField={getFilterField()}
            />
        );
    }

    function getISO27001Content() {
        return (
            <ISOTemplates
                templates={isoTemplates.iso27001Templates}
                onSelectActivity={onSelectActivity}
                searchField={getSearchField()}
                selectedActivity={selectedActivity}
                additionalFilterField={getFilterField()}
            />
        );
    }

    function getISO27002Content() {
        return (
            <ISO27002Templates
                iso27002Templates={isoTemplates.iso27002Templates}
                iso27002_2013Templates={isoTemplates.iso27002_2013Templates}
                onSelectActivity={onSelectActivity}
                searchField={getSearchField()}
                selectedActivity={selectedActivity}
                additionalFilterField={getFilterField()}
            />
        );
    }

    function getNIS2Content() {
        return (
            <Nis2Templates
                templateContainer={nis2Templates}
                selectedActivity={selectedActivity}
                additionalFilterField={getFilterField()}
                searchField={<DotLegalSearchField labelSearch={translateString("search")} useFullWidth={false} value={searchInput} onChange={setSearchInput} />}
                onSelectActivity={onSelectActivity}
            />
        );
    }

    function getCustomContent() {
        return (
            <CustomTemplates
                templates={customActiviites}
                onSelectActivity={onSelectActivity}
                searchField={getSearchField()}
                onCopyClick={(activity) => copyCustomTemplate({ id: activity.customTemplateId!, name: activity.name })}
                onDeleteClick={(activtyId) => {
                    onCustomTemplateDelete(activtyId);
                }}
                onEditClick={(activity) => {
                    onCustomTemplateEdit(activity);
                }}
                hasCustomTemplates={hasCustomActivities}
                selectedActivity={selectedActivity}
                onCreateClick={onCustomTemplateCreate}
                additionalFilterField={getFilterField()}
            />
        );
    }

    function getSearchField() {
        return (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <DotLegalSearchField labelSearch={translateString("search")} useFullWidth={false} value={searchInput} onChange={setSearchInput} />
            </div>
        );
    }

    function getFilterField() {
        return (
            <DotLegalSelect
                selectedItem={selectedFilterOption}
                options={[
                    { id: AnnualWheelFilterOption.All.toString(), name: translateString("all") },
                    { id: AnnualWheelFilterOption.Planned.toString(), name: translateString("planned") },
                    { id: AnnualWheelFilterOption.NotPlanned.toString(), name: translateString("notScheduled") },
                ]}
                onChange={(item) => setSelectedFilterOption(item!)}
                placeholder={""}
                label={""}
                noOptionsLabel={""}
                disableClearable
                noMargin
                width={"200px"}
            />
        );
    }

    return (
        <>
            <DotLegalDialog
                isLoading={isLoading}
                fullHeight
                size={"xl"}
                buttonRight
                buttonOkText={translateString("next")}
                onOkClick={() => props.onNextClick(selectedActivity!)}
                okButtonDisabled={!selectedActivity}
                onDialogClose={() => props.onDialogClose()}
                open={showTemplateDialog}
                buttonOkIcon={arrow}
            >
                <Box>{getDialogTabs()}</Box>
            </DotLegalDialog>

            {customTemplateIdForDeletion && (
                <DeleteAnnualWheelActivityTemplateDialog
                    onCloseDialog={() => setCustomTemplateIdForDeletion(undefined)}
                    onSaveCorrect={() => refetch()}
                    id={customTemplateIdForDeletion}
                    name={customActiviites.find((x) => x.customTemplateId === customTemplateIdForDeletion)!.name}
                ></DeleteAnnualWheelActivityTemplateDialog>
            )}
            {showAddEditTemplateDialog && (
                <CustomTemplateAddEditDialog
                    templateModel={templateModel}
                    onCancel={onAddEditCanceled}
                    onUpdated={onCustomTemplateUpdated}
                    onCreated={onCustomTemplateAdded}
                />
            )}
        </>
    );
}

export default TemplateAnnualWheelActivityDialog;
