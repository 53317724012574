export enum DotLegalDocumentType {
    Other = 1,
    DataProcessorAgreement = 2,
    TIA = 3,
    CTA = 4,
    CooperationAgreement = 5,
    ServiceAgreement = 6,
    PhDAgreement = 7,
    DisclosureAgreement = 8,
    JointDataControllerAgreement = 9,
    OtherAgreements = 10,
    QualityProjects = 11,
    Oversight = 12,
    ExceptForDataProcessingAgreement = 13,
    DataProcessingAgreementAsDataProcessor = 14,
    ConfidentialityAgreement = 15,
    SubProcessor = 16,
    Declaration = 17,
}

export enum DocumentStatus {
    draft = 1,
    active = 2,
    archived = 3,
}

export interface DocumentSaveModel {
    base64Data: string;
    fileName: string;
}

export interface DocumentViewModel {
    id: string;
    fileName: string;
    dataId: string;
}
