import DeclarationControlDialogContent from "../declarationControlDialogContent/DeclarationControlDialogContent";
import { IDeclarationControlDialogWrapper } from "../DeclarationControlDialogWrapper";
import useEditDeclarationControlDialogHooks from "./EditDeclarationControlDialog.hooks";

function EditDecarationControlDialog(props: IDeclarationControlDialogWrapper) {
    const { isSaving, onSaveClick, saveModel, validation, modelUpdater, isLoading } = useEditDeclarationControlDialogHooks(props);

    return (
        <DeclarationControlDialogContent
            modelUpdater={modelUpdater}
            {...props}
            isEdit
            onOkClick={onSaveClick}
            saveModel={saveModel}
            isLoading={isLoading}
            isSaving={isSaving}
            validation={validation}
        />
    );
}

export default EditDecarationControlDialog;
