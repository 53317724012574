import { useMutation, useQueryClient } from "react-query";
import { deleteHttp, put } from "../../../common/api/apiShared";
import { ProcessingActivityModel, Source } from "../../ProcessingActivity.types";
import { useTranslation } from "../../../localization/useTranslation";
import { ValidationError } from "../../../common/validationError";
import { useSelectableLegalEntityQuery } from "../../../legalEntity/useSelectableLegalEntityQuery";
import { useSelectableDataCategoriesQuery } from "../../hooks/useSelectableDataCategoriesQuery";
import { useSelectableDocumentsQuery } from "../../hooks/useSelectableDocumentQuery";
import { useResponsibleQuery } from "../../../user/hooks/useResponsibleQuery";
import { UserSelectableItem } from "../../../user/User.types";
import { ProcessingActivitySourcesPermissions } from "../../../auth/userContextProvider/UserContextProvider.types";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { useState } from "react";
import { AgreementSaveModel, DataProcessorAgreementSaveModel } from "../agreementDialog/DataProcessorAgreementDialog.types";
import { SelectedLegalEntityDocument } from "../../../legalEntity/legalEntityDocumentTab/LegalEntityDocumentTab.types";
import { useExpandedId } from "../../../common/hooks/useExpandedId";
import { useOptimisticUpdate } from "../../../common/hooks/useOptimisticUpdate";
import { useGroupEntitiesQuery } from "../../hooks/useGroupEntitiesQuery.ts";

export function useSourceMapping(selectedDataCategories: Array<string>, processingActivity: ProcessingActivityModel, sources: Array<Source>) {
    const { permissions } = useUserContext();
    const [dataProcessorAgreementSaveModel, setDataProcessorAgreementSaveModel] = useState<DataProcessorAgreementSaveModel | undefined>(undefined);
    const [sourceId, setSourceId] = useState("");
    const [selectedDocument, setSelectedDocument] = useState<SelectedLegalEntityDocument>();
    const [showEditDocumentDialog, setShowEditDocumentDialog] = useState(false);
    const { getExpandedId, setExpandedId } = useExpandedId();
    const optimisticUpdate = useOptimisticUpdate();
    const queryClient = useQueryClient();

    let queryKey = "processingActivity" + processingActivity.id;

    const sourcePermissions = permissions.processingActivitySourcesPermissions;

    let dataCategoriesQuery = useSelectableDataCategoriesQuery();
    let legalEntitiesQuery = useSelectableLegalEntityQuery();
    let documentsQuery = useSelectableDocumentsQuery(
        sourcePermissions.hasAgreementPermission,
        sources.find((x) => x.id === getExpandedId())?.dataControllerId ?? undefined,
        processingActivity.id
    );
    let responsibleQuery = useResponsibleQuery(sourcePermissions.hasResponsiblePermission);
    let groupEntityQuery = useGroupEntitiesQuery();

    let dataCategoryLoading = dataCategoriesQuery.isLoading;
    let legalEntityLoading = legalEntitiesQuery.isLoading;
    let groupEntityLoading = groupEntityQuery.isLoading;
    let responsibleLoading = responsibleQuery.isLoading;
    let documentsLoading = documentsQuery.isLoading;

    let legalEntityData = legalEntitiesQuery.data;
    let groupEntityData = groupEntityQuery.allGroupEntities;
    let documentsData = documentsQuery.disclosureDocuments;

    let dataCategoriesData;
    if (dataCategoriesQuery.allDataCategories) {
        dataCategoriesData = dataCategoriesQuery.allDataCategories!.filter(function (item) {
            return selectedDataCategories.includes(item.id);
        });
    }

    const removeAgreementMutation = useMutation(removeAgreementAPI, {
        onSuccess: (data) => {
            const processingActivity = data.value();
            queryClient.setQueryData(queryKey, processingActivity);
        },
    });
    const addAgreementMutation = useMutation(addAgreementAPI, {
        onSuccess: (data) => {
            const processingActivity = data.value();
            queryClient.setQueryData(queryKey, processingActivity);
        },
    });

    const onAddAgreement = async (agreementId: string, sourceId: string) => {
        const newProcessingActivityModel = { ...processingActivity };
        const source = newProcessingActivityModel.sources.find((d) => d.id === sourceId)!;

        source.agreementIds.push(agreementId);

        await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, addAgreementMutation.mutateAsync, {
            processingActivityId: processingActivity.id,
            sourceId: sourceId,
            saveModel: { agreementId: agreementId },
        });
    };

    const onDeleteAgreement = async (agreementId: string, sourceId: string) => {
        const newProcessingActivityModel = { ...processingActivity };
        const source = newProcessingActivityModel.sources.find((d) => d.id === sourceId)!;

        source.agreementIds = source.agreementIds.filter((x) => x !== agreementId);

        await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, removeAgreementMutation.mutateAsync, {
            processingActivityId: processingActivity.id,
            sourceId: sourceId,
            saveModel: { agreementId: agreementId },
        });
    };

    const onHasAgreementChange = async (hasAgreement: boolean, sourceId: string) => {
        const newProcessingActivityModel = { ...processingActivity };
        const source = newProcessingActivityModel.sources.find((d) => d.id === sourceId)!;

        source.hasDataProcessingAgreement = hasAgreement;
        source.agreementIds = [];

        await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, addAgreementMutation.mutateAsync, {
            processingActivityId: processingActivity.id,
            sourceId: sourceId,
            saveModel: { hasAgreement: hasAgreement },
        });
    };

    return {
        dataCategoryLoading,
        dataCategoriesData,
        legalEntityLoading,
        legalEntityData,
        responsibleLoading,
        groupEntityLoading,
        groupEntityData,
        documentsLoading,
        documentsData,
        getResponsibles: responsibleQuery.data,
        dataProcessorAgreementSaveModel,
        setDataProcessorAgreementSaveModel,
        sourceId,
        setSourceId,
        refetchDocuments: documentsQuery.refetch,
        selectedDocument,
        setSelectedDocument,
        showEditDocumentDialog,
        setShowEditDocumentDialog,
        getExpandedId,
        setExpandedId,
        onHasAgreementChange,
        onAddAgreement,
        onDeleteAgreement,
    };

    async function addAgreementAPI(data: { processingActivityId: string; sourceId: string; saveModel: AgreementSaveModel }) {
        return await put("/processingactivity/" + data.processingActivityId + "/source/" + data.sourceId + "/agreement", data.saveModel);
    }

    async function removeAgreementAPI(data: { processingActivityId: string; sourceId: string; saveModel: AgreementSaveModel }) {
        return await deleteHttp(`/processingactivity/${data.processingActivityId}/source/${data.sourceId}/agreement?agreementId=${data.saveModel.agreementId}`);
    }
}

export function useValidateSourceStep(permissions: ProcessingActivitySourcesPermissions) {
    const { translateString } = useTranslation();
    return (model: ProcessingActivityModel, responsibles: Array<UserSelectableItem> | undefined): Array<ValidationError> => {
        return getSourcesErrors(model, translateString, responsibles, permissions);
    };
}

function getSourcesErrors(
    model: ProcessingActivityModel,
    translateString: any,
    responsibles: Array<UserSelectableItem> | undefined,
    permissions: ProcessingActivitySourcesPermissions
) {
    const result: Array<ValidationError> = [];

    if (permissions) {
        if (!model.disableSources && !model.hasSources) {
            result.push(new ValidationError("noDataSharingsSources", translateString("atLeastOneSharingRequired")));
        }

        model.sources.forEach((source, index) => {
            if (!source.dataControllerId) {
                result.push(new ValidationError(`${index}legalEntityId`, translateString("noControllerError")));
            }
            if (permissions.hasDataCategoriesPermission && source.dataCategories.length === 0) {
                result.push(new ValidationError(`${index}dataCategories`, translateString("dataCategoriesRequired")));
            }
            if (source.groupEntities.length === 0) {
                result.push(new ValidationError(`${index}companies`, translateString("noCompaniesForTransferChosenError")));
            }
            if (source.groupEntities.some((l) => l === source.dataControllerId)) {
                result.push(new ValidationError(`${index}legalEntityId`, translateString("dataControllerCantBeInCompanies")));
            }

            if (permissions.hasResponsiblePermission) {
                let user = responsibles?.find((x) => x.id === source.responsible);
                if (user && !user.active) {
                    result.push(new ValidationError(`${index}inactiveUser`, translateString("userDeactivated")));
                }
            }
        });
    }

    return result;
}
