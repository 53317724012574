import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { Box } from "@mui/material";
import { useAnnualWheelResponsibleForm } from "./AnnualWheelResponsibleForm.hooks";
import { useAnnualWheelDialogStyles } from "../AnnualWheelDialog.styles";
import { AnnualWheelResponsibleFormModel } from "./AnnualWheelResponsibleForm.types";
import { DotLegalHeader, DotLegalMultiSelect, DotLegalSelect, useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";
import { ApprovalOption } from "../../AnnualWheel.types";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import { PurpleLockIcon } from "../../../common/icons";
import PlanInputFieldWrapper from "../../../plan/planInputFieldWrapper/PlanInputFieldWrapper";
import AnnualWheelGroupEntitySelector from "../AnnualWheelGroupEntitySelector/AnnualWheelGroupEntitySelector.tsx";

export interface AnnualWheelResponsibleFormProps {
    saveModel: AnnualWheelResponsibleFormModel | undefined;
    onChange: (saveModel: AnnualWheelResponsibleFormModel) => void;
    showError: boolean;
}

function AnnualWheelResponsibleForm(props: AnnualWheelResponsibleFormProps) {
    const { translateString } = useTranslation();
    const { platformFeatures } = usePlanContext();

    const isUseOnSmallScreen = useIsOnSmallScreen();
    const {
        selectableBusinessAreaLoading,
        selectableBusinessAreaData,
        usersIsLoading,
        selectableResponsibles,
        selectableAssignees,
        onBusinessAreaChange,
        onResponsibleChange,
        onApprovalChange,
        onAssigneeChange,
        onGroupCompaniesChange,
        onGroupCompanyResponsibleChange,
    } = useAnnualWheelResponsibleForm(props);

    const styles = useAnnualWheelDialogStyles(isUseOnSmallScreen);

    const getErrorMessage = (error: string) => {
        if (error) return <Box sx={styles.error}>{error}</Box>;

        return undefined;
    };

    const isSignOffEnabled =
        props.saveModel?.responsible !== undefined ||
        (props.saveModel?.allCompaniesHasUsers(props.saveModel.groupCompanies ?? []) && props.saveModel.groupCompanies.length > 0);

    let assigneesPlaceholder =
        props.saveModel && selectableResponsibles && props.saveModel.responsible && props.saveModel.assignees.length === 0
            ? selectableResponsibles.find((x) => x.id === props.saveModel!.responsible)?.name
            : "";

    return (
        <Box>
            <DotLegalHeader headerStyle={"small"} marginTop={2} marginBottom={0.5}>
                {translateString("businessAreasForTask")}
            </DotLegalHeader>

            <DotLegalMultiSelect
                label={""}
                options={selectableBusinessAreaData}
                isLoading={selectableBusinessAreaLoading}
                chosenOptions={props.saveModel?.businessAreas ?? []}
                placeholder={""}
                onChange={(businessAreas) => onBusinessAreaChange(businessAreas)}
                noMargin
                noOptionsLabel={translateString("noOptions")}
            />
            {getErrorMessage(props.showError && props.saveModel?.IsModelInvalid() ? translateString("annualWheelDataTaskResponsibleError") : "")}

            <DotLegalHeader headerStyle={"small"} marginTop={4} marginBottom={0.5} toolTip={translateString("responsibleForTaskTooltip")}>
                {translateString("responsibleForTask")}
            </DotLegalHeader>

            <DotLegalSelect
                options={selectableResponsibles}
                isLoading={usersIsLoading}
                selectedItem={props.saveModel?.responsible}
                placeholder={""}
                label={""}
                onChange={(responsible) => onResponsibleChange(responsible)}
                noMargin
                noOptionsLabel={translateString("noOptions")}
            />
            {getErrorMessage(props.showError && props.saveModel?.IsModelInvalid() ? translateString("annualWheelDataTaskResponsibleError") : "")}

            <DotLegalHeader headerStyle={"small"} marginTop={4} marginBottom={0.5}>
                {translateString("annualWheelActivityAssignee")}
            </DotLegalHeader>

            <DotLegalMultiSelect
                label={""}
                options={selectableAssignees}
                isLoading={usersIsLoading}
                chosenOptions={props.saveModel?.assignees ?? []}
                placeholder={assigneesPlaceholder}
                onChange={(assigness) => onAssigneeChange(assigness)}
                noMargin
                noOptionsLabel={translateString("noOptions")}
            />

            <DotLegalHeader headerStyle={"small"} marginTop={4} marginBottom={0.5}>
                {translateString("doesTasksNeedApproval")}
            </DotLegalHeader>

            <PlanInputFieldWrapper hasAccess={platformFeatures.signOff} hoverText={translateString("upgradePrivacyPlanToday")}>
                <DotLegalSelect
                    options={[
                        {
                            id: ApprovalOption.ApprovalNotRequired.toString(),
                            name: translateString("approvalNotRequired"),
                        },
                        {
                            id: ApprovalOption.ApprovalNotRequiredResponsibleGetNotified.toString(),
                            name: translateString("approvalNotRequiredResponsibleGetNotified"),
                            disabled: !isSignOffEnabled,
                            hoverText: !isSignOffEnabled ? translateString("responsibleRequiredForApprovalTooltip") : undefined,
                        },
                        {
                            id: ApprovalOption.ApprovalRequiredByResponsible.toString(),
                            name: translateString("approvalRequiredByResponsible"),
                            disabled: !isSignOffEnabled,
                            hoverText: !isSignOffEnabled ? translateString("responsibleRequiredForApprovalTooltip") : undefined,
                        },
                    ]}
                    isLoading={usersIsLoading}
                    selectedItem={props.saveModel?.approval.toString()}
                    placeholder={""}
                    label={""}
                    onChange={(option) => onApprovalChange(option!)}
                    noMargin
                    noOptionsLabel={translateString("noOptions")}
                    disableClearable
                    disableSearch
                    disabled={!platformFeatures.signOff}
                    icon={!platformFeatures.signOff ? PurpleLockIcon : undefined}
                />
            </PlanInputFieldWrapper>

            <AnnualWheelGroupEntitySelector
                selectedUserGroupCompanies={props.saveModel?.groupCompanies}
                assignees={props.saveModel?.assignees}
                responsible={props.saveModel?.responsible}
                onGroupCompaniesChange={onGroupCompaniesChange}
                onGroupCompanyResponsibleChange={onGroupCompanyResponsibleChange}
                showError={props.showError && props.saveModel?.IsModelInvalid()}
                enableResponsibleColumn
                explanation={translateString("globalAnnualWheelActivityTooltip")}
            />
        </Box>
    );
}

export default AnnualWheelResponsibleForm;
