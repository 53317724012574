import { createElement, useRef, useState } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { post } from "../../common/api/apiShared";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { ICopySystemDialogProps } from "./CopySystemDialog";
import { CopySystem } from "./CopySystemDialog.types";
import { useValidator } from "../../common/hooks/validation";

export function useCopySystem(props: ICopySystemDialogProps) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [inProgress, setInProgress] = useState(false);
    const [copyModel, setCopyModel] = useState<CopySystem>({ id: props.copyModel.id, name: `${translateString("copySingular")} ${props.copyModel.name}` });

    const validation = useValidator<CopySystem>(
        (validators) => ({
            name: validators.validateNotEmpty((item) => item.name, translateString("name")),
        }),
        [copyModel]
    );
    validation.check(copyModel);

    const saveMutation = useMutation(copy, {
        onSuccess: (response: any) => {
            props.onSave();
            snackbar.show(createElement(Trans, { i18nKey: "copiedSuccessfully", values: { name: props.copyModel.name, newName: copyModel.name } }));
        },
    });

    const copySystem = async () => {
        await saveMutation.mutateAsync();
    };

    function copy() {
        setInProgress(true);
        return post<{}>(`/system/${copyModel.id}/copy`, copyModel.name);
    }

    return {
        inProgress,
        setInProgress,
        copySystem,
        copyModel,
        setCopyModel,
        validation,
    };
}
