import { RelatedTasksTabProps } from "./RelatedTasksTab.tsx";
import { useMutation, useQuery } from "react-query";
import { deleteHttp, get } from "../../common/api/apiShared.ts";
import { RelatedTasksTableModel, RelatedTaskViewModel } from "./RelatedTasksTab.types.ts";
import { useState } from "react";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation.ts";
import { AnnualWheelMonthEnum } from "../../annualWheel/AnnualWheel.types.ts";
import { getRawStateString } from "../editTaskDialog/masterDataSection/MasterDataSection.hooks.ts";
import { useAddTaskMenuItem } from "../../common/components/addTaskMenuItem/AddTaskMenuItemContext.hooks.ts";
import { isNullOrWhitespace, setFirstLetterToLowerCase } from "../../common/stringOperations.ts";
import { EntityType } from "../../common/Common.types.ts";

export function useRelatedTasksTab(props: RelatedTasksTabProps) {
    const snackbar = useDotLegalSnackbar();
    const { translateString, translateEntityType } = useTranslation();
    const context = useAddTaskMenuItem();

    const [selectedRelationToRemove, setSelectedRelationToRemove] = useState<RelatedTasksTableModel | undefined>(undefined);
    const [selectedTask, setSelectedTask] = useState<string | undefined>(undefined);

    const url = isNullOrWhitespace(props.customApiUrl ?? null) ? `/relations/${props.forEntityId}/tasks` : props.customApiUrl!;
    const { isLoading, data, refetch } = useQuery(url, () => get<Array<RelatedTaskViewModel>>(url));
    if (context.registerRefetchFunc) {
        context.registerRefetchFunc(refetch);
    }

    const removeMutation = useMutation(deleteApi);
    const removeRelation = async (relationId: string) => {
        await removeMutation.mutateAsync(relationId, {
            onSuccess: () => {
                refetch();
                snackbar.show(translateString("relationRemoved"), "success");
                setSelectedRelationToRemove(undefined);
            },
        });
    };

    return {
        isLoading,
        refetchTasks: refetch,
        data: convertRowsToTableModel(data ?? []),
        selectedRelationToRemove,
        setSelectedRelationToRemove,
        removeRelation,
        selectedTask,
        setSelectedTask,
    };

    async function deleteApi(relationId: string) {
        return await deleteHttp(`/relations/${relationId}`);
    }

    function convertRowsToTableModel(rows: Array<RelatedTaskViewModel>) {
        return rows.map((x) => {
            return {
                ...x,
                deadlineString: `${translateString(AnnualWheelMonthEnum[new Date(x.deadline).getMonth() + 1].toLocaleLowerCase())} ${new Date(x.deadline).getFullYear()}`,
                stateString: getRawStateString(x.state, translateString),
                entityTypeString: x.additionalEntityInfo ? translateEntityType(EntityType[x.additionalEntityInfo.entityType].toString()) : undefined,
                entityName: x.additionalEntityInfo ? x.additionalEntityInfo.additionalEntityName : undefined,
            };
        });
    }
}
