import { useEffect, useState } from "react";
import { useOptimisticUpdate } from "../../../hooks/useOptimisticUpdate.ts";
import { usePlanContext } from "../../../../auth/planProvider/PlanProvider.tsx";
import { isNullOrWhitespace } from "../../../stringOperations.ts";
import { useMutation, useQuery } from "react-query";
import { deleteHttp, get, post } from "../../../api/apiShared.ts";
import { TagRelationCreateModel } from "../TagsMultiSelect.types.ts";
import { TagsMultiSelectStandAloneProps } from "./TagsMultiSelectAutoUpdater.tsx";

export function useTagsMultiSelectAutoUpdater(props: TagsMultiSelectStandAloneProps) {
    const [selectedTags, setSelectedTags] = useState<Array<string>>([]);

    const { putOnQueueAndSetQueryData } = useOptimisticUpdate();
    const { platformFeatures } = usePlanContext();
    const enableTagRelationsQuery = !isNullOrWhitespace(props.entityId) && platformFeatures.tags;

    const tagRelationUrl = `/tagRelation/${props.entityId}`;
    const { isLoading, data } = useQuery(tagRelationUrl, () => get<Array<string>>(tagRelationUrl), { enabled: enableTagRelationsQuery });

    useEffect(() => {
        if (data) {
            setSelectedTags(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const addTagRelationMutation = useMutation(addTagRelationApi);
    const onAddTagRelation = async (tagId: string) => {
        const newRelation = createNewRelation(tagId);
        let tempTags = [...selectedTags];
        tempTags.push(tagId);
        putOnQueueAndSetQueryData(tempTags, tagRelationUrl, addTagRelationMutation.mutateAsync, newRelation);
    };

    function createNewRelation(tagId: string): TagRelationCreateModel {
        return { tagId: tagId, entityId: props.entityId!, entityType: props.entityType };
    }

    const removeTagRelationMutation = useMutation(removeTagRelationApi);
    const onRemoveTagRelation = async (tagId: string) => {
        let tempRelations = [...selectedTags];
        tempRelations = tempRelations.filter((x) => x !== tagId);
        putOnQueueAndSetQueryData(tempRelations, tagRelationUrl, removeTagRelationMutation.mutateAsync, { entityId: props.entityId!, tagId: tagId });
    };

    const removeAllRelationsFromEntityMutation = useMutation(removeRelationsFromEntityApi);
    const onRemoveAllRelationsFromEntity = async () => {
        removeAllRelationsFromEntityMutation.mutateAsync(props.entityId, {
            onSuccess: () => {
                setSelectedTags([]);
            },
        });
    };

    return {
        selectedTags,
        onAddTagRelation,
        onRemoveTagRelation,
        isLoading,
        onRemoveAllRelationsFromEntity,
    };

    async function addTagRelationApi(tagRelation: TagRelationCreateModel) {
        return await post("/tagRelation", tagRelation);
    }

    async function removeTagRelationApi(data: { entityId: string; tagId: string }) {
        return await deleteHttp(`/tagRelation/${data.entityId}?tagId=${data.tagId}`);
    }

    async function removeRelationsFromEntityApi(entityId: string) {
        return await deleteHttp(`/tagRelation/${entityId}/remove-relations`);
    }
}
