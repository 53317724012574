import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { ProcessingActivityJointDataControllerPermissions } from "../../../auth/userContextProvider/UserContextProvider.types";
import { deleteHttp, put } from "../../../common/api/apiShared";
import { useExpandedId } from "../../../common/hooks/useExpandedId";
import { ValidationError } from "../../../common/validationError";
import { useSelectableLegalEntityQuery } from "../../../legalEntity/useSelectableLegalEntityQuery";
import { useTranslation } from "../../../localization/useTranslation";
import { useResponsibleQuery } from "../../../user/hooks/useResponsibleQuery";
import { UserSelectableItem } from "../../../user/User.types";
import { useSelectableDataCategoriesQuery } from "../../hooks/useSelectableDataCategoriesQuery";
import { useSelectableDocumentsQuery } from "../../hooks/useSelectableDocumentQuery";
import { JointDataController, ProcessingActivityModel } from "../../ProcessingActivity.types";
import { AgreementSaveModel, DataProcessorAgreementSaveModel } from "../agreementDialog/DataProcessorAgreementDialog.types";
import { SelectedLegalEntityDocument } from "../../../legalEntity/legalEntityDocumentTab/LegalEntityDocumentTab.types";
import { useOptimisticUpdate } from "../../../common/hooks/useOptimisticUpdate";
import { useGroupEntitiesQuery } from "../../hooks/useGroupEntitiesQuery.ts";

export function useJointDataControllerStep(processingActivity: ProcessingActivityModel, jointDataControllers: Array<JointDataController>) {
    const { permissions } = useUserContext();

    const optimisticUpdate = useOptimisticUpdate();
    const queryClient = useQueryClient();

    let queryKey = "processingActivity" + processingActivity.id;

    const jointDataControllerPermissions = permissions.processingActivityJointDataControllerPermissions;

    const [agreementSaveModel, setAgreementSaveModel] = useState<DataProcessorAgreementSaveModel | undefined>(undefined);
    const [jointDataControllerId, setJointDataControllerId] = useState("");
    const { getExpandedId, setExpandedId } = useExpandedId();
    const [selectedDocument, setSelectedDocument] = useState<SelectedLegalEntityDocument>();
    const [showEditDocumentDialog, setShowEditDocumentDialog] = useState(false);

    let documentsQuery = useSelectableDocumentsQuery(
        jointDataControllerPermissions.hasAgreementPermission,
        jointDataControllers.find((x) => x.id === getExpandedId())?.dataControllerId ?? undefined,
        processingActivity.id
    );
    let responsibleQuery = useResponsibleQuery(jointDataControllerPermissions.hasResponsiblePermission);
    let dataCategoriesQuery = useSelectableDataCategoriesQuery();
    let legalEntitiesQuery = useSelectableLegalEntityQuery();
    let groupEntityQuery = useGroupEntitiesQuery();

    let dataCategoriesData;
    if (dataCategoriesQuery.allDataCategories) {
        dataCategoriesData = dataCategoriesQuery.allDataCategories!.filter((item) =>
            processingActivity.dataCategories.some((dc) => dc.dataCategoryId === item.id)
        );
    }

    let documentsLoading = documentsQuery.isLoading;
    let documentsData = documentsQuery.jointDataControllerDocuments;

    let groupEntityLoading = groupEntityQuery.isLoading;
    let groupEntityData = groupEntityQuery.allGroupEntities;

    let legalEntityLoading = legalEntitiesQuery.isLoading;
    let legalEntityData = legalEntitiesQuery.data;

    let responsibleLoading = responsibleQuery.isLoading;
    let responsibleData = responsibleQuery.data;

    const removeAgreementMutation = useMutation(removeAgreementAPI, {
        onSuccess: (data) => {
            const processingActivity = data.value();
            queryClient.setQueryData(queryKey, processingActivity);
        },
    });
    const addAgreementMutation = useMutation(addAgreementAPI, {
        onSuccess: (data) => {
            const processingActivity = data.value();
            queryClient.setQueryData(queryKey, processingActivity);
        },
    });

    const onAddAgreement = async (agreementId: string, jointDataControllerId: string) => {
        const newProcessingActivityModel = { ...processingActivity };
        const jointDataController = newProcessingActivityModel.jointDataControllers.find((d) => d.id === jointDataControllerId)!;

        jointDataController.documents.push(agreementId);

        await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, addAgreementMutation.mutateAsync, {
            processingActivityId: processingActivity.id,
            jointDataControllerId: jointDataControllerId,
            saveModel: { agreementId: agreementId },
        });
    };

    const onDeleteAgreement = async (agreementId: string, jointDataControllerId: string) => {
        const newProcessingActivityModel = { ...processingActivity };
        const jointDataController = newProcessingActivityModel.jointDataControllers.find((d) => d.id === jointDataControllerId)!;

        jointDataController.documents = jointDataController.documents.filter((x) => x !== agreementId);

        await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, removeAgreementMutation.mutateAsync, {
            processingActivityId: processingActivity.id,
            jointDataControllerId: jointDataControllerId,
            saveModel: { agreementId: agreementId },
        });
    };

    const onHasAgreementChange = async (hasAgreement: boolean, jointDataControllerId: string) => {
        const newProcessingActivityModel = { ...processingActivity };
        const jointDataController = newProcessingActivityModel.jointDataControllers.find((d) => d.id === jointDataControllerId)!;

        jointDataController.hasAgreement = hasAgreement;
        jointDataController.documents = [];

        await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, addAgreementMutation.mutateAsync, {
            processingActivityId: processingActivity.id,
            jointDataControllerId: jointDataControllerId,
            saveModel: { hasAgreement: hasAgreement },
        });
    };

    return {
        getExpandedId,
        setExpandedId,
        legalEntityData,
        legalEntityLoading,
        responsibleLoading,
        responsibleData,
        dataCategoriesData,
        groupEntityLoading,
        groupEntityData,
        jointDataControllerId,
        setJointDataControllerId,
        agreementSaveModel,
        setAgreementSaveModel,
        documentsLoading,
        documentsData,
        refetchDocuments: documentsQuery.refetch,
        selectedDocument,
        setSelectedDocument,
        showEditDocumentDialog,
        setShowEditDocumentDialog,
        onAddAgreement,
        onDeleteAgreement,
        onHasAgreementChange,
    };

    async function addAgreementAPI(data: { processingActivityId: string; jointDataControllerId: string; saveModel: AgreementSaveModel }) {
        return await put(
            "/processingactivity/" + data.processingActivityId + "/jointDataController/" + data.jointDataControllerId + "/agreement",
            data.saveModel
        );
    }

    async function removeAgreementAPI(data: { processingActivityId: string; jointDataControllerId: string; saveModel: AgreementSaveModel }) {
        return await deleteHttp(
            `/processingactivity/${data.processingActivityId}/jointDataController/${data.jointDataControllerId}/agreement?agreementId=${data.saveModel.agreementId}`
        );
    }
}

export function useValidateJointDataControllerStep(permissions: ProcessingActivityJointDataControllerPermissions) {
    const { translateString } = useTranslation();
    return (model: ProcessingActivityModel, responsibles: Array<UserSelectableItem> | undefined): Array<ValidationError> => {
        return getJointDataControllersErrors(model, translateString, responsibles, permissions);
    };
}

function getJointDataControllersErrors(
    model: ProcessingActivityModel,
    translateString: any,
    responsibles: Array<UserSelectableItem> | undefined,
    permissions: ProcessingActivityJointDataControllerPermissions
) {
    const result: Array<ValidationError> = [];

    if (permissions) {
        if (!model.disableJointDataControllers && !model.hasJointDataControllers) {
            result.push(new ValidationError("noDataSharingsJointDataControllers", translateString("atLeastOneSharingRequired")));
        }

        model.jointDataControllers.forEach((jointDataController, index) => {
            if (!jointDataController.dataControllerId) {
                result.push(new ValidationError(`${index}legalEntityId`, translateString("noControllerError")));
            }
            if (permissions.hasDataCategoriesPermission && jointDataController.dataCategories.length === 0) {
                result.push(new ValidationError(`${index}dataCategories`, translateString("dataCategoriesRequired")));
            }
            if (jointDataController.legalEntities.length === 0) {
                result.push(new ValidationError(`${index}companies`, translateString("noCompaniesForTransferChosenError")));
            }
            if (jointDataController.legalEntities.some((l) => l === jointDataController.dataControllerId)) {
                result.push(new ValidationError(`${index}legalEntityId`, translateString("dataControllerCantBeInCompanies")));
            }

            if (permissions.hasResponsiblePermission) {
                let user = responsibles?.find((x) => x.id === jointDataController.responsible);
                if (user && !user.active) {
                    result.push(new ValidationError(`${index}inactiveUser`, translateString("userDeactivated")));
                }
            }
        });
    }

    return result;
}
