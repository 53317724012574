import AddDeclarationControlDialog from "./addDeclarationControlDialog/AddDeclarationControlDialog";
import EditDecarationControlDialog from "./editDeclarationControlDialog/EditDeclarationControlDialog";

export interface IDeclarationControlDialogWrapper {
    onDialogClose: () => void;
    deadline: Date;
    declarationId: string;
    onControlSaved: () => void;
    declarationControlId?: string;
    isLocked?: boolean;
}

function DeclarationControlDialogWrapper(props: IDeclarationControlDialogWrapper) {
    return <>{props.declarationControlId ? <EditDecarationControlDialog {...props} /> : <AddDeclarationControlDialog {...props} />}</>;
}

export default DeclarationControlDialogWrapper;
