export interface RoleMasterDataViewModel {
    id: string;
    name: string;
    description: string;
    canOnlyAccessDataWhereResponsible: boolean;
    isStandardRole: boolean;
    canBeChosenAsResponsible: boolean;
}

export interface Permission {
    type: PermissionType;
    level: PermissionLevel | null;
}

export enum PermissionLevel {
    Read = 1,
    Edit = 2,
    Create = 3,
    Delete = 4,
}

export enum PermissionType {
    ProcessingActivityMasterData = 1,
    UserManagement = 3,
    CompanyManagement = 4,
    CustomerManagement = 5,
    GroupCompanyManagement = 6,
    MasterDataManagement = 7,
    CanChangeUserLoginProvider = 8,
    StandardDataManagement = 9,
    AnnualWheelManagement = 10,
    DefaultNotificationReceiver = 11,
    CanAccessAllData = 12,
    ProcessingActivityMasterDataPurpose = 16,
    ProcessingActivityDataSubject = 17,
    ProcessingActivityDataSubjectNumberOfProcessors = 18,
    ProcessingActivityDataSubjectSecurityMeasures = 19,
    ProcessingActivityAssociation = 20,
    ProcessingActivityAssociationGroupEntities = 21,
    ProcessingActivityDataCategories = 22,
    ProcessingActivityLegalBases = 23,
    ProcessingActivityLegalBasisLegalBases = 24,
    ProcessingActivityLegalBasisDeletionPeriods = 25,
    ProcessingActivitySystems = 26,
    ProcessingActivityDataProcessors = 27,
    ProcessingActivityDataProcessorsDataProcessorAgreement = 28,
    ProcessingActivityDataProcessorsTiaAndTransferBasis = 29,
    ProcessingActivityDataProcessorsResponsible = 30,
    ProcessingActivityDataProcessorsSubProcessors = 31,
    ProcessingActivityDisclosures = 32,
    ProcessingActivityDisclosuresAgreement = 33,
    ProcessingActivityDisclosuresTransferBasis = 34,
    ProcessingActivityDisclosuresBasisOfDisclosure = 35,
    ProcessingActivityDisclosuresResponsible = 36,
    ProcessingActivitySources = 37,
    ProcessingActivitySourcesAgreement = 38,
    ProcessingActivitySourcesResponsible = 39,
    ProcessingActivityDataControllers = 40,
    ProcessingActivityDataControllersDataProcessorAgreement = 41,
    ProcessingActivityDataControllersResponsible = 42,
    ProcessingActivityDataControllersSubProcessors = 43,
    Record301 = 44,
    Record302 = 45,
    ProcessingActivityManagement = 46,
    SystemManagement = 47,
    SystemDescription = 48,
    SystemAssociation = 49,
    SystemDataProcessor = 50,
    SystemInformation = 51,
    RiskManagement = 52,
    RiskTemplateManagement = 53,
    TaskManagement = 54,
    CustomListManagement = 55,
    RoleManagement = 56,
    CanBeChosenAsResponsible = 57,
    CanValidateProcessingActivities = 58,
    SystemAssociationSystemType = 59,
    SystemDataProcessorDataProcessorAgreement = 60,
    SystemDataProcessorStorageLocaion = 61,
    SystemDataProcessorSupportAccess = 62,
    SystemDataProcessorSubProcessors = 63,
    SystemInformationSecurityMeasures = 64,
    SystemDataProcessorTiaAndTransferBasis = 65,
    StandardTemplateProcessingActivityManagement = 66,
    StandardTemplateSystemManagement = 67,
    CompanyDocumentationManagement = 68,
    PolicyManagement = 69,
    ProcessingActivityPolicies = 70,
    ProcessingActivityStatus = 71,
    ProcessingActivityComments = 72,
    ProcessingActivityJointDataControllers = 73,
    ProcessingActivityJointDataControllersAgreement = 74,
    ProcessingActivityJointDataControllersResponsible = 75,
    ProcessingActivityDraftStatusManagement = 76,
    ProcessingActivtyNote = 77,
    ProcessingActivityDataControllersTiaAndTransferBasis = 78,
    ProcessingActivityDataSubjectNumberOfDataSubjects = 79,
    CanAccessAuditLog = 80,
    ProcessingActivityDataProcessorsDataCategories = 81,
    ProcessingActivityDisclosuresDataCategories = 82,
    ProcessingActivitySourcesDataCategories = 83,
    ProcessingActivityDataControllersDataCategories = 84,
    ProcessingActivityJointDataControllersDataCategories = 85,
    ProcessingActivityStatistic = 86,
    DocumentStatus = 87,
    DeleteDocumentVersion = 88,
    SystemPolicies = 89,
    SystemDataAndStorage = 90,
    SystemDataAndStorageStorageLocaion = 91,
    SystemDataAndStorageSupportAccess = 92,
    GdprIncidentLog = 93,
    RiskVersionManagement = 94,
    ProcessingActivityAssociationOwnership = 95,
    ProcessingActivityAssociationsBusinessAreas = 96,
    TaskMasterDataManagement = 97,
    SystemPersonalData = 98,
    SystemAssociatedAssets = 99,
    ProcessingActivityDataSubjectDataSource = 100,
    ProcessingActivityCopy = 101,
    InternalAssessment = 102,
    DeclarationManagement = 103,
    DeclarationAuditorApproval = 104,
    AuditGroups = 105,
    SystemAI = 106,
    ProcessingActivityDataSubjectNumberOfProcessorsOnlyCustomValue = 107,
    ProcessingActivityDataSubjectNumberOfDataSubjectsOnlyCustomValue = 108,
    Tags = 109,
    Contracts = 110,
}
