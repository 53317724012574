import { useMutation, useQuery } from "react-query";
import { deleteHttp, get } from "../../../common/api/apiShared";
import { DocumentType } from "./DocumentTypes.types";
import { useMasterDataBreadCrumbProvider } from "../../useMasterDataBreadCrumbProvider";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { useTranslation } from "../../../localization/useTranslation";
import { createElement, useState } from "react";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";

export default function useDocumentTypesHooks() {
    const url = "/DocumentTypes";
    const { isLoading, data: documentTypes, refetch } = useQuery(url, () => get<Array<DocumentType>>(url));
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const { translateString } = useTranslation();
    const [documentTypeForDeletion, setDocumentTypeForDeletion] = useState<DocumentType | undefined>(undefined);
    const [documentTypeForUpdate, setDocumentTypeForUpdate] = useState<DocumentType | undefined>();
    const snackBar = useDotLegalSnackbar();

    const { permissions } = useUserContext();
    const masterDataBreadCrumbProvider = useMasterDataBreadCrumbProvider();
    const breadCrumbs = masterDataBreadCrumbProvider.getBreadCrumbs(translateString("documentTypes"));

    const deleteDataType = async (id: string) => {
        return await deleteHttp(`/documentTypes/${id}`);
    };

    const deleteMutation = useMutation(deleteDataType, {
        onSuccess: async (result) => {
            if (result.success()) {
                await refetch();
                snackBar.show(createElement(Trans, { i18nKey: "itemDeletedSuccess", values: { name: documentTypeForDeletion?.name } }));
            } else {
                snackBar.show(createElement(Trans, { i18nKey: "cantDocumentType", values: { name: documentTypeForDeletion?.name } }), "warning");
            }
            setDocumentTypeForDeletion(undefined);
        },
    });

    const onCreateClick = () => {
        setShowCreateDialog(true);
    };

    const onUpdateClick = (documentType: DocumentType) => {
        setDocumentTypeForUpdate(documentType);
    };

    const onSaved = async () => {
        await refetch();
        onDialogClose();
    };

    const onDialogClose = () => {
        setDocumentTypeForUpdate(undefined);
        setShowCreateDialog(false);
    };

    const onDelete = () => {
        deleteMutation.mutate(documentTypeForDeletion!.id);
    };

    const getMenuItems = (documentType: DocumentType) => {
        let menuItems = [];
        if (permissions.canManageMasterData) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    setDocumentTypeForUpdate(documentType);
                },
            });

            menuItems.push({
                menuItem: translateString("delete"),
                onClick: () => {
                    setDocumentTypeForDeletion(documentType);
                },
            });
        }

        return menuItems;
    };

    return {
        isLoading,
        documentTypes,
        breadCrumbs,
        getMenuItems,
        showCreateDialog,
        onSaved,
        documentTypeForDeletion,
        documentTypeForUpdate,
        setDocumentTypeForDeletion,
        onDelete,
        onCreateClick,
        onUpdateClick,
        onDialogClose,
        isDeleting: deleteMutation.isLoading,
    };
}
