import { deleteHttp } from "../common/api/apiShared.ts";
import { useMutation } from "react-query";
import { LegalEntityRowModel } from "./legalEntities/LegalEntities.types.ts";
import { createElement, useState } from "react";
import { Trans } from "react-i18next";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";

export type LegalEntityToDelete = {
    legalEntityId: string;
    name: string;
};

export function useDeleteLegalEntity(onLegalEntityDeleted?: () => void) {
    const snackbar = useDotLegalSnackbar();
    const [legalEntityToDelete, setLegalEntityToDelete] = useState<LegalEntityToDelete | undefined>();

    const deleteLegalEntityMutation = useMutation(deleteLegalEntityApi);
    const deleteLegalEntity = async (legalEntity: LegalEntityToDelete) => {
        await deleteLegalEntityMutation.mutateAsync(legalEntity.legalEntityId, {
            onSuccess: (response: any) => {
                setLegalEntityToDelete(undefined);

                const resp = response.value();
                if (resp.isDeleted) {
                    snackbar.show(createElement(Trans, { i18nKey: "itemDeletedSuccess", values: { name: legalEntity!.name } }));
                    if (onLegalEntityDeleted) {
                        onLegalEntityDeleted();
                    }
                } else {
                    snackbar.show(createElement(Trans, { i18nKey: "deleteCompanyError", values: { name: resp.name } }), "warning");
                }
            },
        });
    };

    function deleteLegalEntityApi(legalEntityId: string) {
        return deleteHttp<string>("/legalentity/" + legalEntityId);
    }

    return {
        legalEntityToDelete,
        setLegalEntityToDelete,
        deleteLegalEntity,
        isDeletingLegalEntity: deleteLegalEntityMutation.isLoading,
    };
}
