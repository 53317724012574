import { Box, Grid } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";
import ReactPlayer from "react-player";
import { AnnualWheelActivityType } from "../annualWheel/annualWheelDialog/AnnualWheelDialog.types";
import { DotLegalButton, DotLegalHeader, DotLegalPageHeader, DotLegalPrettyScrollbar } from "@dotlegal/dotlegal-ui-components";
import DotLegalFullScreenSpinner from "../common/components/dotLegalFullScreenSpinner/DotLegalFullScreenSpinner";
import DotLegalLink from "../common/components/dotLegalLink/DotLegalLink";
import DotLegalNotificationCard from "../common/components/dotLegalNotificationCard/DotLegalNotificationCard";
import DotLegalPaper from "../common/components/dotLegalPaper/DotLegalPaper";
import { useTranslation } from "../localization/useTranslation";
import DeleteNotificationDialog from "./deleteNotificationDialog/DeleteNotificationDialog";
import { useNotificationDataMapping } from "./Notification.hooks";
import { useNotificationStyles } from "./Notification.styles";
import { getNotificationDescription, getNotificationHeader, NotificationType, NotificationViewModel } from "./Notification.types";
import NotificationEmptyState from "./notificationEmptyState/NotificationEmptyState";
import NotificationMarkDownContent from "./notificationMarkDownContent/NotificationMarkDownContent";
import { useUrlProvider } from "../useUrlProvider";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider";

function Notification() {
    const { translateString, translateDate, translateDateWithFullMonthAndYear } = useTranslation();
    const {
        getProcessingActivityOverviewWithOpenCommenDialogUrl,
        getProcessingActivityOverviewUrl,
        getTaskManagementTaskDialogUrl,
        getTaskManagementUrlForTask,
    } = useUrlProvider();
    const {
        isLoading,
        getBreadCrumbs,
        data,
        selectedNotificationId,
        setSelectedNotificationId,
        selectedNotification,
        videoData,
        updateNotificationState,
        showDeleteNotificationDialog,
        setShowDeleteNotificationDialog,
        refetchNotifications,
        hasNotifications,
    } = useNotificationDataMapping();
    const styles = useNotificationStyles();
    const { customerName } = useUserContext();

    const scrollRef = React.createRef<HTMLDivElement>();

    const notificationCardSize = "expanded";
    const getNotifications = () => {
        return data?.map((x) => {
            let header = getNotificationHeader(x.notificationType, translateString);
            if (isProcessingActivityValidation(x)) {
                header = translateString("taskNotifications");
            }

            return (
                <Box sx={styles.notificationCard} key={x.id}>
                    <DotLegalNotificationCard
                        onClick={() => {
                            setSelectedNotificationId(x.id);

                            if (!x.isRead) updateNotificationState(x.id);
                        }}
                        isClicked={selectedNotificationId === x.id}
                        description={getNotificationDescription(x, translateString, notificationCardSize)}
                        header={header}
                        isRead={x.isRead}
                        taskHrefText=""
                        taskHref=""
                        taskDate={x.created}
                        onDelete={() => setShowDeleteNotificationDialog(true)}
                        notificationType={x.notificationType}
                        size={notificationCardSize}
                        clickable
                    />
                </Box>
            );
        });
    };

    const getFullNotificationHeader = (notification: NotificationViewModel) => {
        const description = notification.description;

        if (isProcessingActivityValidation(notification)) {
            return `${translateString("taskNotifications")}: ${description}`;
        }

        switch (notification.notificationType) {
            case NotificationType.LegalUpdates:
                return translateString("legalUpdatesNotification");
            case NotificationType.Release:
                return translateString("newFunctionsNotification");
            case NotificationType.OnBoardingTask:
                return `${translateString("taskNotification")}: ${translateString("onBoardingNotificationDescription")}`;
            case NotificationType.ProcessingActivityCommentType:
            case NotificationType.TaskComment:
                return translateString("notificationNewCommentFullNotificationHeader");
            case NotificationType.TaskCompleted:
                return `${translateString("taskCompleted")}: ${description}`;
            case NotificationType.TaskReadyForApproval:
                return `${translateString("taskReadyForApproval")}: ${description}`;
            default:
                return `${translateString("taskNotification")}: ${description}`;
        }
    };

    const getNotificationContent = (notification: NotificationViewModel) => {
        if (notification.contentUrl) {
            return <NotificationMarkDownContent url={notification.contentUrl} />;
        } else if (notification.notificationType === NotificationType.OnBoardingTask) {
            return getOnBoardingTaskContent(notification.taskId!);
        } else if (notification.notificationType === NotificationType.ProcessingActivityCommentType) {
            return getProcessingActivityCommentContent(notification);
        } else if (notification.notificationType === NotificationType.TaskComment) {
            return getTaskCommentContent(notification);
        } else if (notification.notificationType === NotificationType.TaskAssignee || notification.notificationType === NotificationType.TaskResponsible) {
            return getTaskAccountableContent(notification);
        } else if (
            notification.notificationType === NotificationType.TaskCompleted ||
            notification.notificationType === NotificationType.TaskReadyForApproval
        ) {
            return getTaskCompletedContent(notification);
        } else {
            return getTaskContent(notification);
        }
    };

    function getProcessingActivityCommentContent(notification: NotificationViewModel) {
        return (
            <React.Fragment>
                <Box sx={{ marginBottom: 1 }}>
                    <Trans
                        i18nKey="notificationNewCommentFullContent"
                        values={{
                            name: notification.sender,
                            processingActivity: notification.processingActivityName,
                        }}
                        components={{
                            url: (
                                <DotLegalLink to={getProcessingActivityOverviewUrl(notification.processingActivityId!)}>
                                    {notification.processingActivityName}
                                </DotLegalLink>
                            ),
                            wrapper: (
                                <Box
                                    sx={(theme) => ({
                                        color: theme.palette.secondary.main,
                                        fontWeight: theme.typography.fontWeightBold,
                                    })}
                                    component="span"
                                ></Box>
                            ),
                        }}
                    />
                </Box>
                <DotLegalHeader fontWeight={0} headerStyle="small">
                    {translateString("notificationNewCommentUnsubscribe")}
                </DotLegalHeader>

                <DotLegalButton href={getProcessingActivityOverviewWithOpenCommenDialogUrl(notification.processingActivityId!)} buttonType="primary">
                    {translateString("nofificationGoToComment")}
                </DotLegalButton>
            </React.Fragment>
        );
    }

    function getTaskCommentContent(notification: NotificationViewModel) {
        return (
            <React.Fragment>
                <Box sx={{ marginBottom: 1 }}>
                    <Trans
                        i18nKey="notificationNewTaskCommentFullContent"
                        values={{
                            name: notification.sender,
                            task: notification.description,
                        }}
                        components={{
                            url: <DotLegalLink to={getTaskManagementTaskDialogUrl(notification.taskId)}>{notification.description}</DotLegalLink>,
                            wrapper: (
                                <Box
                                    sx={(theme) => ({
                                        color: theme.palette.secondary.main,
                                        fontWeight: theme.typography.fontWeightBold,
                                    })}
                                    component="span"
                                ></Box>
                            ),
                        }}
                    />
                </Box>
                <DotLegalHeader fontWeight={0} headerStyle="small">
                    {translateString("notificationTaskCommentUnsubscribe")}
                </DotLegalHeader>

                <DotLegalButton href={getTaskManagementTaskDialogUrl(notification.taskId)} buttonType="primary">
                    {translateString("nofificationGoToComment")}
                </DotLegalButton>
            </React.Fragment>
        );
    }

    function getTaskContent(notification: NotificationViewModel) {
        if (notification.annualWheelActivityType === AnnualWheelActivityType.ProcessingActivityValidation)
            return getProcessingActivityTaskContent(notification);
        else return getRegularTaskContent(notification);
    }

    function getOnBoardingTaskContent(taskId: string) {
        return (
            <React.Fragment>
                <Box sx={styles.content}>
                    <div>{translateString("onBoardingNotificationContent")}</div>
                    <Box sx={styles.introVideo}>{translateString("replayIntroVideo")}</Box>
                    <Box sx={styles.player}>
                        <ReactPlayer controls url={videoData}></ReactPlayer>
                    </Box>
                </Box>

                <Box sx={styles.buttons}>
                    <DotLegalButton buttonType="secondary" href={`task-management?taskId=${taskId}`}>
                        {translateString("seeTask")}
                    </DotLegalButton>
                </Box>
            </React.Fragment>
        );
    }

    function getProcessingActivityTaskContent(notification: NotificationViewModel) {
        return (
            <React.Fragment>
                <Box sx={styles.content}>
                    <div>
                        {
                            <Trans
                                i18nKey="processingActivityNotification"
                                values={{
                                    days: notification.notificationPeriodInDays,
                                    deadline: translateDate(notification.deadLine!),
                                }}
                            />
                        }
                    </div>
                    <Box sx={styles.section}>{translateString("processingActivitiesToBeValidated")}</Box>
                    <Box sx={styles.processingActivityContent}>
                        {notification.processingActivityTaskViewModel.map((x) => {
                            return (
                                <div key={x.processingActivityId}>
                                    <DotLegalLink to={`processingactivity/${x.processingActivityId}/validate`}>{x.processingActivityName}</DotLegalLink>
                                </div>
                            );
                        })}
                    </Box>
                </Box>

                {getTaskButton(notification)}
            </React.Fragment>
        );
    }

    function getTaskAccountableContent(notification: NotificationViewModel) {
        return (
            <React.Fragment>
                <Box sx={styles.content}>
                    <div>
                        {
                            <Trans
                                i18nKey={
                                    notification.notificationType === NotificationType.TaskAssignee
                                        ? "assigneeNotificationFullDescription"
                                        : "taskResponsibleNotificationFullDescription"
                                }
                                values={{
                                    deadline: translateDate(notification.deadLine!),
                                    task: notification.description,
                                }}
                                components={{
                                    url: <DotLegalLink to={getTaskManagementTaskDialogUrl(notification.taskId)}>{notification.description}</DotLegalLink>,
                                    wrapper: (
                                        <Box
                                            sx={(theme) => ({
                                                color: theme.palette.secondary.main,
                                                fontWeight: theme.typography.fontWeightBold,
                                            })}
                                            component="span"
                                        ></Box>
                                    ),
                                }}
                            />
                        }
                    </div>
                </Box>

                {getTaskButton(notification)}
            </React.Fragment>
        );
    }

    function getRegularTaskContent(notification: NotificationViewModel) {
        return (
            <React.Fragment>
                <Box sx={styles.content}>
                    <div>
                        {
                            <Trans
                                i18nKey="regularTaskNotification"
                                values={{
                                    days: notification.notificationPeriodInDays,
                                    deadline: translateDate(notification.deadLine!),
                                    type: notification.description,
                                }}
                            />
                        }
                    </div>
                </Box>

                {getTaskButton(notification)}
            </React.Fragment>
        );
    }

    function getTaskCompletedContent(notification: NotificationViewModel) {
        return (
            <React.Fragment>
                <Box sx={styles.content}>
                    <Trans
                        i18nKey={
                            notification.notificationType === NotificationType.TaskReadyForApproval
                                ? "notificationBodyForTaskReadyForApproval"
                                : "notificationBodyForTaskCompleted"
                        }
                        values={{
                            fromUser: notification.fromUser,
                            date: translateDate(notification.created),
                            deadline: translateDateWithFullMonthAndYear(notification.deadLine!),
                            task: notification.description,
                        }}
                        components={{
                            url: <DotLegalLink to={`${getTaskManagementTaskDialogUrl(notification.taskId)}`}>{notification.description}</DotLegalLink>,
                            wrapper: (
                                <Box
                                    sx={(theme) => ({
                                        color: theme.palette.secondary.main,
                                        fontWeight: theme.typography.fontWeightBold,
                                    })}
                                    component="span"
                                ></Box>
                            ),
                        }}
                    />
                </Box>

                {getTaskButton(notification)}
            </React.Fragment>
        );
    }

    function getTaskButton(notification: NotificationViewModel) {
        const month = new Date(notification.deadLine!).getMonth() + 1;
        const showAsMultipleTasks = isProcessingActivityValidation(notification);
        let href = getTaskManagementTaskDialogUrl(notification.taskId, month);

        if (showAsMultipleTasks && notification.annualWheelActivityId) {
            href = getTaskManagementUrlForTask(month, notification.annualWheelActivityId);
        }

        return (
            <Box sx={styles.buttons}>
                <DotLegalButton buttonType="secondary" href={href}>
                    {showAsMultipleTasks ? translateString("seeTasks") : translateString("seeTask")}
                </DotLegalButton>
            </Box>
        );
    }

    const DetailsWrapper = React.forwardRef<HTMLDivElement>(function detailsWrapper(props, ref) {
        return (
            <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <DotLegalPrettyScrollbar sx={styles.notificationList} ref={ref}>
                    <Box sx={styles.detailsContainer}>
                        <Box sx={styles.detailsHeader}>
                            <Box sx={styles.notificationType}>{getFullNotificationHeader(selectedNotification!)}</Box>
                            <Box sx={styles.notificationDate}>{translateDate(selectedNotification!.created)}</Box>
                        </Box>
                        <Box sx={styles.detailsContent}>{getNotificationContent(selectedNotification!)}</Box>
                    </Box>
                </DotLegalPrettyScrollbar>
            </Box>
        );
    });
    return (
        <React.Fragment>
            <DotLegalPageHeader userContext={{ customerName }} breadCrumbs={getBreadCrumbs()} />
            {isLoading ? (
                <DotLegalFullScreenSpinner />
            ) : hasNotifications ? (
                <React.Fragment>
                    <Grid sx={[styles.root, { paddingBottom: 2 }]} container spacing={2}>
                        <Grid item lg={4} md={6} sm={12} style={{ height: "100%" }}>
                            <DotLegalPaper fullHeight>
                                <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                                    <DotLegalPrettyScrollbar width={14} sx={styles.notificationList}>
                                        {getNotifications()}
                                    </DotLegalPrettyScrollbar>
                                </Box>
                            </DotLegalPaper>
                        </Grid>
                        <Grid item lg={8} md={6} sm={12} style={{ height: "100%" }}>
                            <DotLegalPaper fullHeight>
                                {selectedNotification ? (
                                    <DetailsWrapper ref={scrollRef} />
                                ) : (
                                    <NotificationEmptyState notificationText={translateString("noNotificationsSelected")} isPopOver={false} />
                                )}
                            </DotLegalPaper>
                        </Grid>
                    </Grid>
                </React.Fragment>
            ) : (
                <DotLegalPaper fullHeight>
                    <NotificationEmptyState notificationText={translateString("noNotifications")} isPopOver={false} />
                </DotLegalPaper>
            )}

            {showDeleteNotificationDialog && (
                <DeleteNotificationDialog
                    onCloseDialog={() => setShowDeleteNotificationDialog(false)}
                    onSaveCorrect={() => refetchNotifications()}
                    notification={selectedNotification!}
                />
            )}
        </React.Fragment>
    );

    function isProcessingActivityValidation(notification: NotificationViewModel) {
        return notification.annualWheelActivityType === AnnualWheelActivityType.ProcessingActivityValidation;
    }
}

export default Notification;
