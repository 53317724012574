import {
    DotLegalEmptyState,
    DotLegalMultiSelect,
    DotLegalPaper,
    DotLegalTableWithControls,
    DotLegalTooltip,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { Box, Grid, TableCell, TableRow } from "@mui/material";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import React from "react";
import { GridSizes } from "../../../common/components/dotLegalTable/DotLegalTable.types";
import { SharingRowModel, SharingRowTableModel, ThirdCountryOrigin } from "../ProcessingActivityOverview.types";
import useProcessingActivitySharingsTabHooks from "./ProcessingActivitySharingsTab.hooks";
import { SelectableDataCategoryItem, SelectableLegalUnitsItem, StepTypeEnum } from "../../ProcessingActivity.types";
import DotLegalLink from "../../../common/components/dotLegalLink/DotLegalLink";
import { useProcessingActivitySharingsTabStyles } from "./ProcessingActivitySharingsTab.styles";
import NoSharings from "./noSharings.svg?react";
import { informationIcon } from "../../../common/icons";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";

export interface IProcessingActivitySharingsTab {
    sharings: Array<SharingRowModel>;
    isLoading: boolean;
    dataCategories: Array<SelectableDataCategoryItem>;
    senders: Array<SelectableLegalUnitsItem>;
    recipients: Array<SelectableLegalUnitsItem>;
    processingActivityId: string;
}

function ProcessingActivitySharingsTab(props: IProcessingActivitySharingsTab) {
    const { translateString } = useTranslation();
    const {
        data,
        searchedDataCategories,
        searchedLevelOfProtection,
        searchedRecipients,
        searchedSenders,
        searchedSharingType,
        setSearchedDataCategories,
        setSearchedRecipients,
        setSearchedSenders,
        setSearchedSharingType,
        setsearchedLevelOfProtection,
        getSharingLink,
        hasAccessToSharing,
        hasSharings,
    } = useProcessingActivitySharingsTabHooks(props);
    const styles = useProcessingActivitySharingsTabStyles();

    const getHeaders = () => {
        let headers = Array<ITableHeader<SharingRowTableModel>>();
        headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
        headers.push({ property: "senderName", text: translateString("Sender"), align: "left", showOnMobile: true });
        headers.push({
            property: "dataCategories",
            text: translateString("dataCategoryHeader"),
            align: "left",
            showOnMobile: true,
        });
        headers.push({
            property: "recipientName",
            text: translateString("recipient"),
            align: "left",
            showOnMobile: true,
        });
        headers.push({
            property: "legalBasisList",
            text: translateString("legalBasisHeader"),
            align: "left",
            showOnMobile: true,
        });
        headers.push({
            property: "transferBasis",
            text: translateString("transferBasis"),
            align: "left",
            showOnMobile: true,
        });
        headers.push({
            property: "deletionPeriods",
            text: translateString("deletionPeriods"),
            align: "left",
            showOnMobile: true,
        });

        headers.push({ property: "system", text: translateString("system"), align: "left", showOnMobile: true });

        return headers;
    };

    const tableGridProps: GridSizes = {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 4,
        xl: true,
    };

    function getAdditionalFilters() {
        return (
            <React.Fragment>
                <Grid item {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedSharingType}
                        chosenOptions={searchedSharingType}
                        label={translateString("sharingType")}
                        placeholder={translateString("sharingType")}
                        isLoading={props.isLoading}
                        options={data?.sharingsTypeOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid item {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedSenders}
                        chosenOptions={searchedSenders}
                        label={translateString("Senders")}
                        placeholder={translateString("Senders")}
                        isLoading={props.isLoading}
                        options={data?.legalEntitiesData}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid item {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedDataCategories}
                        chosenOptions={searchedDataCategories}
                        label={translateString("dataCategoryHeader")}
                        placeholder={translateString("dataCategoryHeader")}
                        isLoading={props.isLoading}
                        options={data?.datacategoriesData}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid item {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedRecipients}
                        chosenOptions={searchedRecipients}
                        label={translateString("Recipients")}
                        placeholder={translateString("Recipients")}
                        isLoading={props.isLoading}
                        options={data?.recipientsEntitiesData}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid item {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setsearchedLevelOfProtection}
                        chosenOptions={searchedLevelOfProtection}
                        label={translateString("sharingsTo")}
                        placeholder={translateString("sharingsTo")}
                        isLoading={props.isLoading}
                        options={data?.sharingsLevelOfProtectionOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    function getSharingLinkIfUserHasAccess(name: string, id: string, step: StepTypeEnum, subStep: StepTypeEnum | undefined, subId: string) {
        if (hasAccessToSharing(subStep)) {
            return (
                <DotLegalLink linkColor={"primary"} to={getSharingLink(id, step, subStep, subId)}>
                    {name}
                </DotLegalLink>
            );
        }

        return <div>{name}</div>;
    }

    return (
        <DotLegalPaper>
            {hasSharings ? (
                <DotLegalTableWithControls
                    searchFieldGridSizes={tableGridProps}
                    searchFieldFullWidth
                    extraControls={getAdditionalFilters()}
                    headers={getHeaders()}
                    getUserSpecificPageLength={() => 30}
                    hideRowsPerPage
                    clickableRows={false}
                    defaultOrderBy={"senderName"}
                    defaultOrder={"asc"}
                    isLoading={props.isLoading}
                    data={data?.filteredRows}
                    emptyText={translateString("noSharingsMatchesQuery")}
                    labelRowsPerPage={translateString("showEntites")}
                    labelSearch={translateString("search")}
                    noOptionsLabel={translateString("noOptions")}
                    paginationLabelOf={translateString("labelOf")}
                    columnsExcludedFromFilter={["dataCategories"]}
                    renderRow={(row, i) => (
                        <TableRow key={i}>
                            <TableCell>
                                <div>
                                    <DotLegalTooltip
                                        text={
                                            row.description && row.description.trim() !== "" ? (
                                                <Box component={"pre"} sx={styles.pre}>
                                                    {row.description.trim()}
                                                </Box>
                                            ) : (
                                                ""
                                            )
                                        }
                                    >
                                        <div>{row.name}</div>
                                    </DotLegalTooltip>
                                </div>
                            </TableCell>
                            <TableCell>
                                <div>
                                    <Box sx={styles.legalEntity}>{row.senderName}</Box>
                                    {row.senderId !== null && (
                                        <Box sx={styles.entityRole}>
                                            {getSharingLinkIfUserHasAccess(
                                                row.senderRole,
                                                props.processingActivityId,
                                                StepTypeEnum.sharings,
                                                row!.subStep,
                                                row!.sharingId
                                            )}
                                        </Box>
                                    )}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {row.dataCategories.map((x) => {
                                        return (
                                            <div
                                                key={x.id}
                                                style={{
                                                    marginRight: 8,
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div style={{ display: "inline-block", marginRight: 3 }}>{x.name}</div>
                                                {!isNullOrWhitespace(x.description) && (
                                                    <DotLegalTooltip text={x.description}>
                                                        <Box sx={styles.tooltipIcon}>{informationIcon}</Box>
                                                    </DotLegalTooltip>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div>
                                    <Box
                                        sx={(theme) => ({
                                            color: theme.palette.primary.dark,
                                            display: "flex",
                                            alignItems: "center",
                                            "& svg": {
                                                fill:
                                                    row.thirdCountryOrigin === ThirdCountryOrigin.DataProcessor
                                                        ? theme.palette.error.light
                                                        : theme.palette.primary.dark,
                                                marginLeft: theme.spacing(0.5),
                                                height: 17,
                                                width: 17,
                                            },
                                        })}
                                    >
                                        {row.recipientName}
                                        {row.thirdCountryOrigin && (
                                            <DotLegalTooltip
                                                text={
                                                    row.thirdCountryOrigin === ThirdCountryOrigin.DataProcessor
                                                        ? translateString("recipientFromInsecureThirdCountry")
                                                        : translateString("dataStoredInThirdCountry")
                                                }
                                            >
                                                <ReportProblemOutlinedIcon fontSize={"small"} />
                                            </DotLegalTooltip>
                                        )}
                                    </Box>
                                    {row.recipientId !== null && (
                                        <Box sx={styles.entityRole}>
                                            {getSharingLinkIfUserHasAccess(
                                                row.recipientRole,
                                                props.processingActivityId,
                                                StepTypeEnum.sharings,
                                                row!.subStep,
                                                row!.sharingId
                                            )}
                                        </Box>
                                    )}
                                </div>
                            </TableCell>
                            <TableCell>
                                <Box sx={styles.entityRole}>
                                    {getSharingLinkIfUserHasAccess(
                                        row.legalBasisList.join(", "),
                                        props.processingActivityId,
                                        StepTypeEnum.sharings,
                                        StepTypeEnum.disclosure,
                                        row.sharingId
                                    )}
                                </Box>
                            </TableCell>
                            <TableCell>{row.transferBasis}</TableCell>
                            <TableCell>{row.deletionPeriods.join(", ")}</TableCell>

                            <TableCell>
                                {row.senderId != null ? (
                                    <Box sx={styles.entityRole}>
                                        {getSharingLinkIfUserHasAccess(row.system, props.processingActivityId, StepTypeEnum.systems, undefined, row.systemId)}
                                    </Box>
                                ) : (
                                    row.system
                                )}
                            </TableCell>
                        </TableRow>
                    )}
                />
            ) : (
                <DotLegalEmptyState icon={<NoSharings />} text={translateString("noSharingsOnProcessingActivity")} />
            )}
        </DotLegalPaper>
    );
}

export default ProcessingActivitySharingsTab;
