import { DotLegalDialog, DotLegalHeader, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../../localization/useTranslation";
import { Box, Divider, Grid } from "@mui/material";
import useDeclarationControlDialogHooks from "./DeclarationControlDialogContent.hooks";
import RelatedTasksSelector from "../../../../taskManagement/editTaskDialog/taskRelations/relatedTasksSelector/RelatedTasksSelector";
import DotLegalSelectApi from "../../../../common/components/dotLegalSelectApi/DotLegalSelectApi";
import { apiUrls } from "../../../../common/api/apiUrls";
import { SelectableItem } from "../../../../processingActivity/ProcessingActivity.types";
import { SingleValidator } from "../../../../common/hooks/validation/SingleValidator";
import { DeclarationControlSaveModel } from "./DeclarationControlDialogContent.types";
import { IDeclarationControlDialogWrapper } from "../DeclarationControlDialogWrapper";

export interface IDeclarationControlDialogContent extends IDeclarationControlDialogWrapper {
    saveModel: DeclarationControlSaveModel;
    modelUpdater: {
        onNameChange: (name: string) => void;
        onIDChange: (id: string) => void;
        onDescriptionChange: (description: string) => void;
        onResponsibleChanged: (responsible: string | null) => void;
        onCategoryChanged: (category: SelectableItem | undefined) => void;
        onAddTask: (taskId: string) => void;
        onRemoveTask: (taskId: string) => void;
    };
    validation: SingleValidator<DeclarationControlSaveModel, DeclarationControlSaveModel>;
    onOkClick: () => void;
    isSaving: boolean;
    isLoading: boolean;
    isEdit: boolean;
}

function DeclarationControlDialogContent(props: IDeclarationControlDialogContent) {
    const { translateString } = useTranslation();
    const { data, selectableResponsiblesData, selectableresponsiblesLoading } = useDeclarationControlDialogHooks(props);

    props.validation.check(props.saveModel);

    return (
        <DotLegalDialog
            header={props.isLocked ? translateString("controlActivity") : props.isEdit ? translateString("editControl") : translateString("addControl")}
            buttonOkText={props.isEdit ? translateString("close") : translateString("save")}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={() => props.onOkClick()}
            size="lg"
            inProgress={props.isSaving}
            isLoading={props.isLoading}
            hideOkButton={props.isLocked}
            disableBackdropOnCloseIfValueChange={props.saveModel}
        >
            <Box sx={{ marginLeft: 3, marginRight: 3 }}>
                <Grid container columnSpacing={8}>
                    <Grid item sm={6}>
                        <DotLegalTextField
                            disabled={props.isLocked}
                            label={translateString("nameOfControl")}
                            errorText={props.validation.getErrorsForDisplay("name")}
                            value={props.saveModel.name}
                            onChange={props.modelUpdater.onNameChange}
                            debounce={props.isEdit}
                            toolTipText={translateString("declarationControlNameTooltip")}
                        ></DotLegalTextField>
                    </Grid>
                    <Grid item sm={6}>
                        <DotLegalTextField
                            disabled={props.isLocked}
                            label="ID"
                            value={props.saveModel.customId}
                            debounce={props.isEdit}
                            toolTipText={translateString("declarationControlIdTooltip")}
                            onChange={props.modelUpdater.onIDChange}
                        ></DotLegalTextField>
                    </Grid>
                    <Grid item sm={6}>
                        <DotLegalSelectApi
                            disabled={props.isLocked}
                            url={apiUrls().declarations.selectableItems(props.declarationId)}
                            selectedItem={props.saveModel.categoryId}
                            errorText={props.validation.getErrorsForDisplay("categoryId")}
                            onChange={props.modelUpdater.onCategoryChanged}
                            label={translateString("category")}
                            noOptionsLabel={translateString("noOptions")}
                            toolTipText={translateString("declarationControlCategoryTooltip")}
                        ></DotLegalSelectApi>
                    </Grid>
                    <Grid item sm={6}>
                        <DotLegalSelect
                            disabled={props.isLocked}
                            disableClearable
                            options={selectableResponsiblesData}
                            isLoading={selectableresponsiblesLoading}
                            selectedItem={props.saveModel.responsibleId}
                            onChange={props.modelUpdater.onResponsibleChanged}
                            label={translateString("responsible")}
                            noOptionsLabel={translateString("noOptions")}
                            toolTipText={translateString("declarationControlResponsibleTooltip")}
                            errorText={props.validation.getErrorsForDisplay("responsibleId")}
                        ></DotLegalSelect>
                    </Grid>
                    <Grid item sm={6}>
                        <DotLegalTextField
                            disabled={props.isLocked}
                            label={translateString("description")}
                            value={props.saveModel.description}
                            debounce={props.isEdit}
                            toolTipText={translateString("declarationControlDescriptionTooltip")}
                            multiline
                            rows={3}
                            onChange={props.modelUpdater.onDescriptionChange}
                        ></DotLegalTextField>
                    </Grid>
                </Grid>

                <Divider sx={(theme) => ({ marginTop: 4, marginBottom: 4, borderColor: theme.palette.primary.light })} />

                <Box sx={{ display: "flex", justifyContent: "center", textTransform: "uppercase" }}>
                    <DotLegalHeader center headerStyle={"small"} toolTip={translateString("declarationControlDocumentationTooltip")}>
                        {translateString("linkDocumentation")}
                    </DotLegalHeader>
                </Box>

                <RelatedTasksSelector
                    firstColumnHeader={translateString("tasksToBeCompleted")}
                    readOnly={props.isLocked}
                    showRelatedTasks={false}
                    selectableTasks={data?.filter((x) => !props.saveModel.taskIds.includes(x.taskId)) ?? []}
                    shouldAddRelatedTask={false}
                    updateRelatedTaskScenario={() => {}}
                    onAddTask={props.modelUpdater.onAddTask}
                    onDeleteTaskClick={props.modelUpdater.onRemoveTask}
                    selectedTasks={data?.filter((x) => props.saveModel.taskIds.includes(x.taskId))}
                />
            </Box>
        </DotLegalDialog>
    );
}

export default DeclarationControlDialogContent;
