import { useMutation, useQuery, useQueryClient } from "react-query";
import { get, put } from "../../common/api/apiShared";
import { useTranslation } from "../../localization/useTranslation";
import { LegalBasesDialogViewModel, ProcessingActivityOverviewViewModel } from "./ProcessingActivityOverview.types";
import { useState } from "react";
import { useUrlProvider } from "../../useUrlProvider";
import { useStateUrlParams, useStateUrlParamsNumber } from "../../common/hooks/useStateUrlParams";
import { SelectableCompany } from "../../common/components/dotLegalCompanySelector/DotLegalCompanySelector.types";
import { RiskAssessmentScoreViewModel } from "../../riskAssessment/RiskAssessment.types";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { NumberOfDataSubjectsEnum } from "../ProcessingActivity.types";
import { useDotLegalCompanySelector } from "../../common/components/dotLegalCompanySelector/GroupCompanySelectorContext";

export function useProcessingActivityOverviewDataMapping(activityId: string | undefined) {
    const navigate = useNavigate();
    const { selectedGroupCompany: selectedCompany } = useDotLegalCompanySelector();
    const [selectedTab, setSelectedTab] = useStateUrlParamsNumber("tab", 0);
    const queryClient = useQueryClient();
    const { getProcessingActivitiesOverviewUrl } = useUrlProvider();
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();

    let queryKey = "processings" + activityId + selectedCompany;
    let { isLoading, data, refetch } = useQuery(queryKey, () =>
        get<ProcessingActivityOverviewViewModel>("/processingactivityoverview?activityId=" + activityId + "&groupEntityId=" + selectedCompany)
    );
    let riskAssessmentScoreUrl = `/processingActivityRiskAssessment/${activityId}/scores`;
    let riskScoreQuery = useQuery(riskAssessmentScoreUrl, () => get<RiskAssessmentScoreViewModel>(riskAssessmentScoreUrl), {
        enabled: permissions.canManageRiskAssessment,
    });

    const [showAuditLogDialog, setShowAuditLogDialog] = useStateUrlParams("auditlog", "false");

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showSystemsDialog, setShowSystemsDialog] = useState(false);
    const [showNoteDialog, setShowNoteDialog] = useState(false);
    const [showStatusDialog, setShowStatusDialog] = useState(false);
    const [showProcessingActivityReportDialog, setShowProcessingActivityReportDialog] = useState(false);
    const [showDataCategoryOverviewDialog, setShowDataCategoryOverviewDialog] = useState({
        showDialog: false,
        chosenDataCategories: [] as Array<LegalBasesDialogViewModel>,
    });
    const [showPolicyDialog, setShowPolicyDialog] = useState(false);

    const saveNoteMutation = useMutation(updateProcessingActivityNote);
    const updateNote = (note: string) => {
        saveNoteMutation.mutateAsync(note, {
            onSuccess: (resp: any) => {
                snackbar.show(translateString("noteUpdated"), "success");
                queryClient.fetchQuery(queryKey);
                setShowNoteDialog(false);
            },
        });
    };

    let processingViewModel;
    let selectableCompanyItems: Array<SelectableCompany> = [];

    if (data) {
        processingViewModel = {
            processingActivitiesInfoModel: data.processingActivityInfo,
        };

        selectableCompanyItems = data.processingActivityInfo.selectableCompanyItems;
    }

    function updateProcessingActivityNote(note: string) {
        return put<{}>(`/ProcessingActivity/${activityId}/note`, note);
    }

    const onProcessingActivityDeleteSuccess = () => {
        navigate(getProcessingActivitiesOverviewUrl());
    };

    return {
        isLoading,
        processingViewModel,
        showSystemsDialog,
        setShowSystemsDialog,
        showDataCategoryOverviewDialog,
        setShowDataCategoryOverviewDialog,
        showNoteDialog,
        setShowNoteDialog,
        selectableCompanyItems,
        riskAssessmentData: riskScoreQuery.data,
        riskAssessmentLoading: riskScoreQuery.isLoading,
        updateNote,
        showDeleteDialog,
        setShowDeleteDialog,
        savingNote: saveNoteMutation.isLoading,
        onProcessingActivityDeleteSuccess,
        showPolicyDialog,
        setShowPolicyDialog,
        showStatusDialog,
        setShowStatusDialog,
        refetchProcessingActivity: refetch,
        showAuditLogDialog,
        setShowAuditLogDialog,
        showProcessingActivityReportDialog,
        setShowProcessingActivityReportDialog,
        data,
        selectedTab,
        setSelectedTab,
    };
}

export function getNumberOfDataSubjects(
    translateDataSubjectsEnum: (dataSubject: string) => string,
    numberOfSubjects?: NumberOfDataSubjectsEnum,
    noOfSubjects?: number
) {
    if (numberOfSubjects) {
        if (numberOfSubjects === NumberOfDataSubjectsEnum.UserEnteredNumberOfSubjects) {
            return noOfSubjects;
        }

        return translateDataSubjectsEnum(numberOfSubjects!.toString());
    }

    return 0;
}
