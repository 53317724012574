import { DotLegalHeader, DotLegalSelect, DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Theme, styled } from "@mui/material";
import React from "react";
import { useTranslation } from "../../../../localization/useTranslation";
import AddIcon from "@mui/icons-material/Add";
import useRelatedTasksSelectorHooks from "./RelatedTasksSelector.hook";
import DotLegalLink from "../../../../common/components/dotLegalLink/DotLegalLink";
import { TaskStateEnum } from "../../../../annualWheel/AnnualWheel.types";
import { setFirstLetterToLowerCase } from "../../../../common/stringOperations";
import { useRelatedTasksSelectorStyle } from "./RelatedTasksSelector.styles";
import { useUrlProvider } from "../../../../useUrlProvider";
import { TaskRelationRow } from "../TaskRelations.types";
import { removeIcon } from "../../../../common/icons";

export interface IRelatedTasksSelector {
    readOnly?: boolean;
    selectedTasks?: Array<TaskRelationRow>;
    showRelatedTasks: boolean;
    selectableTasks: Array<TaskRelationRow>;
    shouldAddRelatedTask: boolean;
    updateRelatedTaskScenario: () => void;
    onAddTask: (taskId: string) => void;
    onDeleteTaskClick: (taskId: string) => void;
    firstColumnHeader: string;
}

const TaskStateComponent = styled("div")({
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 140,
});

const getStatusColors = (theme: Theme, state: TaskStateEnum) => {
    switch (state) {
        case TaskStateEnum.Completed:
            return { backgroundColor: "#E3FCEF", color: "#2BD5C4" };
        case TaskStateEnum.Overdue:
            return { backgroundColor: "#F7E7F2", color: "#D54678" };
        case TaskStateEnum.InProgress: {
            return { backgroundColor: "#E9EDFF", color: theme.palette.primary.main };
        }
        default:
            return { backgroundColor: "#CCE4FF", color: "#1774DE" };
    }
};

function RelatedTasksSelector(props: IRelatedTasksSelector) {
    const { translateString } = useTranslation();
    const { getTaskManagementUrl } = useUrlProvider();
    const styles = useRelatedTasksSelectorStyle();

    const {
        sortedData,
        onSortClick,
        order,
        orderBy,
        hoveredTask,
        setHoveredTask,
        addClicked,
        showSelectActivities,
        setShowSelectActivities,
        showAndAddRelatedTask,
        formatDate,
        selectableTasks,
    } = useRelatedTasksSelectorHooks(props);

    const getSortProps = (name: string) => {
        return {
            active: name === orderBy,
            onClick: () => onSortClick(name),
            direction: name === orderBy ? order : "asc",
        };
    };

    const shouldShowAddRelationBox = () => {
        if (showSelectActivities || (props.showRelatedTasks && (props.selectedTasks ?? []).length === 0)) {
            return true;
        }

        return false;
    };

    return (
        <React.Fragment>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow sx={{ border: 0 }}>
                            <TableCell sx={styles.tableCellLeft} align="left">
                                <TableSortLabel {...getSortProps("activityName")}>
                                    <DotLegalHeader marginTop={0} marginBottom={0} headerStyle="small">
                                        {props.firstColumnHeader}
                                    </DotLegalHeader>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={styles.tableCellLeft} align="left">
                                <TableSortLabel {...getSortProps("status")}>
                                    <DotLegalHeader marginTop={0} marginBottom={0} headerStyle="small">
                                        {translateString("status")}
                                    </DotLegalHeader>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={styles.tableCellLeft} align="left">
                                <TableSortLabel {...getSortProps("deadline")}>
                                    <DotLegalHeader marginTop={0} marginBottom={0} headerStyle="small">
                                        {translateString("deadline")}
                                    </DotLegalHeader>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={styles.tableCellLeft} align="left">
                                <TableSortLabel {...getSortProps("groupEntity")}>
                                    <DotLegalHeader marginTop={0} marginBottom={0} headerStyle="small">
                                        {translateString("groupEntity")}
                                    </DotLegalHeader>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={styles.tableCellRight} align="right">
                                {!props.readOnly && (
                                    <DotLegalTooltip text={translateString("addRelatedTask")}>
                                        <IconButton onClick={() => showAndAddRelatedTask()} sx={{ padding: 0.5 }}>
                                            <AddIcon />
                                        </IconButton>
                                    </DotLegalTooltip>
                                )}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.map((row) => (
                            <TableRow
                                sx={{ height: 30.5 }}
                                onMouseEnter={() => setHoveredTask(row.taskId)}
                                onMouseLeave={() => setHoveredTask("")}
                                key={row.taskId}
                            >
                                <TableCell sx={styles.tableCellLeft} align="left">
                                    <DotLegalHeader marginBottom={0} headerStyle="small">
                                        <DotLegalLink linkColor={"primary"} to={getTaskManagementUrl() + `?taskId=${row.taskId}`}>
                                            {row.activityName}
                                        </DotLegalLink>
                                    </DotLegalHeader>
                                </TableCell>
                                <TableCell sx={styles.tableCellLeft} align="left">
                                    <TaskStateComponent sx={(theme) => getStatusColors(theme, row.status)}>
                                        {translateString(setFirstLetterToLowerCase(TaskStateEnum[row.status].toString()))}
                                    </TaskStateComponent>
                                </TableCell>
                                <TableCell sx={styles.tableCellTextLeft} align="left">
                                    {formatDate(row.deadline)}
                                </TableCell>
                                <TableCell sx={styles.tableCellTextLeft} align="left">
                                    {row.groupEntity ? row.groupEntity : translateString("entireGroup")}
                                </TableCell>
                                <TableCell sx={styles.tableCellRight} align="right">
                                    {!props.readOnly && hoveredTask === row.taskId && (
                                        <IconButton size="small" onClick={() => props.onDeleteTaskClick(row.taskId)}>
                                            {removeIcon}
                                        </IconButton>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ visibility: shouldShowAddRelationBox() ? "visible" : "hidden" }}>
                <DotLegalSelect
                    variant="standard"
                    options={selectableTasks}
                    selectedItem={""}
                    onChange={(value) => {
                        props.onAddTask(value!);
                    }}
                    placeholder={translateString("selectTask")}
                    label={""}
                    noOptionsLabel={""}
                    clearOnSelect
                    noMargin
                    alwaysOpenInBottom
                    focusAndOpen={addClicked}
                    listMaxHeight={190}
                    onClose={() => setShowSelectActivities(false)}
                />
            </Box>
        </React.Fragment>
    );
}

export default RelatedTasksSelector;
