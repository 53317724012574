import TagsMultiSelect from "../TagsMultiSelect.tsx";
import { EntityType } from "../../../Common.types.ts";
import { useTagsMultiSelectAutoUpdater } from "./TagsMultiSelectAutoUpdater.hooks.ts";

export interface TagsMultiSelectStandAloneProps {
    entityId: string;
    isLoading: boolean;
    entityType: EntityType;
    variant?: "standard" | "outlined";
    disabled?: boolean;
    displayOnlyAsChips?: boolean;
    hideLabel?: boolean;
    noMargin?: boolean;
}

function TagsMultiSelectAutoUpdater(props: TagsMultiSelectStandAloneProps) {
    const { selectedTags, onAddTagRelation, onRemoveTagRelation, isLoading, onRemoveAllRelationsFromEntity } = useTagsMultiSelectAutoUpdater(props);

    return (
        <TagsMultiSelect
            selectedTags={selectedTags}
            onTagAdded={onAddTagRelation}
            onTagRemoved={onRemoveTagRelation}
            isLoading={isLoading || props.isLoading}
            entityType={props.entityType}
            variant={props.variant}
            disabled={props.disabled}
            noMargin={props.noMargin}
            displayOnlyAsChips={props.displayOnlyAsChips}
            hideLabel={props.hideLabel}
            onChange={(tags) => {
                if (selectedTags.length > 1 && tags.length === 0) {
                    onRemoveAllRelationsFromEntity();
                }
            }}
        />
    );
}

export default TagsMultiSelectAutoUpdater;
