import { createElement, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { get, post, put } from "../../common/api/apiShared";
import { Result } from "../../common/api/result";
import { validateEmail } from "../../common/emailValidator";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { useTranslation } from "../../localization/useTranslation";
import { SelectableColoredItem, SelectableItem } from "../../processingActivity/ProcessingActivity.types";
import { UserSaveModel, UserViewModel } from "../User.types";
import { IAddEditUserDialogProps } from "./AddEditUserDialog";
import { useValidator } from "../../common/hooks/validation";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";

export function useAddEditUserDialogHook(props: IAddEditUserDialogProps) {
    const [viewModel, setViewModel] = useState<UserSaveModel | undefined>(undefined);
    const [errors, setErrors] = useState({ firstNameError: "", lastNameError: "", emailError: "" });
    const { permissions } = useUserContext();
    const { translateString } = useTranslation();
    const snackbar = useDotLegalSnackbar();

    let { isLoading, data } = useQuery("addUser" + props.userId, () => get<UserViewModel>(`/user/${props.userId}`), { enabled: props.userId !== undefined });
    let userRolesQuery = useQuery("userRoles", () => get<Array<SelectableItem>>("/userroles/selectableItems"));
    let businessAreaQuery = useQuery("selectableBusinessAreas", () => get<Array<SelectableColoredItem>>("/BusinessAreas/businessareas"));

    useEffect(() => {
        if (data) {
            setViewModel({
                firstName: data.firstName ?? "",
                lastName: data.lastName ?? "",
                emailAddress: data.emailAddress ?? "",
                businessAreas: data.businessAreas.map((businessArea) => businessArea.id) ?? [],
                userRoles: data.userRoles.map((role) => role.id) ?? [],
                gdpo: data.gdpo ?? false,
                integrationIdentifier: data.integrationIdentifier,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.userId, data]);

    const validation = useValidator<UserSaveModel>(
        (validators) => ({
            integrationIdentifier: !permissions.canManageCustomers
                ? undefined
                : validators.validateNotEmpty((item) => item.integrationIdentifier, "IntegrationIdentifier"),
        }),
        [viewModel]
    );

    const saveMutation = useMutation(save);
    const createUser = () => {
        saveMutation.mutateAsync(viewModel!, {
            onSuccess: (resp: Result<string>) => {
                if (resp.hasErrors()) {
                    snackbar.show(translateString("userExistWithEmail"), "warning");
                } else {
                    snackbar.show(
                        createElement(Trans, { i18nKey: "createdSuccessGeneric", values: { name: viewModel?.firstName + " " + viewModel?.lastName } })
                    );
                    props.onSaveCorrect();
                }
            },
        });
    };

    const editMutation = useMutation(edit);
    const updateUser = () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        editMutation.mutateAsync(viewModel!, {
            onSuccess: (resp: any) => {
                const userEditReponst = resp.value();
                if (userEditReponst.success) {
                    snackbar.show(createElement(Trans, { i18nKey: "userEditSuccess", values: { name: userEditReponst.name } }));
                    props.onSaveCorrect();
                } else {
                    snackbar.show(translateString("userExistWithEmail"), "warning");
                }
            },
        });
    };

    return {
        userRolesLoading: userRolesQuery.isLoading,
        userRolesData: userRolesQuery.data,
        businessAreasLoading: businessAreaQuery.isLoading,
        businessAreaData: businessAreaQuery.data,
        isLoading,
        viewModel,
        setViewModel,
        errors,
        setErrors,
        validateUserForm,
        validateName,
        validateEmailAddress,
        isSaving: saveMutation.isLoading || editMutation.isLoading,
        validation,
        permissions,
    };

    function save(userModel: UserSaveModel) {
        return post<string>("/user", userModel);
    }

    function edit(viewModel: UserSaveModel) {
        return put<{}>(`/user/${props.userId}`, viewModel);
    }

    function validateName(name: string, isFirstName: boolean) {
        let tempErrors = { ...errors };
        if (isNullOrWhitespace(name)) {
            isFirstName ? (tempErrors.firstNameError = translateString("fieldMandatory")) : (tempErrors.lastNameError = translateString("fieldMandatory"));
            setErrors(tempErrors);
        } else if (name.length === 1) {
            isFirstName ? (tempErrors.firstNameError = "") : (tempErrors.lastNameError = "");
            setErrors(tempErrors);
        }
    }

    function validateEmailAddress(emailAddress: string) {
        let tempErrors = { ...errors };
        if (validateEmail(emailAddress) === false) {
            tempErrors.emailError = translateString("emailInvalid");
            if (isNullOrWhitespace(errors.emailError)) {
                setErrors(tempErrors);
            }
        } else {
            tempErrors.emailError = "";
            if (tempErrors.emailError !== errors.emailError) {
                setErrors(tempErrors);
            }
        }
    }

    function validateUserForm(firstName: string, lastName: string, emailAddress: string) {
        let formValid = true;
        let tempErrors = { ...errors };

        if (isNullOrWhitespace(firstName)) {
            tempErrors.firstNameError = translateString("fieldMandatory");
            formValid = false;
        }

        if (isNullOrWhitespace(lastName)) {
            tempErrors.lastNameError = translateString("fieldMandatory");
            formValid = false;
        }

        if (validateEmail(emailAddress) === false || isNullOrWhitespace(emailAddress)) {
            tempErrors.emailError = translateString("emailInvalid");
            formValid = false;
        }

        if (formValid) {
            if (props.isEdit) updateUser();
            else createUser();
        } else {
            setErrors(tempErrors);
        }
    }
}
