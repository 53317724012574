import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { useSystemDocuments } from "./SystemDocuments.hooks";
import DotLegalPaper from "../../../common/components/dotLegalPaper/DotLegalPaper";
import { DotLegalButton, DotLegalOverflowMenu, DotLegalTableWithControls, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import { Box, TableCell, TableRow } from "@mui/material";
import NoSharings from "../../../processingActivity/processingActivityOverview/processingActivitySharingsTab/noSharings.svg?react";
import { SystemDocumentRowViewModel } from "./SystemDocuments.types";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import SystemDocumentDialog from "./systemDocumentDialog/SystemDocumentDialog";
import SystemDocumentDeleteDialog from "./systemDocumentDeleteDialog/SystemDocumentDeleteDialog";

export interface SystemDocumentTabProps {
    systemId?: string;
    refetch: () => void;
}

function SystemDocumentTab(props: SystemDocumentTabProps) {
    const { translateString, translateDate } = useTranslation();
    const { permissions } = useUserContext();
    const { informationSecurityFeatures } = usePlanContext();
    const {
        isLoading,
        data,
        hasDocuments,
        refetchDocuments,
        getMenuItems,
        selectedDocument,
        setSelectedDocument,
        showDocumentDialog,
        setShowDocumentDialog,
        showDeleteDialog,
        setShowDeleteDialog,
    } = useSystemDocuments(props);

    return (
        <DotLegalPaper>
            {hasDocuments ? (
                <DotLegalTableWithControls
                    extraControls={undefined}
                    headers={GetDocumentHeader(translateString)}
                    getUserSpecificPageLength={() => 30}
                    hideRowsPerPage
                    defaultOrderBy={"name"}
                    defaultOrder={"asc"}
                    isLoading={isLoading}
                    data={data}
                    emptyText={translateString("noSharingsMatchesQuery")}
                    labelRowsPerPage={translateString("showEntites")}
                    labelSearch={translateString("search")}
                    noOptionsLabel={translateString("noOptions")}
                    paginationLabelOf={translateString("labelOf")}
                    renderRow={(row, i) => (
                        <TableRow key={i}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.fileName ?? row.link}</TableCell>
                            <TableCell>{translateDate(row.created)}</TableCell>
                            <TableCell align={"right"}>
                                <DotLegalOverflowMenu items={getMenuItems(row)} />
                            </TableCell>
                        </TableRow>
                    )}
                />
            ) : (
                <DotLegalEmptyState icon={<NoSharings />} text={translateString("noDocumentsAttachedToLegalEntity")} />
            )}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {permissions.systemPermissions.edit && (
                    <DotLegalButton
                        buttonType={"primary"}
                        onClick={() => {
                            setSelectedDocument(undefined);
                            setShowDocumentDialog(true);
                        }}
                    >
                        {translateString("createNew")}
                    </DotLegalButton>
                )}
            </Box>

            {showDocumentDialog && (
                <SystemDocumentDialog
                    onDialogClose={() => setShowDocumentDialog(false)}
                    selectedDocument={selectedDocument}
                    onSave={() => refetchDocuments()}
                />
            )}

            {showDeleteDialog && (
                <SystemDocumentDeleteDialog
                    onCloseDialog={() => setShowDeleteDialog(false)}
                    onDeletedDocument={() => refetchDocuments()}
                    systemId={props.systemId}
                    selectedDocument={selectedDocument!}
                />
            )}
        </DotLegalPaper>
    );
}

export default SystemDocumentTab;

function GetDocumentHeader(translateString: any) {
    const headers = Array<ITableHeader<SystemDocumentRowViewModel>>();
    headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
    headers.push({ property: "fileName", text: translateString("document"), align: "left", showOnMobile: true });
    headers.push({ property: "created", text: translateString("created"), align: "left", showOnMobile: true });
    return headers;
}
