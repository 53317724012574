import { useState } from "react";
import { useQuery } from "react-query";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { get } from "../../common/api/apiShared";
import { ValidationError } from "../../common/validationError";
import { useTranslation } from "../../localization/useTranslation";
import { useResponsibleQuery } from "../../user/hooks/useResponsibleQuery";
import { UserSelectableItem } from "../../user/User.types";
import { ProcessingActivityModel, SelectableColoredItem } from "../ProcessingActivity.types";
import { IAssociationStepProps } from "./AssociationStep";
import { useGroupEntitiesQuery } from "../hooks/useGroupEntitiesQuery.ts";

export function useAssociationStepDataMapping(props: IAssociationStepProps) {
    const [showBusinessAreaDialog, setShowBusinessAreaDialog] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const { permissions } = useUserContext();

    const [transferDialogContent, setTransferDialogContent] = useState({
        showDialog: false,
        userFrom: undefined as UserSelectableItem | undefined,
        userTo: undefined as UserSelectableItem | undefined,
    });

    let { allGroupEntities, isLoading } = useGroupEntitiesQuery(permissions.processingActivityAssociationPermissions.hasGroupEntitiesPermission);

    let areaQuery = useQuery("selectableBusinessAreas", () => get<Array<SelectableColoredItem>>("/BusinessAreas/businessareas"));
    let responsiblesQuery = useResponsibleQuery();

    let areasLoading = areaQuery.isLoading;
    let ownersLoading = responsiblesQuery.isLoading;

    let areasData;
    if (areaQuery.data) {
        areasData = areaQuery.data.map((d, index) => {
            return {
                name: d.name,
                id: d.id,
                color: d.color,
            };
        });
    }

    let ownersData: Array<UserSelectableItem> = [];
    let sharedResponsiblesData;
    if (responsiblesQuery.userData) {
        ownersData = responsiblesQuery.data(props.owner)?.filter((x) => !props.selectedSharedResponsibles.includes(x.id)) ?? [];

        sharedResponsiblesData = responsiblesQuery
            .dataForMultiSelect(props.selectedSharedResponsibles)
            ?.filter((x) => x.id !== props.owner)
            .map((d) => {
                return d;
            });
    }

    const onSaveBusinessArea = (response: any) => {
        var temp = [...props.processingAreas!];
        temp.push(response.id);
        props.onProcessingAreaChange(temp);

        areaQuery.refetch();
        setSearchValue("");
    };

    const updateOwner = (userId: string | null) => {
        const isUserChanged = userId && props.owner && userId !== props.owner;
        if (isUserChanged && ownersData && ownersData.length > 0) {
            const toUser = ownersData.find((x) => x.id === userId)!;
            const fromUser = ownersData.find((x) => x.id === props.owner!)!;
            setTransferDialogContent({ showDialog: true, userFrom: fromUser, userTo: toUser });
        }

        props.onOwnerChange(userId);
    };

    return {
        isLoading,
        data: allGroupEntities,
        areasData,
        ownersData,
        sharedResponsiblesData,
        areasLoading,
        ownersLoading,
        onSaveBusinessArea,
        searchValue,
        setSearchValue,
        showBusinessAreaDialog,
        setShowBusinessAreaDialog,
        transferDialogContent,
        setTransferDialogContent,
        updateOwner,
    };
}

export function useValidationAssociationStep(hasGroupEntityAccess: boolean, hasOwnershipAccess: boolean) {
    const { translateString } = useTranslation();
    return (model: ProcessingActivityModel, responsibles: Array<UserSelectableItem> | undefined): Array<ValidationError> => {
        const result = [];

        if (hasGroupEntityAccess && (!model.legalEntities || model.legalEntities.length === 0)) {
            result.push(new ValidationError("legalEntities", translateString("affiliationRequired")));
        }

        if (hasOwnershipAccess) {
            let user = responsibles?.find((x) => x.id === model.owner);
            if (user && !user.active) {
                result.push(new ValidationError("inActiveUserAffiliation", translateString("userDeactivated")));
            }
        }

        return result;
    };
}
