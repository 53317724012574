import Routing from "./Routing";
import RouterSwitch from "./RouterSwitch";
import Providers from "./Providers";
import React, { Suspense } from "react";
import Main from "./main/Main";
import { getSettings } from "./common/settingsProvider";
import PrivacyAuthProvider from "./auth/PrivacyAuthProvider";
import ExternalUserLoader from "./auth/ExternalUserLoader";
import UserContextProvider from "./auth/userContextProvider/UserContextProvider";
import CustomerDialog from "./auth/customerDialog/CustomerDialog";
import { TranslationProvider } from "./localization/LocalizationContext";
import ErrorBoundaryWrapper from "./errorPage/ErrorBoundaryWrapper";
import ErrorRouterSwitch from "./ErrorRouterSwitch";
import "./pdf.css";
import UserRouterSwitch from "./auth/UserRouterSwitch";
import { setupErrorHandling } from "./common/errorHandling";
import PlanProvider from "./auth/planProvider/PlanProvider";
import { DotLegalBreadCrumbModel, DotLegalFullScreenSpinner } from "@dotlegal/dotlegal-ui-components";
import { Route, Routes } from "react-router-dom";
import MicroFrontend from "./microFrontend/MicroFrontend";
import RedirectLegacyExternalQuestionnaireUrls from "./RedirectLegacyExternalQuestionnaireUrls.tsx";
import PublicInternalAssessmentInitiation from "./internalAssessment/publicInternalAssessmentTemplate/publicInitiation/PublicInternalAssessmentInitiation.tsx";
import PublicInternalAssessmentReceipt from "./internalAssessment/publicInternalAssessmentTemplate/publicInitiation/PublicInternalAssessmentReceipt.tsx";
import UserPilotProvider from "./auth/UserPilotProvider.tsx";
import LegalEntityAuditGroupManagementReport from "./legalEntityAuditGroup/legalEntityAuditGroupManagementReport/LegalEntityAuditGroupManagementReport.tsx";
import ComplianceReport from "./dashboard/complianceReport/ComplianceReport.tsx";
import AddTaskMenuItemProvider from "./common/components/addTaskMenuItem/AddTaskMenuItemContext.tsx";

setupErrorHandling();

// Date part is added to prevent caching of remoteEntry.js. Js references inside this file changes on new releases of the microfrontend. So caching leads to 404s.
(window as any).remoteURL = getSettings().questionnaireFrontEndUrl + "/assets/remoteEntry.js?" + new Date().getTime();

export interface IAppProps {
    breadCrumbsCallback?: (breadCrumbs: Array<DotLegalBreadCrumbModel>) => void;
}

function App(props: IAppProps) {
    let content = (
        <TranslationProvider>
            <ErrorRouterSwitch>
                <ErrorBoundaryWrapper>
                    <Routes>
                        <Route path="privacy/start-internal-assessment">
                            <Route path=":externalId" element={<PublicInternalAssessmentInitiation />} />
                            <Route path=":externalId/receipt" element={<PublicInternalAssessmentReceipt />} />
                        </Route>
                        <Route
                            path={"*"}
                            element={
                                <PrivacyAuthProvider>
                                    <UserRouterSwitch>
                                        <ExternalUserLoader>
                                            <CustomerDialog>
                                                <UserContextProvider>
                                                    <PlanProvider>
                                                        <UserPilotProvider>
                                                            <AddTaskMenuItemProvider>
                                                                <Routes>
                                                                    {/* "no-menu" is for reports shown from Privacy that requires login*/}
                                                                    <Route path="privacy/questionnaires/no-menu/*" element={<MicroFrontend />} />
                                                                    <Route
                                                                        path="privacy/audit-group/:id/management-report"
                                                                        element={<LegalEntityAuditGroupManagementReport />}
                                                                    />
                                                                    <Route path="privacy/dashboard/compliance-report/:id" element={<ComplianceReport />} />
                                                                    <Route
                                                                        path="*"
                                                                        element={
                                                                            <Main>
                                                                                <RouterSwitch />
                                                                            </Main>
                                                                        }
                                                                    />
                                                                </Routes>
                                                            </AddTaskMenuItemProvider>
                                                        </UserPilotProvider>
                                                    </PlanProvider>
                                                </UserContextProvider>
                                            </CustomerDialog>
                                        </ExternalUserLoader>
                                    </UserRouterSwitch>
                                </PrivacyAuthProvider>
                            }
                        />
                    </Routes>
                </ErrorBoundaryWrapper>
            </ErrorRouterSwitch>
        </TranslationProvider>
    );

    return (
        <React.StrictMode>
            <Suspense fallback={<DotLegalFullScreenSpinner />}>
                <Routing>
                    <Providers breadCrumbsCallback={props.breadCrumbsCallback}>
                        <Routes>
                            {/* "external" is for all external responses that are anonymous and does not require a menu*/}
                            <Route path="privacy/questionnaires/external/*" element={<MicroFrontend />} />
                            <Route path="privacy/questionnaires/response/*" element={<RedirectLegacyExternalQuestionnaireUrls />} />
                            <Route path="*" element={content} />
                        </Routes>
                    </Providers>
                </Routing>
            </Suspense>
        </React.StrictMode>
    );
}

export default App;
