import React from "react";
import { AnnualWheelActivityTemplateItem } from "../../AnnualWheelDialog.types";
import { Box, Grid } from "@mui/material";
import { SelectableActivityTemplate } from "../selectableActivityTemplate/SelectableActivityTemplate";
import { DotLegalButton, DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import EmptyStateIcon from "../../../../customLists/noCustomLists.svg?react";
import { useTranslation } from "../../../../localization/useTranslation";
import { SelectedAnnualWheelActivityTemplate } from "../../../annualWheelAddEditDialog/AnnualWheelAddEditDialog.types.ts";

export interface ICustomTemplates {
    templates: Array<AnnualWheelActivityTemplateItem>;
    onSelectActivity: (activity: AnnualWheelActivityTemplateItem) => void;
    selectedActivity?: SelectedAnnualWheelActivityTemplate;
    searchField: JSX.Element;
    onCopyClick: (activity: AnnualWheelActivityTemplateItem) => void;
    onDeleteClick: (activtyId: string) => void;
    onEditClick: (activity: AnnualWheelActivityTemplateItem) => void;
    hasCustomTemplates: boolean;
    onCreateClick: () => void;
    additionalFilterField?: JSX.Element;
}

function CustomTemplates(props: ICustomTemplates) {
    const { translateString } = useTranslation();

    return (
        <React.Fragment>
            <Box sx={{ display: "flex", justifyContent: "space-between", paddingBottom: 2 }}>
                <Box sx={{ display: "flex", gap: 1 }}>
                    <DotLegalButton onClick={props.onCreateClick} buttonType="secondary">
                        {translateString("createTemplate")}
                    </DotLegalButton>
                    {props.additionalFilterField}
                </Box>
                {props.searchField}
            </Box>

            {props.hasCustomTemplates ? (
                <Grid container columnSpacing={1}>
                    {props.templates.map((activity) => {
                        return (
                            <SelectableActivityTemplate
                                key={activity.type.toString() + activity.customTemplateId ?? ""}
                                activity={activity}
                                onClick={(activity: AnnualWheelActivityTemplateItem) => props.onSelectActivity(activity)}
                                selectedActivity={props.selectedActivity}
                                onCopyClick={() => props.onCopyClick(activity)}
                                onDeleteClick={() => props.onDeleteClick(activity.customTemplateId!)}
                                onEditClick={() => props.onEditClick(activity)}
                            />
                        );
                    })}
                </Grid>
            ) : (
                <DotLegalEmptyState icon={<EmptyStateIcon />} text={translateString("annualWheelActivityTemplateEmptyState")} />
            )}

            <Grid container columnSpacing={1}></Grid>
        </React.Fragment>
    );
}

export default CustomTemplates;
