import { AnnualWheelActivityType } from "../../annualWheel/annualWheelDialog/AnnualWheelDialog.types";

export interface PolicyRowModel {
    name: string;
    type: PolicyType;
    responsible?: string;
    responsibleId?: string;
    numberOfProcessingActivities: number;
    numberOfSystems: number;
    id: string;
    tags: Array<string>;
}

export interface PolicyRowModelTableModel extends PolicyRowModel {
    typeString: string;
}

export enum PolicyType {
    Other = 0,
    UpdatePrivacyPolicyWebsite = AnnualWheelActivityType.UpdatePrivacyPolicyWebsite, //21
    ReviewSocialMediaPrivacyPolicies = AnnualWheelActivityType.ReviewSocialMediaPrivacyPolicies, //22
    CheckCookiePolicyAndBanner = AnnualWheelActivityType.CheckCookiePolicyAndBanner, //23
    ReviewDeletionPolicy = AnnualWheelActivityType.ReviewDeletionPolicy, //24
    ReviewRequirementsForJobApplicants = AnnualWheelActivityType.ReviewRequirementsForJobApplicants, //28
    ReviewEmployeeDisclosure = AnnualWheelActivityType.ReviewEmployeeDisclosure, //29
    ReviewPolicyForDataSubjectsRights = AnnualWheelActivityType.ReviewPolicyForDataSubjectsRights, //30
    TestSecurityBreachProcedure = AnnualWheelActivityType.TestSecurityBreachProcedure, //35
    TestAndFollowUpITContingencyPlan = AnnualWheelActivityType.TestAndFollowUpITContingencyPlan, //37
    UpdateItSecurityPolicy = AnnualWheelActivityType.UpdateItSecurityPolicy, //39
    WhistleblowerPolicy = 40,
    EmployeeInstruction = 41,
    PrivacyPolicyForCustomers = 42,
    General = 43,
}

export interface PolicySaveModel {
    id: string;
    name: string;
    responsibleId: string | undefined | null;
    type?: PolicyType;
    tags: Array<string>;
}

export interface SelectablePolicyItem {
    id: string;
    name: string;
    type: PolicyType;
}
