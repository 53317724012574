import React from "react";
import { Box, Divider, Grid } from "@mui/material";
import { DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { ISOCategory } from "../IsoTemplates.types";
import { AnnualWheelActivityTemplateItem } from "../../../AnnualWheelDialog.types";
import { useTranslation } from "../../../../../localization/useTranslation";
import { SelectableActivityTemplate } from "../../selectableActivityTemplate/SelectableActivityTemplate";
import useIso27001TemplateHooks from "./Iso27002Templates.hooks";
import { Iso27002TemplateType } from "./Iso27002Templates.types";
import { SelectedAnnualWheelActivityTemplate } from "../../../../annualWheelAddEditDialog/AnnualWheelAddEditDialog.types.ts";

export interface IISO27002Templates {
    iso27002Templates: Array<ISOCategory>;
    iso27002_2013Templates: Array<ISOCategory>;
    searchField: JSX.Element;
    additionalFilterField?: JSX.Element;
    onSelectActivity: (activity: AnnualWheelActivityTemplateItem) => void;
    selectedActivity?: SelectedAnnualWheelActivityTemplate;
}

function ISO27002Templates(props: IISO27002Templates) {
    const { translateISO27001CategoryTypes } = useTranslation();
    const { selectedIsoType, setSelectedIsoType } = useIso27001TemplateHooks();

    const getISOTemplates = (templates: Array<ISOCategory>) => {
        const filteredTemplates = templates.filter((x) => x.hasTemplates());

        return (
            <Box>
                {filteredTemplates.map((x, index) => {
                    return (
                        <React.Fragment key={x.categoryType}>
                            <Box
                                key={x.categoryType}
                                sx={(theme) => ({
                                    marginTop: theme.spacing(2),
                                    marginLeft: theme.spacing(1),
                                    fontWeight: theme.typography.fontWeightBold,
                                    color: theme.palette.grey[500],
                                })}
                            >
                                {translateISO27001CategoryTypes(x.categoryType.toString())}
                            </Box>
                            <Grid container columnSpacing={1}>
                                {x.templates.map((activity) => {
                                    return (
                                        <SelectableActivityTemplate
                                            key={activity.type}
                                            activity={activity}
                                            isActivityLocked={false}
                                            selectedActivity={props.selectedActivity}
                                            onClick={props.onSelectActivity}
                                            addCustomIdToHeader
                                        />
                                    );
                                })}
                            </Grid>
                            {++index !== filteredTemplates.length && <Divider sx={{ marginTop: 1, marginBottom: 3, color: "#D9DAEE" }} />}
                        </React.Fragment>
                    );
                })}
            </Box>
        );
    };

    const getISO27002_2022Templates = () => {
        return getISOTemplates(props.iso27002Templates);
    };

    const getISO27002_2013Templates = () => {
        return getISOTemplates(props.iso27002_2013Templates);
    };

    return (
        <React.Fragment>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", gap: 1 }}>
                    <DotLegalSelect
                        selectedItem={selectedIsoType}
                        options={[
                            { id: Iso27002TemplateType.ISO27002_2022.toString(), name: "ISO 27002:2022" },
                            { id: Iso27002TemplateType.ISO27002_2013.toString(), name: "ISO 27002:2013" },
                        ]}
                        onChange={(item) => setSelectedIsoType(item!)}
                        placeholder={""}
                        label={""}
                        noOptionsLabel={""}
                        disableClearable
                        noMargin
                        width={"200px"}
                    />
                    {props.additionalFilterField}
                </Box>
                {props.searchField}
            </Box>
            <Box>{selectedIsoType === Iso27002TemplateType.ISO27002_2022.toString() ? getISO27002_2022Templates() : getISO27002_2013Templates()}</Box>
        </React.Fragment>
    );
}

export default ISO27002Templates;
