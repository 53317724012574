import { useQuery } from "react-query";
import { postGet } from "../../common/api/apiShared";
import { SelectableDocumentItem } from "../ProcessingActivity.types";

export function useSelectableDocumentsQuery(isEnabled: boolean = true, legalEntityId?: string, processingActivityId?: string) {
    return useSelectableDocumentsForLegalEntitiesQuery(isEnabled, legalEntityId ? [legalEntityId] : [], processingActivityId);
}

export function useSelectableDocumentsForLegalEntitiesQuery(isEnabled: boolean = true, legalEntityIds: Array<string>, processingActivityId?: string) {
    const url = getDocumentQueryUrl(processingActivityId);
    let query = useQuery(
        "useSelectableDocumentsForLegalEntitiesQuery" + legalEntityIds.join("|"),
        () => postGet<SelectableLegalEntityDocumentLists>(url, legalEntityIds),
        {
            enabled: isEnabled && legalEntityIds.length > 0,
        }
    );
    return {
        isLoading: query.isLoading,
        dataProcessorDocuments: query?.data?.dataProcessorDocuments ?? [],
        disclosureDocuments: query?.data?.disclosureDocuments ?? [],
        jointDataControllerDocuments: query?.data?.jointDataControllerDocuments ?? [],
        transferImpactAssessmentDocuments: query?.data?.transferImpactAssessmentDocuments ?? [],
        sourceDocuments: query?.data?.sourceDocuments ?? [],
        allDocuments: query?.data?.allDocuments ?? [],
        refetch: query.refetch,
    };
}

function getDocumentQueryUrl(processingActivityId?: string) {
    if (processingActivityId) {
        return `/LegalEntity/selectableDocuments/${processingActivityId}`;
    }

    return `/LegalEntity/selectableDocuments`;
}

export interface SelectableLegalEntityDocumentLists {
    dataProcessorDocuments: Array<SelectableDocumentItem>;
    disclosureDocuments: Array<SelectableDocumentItem>;
    sourceDocuments: Array<SelectableDocumentItem>;
    jointDataControllerDocuments: Array<SelectableDocumentItem>;
    transferImpactAssessmentDocuments: Array<SelectableDocumentItem>;
    allDocuments: Array<SelectableDocumentItem>;
}

export function getUserIdForDocumentQuery(userProfileId: string, sharedResponsibles: Array<string>, processingActivityOwner?: string | undefined | null) {
    if (processingActivityOwner && sharedResponsibles.some((x) => x === userProfileId)) {
        return processingActivityOwner;
    }

    return null;
}
