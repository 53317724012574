import { useEffect } from "react";
import { IGroupEntityAcceesSelector } from "./GroupEntityAccessSelector";
import { useGroupEntitiesQuery } from "../../processingActivity/hooks/useGroupEntitiesQuery.ts";

export function useGroupEntityAccessSelectorHooks(props: IGroupEntityAcceesSelector) {
    const groupEntitiesQuery = useGroupEntitiesQuery();

    let selectableGroupCompaniesData = groupEntitiesQuery.allGroupEntities;
    let selectableGroupCompaniesLoading = groupEntitiesQuery.isLoading;

    useEffect(() => {
        if (!props.isEdit && selectableGroupCompaniesData && selectableGroupCompaniesData.length === 1) {
            props.onGroupEntityAccessIdsChange(selectableGroupCompaniesData.map((x) => x.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectableGroupCompaniesData, props.isEdit]);

    return {
        selectableGroupCompaniesData,
        selectableGroupCompaniesLoading,
    };
}
