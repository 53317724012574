import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { useAuth } from "oidc-react";
import { useState } from "react";
import { riskAreaAndComplianceAreaKeyStorageKey } from "../../riskAssessment/riskAssessments/RIskAssessments.hooks";
import { IdNamePair } from "../../customLists/customListView/CustomListView.types.ts";

export function useDataMapping() {
    const auth = useAuth();
    const urlCustomerId = new URLSearchParams(window.location.search).get("customerId");

    let chooseCustomer = Boolean(localStorage.getItem("customerId") === null);

    if (localStorage.getItem("customerId") === null && urlCustomerId) chooseCustomer = false;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [customerId, setCustomerId] = useState("");
    const [showDialog, setShowDialog] = useState(chooseCustomer);
    const url = `/customerSelector?token=${auth.userData?.access_token!}`;
    const query = useQuery(url, () => get<Array<IdNamePair>>(url), { enabled: chooseCustomer });

    function onCustomerChosen(id: string | null) {
        setShowDialog(false);
        if (id) {
            localStorage.removeItem(riskAreaAndComplianceAreaKeyStorageKey);
            localStorage.setItem("customerId", id);
            setCustomerId(id);
        } else {
            auth.userManager.signoutRedirect();
        }
    }

    function onDialogClose() {
        setShowDialog(false);
        onCustomerChosen(null);
    }

    return {
        isLoading: query.isLoading,
        customers: query.data ?? [],
        onCustomerChosen,
        onDialogClose,
        showDialog: showDialog && (query.data?.length ?? 0) > 1,
    };
}
