import { createElement, useState } from "react";
import { useQuery } from "react-query";
import { get } from "../common/api/apiShared";
import {
    AnnualWheelActivityTableModel,
    AnnualWheelActivityViewModel,
    AnnualWheelMonthEnum,
    AnnualWheelViewModel,
    TaskRawStateEnum,
    TaskStateEnum,
} from "./AnnualWheel.types";
import { useUrlProvider } from "../useUrlProvider";
import { getSettings } from "../common/settingsProvider";
import { createPdfUrl } from "../common/pdfUrlHelper";
import { useDotLegalSnackbar, useStateLocalStorage } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";
import { downloadFile } from "../common/api/downloadFile";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider";
import { useSetPdfPageSize } from "../common/pdfPageSizeHelper";
import { useTranslation } from "../localization/useTranslation";
import { AnnualWheelActivityType, AnnualWheelPriority } from "./annualWheelDialog/AnnualWheelDialog.types";
import { DotLegalSelectOption } from "../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { getEnumValues } from "../common/enumOperations";
import { isNullOrWhitespace, setFirstLetterToLowerCase } from "../common/stringOperations";
import { useStateUrlParamsArray } from "../common/hooks/useStateUrlParams";
import { SelectedAnnualWheelActivityTemplate } from "./annualWheelAddEditDialog/AnnualWheelAddEditDialog.types.ts";
import { AnnualWheelActivityTemplateSaveModel } from "./customTemplateAddEditDialog/CustomTemplateAddEditDialog.types.ts";

export function useAnnualWheelDataMapping() {
    useSetPdfPageSize("landscape");

    const snackBar = useDotLegalSnackbar();
    const { customerName, language, permissions } = useUserContext();
    const { getAnnualWheelPDFUrl } = useUrlProvider();
    const [showTemplateAnnualWheelActivityDialog, setShowTemplateAnnualWheelActivityDialog] = useState(false);
    const [year, setYear] = useStateLocalStorage("annualWheelYear", new Date().getFullYear());
    const [month, setMonth] = useState<AnnualWheelMonthEnum | undefined>(undefined);
    const [activity, setActivity] = useState<AnnualWheelActivityViewModel | undefined>(undefined);
    const [showDeleteActivityDialog, setShowDeleteActivityDialog] = useState({ showDialog: false, activityName: "" });
    const [isDownloadingPdf, setIsDownloadingPdf] = useState<boolean>(false);
    const [isDownloadingReport, setIsDownloadingReport] = useState<boolean>(false);
    const { translateString } = useTranslation();
    const [showListView, setShowListView] = useStateLocalStorage("ShowAnnualWheelListView", true);
    const [searchedMonths, setSearchedMonths] = useStateUrlParamsArray("months", []);
    const [searchedActivities, setSearchedActivities] = useStateUrlParamsArray("activities", []);
    const [searchedPriorities, setSearchedPriorities] = useStateUrlParamsArray("priorities", []);
    const [searchedStatus, setSearchedStatus] = useStateUrlParamsArray("status", []);
    const [searchedAreas, setSearchedAreas] = useStateUrlParamsArray("areas", []);
    const [searchedResponisbles, setSearchedResponsibles] = useStateUrlParamsArray("responsibles", []);
    const [searchedBusinessAreas, setSearchedBusinessAreas] = useStateUrlParamsArray("businessAreas", []);
    const [searchedGroupCompanies, setSearchedGroupCompanies] = useStateUrlParamsArray("groupCompany", []);
    const [searchedString, setSearchedString] = useState("");

    const [selectedAnnualWheelActivityTemplate, setSelectedAnnualWheelActivityTemplate] = useState<SelectedAnnualWheelActivityTemplate | undefined>(undefined);
    const [showAddEditDialog, setShowAddEditDialog] = useState(false);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const isPdf = Boolean(urlParams.get("isPdf"));
    const pdfYear = Number(urlParams.get("year"));
    const pdfCustomer = urlParams.get("customer");

    const url = `/annualwheel?year=${isPdf ? pdfYear : year}`;
    const { isLoading, data, refetch } = useQuery(url, () => get<AnnualWheelViewModel>(url));

    const { getTaskManagementUrl } = useUrlProvider();

    function refetchData() {
        refetch();
        if (activity) {
            setActivity(undefined);
        }
    }

    let monthsFilterOptions: Array<DotLegalSelectOption> = [];
    let prioritiesFilterOptions: Array<DotLegalSelectOption> = [];
    let statusFilterOptions: Array<DotLegalSelectOption> = [];
    let areasFilterOptions: Array<DotLegalSelectOption> = [];
    let businessAreasFilterOptions: Array<DotLegalSelectOption> = [];
    let responsiblesFilterOptions: Array<DotLegalSelectOption> = [];
    let groupCompanyOptions: Array<DotLegalSelectOption> = [];
    let annualWheelActivityOptions: Array<DotLegalSelectOption> = [];
    let searchableAnnualWheelActivities: Array<AnnualWheelActivityTableModel> = [];

    function convertToTableModel(annualWheelActivities: Array<AnnualWheelActivityViewModel>): Array<AnnualWheelActivityTableModel> {
        return annualWheelActivities.map((x) => {
            return {
                ...x,
                monthString: translateString(setFirstLetterToLowerCase(AnnualWheelMonthEnum[x.month].toString())),
                priorityString: translateString(setFirstLetterToLowerCase(AnnualWheelPriority[x.priority].toString())),
                statusString: translateString(setFirstLetterToLowerCase(TaskStateEnum[x.taskStatus].toString())),
                businessAreaString: x.businessAreas.map((x) => x.name).join(", "),
                tagsString: x.tags.map((x) => x.name).join(", "),
                complianceAreaString: x.areas.map((x) => x.name).join(", "),
                recurringIntervalInMonthsString: x.recurringIntervalInMonths
                    ? x.recurringIntervalInMonths === 1
                        ? translateString("everyMonth")
                        : translateString("repertEveryXMonth", { repetition: x.recurringIntervalInMonths })
                    : translateString("oneTimeActivity"),
                documentationRequiredString: translateString(x.documentationRequired ? "yes" : "no"),
                groupCompaniesString: x.groupCompanies.length === 0 ? translateString("all") : x.groupCompanies.map((x) => x.name).join(", "),
            };
        });
    }

    if (data) {
        convertToTableModel(data.annualWheelActivities);
        createMonthOptions(data.annualWheelActivities);
        createPriorityOptions(data.annualWheelActivities);
        createStatusOptions(data.annualWheelActivities);
        createAnnualWheelActivityOptions(data.annualWheelActivities);
        createLegalEntityOptions(data.groupCompanyOptions);
        businessAreasFilterOptions = data.businessAreaOptions;
        responsiblesFilterOptions = data.userOptions;
        areasFilterOptions = data.areaOptions;

        searchableAnnualWheelActivities = convertToTableModel(data.annualWheelActivities);
    }

    const hasActivities = isLoading || data!.annualWheelActivities.length > 0;

    function getFilteredData(activities: Array<AnnualWheelActivityTableModel>) {
        let tempData = [...activities];

        if (searchedMonths.length > 0 && !isNullOrWhitespace(searchedMonths[0])) {
            tempData = tempData.filter((row) => searchedMonths.indexOf(row.month.toString() ?? "") >= 0);
        }

        if (searchedPriorities.length > 0 && !isNullOrWhitespace(searchedPriorities[0])) {
            tempData = tempData.filter((row) => searchedPriorities.indexOf(row.priority.toString() ?? "") >= 0);
        }

        if (searchedStatus.length > 0 && !isNullOrWhitespace(searchedStatus[0])) {
            tempData = tempData.filter((row) => searchedStatus.indexOf(row.taskStatus.toString() ?? "") >= 0);
        }

        if (searchedResponisbles.length > 0 && !isNullOrWhitespace(searchedResponisbles[0])) {
            tempData = tempData.filter((row) => row.responsibles.filter((x) => searchedResponisbles.includes(x.id)).length > 0);
        }

        if (searchedAreas.length > 0 && !isNullOrWhitespace(searchedAreas[0])) {
            tempData = tempData.filter((row) => row.areas.filter((x) => searchedAreas.includes(x.id)).length > 0);
        }

        if (searchedBusinessAreas.length > 0 && !isNullOrWhitespace(searchedBusinessAreas[0])) {
            tempData = tempData.filter((row) => row.businessAreas.filter((x) => searchedBusinessAreas.includes(x.id)).length > 0);
        }

        if (searchedGroupCompanies.length > 0 && !isNullOrWhitespace(searchedGroupCompanies[0])) {
            tempData = tempData.filter(
                (row) =>
                    row.groupCompanies.filter((gc) => searchedGroupCompanies.some((id) => gc.id === id)).length > 0 ||
                    (row.groupCompanies.length === 0 && searchedGroupCompanies.includes("null"))
            );
        }

        if (hasSearchValue(searchedActivities)) {
            tempData = tempData.filter((row) => searchedActivities.includes(row.id));
        }

        if (searchedString.length > 0) {
            tempData = tempData.filter(
                (row) =>
                    row.name.toLowerCase().includes(searchedString.toLowerCase()) ||
                    (row.customId && row.customId.toLowerCase().includes(searchedString.toLowerCase())) ||
                    row.priorityString.toLowerCase().includes(searchedString.toLowerCase()) ||
                    row.statusString.toLowerCase().includes(searchedString.toLowerCase()) ||
                    row.businessAreas.some((x) => x.name.toLowerCase().includes(searchedString.toLowerCase())) ||
                    row.areas.some((x) => x.name.toLowerCase().includes(searchedString.toLowerCase())) ||
                    row.tags.some((x) => x.name.toLowerCase().includes(searchedString.toLowerCase())) ||
                    row.monthString.toLowerCase().includes(searchedString.toLowerCase()) ||
                    row.responsibles.some((x) => x.name.toLowerCase().includes(searchedString.toLowerCase())) ||
                    row.relatedActivities.some((x) => x.toLowerCase().includes(searchedString.toLowerCase())) ||
                    row.recurringIntervalInMonthsString.toLowerCase().includes(searchedString.toLowerCase()) ||
                    row.groupCompaniesString.toLowerCase().includes(searchedString.toLowerCase())
            );
        }

        return tempData;
    }

    function hasSearchValue(searched: Array<string>) {
        return searched.length > 0 && !isNullOrWhitespace(searched[0]);
    }

    function createMonthOptions(activities: Array<AnnualWheelActivityViewModel>) {
        return getEnumValues(AnnualWheelMonthEnum).forEach((x) => {
            const name = translateString(setFirstLetterToLowerCase(AnnualWheelMonthEnum[x].toString()));
            if (!monthsFilterOptions.some((s) => s.id === x.toString())) {
                const option = activities.map((d) => d.month.toString()).find((t) => t === x.toString());
                if (option) {
                    monthsFilterOptions.push({ name: name, id: x.toString() });
                }
            }
        });
    }

    function createPriorityOptions(activities: Array<AnnualWheelActivityViewModel>) {
        return getEnumValues(AnnualWheelPriority).forEach((x) => {
            const name = translateString(setFirstLetterToLowerCase(AnnualWheelPriority[x].toString()));
            if (!prioritiesFilterOptions.some((s) => s.id === x.toString())) {
                const option = activities.map((d) => d.priority.toString()).find((t) => t === x.toString());
                if (option) {
                    prioritiesFilterOptions.push({ name: name, id: x.toString() });
                }
            }
        });
    }

    function createStatusOptions(activities: Array<AnnualWheelActivityViewModel>) {
        return getEnumValues(TaskStateEnum).forEach((x) => {
            const name = translateString(setFirstLetterToLowerCase(TaskStateEnum[x].toString()));
            if (!statusFilterOptions.some((s) => s.id === x.toString())) {
                const option = activities.map((d) => d.taskStatus.toString()).find((t) => t === x.toString());
                if (option) {
                    statusFilterOptions.push({ name: name, id: x.toString() });
                }
            }
        });
    }

    function createLegalEntityOptions(legalEntities: Array<DotLegalSelectOption>) {
        legalEntities
            .filter((x) => data?.annualWheelActivities.some((a) => a.groupCompanies.some((gc) => gc.id === x.id)))
            .forEach((x) => {
                groupCompanyOptions.push({ id: x.id, name: x.name });
            });

        groupCompanyOptions.push({ id: "null", name: translateString("globalAnnualWheelActivity") });
    }

    function createAnnualWheelActivityOptions(activities: Array<AnnualWheelActivityViewModel>) {
        activities
            .filter((item, index, self) => index === self.findIndex((t) => t.id === item.id))
            .forEach((x) => {
                annualWheelActivityOptions.push({ id: x.id, name: x.name });
            });
    }

    function onBoxCounterClick(status: TaskStateEnum) {
        const temp = [...searchedStatus];

        const index = temp.indexOf(status.toString());
        if (index > -1) {
            temp.splice(index, 1);
        } else {
            if (isNullOrWhitespace(temp[0])) {
                temp[0] = status.toString();
            } else {
                temp.push(status.toString());
            }
        }

        setSearchedStatus(temp);
    }

    async function onDownloadPdfClick() {
        const fileName = "annual-wheel";
        setIsDownloadingPdf(true);
        let queryUrl = getSettings().mainSiteBaseUrl + getAnnualWheelPDFUrl() + `?&isPdf=true&year=${year}&customer=${customerName}`;
        await downloadFile(getSettings().apiBaseUrl + "/pdf/generate?url=" + createPdfUrl(queryUrl, language), `${fileName}.pdf`).then((x) => {
            snackBar.show(createElement(Trans, { i18nKey: "pdfDownloadComplete", values: { fileName: fileName } }));
            setIsDownloadingPdf(false);
        });
    }

    function getAnnualWheelExcelExportTranslations(): {
        priority: string;
        status: string;
        title: string;
        deadline: string;
        completedDate: string;
        completedBy: string;
        comment: string;
        description: string;
        documentation: string;
        businessArea: string;
        responsible: string;
        recurrence: string;
        customId: string;
        complianceAreas: string;
        legalEntity: string;
        entireGroup: string;
    } {
        return {
            priority: translateString("priority"),
            status: translateString("status"),
            title: translateString("activity"),
            deadline: translateString("deadline"),
            completedDate: translateString("completedDate"),
            completedBy: translateString("completedBy"),
            comment: translateString("pinnedComments"),
            description: translateString("description"),
            documentation: translateString("requiredDocumentation"),
            businessArea: translateString("businessArea"),
            responsible: translateString("responsible"),
            recurrence: translateString("recurrence") + ` (${translateString("every")} X ${translateString("months")})`,
            customId: "ID",
            complianceAreas: translateString("areas"),
            legalEntity: translateString("groupCompany"),
            entireGroup: translateString("entireGroup"),
        };
    }

    async function onClickDownloadExcelReport() {
        setIsDownloadingReport(true);
        const reportName = translateString("annualWheelReport") + ` ${year}`;
        const fileName = `${reportName} ${new Date().getTime()}.xlsx`;
        const headerTranslations = getAnnualWheelExcelExportTranslations();
        const priorityEnumTranslations: { [K in keyof typeof AnnualWheelPriority]: string } = {
            VeryLow: translateString("veryLow"),
            Low: translateString("low"),
            Moderate: translateString("moderate"),
            High: translateString("high"),
            VeryHigh: translateString("veryHigh"),
        };
        const statusEnumTranslations: { [K in keyof typeof TaskRawStateEnum]: string } = {
            InProgress: translateString("inProgress"),
            Completed: translateString("completed"),
            Ready: translateString("ready"),
            ReadyForApproval: translateString("readyForApproval"),
        };
        const booleanTranslation: { True: string; False: string } = {
            True: translateString("true"),
            False: translateString("false"),
        };
        await downloadFile(getSettings().apiBaseUrl + "/annualWheel/excelExport", fileName, "POST", {
            name: reportName,
            year: year,
            headerTranslations,
            priorityEnumTranslations,
            statusEnumTranslations,
            booleanTranslation,
        });
        setIsDownloadingReport(false);
    }

    function getTaskManagementUrlForMonth(month: AnnualWheelMonthEnum) {
        return getTaskManagementUrl() + "?months=" + month.toString();
    }

    const onCustomTemplateCreated = (customTemplateModel: AnnualWheelActivityTemplateSaveModel) => {
        setSelectedAnnualWheelActivityTemplate({
            name: customTemplateModel.name,
            description: customTemplateModel.description,
            subTasks: customTemplateModel.subTasks,
            type: AnnualWheelActivityType.Custom,
            customTemplateId: customTemplateModel.id,
            interval: 0,
            notificationPeriodInDays: 14,
            framework: undefined,
            customId: undefined,
            priority: AnnualWheelPriority.Moderate,
            planNow: true,
            showTrialWarning: false,
            notPartOfCurrentPlan: false,
        });
        setShowAddEditDialog(true);
        setShowTemplateAnnualWheelActivityDialog(false);
    };

    const onAddEditDialogClosed = () => {
        setSelectedAnnualWheelActivityTemplate(undefined);
        setShowAddEditDialog(false);
        if (activity) {
            setActivity(undefined);
        }
    };

    const onAddEditPreviousClick = (isEdit: boolean) => {
        if (isEdit) {
            setActivity(undefined);
        } else {
            setShowTemplateAnnualWheelActivityDialog(true);
        }
        setShowAddEditDialog(false);
    };

    const onTemplateSelected = (template: SelectedAnnualWheelActivityTemplate | undefined) => {
        setSelectedAnnualWheelActivityTemplate(template);
        if (template) {
            setShowAddEditDialog(true);
            setShowTemplateAnnualWheelActivityDialog(false);
        }
    };

    function getStartDate() {
        const monthMinus1 = (month ?? new Date().getMonth()) - 1;
        return new Date(year!, monthMinus1, 15);
    }

    const onMonthAddClicked = (month: AnnualWheelMonthEnum | undefined) => {
        setMonth(month);
        setShowAddEditDialog(true);
    };

    return {
        isLoading,
        data,
        showTemplateAnnualWheelActivityDialog,
        setShowTemplateAnnualWheelActivityDialog,
        refetchData,
        year,
        setYear,
        setMonth,
        activity,
        setActivity,
        showDeleteActivityDialog,
        setShowDeleteActivityDialog,
        onDownloadPdfClick,
        isDownloadingPdf,
        pdfYear,
        customerName,
        pdfCustomer,
        permissions,
        onClickDownloadExcelReport,
        isDownloadingReport,
        showListView,
        setShowListView,
        monthsFilterOptions,
        prioritiesFilterOptions,
        statusFilterOptions,
        areasFilterOptions,
        businessAreasFilterOptions,
        responsiblesFilterOptions,
        annualWheelActivityOptions,
        setSearchedMonths,
        setSearchedPriorities,
        setSearchedStatus,
        setSearchedAreas,
        setSearchedResponsibles,
        setSearchedBusinessAreas,
        setSearchedActivities,
        searchedMonths,
        searchedPriorities,
        searchedStatus,
        searchedAreas,
        searchedResponisbles,
        searchedBusinessAreas,
        searchedActivities,
        getFilteredData,
        searchedString,
        setSearchedString,
        onBoxCounterClick,
        getTaskManagementUrlForMonth,
        groupCompanyOptions,
        searchedGroupCompanies,
        setSearchedGroupCompanies,
        hasActivities,
        onCustomTemplateCreated,
        selectedAnnualWheelActivityTemplate,
        setSelectedAnnualWheelActivityTemplate,
        showAddEditDialog,
        setShowAddEditDialog,
        onAddEditDialogClosed,
        onTemplateSelected,
        getStartDate,
        onAddEditPreviousClick,
        onMonthAddClicked,
        searchableAnnualWheelActivities,
    };
}
