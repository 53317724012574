import {
    AgreementSaveModel,
    DataProcessorAgreementSaveModel,
} from "../../../../processingActivity/sharingOfDataStep/agreementDialog/DataProcessorAgreementDialog.types";
import { SelectedLegalEntityDocument } from "../../../../legalEntity/legalEntityDocumentTab/LegalEntityDocumentTab.types";
import { useSelectableDocumentsForLegalEntitiesQuery, useSelectableDocumentsQuery } from "../../../../processingActivity/hooks/useSelectableDocumentQuery";
import { ISystemSupplier } from "./SystemSupplier";
import { useState } from "react";
import { SystemModel, SystemSupplierViewModel } from "../../SystemEdit.types";
import { useMutation, useQueryClient } from "react-query";
import { getSystemEditQueryKey } from "../../SystemEdit.hooks";
import { ajaxQueue } from "../../../../common/api/ajaxQueue";
import { deleteHttp, post, put } from "../../../../common/api/apiShared";
import { isBool } from "../../../../common/booleanOperations";
import { usePlanContext } from "../../../../auth/planProvider/PlanProvider";
import { LevelOfProtectionType } from "../../../../legalEntity/addLegalEntityDialog/AddLegalEntityDialog.types";
import { EUToUSDataPrivacyFrameworkId } from "../../../../legalBasis/TransferBasis.Helper";

export function useSystemSupplierHooks(props: ISystemSupplier) {
    const queryClient = useQueryClient();
    const { informationSecurityFeatures } = usePlanContext();

    const [showDescription, setShowDescription] = useState(false);
    const [showSecondarySuppliers, setShowSecondarySuppliers] = useState(false);
    const [dataProcessorAgreementSaveModel, setDataProcessorAgreementSaveModel] = useState<DataProcessorAgreementSaveModel | undefined>(undefined);
    const [selectedDocument, setSelectedDocument] = useState<SelectedLegalEntityDocument>();
    const [showEditDocumentDialog, setShowEditDocumentDialog] = useState(false);

    const queryKey = getSystemEditQueryKey(props.system.id);

    let documentsQuery = useSelectableDocumentsQuery(true, props.supplier.legalEntityId);

    let subProcessorsDocumentsQuery = useSelectableDocumentsForLegalEntitiesQuery(
        true,
        props.supplier.secondarySuppliers.filter((s) => s.dataProcessorId).map((s) => s.dataProcessorId!) ?? []
    );

    let documentsLoading = documentsQuery.isLoading;

    let dataProcessorAgreementDocs = documentsQuery.dataProcessorDocuments;
    let transferImpactAssessmentDocs = documentsQuery.transferImpactAssessmentDocuments;

    let subProcessorsTIADocsLoading = subProcessorsDocumentsQuery.isLoading;
    let subProcessorsTIADocs = subProcessorsDocumentsQuery.transferImpactAssessmentDocuments;

    const removeAgreementMutation = useMutation(removeAgreementAPI, {
        onSuccess: (data) => {
            const system = data.value();
            queryClient.setQueryData(queryKey, system);
        },
    });
    const addAgreementMutation = useMutation(addAgreementAPI, {
        onSuccess: (data) => {
            const system = data.value();
            queryClient.setQueryData(queryKey, system);
        },
    });

    const updateSupplierMutation = useMutation(updateSupplierAPI, {
        onSuccess: (data) => {
            const system = data.value();
            queryClient.setQueryData(queryKey, system);
        },
    });

    const updateSupplier = async (changeFunc: (supplier: SystemSupplierViewModel) => void) => {
        const temp = { ...props.system };
        let tempSupplier = temp.suppliers.find((x) => x.id === props.supplier.id)!;
        changeFunc(tempSupplier);

        queryClient.setQueryData(queryKey, temp);
        await ajaxQueue().addRequest(updateSupplierMutation.mutateAsync, {
            systemId: props.system.id,
            supplier: tempSupplier,
        });
    };

    const supplierUpdater = {
        onLegalEntityChange: (legalEntityId: string | null) => {
            updateSupplier((s) => {
                s.legalEntityId = legalEntityId ?? undefined;
                s.dataProcessorAgreementIds = [];
                s.transferImpactAssessmentId = undefined;
                s.hasTransferImpactAssessment = false;
                s.hasDataProcessorAgreement = false;
                s.transferBasisId = undefined;

                let selectedLegalEntity = props.legalEntityData?.find((x) => x.id === legalEntityId);

                if (selectedLegalEntity?.levelOfProtection === LevelOfProtectionType.DataPrivacyFramework) {
                    s.transferBasisId = EUToUSDataPrivacyFrameworkId;
                }
            });
        },
        onIsSupplierADataProcessorChange: (isDataProcessor: boolean) => {
            updateSupplier((s) => {
                s.isSupplierADataProcessor = isDataProcessor;

                if (!isDataProcessor) {
                    s.generateSharings = false;
                    s.transferBasisId = undefined;
                    s.transferImpactAssessmentId = undefined;
                    s.hasTransferImpactAssessment = false;
                } else {
                    s.generateSharings = informationSecurityFeatures.automaticRegistrationOfDataProcessorTransfers;
                }
            });
        },
        onGenerateSharingsChange: (value: boolean) => {
            updateSupplier((s) => {
                s.generateSharings = value;
            });
        },
        onTransferBasisChange: (value: string | null) => {
            updateSupplier((s) => {
                s.transferBasisId = value ?? undefined;
            });
        },
        onTransferImpactAssessmentChange: (tia: string | null) => {
            updateSupplier((s) => {
                if (tia) {
                    if (isBool(tia)) {
                        s.hasTransferImpactAssessment = tia.toLowerCase() === "true";
                        s.transferImpactAssessmentId = undefined;
                    } else {
                        s.transferImpactAssessmentId = tia;
                        s.hasTransferImpactAssessment = false;
                    }
                }
            });
        },
        onDataProcessorAgreementChange: (agreements: Array<string>) => {
            updateSupplier((s) => {
                if (agreements.length > 0) {
                    const boolAgreement = agreements.find((x) => isBool(x));
                    const isDocumentAdd = s.dataProcessorAgreementIds.length === 0 && agreements.length > 1;

                    if (boolAgreement && isDocumentAdd === false) {
                        let bool = boolAgreement.toLowerCase() === "true";
                        s.hasDataProcessorAgreement = bool;
                        s.dataProcessorAgreementIds = [];
                    } else {
                        s.dataProcessorAgreementIds = agreements;
                        s.hasDataProcessorAgreement = false;
                    }
                } else {
                    s.dataProcessorAgreementIds = [];
                }
            });
        },
        onServiceChange: (service: string) => {
            updateSupplier((s) => {
                s.service = service;
            });
        },
        onDescriptionChange: (description: string) => {
            updateSupplier((s) => {
                s.description = description;
            });
        },
        onAddAgreement: async (agreementId: string) => {
            const tempSystem = { ...props.system };
            const supplier = tempSystem.suppliers.find((d) => d.id === props.supplier.id)!;

            supplier.dataProcessorAgreementIds.push(agreementId);

            queryClient.setQueryData(queryKey, tempSystem);
            await ajaxQueue().addRequest(addAgreementMutation.mutateAsync, {
                systemId: props.system.id,
                supplierId: supplier.id,
                saveModel: { agreementId: agreementId },
            });
        },
        onDeleteAgreement: async (agreementId: string) => {
            const tempSystem = { ...props.system };
            const supplier = tempSystem.suppliers.find((d) => d.id === props.supplier.id)!;

            supplier.dataProcessorAgreementIds = supplier.dataProcessorAgreementIds.filter((x) => x !== agreementId);

            queryClient.setQueryData(queryKey, tempSystem);
            await ajaxQueue().addRequest(removeAgreementMutation.mutateAsync, {
                systemId: props.system.id,
                supplierId: supplier.id,
                saveModel: { agreementId: agreementId },
            });
        },
        onHasAgreementChange: async (hasAgreement: boolean) => {
            const tempSystem = { ...props.system };
            const supplier = tempSystem.suppliers.find((d) => d.id === props.supplier.id)!;

            supplier.hasDataProcessorAgreement = hasAgreement;
            supplier.dataProcessorAgreementIds = [];

            queryClient.setQueryData(queryKey, tempSystem);
            await ajaxQueue().addRequest(removeAgreementMutation.mutateAsync, {
                systemId: props.system.id,
                supplierId: supplier.id,
                saveModel: { hasAgreement: hasAgreement },
            });
        },
    };

    const addSecondarySupplierMutation = useMutation(addSecondarySupplierAPI, {
        onSuccess: (data) => {
            const system = data.value();
            queryClient.setQueryData(queryKey, system);
        },
    });

    const addSecondarySupplier = async (isLink: boolean) => {
        await addSecondarySupplierMutation.mutateAsync(isLink);
    };

    const removeSecondarySupplierMutation = useMutation(removeSecondarySupplierApi, {
        onSuccess: (data) => {
            const system = data.value();
            queryClient.setQueryData(queryKey, system);
        },
    });

    const removeSecondarySupplier = async (secondarySupplierId: string) => {
        const temp = { ...props.system };
        let tempSupplier = temp.suppliers.find((x) => x.id === props.supplier.id)!;
        tempSupplier.secondarySuppliers = tempSupplier.secondarySuppliers.filter((x) => x.id !== secondarySupplierId);

        queryClient.setQueryData(queryKey, temp);

        await removeSecondarySupplierMutation.mutateAsync({ secondarySupplierId: secondarySupplierId });
    };

    return {
        documentsLoading,
        dataProcessorAgreementDocs,
        transferImpactAssessmentDocs,
        showSecondarySuppliers,
        setShowSecondarySuppliers,
        dataProcessorAgreementSaveModel,
        setDataProcessorAgreementSaveModel,
        saveAgreement,
        selectedDocument,
        setSelectedDocument,
        showEditDocumentDialog,
        setShowEditDocumentDialog,
        refetchDocuments: documentsQuery.refetch,
        subProcessorsTIADocsLoading,
        subProcessorsTIADocs,
        supplierUpdate: supplierUpdater,
        addSecondarySupplier,
        removeSecondarySupplier,
        showDescription,
        setShowDescription,
        isAddingSecondarySupplier: addSecondarySupplierMutation.isLoading,
    };

    function saveAgreement(agreementId: string) {
        let agreements = props.supplier.dataProcessorAgreementIds;
        agreements.push(agreementId);

        supplierUpdater.onDataProcessorAgreementChange(agreements);

        documentsQuery.refetch();
    }

    async function updateSupplierAPI(data: { systemId: string; supplier: SystemSupplierViewModel }) {
        return await put("/system/" + data.systemId + "/supplier/" + data.supplier.id, data.supplier);
    }

    async function addSecondarySupplierAPI(isLink: boolean) {
        return await post<SystemModel>(`/system/${props.system.id}/supplier/${props.supplier.id}/secondarySupplier`, isLink);
    }

    async function removeSecondarySupplierApi(data: { secondarySupplierId: string }) {
        return await deleteHttp(`/system/${props.system.id}/supplier/${props.supplier.id}/secondarySupplier/${data.secondarySupplierId}`);
    }

    async function addAgreementAPI(data: { systemId: string; supplierId: string; saveModel: AgreementSaveModel }) {
        return await put("/system/" + data.systemId + "/supplier/" + data.supplierId + "/agreement", data.saveModel);
    }

    async function removeAgreementAPI(data: { systemId: string; supplierId: string; saveModel: AgreementSaveModel }) {
        return await deleteHttp(`/system/${data.systemId}/supplier/${data.supplierId}/agreement?agreementId=${data.saveModel.agreementId}`);
    }
}
