import { createElement, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { AnnualWheelMonthEnum, TaskLaneStateEnum, TaskRawStateEnum, TaskStateEnum } from "../annualWheel/AnnualWheel.types";
import { AnnualWheelActivityType, AnnualWheelPriority } from "../annualWheel/annualWheelDialog/AnnualWheelDialog.types";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider";
import { ajaxQueue } from "../common/api/ajaxQueue";
import { get, put } from "../common/api/apiShared";
import { DotLegalSelectOption } from "../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { getEnumValues } from "../common/enumOperations";
import { useOptimisticUpdate } from "../common/hooks/useOptimisticUpdate";
import { useDotLegalSnackbar, useStateSessionStorage } from "@dotlegal/dotlegal-ui-components";
import { useStateLocalStorage } from "@dotlegal/dotlegal-ui-components";
import { useStateUrlParams, useStateUrlParamsArray } from "../common/hooks/useStateUrlParams";
import { isNullOrWhitespace, setFirstLetterToLowerCase } from "../common/stringOperations";
import { useTranslation } from "../localization/useTranslation";
import { OverviewTaskViewModel, TaskManagementViewModel } from "./TaskManagement.types";
import { isApprovalRequired } from "../annualWheel/ApprovalOptions";
import { useDotLegalCompanySelector } from "../common/components/dotLegalCompanySelector/GroupCompanySelectorContext";
import { useGroupEntitiesForUserQuery } from "../common/hooks/useSelectableItemQueries";

export const taskManagementAllMyStorageKey = "tasksOnlyUserSpecific";
export function useTaskManagementDataMapping() {
    const { translateString } = useTranslation();
    const optimisticUpdate = useOptimisticUpdate();
    const { permissions } = useUserContext();
    const snackbar = useDotLegalSnackbar();
    const currentMonth = new Date().getMonth() + 1;
    const [searchedMonths, setSearchedMonths] = useStateUrlParamsArray("months", [currentMonth.toString()]);
    const [searchedBusinessAreas, setSearchedBusinessAreas] = useStateUrlParamsArray("businessArea", []);
    const [searchedAreas, setSearchedAreas] = useStateUrlParamsArray("area", []);
    const [searchedTaskType, setSearchedTaskType] = useStateUrlParams("taskType", null);
    const [searchedResponsibles, setSearchedResponsibles] = useStateUrlParamsArray("responsible", []);
    const [searchedTasks, setSearchedTasks] = useStateUrlParamsArray("task", []);
    const [searchedTaskStatus, setSearchedTaskStatus] = useStateUrlParamsArray("taskStatus", []);
    const [selectedTask, setSelectedTask] = useStateUrlParams("taskId", "");
    const [searchedPriorities, setSearchedPriorities] = useStateUrlParamsArray("priority", []);
    const [showOverdueTasks, setShowOverdueTasks] = useStateUrlParams("overdueTasks", "");
    const [year, setYear] = useStateLocalStorage("annualWheelYear", new Date().getFullYear());
    const [onlyUserSpecific, setOnlyUserSpecific] = useStateSessionStorage(taskManagementAllMyStorageKey, permissions.canAccessAllData);
    const [searchString, setSearchString] = useState("");
    const { selectedGroupCompany } = useDotLegalCompanySelector();

    let groupEntitiesForUserQuery = useGroupEntitiesForUserQuery();

    const url = `/taskmanagement?year=${year}&onlyUserSpecific=${onlyUserSpecific}&groupCompany=${selectedGroupCompany}`;
    const { isLoading, data, refetch } = useQuery(url, () => get<TaskManagementViewModel>(url));

    let overdueTaskCount = 0;
    let inProgressTaskCount = 0;
    let completedTaskCount = 0;

    const updateTaskStateMutation = useMutation(updateTaskState, {
        onSuccess: () => {
            refetchTasks();
        },
    });

    const setYearByTaskYear = (taskYear: number) => {
        if (year !== taskYear && new Date().getFullYear() <= taskYear) {
            setYear(taskYear);
        }
    };

    let businessAreaFilterOptions: Array<DotLegalSelectOption> = [];
    let responsiblesFilterOptions: Array<DotLegalSelectOption> = [];
    let taskFilterOptions: Array<DotLegalSelectOption> = [];
    let priorityFilterOptions: Array<DotLegalSelectOption> = [];
    let monthFilterOptions: Array<DotLegalSelectOption> = [];
    let ready: Array<OverviewTaskViewModel> = [];
    let inProgress: Array<OverviewTaskViewModel> = [];
    let completed: Array<OverviewTaskViewModel> = [];
    let areasFilterOptions: Array<DotLegalSelectOption> = [];
    let taskTypeFilterOptions: Array<DotLegalSelectOption> = [];

    useEffect(() => {
        if (data) {
            if (!data.tasks.some((x) => x.month === currentMonth)) {
                setSearchedMonths(searchedMonths.filter((x) => x !== currentMonth.toString()));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, onlyUserSpecific]);

    assignFilteredTasks();

    if (data) {
        createBusinessAreaSelectOptions(data.businessAreas);
        createTaskSelectOptions();
        createResponsibleSelectOptions(data.users);
        createPrioritySelectOptions();
        createMonthSelectOptions();
        createTaskTypeSelectOptions();
        areasFilterOptions = data.areas;
    }

    function refetchTasks() {
        refetch();
    }

    function assignFilteredTasks() {
        if (data) {
            let tempTasks = [...data!.tasks];

            overdueTaskCount = tempTasks.filter((x) => x.isOverdue).length;
            inProgressTaskCount = tempTasks.filter((x) => x.laneState === TaskLaneStateEnum.InProgress).length;
            completedTaskCount = tempTasks.filter((x) => x.laneState === TaskLaneStateEnum.Completed).length;
            const showOnlyOverdueTasksValue = showOnlyOverdueTasks();

            if (showOnlyOverdueTasksValue) {
                tempTasks = tempTasks.filter((t) => t.isOverdue);
            }

            if (searchedBusinessAreas.length > 0 && !isNullOrWhitespace(searchedBusinessAreas[0])) {
                tempTasks = tempTasks.filter((t) => t.businessAreas.filter((x) => searchedBusinessAreas.includes(x.id)).length > 0);
            }

            if (searchedResponsibles.length > 0 && !isNullOrWhitespace(searchedResponsibles[0])) {
                tempTasks = tempTasks.filter(
                    (t) =>
                        (t.responsibles && t.responsibles.filter((x) => searchedResponsibles.includes(x.id)).length > 0) ||
                        (t.responsibles.filter((x) => x.isResponsible).length === 0 && searchedResponsibles.includes("null"))
                );
            }

            if (searchedTasks.length > 0 && !isNullOrWhitespace(searchedTasks[0])) {
                tempTasks = tempTasks.filter((t) => searchedTasks.includes(t.annualWheelActivityId) || searchedTasks.includes(t.id));
            }

            if (searchedPriorities.length > 0 && !isNullOrWhitespace(searchedPriorities[0])) {
                tempTasks = tempTasks.filter((t) => searchedPriorities.includes(t.priority.toString()));
            }

            if (searchedMonths.length > 0 && !isNullOrWhitespace(searchedMonths[0]) && !showOnlyOverdueTasksValue) {
                tempTasks = tempTasks.filter((t) => t.month == null || searchedMonths.includes(t.month.toString()));
            }

            if (searchedAreas.length > 0 && !isNullOrWhitespace(searchedAreas[0])) {
                tempTasks = tempTasks.filter((t) => t.areas.filter((x) => searchedAreas.includes(x.id)).length > 0);
            }

            if (searchedTaskStatus.length > 0 && !isNullOrWhitespace(searchedTaskStatus[0])) {
                tempTasks = filterTasksByTaskStatus(tempTasks);
            }

            if (searchedTaskType) {
                tempTasks = tempTasks.filter(
                    (t) => (t.isAdHocTask && searchedTaskType.includes("adhoctask")) || (!t.isAdHocTask && searchedTaskType.includes("annualwheel"))
                );
            }

            if (searchString) {
                let lowerCaseSearchString = searchString.toLowerCase();

                tempTasks = tempTasks.filter(
                    (t) =>
                        (t.processingActivityName && t.processingActivityName.toLowerCase().indexOf(lowerCaseSearchString) > -1) ||
                        t.name.toLowerCase().indexOf(lowerCaseSearchString) > -1 ||
                        t.businessAreas.some((x) => x.name.toLowerCase().includes(lowerCaseSearchString)) ||
                        t.responsibles.map((x) => x.firstName + " " + x.lastName).some((x) => x.toLowerCase().includes(lowerCaseSearchString)) ||
                        t.customId?.toLowerCase().includes(lowerCaseSearchString) ||
                        t.areas.some((x) => x.name.toLowerCase().includes(lowerCaseSearchString))
                );
            }

            ready = tempTasks.filter((x) => x.laneState === TaskLaneStateEnum.Ready);
            inProgress = tempTasks.filter((x) => x.laneState === TaskLaneStateEnum.InProgress);
            completed = tempTasks.filter((x) => x.laneState === TaskLaneStateEnum.Completed);
        } else {
            ready = [];
            inProgress = [];
            completed = [];
        }
    }

    async function changeTaskState(sourceId: string, sourceColumn: number, destinationId: string, destinationColumn: number) {
        const newDragNDropContent = { ...data! };

        if (sourceColumn === destinationColumn) {
            newDragNDropContent.tasks = reOrderTasks(newDragNDropContent.tasks, sourceId, destinationId);
        } else {
            newDragNDropContent.tasks = move(newDragNDropContent.tasks, sourceId, sourceColumn, destinationId, destinationColumn);
        }

        ajaxQueue().addRequest(updateTaskStateMutation.mutateAsync, { fromTaskId: sourceId, toTaskId: destinationId, taskState: destinationColumn });

        await optimisticUpdate.setQueryData(url, newDragNDropContent);
    }

    const reOrderTasks = (tasks: Array<OverviewTaskViewModel>, sourceId: string, destinationId: string) => {
        const sourceIndex = tasks.findIndex((x) => x.id === sourceId);
        const destinationIndex = tasks.findIndex((x) => x.id === destinationId);

        const [fromTask] = tasks.splice(sourceIndex, 1);
        tasks.splice(destinationIndex, 0, fromTask);

        return tasks;
    };

    async function failedToChangeTaskState(sourceId: string) {
        const newDragNDropContent = { ...data! };
        var task = newDragNDropContent.tasks.find((x) => x.id === sourceId);

        if (task !== undefined) {
            if (task.documentationRequired && !task.hasDocuments) {
                snackbar.show(translateString("documentataionRequiredForTask"), "error");
                return;
            }

            if (isApprovalRequired(task.approval)) {
                snackbar.show(createElement(Trans, { i18nKey: "approvalRequiredByResponsibleBeforeCompletion", values: { taskName: task.name } }), "error");
                return;
            }

            switch (task.type) {
                case AnnualWheelActivityType.ProcessingActivityValidation:
                    snackbar.show(createElement(Trans, { i18nKey: "processingActivityTaskValidationFailed" }), "error");
                    break;
                default:
                    snackbar.show(translateString("generelTaskUpdateFailed"), "error");
                    break;
            }
        }
    }

    const move = (tasks: Array<OverviewTaskViewModel>, sourceId: string, sourceColumn: number, destinationId: string, destinationColumn: number) => {
        const sourceTasks = tasks.filter((x) => x.laneState === sourceColumn);
        const destinationTasks = tasks.filter((x) => x.laneState === destinationColumn);

        const sourceIndex = sourceTasks.findIndex((x) => x.id === sourceId);
        let destinationIndex = destinationTasks.findIndex((x) => x.id === destinationId);

        if (destinationIndex === -1) {
            destinationIndex = destinationTasks.length === 0 ? 0 : destinationTasks.length + 1;
        }

        const [sourceTask] = sourceTasks.splice(sourceIndex, 1);
        sourceTask.laneState = destinationColumn;
        sourceTask.isOverdue = getState(sourceTask.deadline, destinationColumn) === TaskStateEnum.Overdue;
        destinationTasks.splice(destinationIndex, 0, sourceTask);

        const remainingTasks = tasks.filter((x) => x.laneState !== sourceColumn && x.laneState !== destinationColumn);

        return sourceTasks.concat(destinationTasks, remainingTasks);
    };

    function getState(taskDeadline: Date, destinationState: TaskStateEnum) {
        if (taskDeadline === null) return destinationState;

        if (destinationState === TaskStateEnum.Completed) return TaskStateEnum.Completed;

        const now = new Date();
        const deadline = new Date(taskDeadline);

        return now.getTime() > deadline.getTime() ? TaskStateEnum.Overdue : destinationState;
    }

    function createPrioritySelectOptions() {
        return getEnumValues(AnnualWheelPriority).forEach((x) => {
            var name = translateString(setFirstLetterToLowerCase(AnnualWheelPriority[x].toString()));
            if (!priorityFilterOptions.some((p) => p.id === x.toString())) {
                var priority = data!.tasks.map((y) => y.priority).find((f) => f === x);

                if (priority) priorityFilterOptions.push({ name: name, id: x.toString() });
            }
        });
    }

    function createMonthSelectOptions() {
        return getEnumValues(AnnualWheelMonthEnum).forEach((x) => {
            const name = translateString(setFirstLetterToLowerCase(AnnualWheelMonthEnum[x].toString()));
            if (!monthFilterOptions.some((p) => p.id === x.toString())) {
                const monthHasTasks = data!.tasks.some((t) => t.month === x);

                if (monthHasTasks) monthFilterOptions.push({ name: name, id: x.toString() });
            }
        });
    }

    function createBusinessAreaSelectOptions(businessAreas: Array<DotLegalSelectOption>) {
        businessAreas.forEach((x) => {
            if (!businessAreaFilterOptions.some((b) => b.id === x.id)) {
                data!.tasks
                    .map((y) => y.businessAreas)
                    .forEach((q) => {
                        const businessArea = q.find((w) => w.id === x.id);

                        if (businessArea && !businessAreaFilterOptions.some((i) => i.id === businessArea?.id))
                            businessAreaFilterOptions.push({ name: x.name, id: x.id });
                    });
            }
        });
    }

    function createTaskTypeSelectOptions() {
        if (data?.tasks.some((x) => x.isAdHocTask)) {
            taskTypeFilterOptions.push({ id: "adhoctask", name: translateString("adHocTask") });
        }
        if (data?.tasks.some((x) => !x.isAdHocTask)) {
            taskTypeFilterOptions.push({ id: "annualwheel", name: translateString("annualWheelActivity") });
        }
    }

    function createTaskSelectOptions() {
        taskFilterOptions = data!.tasks
            .map((t) => {
                return { name: getTaskSelectOptionName(t), id: t.isAdHocTask ? t.id : t.annualWheelActivityId };
            })
            .filter((value, index, self) => self.findIndex((x) => x.id === value.id) === index);
    }

    function getTaskSelectOptionName(task: OverviewTaskViewModel) {
        if (task.type === AnnualWheelActivityType.Custom) {
            var similarTasks = data?.tasks.find((x) => x.name === task.name && x.annualWheelActivityId !== task.annualWheelActivityId);

            if (similarTasks) {
                var date = new Date(task.deadline);
                return `${task.name} - ${translateString(AnnualWheelMonthEnum[date.getMonth() + 1].toLocaleLowerCase())} ${date.getFullYear()}`;
            }
        }

        return task.name;
    }

    function createResponsibleSelectOptions(users: Array<DotLegalSelectOption>) {
        users.forEach((x) => {
            if (!responsiblesFilterOptions.some((b) => b.id === x.id)) {
                const responsible = data?.tasks.filter((t) => t.responsibles.find((r) => r.id === x.id));
                if (responsible && responsible?.length > 0) responsiblesFilterOptions.push({ name: x.name, id: x.id });
            }
        });
        responsiblesFilterOptions.push({ name: translateString("responsibleNotAssigned"), id: "null" });
    }

    async function updateTaskState(data: { fromTaskId: string; toTaskId: string; taskState: TaskRawStateEnum }) {
        return await put<{}>("/task/" + data.fromTaskId + "/changeTaskStatus", {
            taskState: data.taskState,
            toTaskId: data.toTaskId ? data.toTaskId : undefined,
        });
    }

    function showOnlyOverdueTasks() {
        return !isNullOrWhitespace(showOverdueTasks!) && JSON.parse(showOverdueTasks!) === true;
    }

    function getTaskStatusOptions() {
        return [
            { id: TaskRawStateEnum.Ready.toString(), name: translateString("ready") },
            { id: TaskRawStateEnum.InProgress.toString(), name: translateString("inProgress") },
            { id: TaskRawStateEnum.ReadyForApproval.toString(), name: translateString("readyForApproval") },
            { id: TaskRawStateEnum.Completed.toString(), name: translateString("completed") },
        ];
    }

    return {
        isLoading,
        data,
        ready,
        inProgress,
        completed,
        searchedBusinessAreas,
        setSearchedBusinessAreas,
        searchedResponsibles,
        setSearchedResponsibles,
        searchedTasks,
        setSearchedTasks,
        searchedPriorities,
        setSearchedPriorities,
        searchedMonths,
        setSearchedMonths,
        showOnlyOverdueTasks,
        setShowOverdueTasks,
        setSearchedAreas,
        searchedAreas,
        setSearchedTaskType,
        searchedTaskType,
        businessAreaFilterOptions,
        responsiblesFilterOptions,
        taskFilterOptions,
        priorityFilterOptions,
        monthFilterOptions,
        areasFilterOptions,
        taskTypeFilterOptions,
        changeTaskState,
        failedToChangeTaskState,
        year,
        setYear,
        selectedTask,
        setSelectedTask,
        refetchTasks,
        searchString,
        setSearchString,
        overdueTaskCount,
        inProgressTaskCount,
        completedTaskCount,
        onlyUserSpecific,
        setOnlyUserSpecific,
        setYearByTaskYear,
        getTaskStatusOptions,
        searchedTaskStatus,
        setSearchedTaskStatus,
        groupEntitiesForUserQuery,
    };

    function filterTasksByTaskStatus(tasks: Array<OverviewTaskViewModel>) {
        return tasks.filter(
            (x) =>
                (searchedTaskStatus.includes(x.laneState.toString()) && !x.isReadyForApproval) ||
                (searchedTaskStatus.includes(TaskRawStateEnum.ReadyForApproval.toString()) && x.isReadyForApproval)
        );
    }
}
