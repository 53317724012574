import { Box, Divider } from "@mui/material";
import React from "react";
import DotLegalStepHeader from "../../../../common/components/dotLegalStepHeader/DotLegalStepHeader";
import SharingsLegalEntity from "../../../../processingActivity/sharingOfDataStep/sharingsLegalEntityBox/sharingsLegalEntity";
import { DotLegalButton, DotLegalMultiSelect, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../../localization/useTranslation";
import { useUserContext } from "../../../../auth/userContextProvider/UserContextProvider";
import { SelectableDocumentItem, SelectableLegalUnitsItem } from "../../../../processingActivity/ProcessingActivity.types";
import { DotLegalSelectOption } from "../../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { showTia, showTransferBasis } from "../../../../legalEntity/isInsecureThirdCountry";
import { SystemModel, SystemSecondarySupplierViewModel } from "../../SystemEdit.types";
import SegmentStep from "../../../../processingActivity/stepSegment/StepSegment";
import {
    filterDocumentDataByCompanyId,
    getSelectableTiaDocumentItems,
    getSelectedDocument,
} from "../../../../processingActivity/hooks/useDataProcessingAgreement";
import { filterTransferBasis } from "../../../../legalBasis/TransferBasis.Helper";
import useSystemSecondarySupplierHooks from "./SystemSecondarySupplier.hooks";
import { useSystemSecondarySupplierStyles } from "./SystemSecondarySupplier.styles";
import { ValidationError } from "../../../../common/validationError";
import { dotlegalLinkIcon } from "../../../../common/icons";
import { Link } from "react-router-dom";

export interface ISystemSecondarySupplier {
    documentsData: Array<SelectableDocumentItem> | undefined;
    validationErrors: Array<ValidationError>;
    legalEntitiesLoading: boolean;
    legalEntityData: Array<SelectableLegalUnitsItem> | undefined;
    transferBasisLoading: boolean;
    transferBasisData: Array<DotLegalSelectOption> | undefined;
    onRemoveSecondarySupplier: () => void;
    secondarySupplierIndex: number;
    documentsLoading: boolean;
    countriesLoading: boolean;
    countryList: Array<DotLegalSelectOption>;
    secondarySupplier: SystemSecondarySupplierViewModel;
    readOnly?: boolean;
    system: SystemModel;
    supplierId: string;
    supplierIsDataProcessor: boolean;
}

function SystemSecondarySupplier(props: ISystemSecondarySupplier) {
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();
    const { onHover, setOnHover, secondarySupplierUpdater } = useSystemSecondarySupplierHooks(props);
    const styles = useSystemSecondarySupplierStyles();

    const getContainerClass = () => {
        let containerClass = styles.subProcessorContainer;

        if (onHover) containerClass = { ...containerClass, ...styles.onRemoveHover };

        return containerClass;
    };

    let secondarySupplier = props.secondarySupplier;
    let selectedSecondarySupplier = props.legalEntityData?.find((x) => x.id === secondarySupplier.dataProcessorId);
    let showTransferBasisFld = showTransferBasis(props.legalEntityData, selectedSecondarySupplier?.id);

    return (
        <React.Fragment>
            {props.secondarySupplierIndex === 0 && <Divider />}
            <Box sx={getContainerClass()} key={props.secondarySupplierIndex}>
                <DotLegalStepHeader record={false}>{translateString("whoIsSecondarySupplier")}</DotLegalStepHeader>
                <Box justifyContent="center" display="flex">
                    <SegmentStep size="small">
                        {props.secondarySupplier.isLink ? (
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Box sx={{ width: "100%" }}>
                                    <DotLegalTextField
                                        label={translateString("link")}
                                        value={props.secondarySupplier.link ?? ""}
                                        debounce
                                        onChange={(value) => secondarySupplierUpdater.onLinkChange(value)}
                                    />
                                </Box>
                                <Box sx={{ marginLeft: "10px;", marginTop: "10px", display: "flex" }}>
                                    {props.secondarySupplier.link && (
                                        <Link to={props.secondarySupplier.link} target={"_blank"}>
                                            {dotlegalLinkIcon}
                                        </Link>
                                    )}
                                </Box>
                            </Box>
                        ) : (
                            <React.Fragment>
                                <SharingsLegalEntity
                                    isLoading={props.legalEntitiesLoading}
                                    selectedItem={secondarySupplier.dataProcessorId}
                                    placeholder={translateString("secondarySupplier")}
                                    label={translateString("secondarySupplier")}
                                    options={props.legalEntityData}
                                    disabled={props.readOnly}
                                    toolTipText={translateString("addSecondarySupplierHelperText")}
                                    onLegalEntityChange={(dataProcessorId) => secondarySupplierUpdater.onDataProcessorChange(dataProcessorId)}
                                    showAddNewEntityButton
                                    isThirdCountry={showTransferBasisFld}
                                    errorText={
                                        props.validationErrors.find((v) => v.field === `${props.supplierId}${secondarySupplier.id}dataProcessorId`)?.error
                                    }
                                />
                                {permissions.systemDataProcessorPermissions.hasDataProcessorAgreementPermission && (
                                    <DotLegalSelect
                                        selectedItem={secondarySupplier.hasDataProcessorAgreement.toString()}
                                        label={translateString("agreement")}
                                        placeholder={translateString("agreement")}
                                        disabled={props.readOnly}
                                        disableClearable
                                        onChange={(option) => secondarySupplierUpdater.onAgreementChange(option ? option.toLowerCase() === "true" : false)}
                                        options={[
                                            {
                                                name: translateString("yes"),
                                                id: "true",
                                            },
                                            { name: translateString("no"), id: "false" },
                                        ]}
                                        toolTipText={translateString("sharingAgreementHelperText")}
                                        noOptionsLabel={translateString("noOptions")}
                                    />
                                )}

                                {props.supplierIsDataProcessor && (
                                    <DotLegalSelect
                                        selectedItem={secondarySupplier.isSubDataProcessor.toString()}
                                        label={translateString("Subprocessor")}
                                        placeholder={translateString("Subprocessor")}
                                        disabled={props.readOnly}
                                        disableClearable
                                        onChange={(option) =>
                                            secondarySupplierUpdater.onIsSubDataProcessorChange(option ? option.toLowerCase() === "true" : false)
                                        }
                                        options={[
                                            {
                                                name: translateString("yes"),
                                                id: "true",
                                            },
                                            { name: translateString("no"), id: "false" },
                                        ]}
                                        toolTipText={translateString("secondarySupplierIsSubProcessorHelperText")}
                                        noOptionsLabel={translateString("noOptions")}
                                    />
                                )}

                                {props.secondarySupplier.isSubDataProcessor && (
                                    <React.Fragment>
                                        {showTransferBasisFld && permissions.systemDataProcessorPermissions.hasTiaAndTransferBasis && (
                                            <DotLegalSelect
                                                selectedItem={secondarySupplier.transferBasisId}
                                                isLoading={props.transferBasisLoading}
                                                label={translateString("transferBasis")}
                                                placeholder={translateString("transferBasis")}
                                                disabled={props.readOnly}
                                                options={filterTransferBasis(props.transferBasisData, selectedSecondarySupplier?.countryCode)}
                                                toolTipText={translateString("subprocessorSpecifyTransferBasis")}
                                                errorText={
                                                    props.validationErrors.find((v) => v.field === `${props.supplierId}${secondarySupplier.id}transferBasisId`)
                                                        ?.error
                                                }
                                                onChange={(transferBasis) => secondarySupplierUpdater.onTransferBasisChange(transferBasis)}
                                                noOptionsLabel={translateString("noOptions")}
                                            />
                                        )}
                                        {showTia(
                                            props.legalEntityData,
                                            secondarySupplier.dataProcessorId,
                                            permissions.systemDataProcessorPermissions.hasTiaAndTransferBasis,
                                            secondarySupplier.transferBasisId
                                        ) && (
                                            <DotLegalSelect
                                                disableClearable
                                                selectedItem={getSelectedDocument(
                                                    secondarySupplier.transferImpactAssessmentId,
                                                    secondarySupplier.hasTransferImpactAssessment,
                                                    secondarySupplier.containsNotAccessibleTia
                                                )}
                                                isLoading={props.documentsLoading}
                                                label={translateString("tia")}
                                                disabled={props.readOnly}
                                                placeholder={translateString("tia")}
                                                options={getSelectableTiaDocumentItems(
                                                    filterDocumentDataByCompanyId(props.documentsData, secondarySupplier.dataProcessorId),
                                                    translateString,
                                                    secondarySupplier.hasTransferImpactAssessment,
                                                    secondarySupplier.containsNotAccessibleTia
                                                )}
                                                toolTipText={translateString("tiaTooltip")}
                                                errorText={
                                                    props.validationErrors.find(
                                                        (v) => v.field === `${props.supplierId}${secondarySupplier.id}transferImpactAssessment`
                                                    )?.error
                                                }
                                                onChange={(tia) => secondarySupplierUpdater.onTransferImpactAssessmentChange(tia)}
                                                noOptionsLabel={translateString("noOptions")}
                                            />
                                        )}
                                    </React.Fragment>
                                )}

                                {permissions.systemDataProcessorPermissions.hasStorageLocationPermission && (
                                    <DotLegalMultiSelect
                                        isLoading={props.countriesLoading}
                                        options={props.countryList}
                                        chosenOptions={props.secondarySupplier.dataStorageLocations}
                                        disabled={props.readOnly}
                                        placeholder={translateString("storageLocation")}
                                        label={translateString("storageLocation")}
                                        toolTipText={translateString("secondarySupplierStorageTooltip")}
                                        onChange={(countries) => secondarySupplierUpdater.onDataStorageLocationChange(countries)}
                                        noOptionsLabel={translateString("noOptions")}
                                    />
                                )}

                                {permissions.systemDataProcessorPermissions.hasSupportAccessPermission && (
                                    <DotLegalMultiSelect
                                        isLoading={props.countriesLoading}
                                        options={props.countryList}
                                        chosenOptions={props.secondarySupplier.supportAccessLocations}
                                        disabled={props.readOnly}
                                        placeholder={translateString("supportAccess")}
                                        label={translateString("supportAccess")}
                                        toolTipText={translateString("secondarySupplierSupportTooltip")}
                                        onChange={(countries) => secondarySupplierUpdater.onSupportAccesssLocationChange(countries)}
                                        noOptionsLabel={translateString("noOptions")}
                                    />
                                )}
                            </React.Fragment>
                        )}
                    </SegmentStep>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <DotLegalButton onMouseHover={setOnHover} buttonType="danger" disabled={props.readOnly} onClick={() => props.onRemoveSecondarySupplier()}>
                        {translateString("removeSecondarySupplier")}
                    </DotLegalButton>
                </Box>
            </Box>
            <Divider />
        </React.Fragment>
    );
}

export default SystemSecondarySupplier;
