export const DOCUMENT_TYPE_DATAPROCESSOR_AGREEMENT_ID = "620fada8-2f01-4c3a-baf6-9771d527fab1";
export const DOCUMENT_TYPE_DISCLOSURE_AGREEMENT_ID = "c11d83ee-74fd-40aa-a199-d20da5fbdf79";
export const DOCUMENT_TYPE_JOINT_DATACONTROLLER_AGREEMENT_ID = "a361c643-299b-4a8f-95ba-84b4df06397f";

export interface DocumentType {
    name: string;
    id: string;
    isCustom: boolean;
}

export enum StandardDocumentType {
    Other = 1,
    DataProcessorAgreement = 2,
    TIA = 3,
    CTA = 4,
    CooperationAgreement = 5,
    ServiceAgreement = 6,
    PhDAgreement = 7,
    DisclosureAgreement = 8,
    JointDataControllerAgreement = 9,
    OtherAgreements = 10,
    QualityProjects = 11,
    Oversight = 12,
    ExceptForDataProcessingAgreement = 13,
    DataProcessingAgreementAsDataProcessor = 14,
    ConfidentialityAgreement = 15,
    SubProcessor = 16,
    Declaration = 17,
}

export enum DocumentTypeUsage {
    DataProcessor = 1,
    Tia = 2,
    Disclosure = 3,
    JointDataController = 4,
    Source = 5,
    DataController = 6,
}
