import { Box, Divider, Grid, GridSize, Skeleton, Typography } from "@mui/material";
import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { getNumberOfDataSubjects, useProcessingActivityOverviewDataMapping } from "./ProcessingActivityOverview.hooks";
import { useProcessingActivityOverviewStyles } from "./ProcessingActivityOverview.styles";
import { DotLegalCompletedPercentage, DotLegalFixedHeightText, DotLegalHeading, DotLegalPageHeader, DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { reactPlugin } from "../../AppInsights";
import { useAppInsightsContext, useTrackMetric, withAITracking } from "@microsoft/applicationinsights-react-js";
import { TrackingEvent, useTrackAIEvent } from "../hooks/useTracking";
import { SecurityMeasureOverviewModel } from "../dataSubjectStep/DataSubjectStep.types";
import CheckIcon from "@mui/icons-material/Check";
import DataCategoryOverviewTable from "./dataCategoryOverviewTable/DataCategoryOverview";
import ProcessingActivityOverViewSystemTable from "./processingActivityOverviewSystemTable/ProcessingActivityOverViewSystemTable";
import DotLegalCompanySelector from "../../common/components/dotLegalCompanySelector/DotLegalCompanySelector";
import RiskIndicator from "../../riskAssessment/riskIndicator/RiskIndicator";
import { RiskAssessmentType } from "../../riskAssessment/RiskAssessment.types";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import DotLegalNoteDialog from "../../common/components/dotLegalNoteDialog/DotLegalNoteDialog";
import ProcessingActivityDeleteDialog from "../processingActivityDeleteDialog/ProcessingActivityDeleteDialog";
import { Trans } from "react-i18next";
import { useNavMenuDataMapping } from "../../main/NavMenu/NavMenu.hooks";
import { hasAccessToProcessingActivitySteps } from "../processingActivities/ProcessingActivities.hooks";
import PolicyOverviewDialog from "./policyOverviewDialog/PolicyOverviewDialog";
import ProcessingActivityStatusDialog from "./processingActivityStatusDialog/ProcessingActivityStatusDialog";
import CommentDialog from "../../comment/commentDialog/CommentDialog";
import AuditLogDialog from "../../auditlogDialog/AuditLogDialog";
import { EntityType } from "../../common/Common.types";
import { ProcessingActivityStatus } from "../processingActivities/ProcessingActivities.types";
import { ProcessingActivityStatusManagement } from "../../auth/userContextProvider/UserContextProvider.types";
import ProcessingActivtyReportDialog from "../../processingActivityReport/processingActivityReportDialog/ProcessingActivityReportDialog";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { useUrlProvider } from "../../useUrlProvider";
import { RiskAssessmentArea, RiskComplianceArea } from "../../riskAssessment/riskAssessments/RiskAssessments.types";
import { shouldDisableComments } from "../ProcessingActivity.hooks";
import { useProcessingActivityValidation } from "./ProcessingActivityValidation.hooks";
import DotLegalTabs from "../../common/components/dotLegalTabs/DotLegalTabs";
import { TabContent } from "../../common/components/dotLegalTabs/DotLegalTabs.types";
import ProcessingActivitySharingsTab from "./processingActivitySharingsTab/ProcessingActivitySharingsTab";
import ProcessingActivityDocumentTab from "./processingActivityDocumentTab/ProcessingActivityDocumentTab";
import { NumberOfProcessorsEnum } from "../ProcessingActivity.types";
import ProcessingActivityAssociatedInternalAssessmentTab from "./processingActivityInternalAssessmentTab/ProcessingActivityAssociatedInternalAssessmentTab";
import { getPlanTab } from "../../plan/planTab/PlanTab";
import PageHeaderEditEntityButton, {
    PageHeaderEditEntityButtonMenuItem,
} from "../../common/components/pageHeaderEditEntityButton/PageHeaderEditEntityButton.tsx";
import RelatedTasksTab from "../../taskManagement/relatedTasksTab/RelatedTasksTab.tsx";
import { useAddTaskMenuItem } from "../../common/components/addTaskMenuItem/AddTaskMenuItemContext.hooks.ts";

function ProcessingActivityOverview() {
    const styles = useProcessingActivityOverviewStyles();
    const { translateString, translateNumber, translateDate, translateStatus, translateDataSubjectsEnum, translateProcessorsEnum } = useTranslation();
    const appInsights = useAppInsightsContext();
    const trackComponent = useTrackMetric(appInsights, "ProcessingActivityOverview");
    const { id } = useParams<{ id: string }>();
    const hasTracked = useRef(false);
    const trackEvent = useTrackAIEvent();
    const { permissions, customerName } = useUserContext();
    const { addonsFeatures, hasVendorManagementAccess, dataProtectionFeatures, vendorManagementFeatures, platformFeatures } = usePlanContext();
    const { getProcessingActivityRiskScenariosDialog, getRiskAssessmentQuestionsDialog } = useUrlProvider();
    const addTaskMenuItem = useAddTaskMenuItem();

    if (!hasTracked.current) {
        trackComponent();
        hasTracked.current = true;
    }

    const {
        isLoading,
        processingViewModel,
        showNoteDialog,
        setShowNoteDialog,
        showSystemsDialog,
        setShowSystemsDialog,
        showDataCategoryOverviewDialog,
        setShowDataCategoryOverviewDialog,
        selectableCompanyItems,
        riskAssessmentData,
        riskAssessmentLoading,
        updateNote,
        savingNote,
        showDeleteDialog,
        setShowDeleteDialog,
        onProcessingActivityDeleteSuccess,
        setShowPolicyDialog,
        showPolicyDialog,
        showStatusDialog,
        setShowStatusDialog,
        refetchProcessingActivity,
        showAuditLogDialog,
        setShowAuditLogDialog,
        showProcessingActivityReportDialog,
        setShowProcessingActivityReportDialog,
        data,
        selectedTab,
        setSelectedTab,
    } = useProcessingActivityOverviewDataMapping(id);

    const { addProcessingActivityValidation } = useProcessingActivityValidation();
    const { refetchMenuNotifications } = useNavMenuDataMapping();

    const getBreadCrumbs = () => {
        return [
            {
                name: translateString("processingActivities"),
                link: "/privacy/processingactivities",
            },
            {
                name: processingViewModel?.processingActivitiesInfoModel.name
                    ? `${processingViewModel?.processingActivitiesInfoModel.name} ${!addonsFeatures.research ? `(${processingViewModel?.processingActivitiesInfoModel.userFriendlyId})` : ""}`
                    : "",
                tooltip: processingViewModel?.processingActivitiesInfoModel.description,
            },
        ];
    };

    const getNumberValue = (value: number | undefined) => {
        return <React.Fragment>{isLoading ? <Skeleton variant={"text"} /> : <Box sx={styles.number}>{translateNumber(value!)}</Box>}</React.Fragment>;
    };

    const getValidation = () => {
        var validation = processingViewModel?.processingActivitiesInfoModel.validation;

        if (validation) {
            var user = validation.validatedBy ? validation.validatedBy : translateString("deletedUser");

            return (
                <Box sx={styles.validationContainer}>
                    <Trans i18nKey="lastValidated" values={{ date: translateDate(validation.validatedDate), user: user.toLowerCase() }} />
                </Box>
            );
        }
        return undefined;
    };

    const OnProcessingActivityClick = (processingActivityName: string, processingActivityId: string) => {
        trackEvent(TrackingEvent.EditProcessingActivityClicked, {
            name: processingActivityName,
            id: processingActivityId,
        });
    };

    const getSecurityMeasures = (securityMeasures: Array<SecurityMeasureOverviewModel> | undefined) => {
        return (
            <React.Fragment>
                {isLoading ? (
                    <Skeleton variant={"text"} />
                ) : (
                    securityMeasures!.map((s, i) => {
                        if (s.isMet)
                            return (
                                <Box key={i} sx={styles.securityMeasure}>
                                    <CheckIcon />
                                    {s.name}
                                </Box>
                            );
                        else
                            return (
                                <Box key={i} sx={styles.securityMeasure}>
                                    {s.name}
                                </Box>
                            );
                    })
                )}
            </React.Fragment>
        );
    };

    const propertiesGridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize; xl: GridSize } = {
        item: true,
        xs: 12,
        sm: 6,
        md: 4,
        lg: 4,
        xl: 2,
    };

    const getOwnerInfoBlock = () => {
        return (
            <Box sx={styles.ownerBlock}>
                {processingViewModel?.processingActivitiesInfoModel.responsible ?? "-"}
                {processingViewModel?.processingActivitiesInfoModel.responsibleActive === false && (
                    <DotLegalTooltip text={translateString("userDeactivated")}>
                        <ReportProblemOutlinedIcon fill="error" fontSize={"small"} />
                    </DotLegalTooltip>
                )}
            </Box>
        );
    };

    let ownerGrid = (
        <Grid {...propertiesGridProps}>
            <DotLegalHeading name={translateString("owner")}>
                <DotLegalFixedHeightText isLoading={isLoading} content={getOwnerInfoBlock()} />
            </DotLegalHeading>
        </Grid>
    );

    const policyGrid = () => {
        const policyCount = processingViewModel?.processingActivitiesInfoModel.policies.length ?? 0;
        const hasPolicies = policyCount > 0;

        return (
            <Grid
                onClick={() => {
                    if (hasPolicies) {
                        setShowPolicyDialog(true);
                    }
                }}
                sx={{
                    "&:hover": {
                        cursor: hasPolicies ? "pointer" : "auto",
                    },
                }}
                {...propertiesGridProps}
            >
                <DotLegalHeading name={translateString("numberOfPolicies")}>{getNumberValue(policyCount)}</DotLegalHeading>
            </Grid>
        );
    };

    const showProcessingActivityStatusMenuItem = () => {
        let statusManagement = permissions.processingActivityStatusManagement;
        let processingActivityStatus = processingViewModel?.processingActivitiesInfoModel!.processingActivityStatus!;

        if (statusManagement === ProcessingActivityStatusManagement.Full) {
            return true;
        } else if (statusManagement === ProcessingActivityStatusManagement.Draft) {
            return (
                processingActivityStatus === ProcessingActivityStatus.Draft ||
                processingActivityStatus === ProcessingActivityStatus.DraftSubmitted ||
                processingActivityStatus === ProcessingActivityStatus.AwaitingResearcher
            );
        }

        return statusManagement === ProcessingActivityStatusManagement.Regular;
    };

    const getTabContent = () => {
        const content: Array<TabContent> = [
            {
                label: translateString("sharings"),
                content: (
                    <ProcessingActivitySharingsTab
                        dataCategories={data?.dataCategories ?? []}
                        isLoading={isLoading}
                        processingActivityId={id!}
                        recipients={data?.recipients ?? []}
                        senders={data?.senders ?? []}
                        sharings={data?.sharings ?? []}
                    />
                ),
                index: 0,
            },
            getPlanTab(
                `${translateString("documents")} ${dataProtectionFeatures.processingActivitiesDocuments && data && data.numberOfDocuments > 0 ? `(${data.numberOfDocuments})` : ""}`,
                <ProcessingActivityDocumentTab processingActivityId={id!} refetch={() => refetchProcessingActivity()} />,
                1,
                dataProtectionFeatures.processingActivitiesDocuments,
                translateString("upgradeDataProtectionPlan")
            ),
        ];
        if (hasVendorManagementAccess && permissions.canManageInternalAssessment) {
            content.push(
                getPlanTab(
                    translateString("internalAssessments"),
                    <ProcessingActivityAssociatedInternalAssessmentTab
                        processingActivityId={id!}
                        processingActivityName={processingViewModel?.processingActivitiesInfoModel.name ?? ""}
                    />,
                    2,
                    vendorManagementFeatures.processingActivityAssociateInternalAssessments,
                    translateString("upgradeVendorManagementPlan")
                )
            );
        }

        if (permissions.taskPermissions.read) {
            content.push({
                label: translateString("tasks"),
                content: (
                    <RelatedTasksTab forEntityId={id!} forEntityType={EntityType.ProcessingActivity} forEntityName={data?.processingActivityInfo.name ?? ""} />
                ),
                index: 3,
            });
        }

        return content;
    };

    const getNumberOfProcessors = () => {
        if (processingViewModel) {
            if (processingViewModel.processingActivitiesInfoModel.numberOfProcessors) {
                if (processingViewModel.processingActivitiesInfoModel.numberOfProcessors === NumberOfProcessorsEnum.UserEnteredNumberOfProcessors)
                    return processingViewModel.processingActivitiesInfoModel.noOfProcessors;

                return translateProcessorsEnum(processingViewModel.processingActivitiesInfoModel.numberOfProcessors!.toString());
            }
        }

        return 0;
    };

    const canSeePolicyGrid =
        dataProtectionFeatures.linkPoliciesAndProcessingActivities &&
        permissions.policyPermissions &&
        permissions.processingActivityPolicyPermissions?.permissions.read;

    const getEditMenuItems = () => {
        let menuItems: Array<PageHeaderEditEntityButtonMenuItem> = [];

        if (hasAccessToProcessingActivitySteps(permissions)) {
            menuItems.push({
                menuItem: translateString("editProcessingActivity"),
                onClick: () => {
                    OnProcessingActivityClick(
                        processingViewModel?.processingActivitiesInfoModel.name! ?? "",
                        processingViewModel?.processingActivitiesInfoModel.id! ?? ""
                    );
                },
                href: "edit",
            });
        }

        if (
            hasAccessToProcessingActivitySteps(permissions) &&
            processingViewModel?.processingActivitiesInfoModel.canValidateProcessingActivity &&
            !addonsFeatures.research
        ) {
            menuItems.push({
                menuItem: translateString("validateProcessingActivity"),
                onClick: async () => {
                    await addProcessingActivityValidation(id!);
                },
                planMenuItemInfo: {
                    hasAccess: dataProtectionFeatures.processingActivityValidationFlow,
                    menuName: translateString("validateProcessingActivity"),
                    tooltip: translateString("upgradeDataProtectionPlan"),
                },
            });
        }

        if (permissions.canManageProcessingActivityNote) {
            menuItems.push({
                menuItem: translateString("note"),
                onClick: () => {
                    setShowNoteDialog(true);
                },
            });
        }

        if (showProcessingActivityStatusMenuItem() && processingViewModel) {
            menuItems.push({
                menuItem: translateString("editProcessingActivityStatus"),
                onClick: () => {
                    setShowStatusDialog(true);
                },
                planMenuItemInfo: {
                    tooltip: translateString("upgradeDataProtectionPlan"),
                    menuName: translateString("editProcessingActivityStatus"),
                    hasAccess: dataProtectionFeatures.processingActivityStatus,
                },
            });
        }

        if (permissions.canAccessAuditLog) {
            menuItems.push({
                menuItem: translateString("auditlog"),
                onClick: () => {
                    setShowAuditLogDialog("true");
                },
                planMenuItemInfo: {
                    hasAccess: platformFeatures.auditLog,
                    menuName: translateString("auditlog"),
                    tooltip: translateString("upgradePrivacyPlanToday"),
                },
            });
        }

        if (addonsFeatures.research && permissions.reportTypes.length > 0 && permissions.processingActivityPermissions.read) {
            menuItems.push({
                menuItem: translateString("processingActivityReportDialog"),
                onClick: () => {
                    setShowProcessingActivityReportDialog(true);
                },
            });
        }

        if (permissions.processingActivityPermissions.delete) {
            menuItems.push({
                menuItem: translateString("deleteProcessingActivity"),
                onClick: () => {
                    setShowDeleteDialog(true);
                },
            });
        }

        addTaskMenuItem.addCreateTaskMenuItem(menuItems, {
            fromEntityId: id!,
            fromEntityType: EntityType.ProcessingActivity,
            toEntityType: EntityType.Task,
        });

        return menuItems;
    };

    return (
        <Box sx={styles.container}>
            <DotLegalPageHeader
                userContext={{ customerName }}
                breadCrumbs={getBreadCrumbs()}
                rightComponent={
                    <Box sx={styles.gridContentAlignment}>
                        <DotLegalCompanySelector isLoading={isLoading} companies={selectableCompanyItems!} />
                        <PageHeaderEditEntityButton menuItems={getEditMenuItems()} />
                    </Box>
                }
            />

            <DotLegalPaper background="purpleWave1" backgroundCover>
                <Grid sx={styles.gridRow} container spacing={3}>
                    {addonsFeatures.research && (
                        <Grid {...propertiesGridProps}>
                            <DotLegalHeading name={translateString("approvelNumber")}>
                                <DotLegalFixedHeightText
                                    isLoading={isLoading}
                                    content={processingViewModel?.processingActivitiesInfoModel.approvelNumber ?? "-"}
                                />
                            </DotLegalHeading>
                        </Grid>
                    )}

                    <Grid {...propertiesGridProps}>
                        <DotLegalHeading name={translateString("Purpose")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                content={processingViewModel?.processingActivitiesInfoModel.purposes.join(", ") ?? "-"}
                            />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...propertiesGridProps}>
                        <DotLegalHeading name={translateString("dataSubjects")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                content={processingViewModel?.processingActivitiesInfoModel.dataSubjects.join(", ") ?? "-"}
                            />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...propertiesGridProps}>
                        <DotLegalHeading name={translateString("securityMeasures")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                content={getSecurityMeasures(processingViewModel?.processingActivitiesInfoModel.securityMeasures)}
                            />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...propertiesGridProps}>
                        <DotLegalHeading name={translateString("NumberOfSubjects")}>
                            {isLoading ? (
                                <Skeleton variant={"text"} />
                            ) : (
                                <Box sx={styles.number}>
                                    {processingViewModel?.processingActivitiesInfoModel &&
                                        getNumberOfDataSubjects(
                                            translateDataSubjectsEnum,
                                            processingViewModel?.processingActivitiesInfoModel.numberOfSubjects,
                                            processingViewModel?.processingActivitiesInfoModel.noOfSubjects
                                        )}
                                </Box>
                            )}
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...propertiesGridProps}>
                        <DotLegalHeading name={translateString("NumberOfProcessors")}>
                            {isLoading ? <Skeleton variant={"text"} /> : <Box sx={styles.number}>{getNumberOfProcessors()}</Box>}
                        </DotLegalHeading>
                    </Grid>

                    {!addonsFeatures.research && canSeePolicyGrid && policyGrid()}
                </Grid>

                <Divider sx={styles.divider} />

                <Grid sx={styles.gridRow} container spacing={3}>
                    {addonsFeatures.research && canSeePolicyGrid && policyGrid()}

                    {ownerGrid}
                    <Grid {...propertiesGridProps}>
                        <DotLegalHeading name={translateString("processingActivityAreas")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                content={processingViewModel?.processingActivitiesInfoModel.dataprocessAreas.join(", ") ?? "-"}
                            />
                        </DotLegalHeading>
                    </Grid>

                    {permissions.canManageRiskAssessment && (
                        <Grid {...propertiesGridProps}>
                            <DotLegalHeading name={translateString("risk")}>
                                {riskAssessmentLoading ? (
                                    <Skeleton variant="text" width={"100%"} />
                                ) : (
                                    <Box sx={styles.riskAssessmentText}>
                                        <Box sx={styles.textAlignLeft}>
                                            <RiskIndicator
                                                isClassification
                                                riskAssessmentType={RiskAssessmentType.Classification}
                                                fixedWidthLeftColumn
                                                textSize="sm"
                                                score={riskAssessmentData?.scenariosScore}
                                                linkHref={getProcessingActivityRiskScenariosDialog(
                                                    id,
                                                    riskAssessmentData?.currentRiskAssessmentVersionId ?? undefined
                                                )}
                                            />

                                            <RiskIndicator
                                                riskAssessmentType={RiskAssessmentType.Risk}
                                                fixedWidthLeftColumn
                                                textSize="sm"
                                                score={riskAssessmentData?.riskAssessmentTotalScore}
                                                linkHref={getRiskAssessmentQuestionsDialog(
                                                    id,
                                                    RiskAssessmentArea.ProcessingActivities,
                                                    RiskComplianceArea.GDPR,
                                                    riskAssessmentData?.currentRiskAssessmentVersionId ?? "null"
                                                )}
                                            />
                                        </Box>
                                    </Box>
                                )}
                            </DotLegalHeading>
                        </Grid>
                    )}
                    <Grid {...propertiesGridProps}>
                        <DotLegalHeading name={translateString("status")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                content={
                                    processingViewModel?.processingActivitiesInfoModel.processingActivityStatus !== undefined
                                        ? translateStatus(processingViewModel!.processingActivitiesInfoModel!.processingActivityStatus!.toString())
                                        : ""
                                }
                            />
                        </DotLegalHeading>
                    </Grid>

                    {platformFeatures.tags && permissions.canAccessTags && permissions.processingActivityMasterDataPermissions?.permissions.read && (
                        <Grid {...propertiesGridProps}>
                            <DotLegalHeading name={translateString("tags")}>
                                <DotLegalFixedHeightText isLoading={isLoading} content={processingViewModel?.processingActivitiesInfoModel.tags.join(", ")} />
                            </DotLegalHeading>
                        </Grid>
                    )}

                    {!addonsFeatures.research && (
                        <Grid {...propertiesGridProps} sx={styles.percentage}>
                            <React.Fragment>
                                {isLoading ? (
                                    <Skeleton sx={styles.percentageSkeleton} variant="text" width={"100%"} />
                                ) : (
                                    <React.Fragment>
                                        <Box sx={styles.percentageContainer}>
                                            <DotLegalCompletedPercentage
                                                hoverText={
                                                    processingViewModel?.processingActivitiesInfoModel.isValidationDue
                                                        ? translateString("processingValidationDue")
                                                        : ""
                                                }
                                                completed={processingViewModel?.processingActivitiesInfoModel.status!}
                                            />
                                        </Box>
                                        {getValidation()}
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        </Grid>
                    )}
                </Grid>
            </DotLegalPaper>

            <DotLegalPaper sx={styles.centerSectionContainer}>
                {isLoading ? (
                    <Grid container spacing={4}>
                        {[0, 1, 2, 3, 4].map((i) => {
                            return coloredBoxWrapper(<Skeleton sx={styles.centerSectionPaper} variant="rectangular" height={100}></Skeleton>, i);
                        })}
                    </Grid>
                ) : (
                    <Grid container spacing={4}>
                        {coloredBoxWrapper(
                            <DotLegalPaper sx={styles.centerSectionPaper} backgroundColor="darkLightBlue">
                                <Typography className="header">{translateString("noOfSharings")}</Typography>
                                <Typography className="body">{processingViewModel?.processingActivitiesInfoModel.noOfActivities}</Typography>
                                <div className="footerContainer" />
                            </DotLegalPaper>
                        )}
                        {coloredBoxWrapper(
                            <DotLegalPaper sx={styles.centerSectionPaper} backgroundColor="lightPurple">
                                <Typography className="header">{translateString("noOfSenders")}</Typography>
                                <Typography className="body">{processingViewModel?.processingActivitiesInfoModel.noOfSenders}</Typography>
                                <div className="footerContainer" />
                            </DotLegalPaper>
                        )}
                        {coloredBoxWrapper(
                            <DotLegalPaper
                                sx={styles.centerSectionPaper}
                                backgroundColor="lightGreen"
                                clickableRows={processingViewModel!.processingActivitiesInfoModel.legalBases.length > 0}
                                onClick={() =>
                                    setShowDataCategoryOverviewDialog({
                                        showDialog: true,
                                        chosenDataCategories: processingViewModel!.processingActivitiesInfoModel.legalBases ?? [],
                                    })
                                }
                            >
                                <Typography className="header">{translateString("noOfDataCategories")}</Typography>
                                <React.Fragment>
                                    <Typography className="body">{processingViewModel?.processingActivitiesInfoModel.noOfDataCategories}</Typography>
                                    <div className="footerContainer">
                                        <Typography className="footer">
                                            {translateString("ordinary")}: {processingViewModel?.processingActivitiesInfoModel.noOfOrdinaryDataCategories}{" "}
                                        </Typography>
                                        <Typography className="footer">
                                            {translateString("sensitive")}: {processingViewModel?.processingActivitiesInfoModel.noOfSensitiveDataCategories}
                                        </Typography>
                                        <Typography className="footer">
                                            {translateString("none")}: {processingViewModel?.processingActivitiesInfoModel.noOfNoInfoDataCategories}
                                        </Typography>
                                    </div>
                                </React.Fragment>
                            </DotLegalPaper>
                        )}

                        {coloredBoxWrapper(
                            <DotLegalPaper sx={styles.centerSectionPaper} backgroundColor="lightBlue">
                                <Typography className="header">{translateString("noOfRecipients")}</Typography>
                                <React.Fragment>
                                    <Typography className="body">{processingViewModel?.processingActivitiesInfoModel.noOfRecipients}</Typography>
                                    <div className="footerContainer">
                                        <Typography className="footer">
                                            {translateString("eu")}: {processingViewModel?.processingActivitiesInfoModel.noOfEUTransfers}{" "}
                                        </Typography>
                                        <Typography className="footer">
                                            {translateString("eea")}: {processingViewModel?.processingActivitiesInfoModel.noOfEEATransfers}
                                        </Typography>
                                        <Typography className="footer">
                                            {translateString("unsafeThirdCountry")}:{" "}
                                            {processingViewModel?.processingActivitiesInfoModel.noOfThirdCountryTransfers}
                                        </Typography>
                                        <Typography className="footer">
                                            {translateString("adequiteCountry")}: {processingViewModel?.processingActivitiesInfoModel.noOfAdequiteTransfers}
                                        </Typography>
                                    </div>
                                </React.Fragment>
                            </DotLegalPaper>
                        )}
                        {coloredBoxWrapper(
                            <DotLegalPaper
                                clickableRows={processingViewModel!.processingActivitiesInfoModel.noOfSystems > 0}
                                onClick={() => setShowSystemsDialog(true)}
                                sx={styles.centerSectionPaper}
                                backgroundColor="lightGrey"
                            >
                                <Typography className="header">{translateString("noOfSystems")}</Typography>
                                <Typography className="body">{processingViewModel?.processingActivitiesInfoModel.noOfSystems}</Typography>
                                <div className="footerContainer" />
                            </DotLegalPaper>
                        )}
                    </Grid>
                )}
            </DotLegalPaper>

            <Box sx={styles.tableContainer}>
                <DotLegalTabs
                    fullWidth={false}
                    tabStyle="secondary"
                    selectedTab={selectedTab ?? 0}
                    setSelectedTab={(index) => setSelectedTab(index)}
                    content={getTabContent()}
                    noPadding
                    ignoreTextTransformation
                />
            </Box>

            {permissions.canManageProcessingActivtyComments && processingViewModel && (
                <CommentDialog
                    processingActivityId={processingViewModel.processingActivitiesInfoModel.id}
                    header={processingViewModel.processingActivitiesInfoModel.name}
                    disabled={shouldDisableComments(addonsFeatures.research, processingViewModel!.processingActivitiesInfoModel.processingActivityStatus)}
                />
            )}

            {showDeleteDialog && (
                <ProcessingActivityDeleteDialog
                    id={processingViewModel?.processingActivitiesInfoModel.id!}
                    name={processingViewModel?.processingActivitiesInfoModel.name!}
                    onCloseDialog={() => setShowDeleteDialog(false)}
                    onSuccessDelete={() => {
                        refetchMenuNotifications();
                        onProcessingActivityDeleteSuccess();
                    }}
                />
            )}

            {showDataCategoryOverviewDialog.showDialog && (
                <DataCategoryOverviewTable
                    legalBases={showDataCategoryOverviewDialog.chosenDataCategories}
                    dialogOpen={showDataCategoryOverviewDialog.showDialog}
                    dialogClose={() => setShowDataCategoryOverviewDialog({ showDialog: false, chosenDataCategories: [] })}
                />
            )}
            {showSystemsDialog && (
                <ProcessingActivityOverViewSystemTable showDialog={showSystemsDialog} setShowDialog={setShowSystemsDialog} processingActivityId={id} />
            )}
            {showNoteDialog && (
                <DotLegalNoteDialog
                    showDialog={showNoteDialog}
                    noteText={processingViewModel?.processingActivitiesInfoModel.note}
                    onDialogClose={() => setShowNoteDialog(false)}
                    onOkClick={(text) => updateNote(text)}
                    isSaving={savingNote}
                />
            )}
            {showPolicyDialog && (
                <PolicyOverviewDialog onDialogClose={() => setShowPolicyDialog(false)} policies={processingViewModel!.processingActivitiesInfoModel.policies} />
            )}
            {showStatusDialog && (
                <ProcessingActivityStatusDialog
                    onDialogClose={() => setShowStatusDialog(false)}
                    Status={processingViewModel!.processingActivitiesInfoModel!.processingActivityStatus}
                    Id={id}
                    onSaveClick={() => refetchProcessingActivity()}
                />
            )}
            {showAuditLogDialog === "true" && (
                <AuditLogDialog
                    entityType={EntityType.ProcessingActivity}
                    entityId={id}
                    entityName={processingViewModel?.processingActivitiesInfoModel?.name}
                    onDialogClose={() => setShowAuditLogDialog("false")}
                />
            )}
            {showProcessingActivityReportDialog && (
                <ProcessingActivtyReportDialog
                    processingActivityId={id}
                    processingActivityName={processingViewModel?.processingActivitiesInfoModel?.name}
                    onDialogClose={() => setShowProcessingActivityReportDialog(false)}
                    processingActivityStatus={processingViewModel?.processingActivitiesInfoModel?.processingActivityStatus}
                    onSubmitReport={refetchProcessingActivity}
                />
            )}
        </Box>
    );

    function coloredBoxWrapper(children: JSX.Element, key?: number) {
        return (
            <Grid key={key} item xs={12} sm={6} md={4} lg>
                {children}
            </Grid>
        );
    }
}

export default withAITracking(reactPlugin, ProcessingActivityOverview, "Edit processing activity", "tracking");
