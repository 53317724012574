import React from "react";
import { useAnnualWheelAddEditDialog } from "./AnnualWheelAddEditDialog.hooks.ts";
import { DialogStep } from "../AnnualWheel.types.ts";
import { arrow, leftWhiteArrowButton } from "../../common/icons.tsx";
import { Box } from "@mui/material";
import { DotLegalDialog, DotLegalFullScreenSpinner, useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";
import { useAnnualWheelDialogStyles } from "../annualWheelDialog/AnnualWheelDialog.styles.ts";
import AnnaulWheelBaseInformation from "../annualWheelDialog/annualWheelBaseInformationForm/AnnualWheelBaseInformationForm.tsx";
import AnnualWheelResponsibleForm from "../annualWheelDialog/annualWheelResponsibleForm/AnnualWheelResponsibleForm.tsx";
import AnnualWheelDurationForm from "../annualWheelDialog/annualWheelDurationForm/AnnualWheelDurationForm.tsx";
import AnnualWheelNameDescriptionSubtasks from "../annualWheelDialog/annualWheelNameDescriptionSubtasks/AnnualWheelNameDescriptionSubtasks.tsx";
import { SelectedAnnualWheelActivityTemplate } from "./AnnualWheelAddEditDialog.types.ts";
import { usePermissionsProvider } from "../../auth/usePermissionsProvider.ts";

export interface IAnnualWheelAddEditDialogProps {
    onDialogClose: () => void;
    onPreviousClick: () => void;
    selectedAnnualWheelActivityTemplate?: SelectedAnnualWheelActivityTemplate;
    onSave: () => void;
    annualWheelActivityId?: string;
    startDate: Date;
}

function AnnualWheelAddEditDialog(props: IAnnualWheelAddEditDialogProps) {
    const isUseOnSmallScreen = useIsOnSmallScreen();
    const { hasTagsAccess } = usePermissionsProvider();
    const styles = useAnnualWheelDialogStyles(isUseOnSmallScreen);
    const {
        isLoading,
        inProgess,
        getOkBtnText,
        getPreviousBtnText,
        getDialogHeader,
        isFinalStep,
        dialogStep,
        onOkClick,
        onPreviousButtonClick,
        annualWheelNameDescriptionSubTasksFormModel,
        setAnnualWheelNameDescriptionSubTasksFormModel,
        annualWheelBaseInformationFormModel,
        setAnnualWheelBaseInformationFormModel,
        annualWheelResponsibleFormModel,
        setAnnualWheelResponsibleFormModel,
        annualWheelDurationFormModel,
        setAnnualWheelDurationFormModel,
        showFormError,
        isEditActivity,
        isValidatePaActivity,
        activityId,
        selectedGroupCompaniesForValidateActivity,
        setSelectedGroupCompaniesForValidateActivity,
    } = useAnnualWheelAddEditDialog(props);

    const getNameDescriptionSubtasksForm = () => {
        return (
            <AnnualWheelNameDescriptionSubtasks
                saveModel={annualWheelNameDescriptionSubTasksFormModel}
                onChange={(model) => setAnnualWheelNameDescriptionSubTasksFormModel(model)}
                showError={showFormError}
                showTrialWarning={props.selectedAnnualWheelActivityTemplate?.showTrialWarning ?? false}
            />
        );
    };

    const getBaseInformationForm = () => {
        return (
            <AnnaulWheelBaseInformation
                saveModel={annualWheelBaseInformationFormModel}
                isProcessingActivityValidationActivity={isValidatePaActivity}
                onChange={(model) => setAnnualWheelBaseInformationFormModel(model)}
                onGroupCompaniesChange={setSelectedGroupCompaniesForValidateActivity}
                selectedGroupCompanies={selectedGroupCompaniesForValidateActivity}
                activityId={activityId == null ? undefined : activityId}
            />
        );
    };

    const getResponsibleForm = () => {
        return (
            <AnnualWheelResponsibleForm
                onChange={(model) => setAnnualWheelResponsibleFormModel(model)}
                saveModel={annualWheelResponsibleFormModel}
                showError={showFormError}
            />
        );
    };

    const getDurationForm = () => {
        return (
            <AnnualWheelDurationForm
                saveModel={annualWheelDurationFormModel}
                onChange={(model) => setAnnualWheelDurationFormModel(model)}
                showError={showFormError}
                isEdit={isEditActivity}
            />
        );
    };

    const getStepForm = () => {
        switch (dialogStep) {
            case DialogStep.NameDescriptionSubtasks:
                return getNameDescriptionSubtasksForm();
            case DialogStep.ActivityDurationData:
                return getDurationForm();
            case DialogStep.ActivityResponsibleData:
                return getResponsibleForm();
            case DialogStep.ActivityBaseInformation:
                return getBaseInformationForm();
            default:
                throw new Error(`Unsupported step ${dialogStep}`);
        }
    };

    return (
        <DotLegalDialog
            inProgress={inProgess}
            size={"md"}
            buttonRight
            buttonOkText={getOkBtnText()}
            header={getDialogHeader()}
            onOkClick={onOkClick}
            onDialogClose={() => props.onDialogClose()}
            open
            buttonOkIcon={!isFinalStep() ? arrow : undefined}
            previousButtonText={getPreviousBtnText()}
            previousButtonClick={onPreviousButtonClick}
            previousButtonIcon={isEditActivity && dialogStep === DialogStep.NameDescriptionSubtasks ? undefined : leftWhiteArrowButton}
            okButtonDisabled={
                dialogStep === DialogStep.ActivityBaseInformation &&
                props.annualWheelActivityId !== undefined &&
                annualWheelBaseInformationFormModel.tags === undefined &&
                hasTagsAccess
            }
        >
            {isLoading ? (
                <DotLegalFullScreenSpinner />
            ) : (
                <Box sx={styles.contentWrapper}>
                    <Box sx={styles.formSpacing}>{getStepForm()}</Box>
                </Box>
            )}
        </DotLegalDialog>
    );
}

export default AnnualWheelAddEditDialog;
