import { useMutation, useQuery } from "react-query";
import { get, put } from "../../../../common/api/apiShared";
import { DeclarationControlSaveModel } from "../declarationControlDialogContent/DeclarationControlDialogContent.types";
import { ajaxQueue, useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../../localization/useTranslation";
import { createElement, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useValidator } from "../../../../common/hooks/validation";
import { SelectableItem } from "../../../../processingActivity/ProcessingActivity.types";
import { IDeclarationControlDialogWrapper } from "../DeclarationControlDialogWrapper";

export default function useEditDeclarationControlDialogHooks(props: IDeclarationControlDialogWrapper) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [saveModel, setSaveModel] = useState<DeclarationControlSaveModel>(getSaveModel());
    const editDeclarationControlMutation = useMutation(editDeclarationControlApiUrl);

    const declarationControlUrl = `/declarationcontrol/${props.declarationControlId}`;
    let { data, isLoading } = useQuery(declarationControlUrl, () => get<DeclarationControlSaveModel>(declarationControlUrl), {
        enabled: props.declarationControlId !== undefined,
    });

    useEffect(() => {
        if (data) {
            setSaveModel(getSaveModel(data));
        }
    }, [data]);

    useEffect(() => {
        if (validation.anyHasErrors && !isLoading) {
            validation.setShowErrors(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveModel.name]);

    const validation = useValidator<DeclarationControlSaveModel>(
        (validators) => ({
            name: validators.validateNotEmpty((item) => item.name, translateString("name")),
        }),
        []
    );
    validation.check(saveModel);

    const updateDeclarationControl = async (changeFunc: (saveModel: DeclarationControlSaveModel) => void) => {
        const temp = { ...saveModel };
        changeFunc(temp);

        setSaveModel(temp);
        await ajaxQueue().addRequest(editDeclarationControlMutation.mutateAsync, temp);
    };

    const modelUpdater = {
        onNameChange: (name: string) => {
            updateDeclarationControl((s) => {
                s.name = name;
            });
        },
        onIDChange: (id: string) => {
            updateDeclarationControl((s) => {
                s.customId = id;
            });
        },
        onDescriptionChange: (description: string) => {
            updateDeclarationControl((s) => {
                s.description = description;
            });
        },
        onResponsibleChanged: (responsible: string | null) => {
            updateDeclarationControl((s) => {
                s.responsibleId = responsible ?? undefined;
            });
        },
        onCategoryChanged: (category: SelectableItem | undefined) => {
            updateDeclarationControl((s) => {
                s.categoryId = category?.id ?? undefined;
            });
        },
        onAddTask: (taskId: string) => {
            updateDeclarationControl((s) => {
                s.taskIds.push(taskId);
            });
        },
        onRemoveTask: (taskId: string) => {
            updateDeclarationControl((s) => {
                s.taskIds = s.taskIds.filter((id) => id !== taskId);
            });
        },
    };

    const editDeclarationControl = () => {
        editDeclarationControlMutation.mutateAsync(saveModel!, {
            onSuccess: (response: any) => {
                snackbar.show(createElement(Trans, { i18nKey: "itemEditedSuccess", values: { name: saveModel.name } }));
                props.onControlSaved();
            },
        });
    };

    function onSaveClick() {
        editDeclarationControl();
    }

    return {
        isSaving: editDeclarationControlMutation.isLoading,
        saveModel,
        onSaveClick,
        validation,
        modelUpdater,
        isLoading,
    };

    function editDeclarationControlApiUrl(saveModel: DeclarationControlSaveModel) {
        return put<string>(`/declarationControl/${props.declarationControlId}/`, saveModel);
    }

    function getSaveModel(saveModel?: DeclarationControlSaveModel): DeclarationControlSaveModel {
        return {
            categoryId: saveModel?.categoryId ?? undefined,
            customId: saveModel?.customId ?? "",
            description: saveModel?.description ?? "",
            name: saveModel?.name ?? "",
            responsibleId: saveModel?.responsibleId ?? undefined,
            taskIds: saveModel?.taskIds ?? [],
        };
    }
}
