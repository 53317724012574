import { createSxStyles } from "../../common/createSxStyles";
import { useTheme } from "@mui/material";

export const useAnnualWheelDialogStyles = (isOnSmallScreen: boolean) => {
    const theme = useTheme();
    return createSxStyles({
        content: {
            width: "200px",
            display: "flex",
            alignItems: "start",
        },
        numberField: {
            width: 50,
            marginRight: theme.spacing(1),
        },
        error: {
            fontSize: 12,
            color: theme.palette.error.main,
        },
        interval: {
            display: "flex",
            alignItems: "center",
            verticalAlign: "center",
            color: theme.palette.primary.dark,
            fontSize: theme.typography.pxToRem(14),
        },
        each: {
            marginRight: theme.spacing(1),
        },
        notificationPeriod: {
            display: "flex",
            alignItems: "center",
            verticalAlign: "center",
            color: theme.palette.primary.dark,
            fontSize: theme.typography.pxToRem(14),
        },
        singularActivity: {
            display: "flex",
            alignItems: "center",
            color: theme.palette.primary.dark,
            fontSize: theme.typography.pxToRem(14),
            marginBottom: -1.5,
        },
        contentWrapper: {
            display: "flex",
            justifyContent: "center",
        },
        formSpacing: {
            width: isOnSmallScreen ? "100%" : "70%",
        },
    });
};
