import { DotLegalButton, DotLegalPaper } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import useLegalEntityClassificationTabHooks from "./LegalEntityClassificationTab.hooks";
import LegalEntityClassificationDialog from "./legalEntityClassificationDialog/LegalEntityClassificationDialog";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { getLegalEntityPermissions } from "../LegalEntity.hooks";
import ClassificationTable from "./classificationTable/ClassificationTable.tsx";
import { WhiteLockIcon } from "../../common/icons.tsx";
import PlanDialogButtonWrapper from "../../plan/planDialogButtonWrapper/planDialogButtonWrapper.tsx";
import { GetLegalEntityClassificationPlanLimit } from "../../auth/planProvider/PlanProvider.hooks.ts";
import { useAddTaskMenuItem } from "../../common/components/addTaskMenuItem/AddTaskMenuItemContext.hooks.ts";

export interface ILegalEntityClassificationTab {
    legalEntityId: string;
    isGroupEntity: boolean;
}

function LegalEntityClassificationTab(props: ILegalEntityClassificationTab) {
    const { translateString } = useTranslation();
    const {
        isLoading,
        data,
        onClassificationCreated,
        setShowClassificationDialog,
        showClassificationDialog,
        refetchData,
        classificationPlanLimitIsLoading,
        classificationPlanLimitData,
    } = useLegalEntityClassificationTabHooks(props);

    const { permissions } = useUserContext();

    const legalEntityPermissions = getLegalEntityPermissions(permissions, props.isGroupEntity);

    return (
        <DotLegalPaper>
            <React.Fragment>
                <ClassificationTable
                    data={data}
                    isLoading={isLoading}
                    refetch={() => refetchData()}
                    paqgeType="legalEntity"
                    emptyStateText={translateString("legalEntityClassificationEmptyState")}
                    planLimit={classificationPlanLimitData}
                />

                {legalEntityPermissions.create && (
                    <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
                        <PlanDialogButtonWrapper
                            enabled={classificationPlanLimitData !== undefined && classificationPlanLimitData.limitReached}
                            text={translateString("classificationLimitReached")}
                        >
                            <DotLegalButton
                                onClick={() => setShowClassificationDialog(true)}
                                buttonType={"primary"}
                                disabled={classificationPlanLimitData?.limitReached}
                                endIcon={classificationPlanLimitData?.limitReached ? WhiteLockIcon : undefined}
                                isLoading={classificationPlanLimitIsLoading}
                                toolTip={classificationPlanLimitData?.limitReached ? translateString("classificationLimitReached") : undefined}
                            >
                                {translateString("addClassification")}
                            </DotLegalButton>
                        </PlanDialogButtonWrapper>
                    </Box>
                )}

                {showClassificationDialog && (
                    <LegalEntityClassificationDialog
                        onCreated={onClassificationCreated}
                        legalEntityId={props.legalEntityId}
                        onDialogClose={() => setShowClassificationDialog(false)}
                        planLimit={classificationPlanLimitData}
                    />
                )}
            </React.Fragment>
        </DotLegalPaper>
    );
}

export default LegalEntityClassificationTab;
