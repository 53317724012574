import React, { useState } from "react";
import { DotLegalHeader, DotLegalInformationTooltip, DotLegalSwitch } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import { useTranslation } from "../../../localization/useTranslation.ts";
import LegalEntitySelector from "../../../legalEntity/legalEntitySelector/LegalEntitySelector.tsx";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider.tsx";
import { useGroupEntitiesQuery } from "../../../processingActivity/hooks/useGroupEntitiesQuery.ts";
import { useSelectableResponsiblesAssignees } from "../../useSelectableResponsiblesAssignees.ts";

export interface IAnnualWheelGroupEntitySelectorProps {
    assignees?: Array<string> | undefined;
    responsible?: string | undefined;
    onGroupCompanyResponsibleChange?: (userId: string | null, groupCompanyId: string) => void;
    onGroupCompaniesChange: (groupCompanies: Array<string>) => void;
    selectedUserGroupCompanies: Array<{ userId: string | null; groupCompanyId: string }> | undefined;
    showError?: boolean;
    enableResponsibleColumn: boolean;
    explanation: string;
}

function AnnualWheelGroupEntitySelector(props: IAnnualWheelGroupEntitySelectorProps) {
    const userCtx = useUserContext();
    const [appliesAllGroupEntities, setAppliesAllGroupEntities] = useState((props.selectedUserGroupCompanies ?? []).length === 0);
    let groupEntitiesQuery = useGroupEntitiesQuery();
    const responsiblesAssignees = useSelectableResponsiblesAssignees(props.assignees, props.responsible);

    const showGroupEntitySelector = groupEntitiesQuery.allGroupEntities && groupEntitiesQuery.allGroupEntities?.length > 1 && userCtx.gdpo;
    const { translateString } = useTranslation();

    const getErrorMessage = (error: string) => {
        if (error)
            return (
                <Box
                    sx={(theme) => ({
                        fontSize: 12,
                        color: theme.palette.error.main,
                    })}
                >
                    {error}
                </Box>
            );

        return undefined;
    };

    return (
        <>
            {showGroupEntitySelector && (
                <>
                    <DotLegalHeader headerStyle={"small"} marginTop={4} marginBottom={0.5}>
                        {translateString("annualWheelActivityAppliesFor")}
                    </DotLegalHeader>

                    <DotLegalSwitch
                        onChange={() => {
                            setAppliesAllGroupEntities(!appliesAllGroupEntities);
                            props.onGroupCompaniesChange([]);
                        }}
                        checked={appliesAllGroupEntities}
                        rightLabel={
                            <Box component={"span"} sx={{ display: "inline-flex", gap: 1 }}>
                                {translateString("globalAnnualWheelActivity")}

                                <DotLegalInformationTooltip text={props.explanation} />
                            </Box>
                        }
                        greyUncheckedTrack
                    />

                    {!appliesAllGroupEntities && (
                        <Box
                            sx={(theme) => ({
                                marginTop: 3,
                                marginLeft: "-11px",
                                "& .MuiAutocomplete-root .MuiInputBase-input": {
                                    fontSize: theme.typography.pxToRem(12),
                                },
                                "& .MuiAutocomplete-root .MuiInputBase-input::placeholder": {
                                    fontSize: theme.typography.pxToRem(12),
                                },
                            })}
                        >
                            <LegalEntitySelector
                                marginTop={0}
                                allColumns={false}
                                isLoading={groupEntitiesQuery.isLoading}
                                selectedEntities={props.selectedUserGroupCompanies?.map((x) => x.groupCompanyId) ?? []}
                                legalEntityItems={groupEntitiesQuery.allGroupEntities?.map((item) => {
                                    return {
                                        users: responsiblesAssignees.selectableResponsibles,
                                        ...item,
                                    };
                                })}
                                setSelectedEntities={(e) => props.onGroupCompaniesChange(e)}
                                hideGridSize
                                gridlength={10000}
                                enableResponsibleColumn={props.enableResponsibleColumn}
                                onResponsibleChange={props.onGroupCompanyResponsibleChange}
                                userGroupCompanies={props.selectedUserGroupCompanies ?? []}
                                mainResponsible={responsiblesAssignees.selectableResponsibles?.find((x) => x.id === props.responsible) ?? undefined}
                            />
                            {getErrorMessage(props.showError ? translateString("annualWheelDataTaskResponsibleError") : "")}
                        </Box>
                    )}
                </>
            )}
        </>
    );
}

export default AnnualWheelGroupEntitySelector;
