import { useEffect, useState } from "react";
import { TaskRelationViewModel } from "./TaskRelationsBox.types";
import { EntityType } from "../../../common/Common.types";
import { ITaskRelationsBox } from "./TaskRelationsBox";
import { useQuery } from "react-query";
import { deleteHttp, get } from "../../../common/api/apiShared";
import { useOptimisticUpdate } from "../../../common/hooks/useOptimisticUpdate";

export default function useTaskRelationsBoxHooks(props: ITaskRelationsBox) {
    const optimisticUpdate = useOptimisticUpdate();
    const [expanded, setExpanded] = useState(false);

    const url = `/relations/${props.taskId}/`;
    const { data } = useQuery(url, () => get<Array<TaskRelationViewModel>>(url));

    useEffect(() => {
        if (data && data.length > 0) {
            setExpanded(true);
        }
    }, [data]);

    async function removeRelation(id: string) {
        let tempData = { ...data };
        tempData = data!.filter((d) => d.relationId !== id);
        await optimisticUpdate.putOnQueueAndSetQueryData(tempData, url, remove, id);
    }

    return { expanded, setExpanded, data, removeRelation };

    async function remove(id: string) {
        return await deleteHttp(`/relations/${id}`);
    }
}
