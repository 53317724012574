import { DotLegalSelectOption } from "../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useTranslation } from "../localization/useTranslation";
import { DocumentStatus } from "./Documents.types";

export function useDocumentHook() {
    const { translateString } = useTranslation();
    const documentStatus = Object.entries(DocumentStatus).filter((q) => isNaN(Number(q[0])));

    const selecableDocumentStatus: Array<DotLegalSelectOption> = documentStatus.map((status) => {
        return {
            name: translateString(status[0].toString()),
            id: status[1].toString(),
        };
    });

    return { selecableDocumentStatus };
}
