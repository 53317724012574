import { useQuery } from "react-query";
import { TaskRelationRow } from "../../../../taskManagement/editTaskDialog/taskRelations/TaskRelations.types";
import { get } from "../../../../common/api/apiShared";
import { useResponsibleQuery } from "../../../../user/hooks/useResponsibleQuery";
import { IDeclarationControlDialogContent } from "./DeclarationControlDialogContent";

export default function useDeclarationControlDialogHooks(props: IDeclarationControlDialogContent) {
    const url = `/taskRelation/selectableTasks?deadline=${props.deadline}`;
    let { data } = useQuery(url, () => get<Array<TaskRelationRow>>(url));

    const responsibleQuery = useResponsibleQuery(true);
    let selectableResponsiblesData = responsibleQuery.userData;
    let selectableresponsiblesLoading = responsibleQuery.isLoading;

    return {
        data,
        selectableResponsiblesData,
        selectableresponsiblesLoading,
    };
}
