import { DotLegalRadioButton, DotLegalTable, DotLegalTextField, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { Box, CircularProgress, Collapse, IconButton, RadioGroup, TableCell, TableRow, Typography } from "@mui/material";
import { useDocumentVersionTableHook } from "./DocumentVersionTable.hooks";
import { downloadIcon, removeIcon } from "../../common/icons";
import { DocumentVersionViewModel } from "./DocumentVersionTable.types";
import React from "react";
import DotLegalFileUploadWrapper from "../../common/components/dotLegalFileUpload/DotLegalFileUploadWrapper";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";

export interface IDocumentVersionTable {
    documentId: string;
    documentsUploading: boolean;
    setDocumentsUploading: (isUploading: boolean) => void;
}

function DocumentVersionTable(props: IDocumentVersionTable) {
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();
    const {
        handleDownloadClick,
        deleteDocumentVersion,
        document,
        documentationOption,
        linkValidation,
        setDocument,
        setDocumentationOption,
        uploadDocumentVersion,
        uploadLink,
        selectedDocumentVersion,
        setSelectedDocumentVersion,
        isLoading,
        data,
    } = useDocumentVersionTableHook(props);

    linkValidation?.check(document);

    function GetDocumentHeader() {
        const headers = Array<ITableHeader<DocumentVersionViewModel>>();
        headers.push({ property: "fileName", text: translateString("name"), align: "left", showOnMobile: true });
        headers.push({ property: "version", text: translateString("version"), align: "left", showOnMobile: true });
        headers.push({ property: "dataId", text: "", align: "left", showOnMobile: true });

        return headers;
    }

    return (
        <React.Fragment>
            <DotLegalTable
                headers={GetDocumentHeader()}
                defaultOrderBy={"version"}
                defaultOrder={"asc"}
                data={data ?? []}
                hideRowsPerPage
                clickableRows={false}
                isLoading={isLoading}
                renderRow={(row, i) => (
                    <TableRow key={i}>
                        <TableCell>{row.fileName ?? row.link}</TableCell>
                        <TableCell>{row.version}</TableCell>
                        <TableCell align="right">
                            <IconButton onClick={() => handleDownloadClick(row.documentId, row.dataId, row.fileName, row.link)}>{downloadIcon}</IconButton>
                            {permissions.canDeleteDoocumentVersion && data && data.length > 1 ? (
                                selectedDocumentVersion === row.documentVersionId ? (
                                    <IconButton>
                                        <CircularProgress size={15} />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        onClick={() => {
                                            setSelectedDocumentVersion(row.documentVersionId);
                                            deleteDocumentVersion(row.documentId, row.documentVersionId);
                                        }}
                                    >
                                        {removeIcon}
                                    </IconButton>
                                )
                            ) : undefined}
                        </TableCell>
                    </TableRow>
                )}
                paginationLabelOf={translateString("labelOf")}
            />
            <Box marginTop={2}>
                <RadioGroup
                    row
                    value={documentationOption}
                    onChange={(e) => {
                        setDocumentationOption(Number((e.target as HTMLInputElement).value));
                    }}
                >
                    <DotLegalRadioButton value={1} labelPlacement="end" label={translateString("uploadDocument")} />
                    <DotLegalRadioButton value={0} labelPlacement="end" label={translateString("insertLink")} />
                </RadioGroup>

                <>
                    <Collapse in={documentationOption === 1}>
                        {
                            <Box sx={{ height: 70 }}>
                                <DotLegalFileUploadWrapper
                                    files={[document?.fileName!]}
                                    onFilesChanged={async (files) => {
                                        await uploadDocumentVersion(files);
                                    }}
                                    isLoading={props.documentsUploading}
                                    pasteEnabled={documentationOption === 1}
                                />
                            </Box>
                        }
                    </Collapse>
                    <Collapse in={documentationOption === 0}>
                        <Box sx={{ height: 70, display: "flex", flexDirection: "column", "& > *": { marginTop: 0 } }}>
                            <DotLegalTextField
                                label={translateString("insertLink")}
                                value={document?.link ?? null}
                                debounce={false}
                                errorText={linkValidation?.getErrorsForDisplay("link")}
                                onChange={(value) => {
                                    let tempModel = { ...document! };
                                    tempModel.link = value;
                                    setDocument(tempModel);
                                }}
                            ></DotLegalTextField>
                            <Typography
                                onClick={uploadLink}
                                sx={(theme) => ({
                                    alignSelf: "end",
                                    fontSize: theme.typography.pxToRem(12),
                                    color: theme.palette.primary.main,
                                    "&:hover": {
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                    },
                                })}
                            >
                                {translateString("insertLink")}
                            </Typography>
                        </Box>
                    </Collapse>
                </>
            </Box>
        </React.Fragment>
    );
}

export default DocumentVersionTable;
