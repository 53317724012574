import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
    DotLegalButton,
    DotLegalEmptyState,
    DotLegalPageHeader,
    DotLegalPaper,
    useHistoryWithReferer,
    useIsOnSmallScreen,
} from "@dotlegal/dotlegal-ui-components";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider.tsx";
import { usePlanContext } from "../auth/planProvider/PlanProvider.tsx";
import { useTranslation } from "../localization/useTranslation.ts";
import { useUrlProvider } from "../useUrlProvider.ts";
import DotLegalAllOrMySwitchBox from "../common/components/dotLegalSwitch/dotLegalAllOrMySwitchBox/dotLegalAllOrMySwitchBox.tsx";
import React from "react";
import EmptyStateSvg from "./contractsEmptyState.svg?react";
import { useContractsStyles } from "./contracts.styles.ts";
import PlanDialogButtonWrapper from "../plan/planDialogButtonWrapper/planDialogButtonWrapper.tsx";
import { PurpleLockIcon, WhiteLockIcon } from "../common/icons.tsx";
import { GetContractsPlanLimit } from "../auth/planProvider/PlanProvider.hooks.ts";
import { useContracts } from "./Contracts.hooks.ts";

function Contracts() {
    const navigate = useNavigate();
    const historyWithReferer = useHistoryWithReferer();
    const { translateString } = useTranslation();
    const isOnSmallScreen = useIsOnSmallScreen();
    const { getContractOverviewUrl } = useUrlProvider();
    const { permissions, customerName } = useUserContext();
    const styles = useContractsStyles();
    const { contractsPlanLimitData, refetchContractsPlanLimit } = GetContractsPlanLimit();
    const { contractManagementFeatures } = usePlanContext();
    const { breadCrumbs, onlyUserSpecific, setOnlyUserSpecific, showCreateDialog, setShowCreateDialog } = useContracts();

    const gotoOverview = (id: string) => {
        historyWithReferer.push(getContractOverviewUrl(id));
    };

    const getButtons = () => {
        if (contractsPlanLimitData !== undefined) {
            return (
                <React.Fragment>
                    {permissions.contractsPermissions.create && (
                        <PlanDialogButtonWrapper
                            text={translateString("contractLimitReached", { count: contractManagementFeatures.maxNumberOfContracts })}
                            enabled={contractsPlanLimitData.limitReached}
                        >
                            <DotLegalButton
                                buttonType="primary"
                                size="medium"
                                onClick={() => setShowCreateDialog(true)}
                                disabled={contractsPlanLimitData.limitReached}
                                endIcon={contractsPlanLimitData.limitReached ? WhiteLockIcon : undefined}
                                toolTip={
                                    contractsPlanLimitData.limitReached
                                        ? translateString("contractLimitReached", { count: contractManagementFeatures.maxNumberOfContracts })
                                        : undefined
                                }
                            >
                                {" "}
                                {translateString("createContract")}
                            </DotLegalButton>
                        </PlanDialogButtonWrapper>
                    )}
                </React.Fragment>
            );
        } else {
            return undefined;
        }
    };

    return (
        <div>
            <DotLegalPageHeader
                userContext={{ customerName: customerName }}
                breadCrumbs={breadCrumbs}
                leftComponent={<DotLegalAllOrMySwitchBox onChange={setOnlyUserSpecific} enabled={onlyUserSpecific} hidden={!permissions.canAccessAllData} />}
            />
            <DotLegalPaper sx={styles.createPaper} background={"smallPurpleWave"} backgroundCover>
                {getButtons()}
            </DotLegalPaper>
            <DotLegalPaper>
                <DotLegalEmptyState
                    icon={<EmptyStateSvg />}
                    text={
                        <Box>
                            <Box
                                sx={(theme) => ({
                                    color: theme.palette.primary.dark,
                                    fontSize: theme.typography.pxToRem(14),
                                    marginBottom: theme.spacing(1),
                                })}
                            >
                                {translateString("noContractsDescriptionHeader")}
                            </Box>
                            <Box>{translateString("noContractsDescription")}</Box>
                        </Box>
                    }
                />
            </DotLegalPaper>
        </div>
    );
}

export default Contracts;
