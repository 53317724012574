import { Box, Grid, GridSize, Skeleton, TableCell } from "@mui/material";
import React from "react";
import {
    DotLegalButton,
    DotLegalDialog,
    DotLegalEmptyState,
    DotLegalHeader,
    DotLegalMultiSelect,
    DotLegalOverflowMenu,
    DotLegalOverflowMenuItem,
    DotLegalPageHeader,
    DotLegalTableWithControls,
    ITableHeader,
    useHistoryWithReferer,
    useIsOnSmallScreen,
} from "@dotlegal/dotlegal-ui-components";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import { PurpleLockIcon, WhiteLockIcon } from "../../common/icons";
import { useTranslation } from "../../localization/useTranslation";
import { useSystemDataMapping } from "./Systems.hooks";
import { useSystemsStyles } from "./Systems.styles";
import { SystemListOverviewRowTableModel, SystemListOverviewRowViewModel } from "./Systems.types";
import AssociationSvg from "../../processingActivity/processingActivities/associations.svg?react";
import ProcessingActivitySvg from "./processingActivity.svg?react";
import DataCategorySvg from "./dataCategory.svg?react";
import SharingSvg from "./sharing.svg?react";
import EmptyStateSvg from "./noSystems.svg?react";
import { useUrlProvider } from "../../useUrlProvider";
import AddSystemDialog from "../addSystemDialog/AddSystemDialog";
import SystemTemplatesDialog from "../systemTemplateDialog/SystemTemplatesDialog";
import CustomListSelector from "../../customLists/customListSelector/CustomListSelector";
import { CustomListSelectorType } from "../../customLists/customListSelector/CustomListSelector.types";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import PlanDialogButtonWrapper from "../../plan/planDialogButtonWrapper/planDialogButtonWrapper";
import DotLegalAllOrMySwitchBox from "../../common/components/dotLegalSwitch/dotLegalAllOrMySwitchBox/dotLegalAllOrMySwitchBox";
import RiskIndicator from "../../riskAssessment/riskIndicator/RiskIndicator";
import { RiskAssessmentType } from "../../riskAssessment/RiskAssessment.types";
import SystemIcon from "../systemIcon/SystemIcon";
import { RiskAssessmentArea, RiskComplianceArea } from "../../riskAssessment/riskAssessments/RiskAssessments.types";
import { useNavigate } from "react-router-dom";
import SystemDeleteDialog from "../systemOverview/systemDeleteDialog/SystemDeleteDialog";
import { hasAccessToSystemSteps } from "../systemOverview/SystemOverview.hooks";
import { GetSystemsPlanLimit } from "../../auth/planProvider/PlanProvider.hooks.ts";
import TrialUsageWarning from "../../common/components/TrialUsageWarning.tsx";
import TrialExpiredRowWrapper from "../../common/components/TrialExpiredRowWrapper.tsx";
import PurpleLockSvg from "../../common/svgs/purpleLock.svg?react";
import CopySystemDialog from "../copySystemDialog/CopySystemDialog.tsx";
import { EntityType } from "../../common/Common.types.ts";
import { useAddTaskMenuItem } from "../../common/components/addTaskMenuItem/AddTaskMenuItemContext.hooks.ts";

function Systems() {
    const { getSystemOverviewUrl, getRiskAssessmentQuestionsDialog, getSystemEditUrl, getSystemCreateViewUrl } = useUrlProvider();
    const navigate = useNavigate();
    const historyWithReferer = useHistoryWithReferer();
    const { permissions, customerName } = useUserContext();
    const { informationSecurityFeatures } = usePlanContext();
    const addTaskMenuItem = useAddTaskMenuItem();

    const {
        breadCrumbs,
        onlyUserSpecific,
        setOnlyUserSpecific,
        systems,
        isLoading,
        searchedBusinessAreas,
        setSearchedBusinessAreas,
        setSearchedDataCategories,
        searchedDataCategories,
        businessAreaFilterOptions,
        dataCategoriesFilterOptions,
        hasData,
        showCreateDialog,
        setShowCreateDialog,
        showAddSystemTemplate,
        setShowAddSystemTemplate,
        showExistingCompaniesDialog,
        setShowExistingCompaniesDialog,
        refetchSystems,
        searchedDataSubjects,
        setSearchedDataSubjects,
        dataSubjectsFilterOptions,
        searchedSystemEntityType,
        setSearchedSystemEntityType,
        systemEntityTypeFilterOptions,
        riskScoresLoading,
        setShowDeleteDialog,
        showDeleteDialog,
        refetch,
        copySystem,
        setCopySystem,
    } = useSystemDataMapping();
    const { data: systemsPlanLimitData, refetch: refetchSystemsPlanLimitData } = GetSystemsPlanLimit();
    const styles = useSystemsStyles();
    const { translateString } = useTranslation();
    const isOnSmallScreen = useIsOnSmallScreen();

    const gotoOverview = (systemId: string) => {
        historyWithReferer.push(getSystemOverviewUrl(systemId));
    };
    const noOptionsLabel = translateString("noOptions");

    function getSearchFields() {
        const gridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize } = {
            item: true,
            xs: 12,
            sm: 6,
            md: 4,
            lg: 2,
        };

        return (
            <React.Fragment>
                <Grid {...gridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedBusinessAreas}
                        chosenOptions={searchedBusinessAreas}
                        label={translateString("processingActivityAreas")}
                        placeholder={translateString("processingActivityAreas")}
                        isLoading={isLoading}
                        options={businessAreaFilterOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={noOptionsLabel}
                    />
                </Grid>
                <Grid {...gridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedDataCategories}
                        chosenOptions={searchedDataCategories}
                        label={translateString("dataCategories")}
                        placeholder={translateString("dataCategories")}
                        isLoading={isLoading}
                        options={dataCategoriesFilterOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={noOptionsLabel}
                    />
                </Grid>
                <Grid {...gridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedDataSubjects}
                        chosenOptions={searchedDataSubjects}
                        label={translateString("dataSubjects")}
                        placeholder={translateString("dataSubjects")}
                        isLoading={isLoading}
                        options={dataSubjectsFilterOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={noOptionsLabel}
                    />
                </Grid>

                {informationSecurityFeatures.entityTypeFilter && (
                    <Grid {...gridProps}>
                        <DotLegalMultiSelect
                            onChange={setSearchedSystemEntityType}
                            chosenOptions={searchedSystemEntityType}
                            label={translateString("entityType")}
                            placeholder={translateString("entityType")}
                            isLoading={isLoading}
                            options={systemEntityTypeFilterOptions}
                            noMargin
                            limit={1}
                            noOptionsLabel={noOptionsLabel}
                        />
                    </Grid>
                )}
            </React.Fragment>
        );
    }

    const getMenuItems = (row: SystemListOverviewRowViewModel) => {
        let menuItems: Array<DotLegalOverflowMenuItem> = [];

        if (!systemsPlanLimitData?.limitExceeded) {
            menuItems.push({
                menuItem: translateString("overview"),
                onClick: () => gotoOverview(row.id),
            });

            if (permissions.systemPermissions.edit) {
                menuItems.push({
                    menuItem: translateString("edit"),
                    onClick: () => {
                        navigate(getSystemEditUrl(row.id));
                    },
                });
            }

            if (permissions.systemPermissions.create) {
                if (systemsPlanLimitData?.limitReached) {
                    menuItems.push({
                        menuItem: (
                            <Box sx={{ display: "flex" }}>
                                <span>{translateString("copy")}</span>
                                <Box sx={{ marginLeft: 0.5 }}>
                                    <PurpleLockSvg />
                                </Box>
                            </Box>
                        ),
                        tooltip: translateString("systemLimitReached", { count: informationSecurityFeatures.maxNumberOfSystems }),
                        disabled: true,
                        tooltipPosition: "left",
                    });
                } else {
                    menuItems.push({
                        menuItem: translateString("copy"),
                        onClick: () => {
                            setCopySystem({ id: row.id, name: row.name });
                        },
                    });
                }
            }
        }

        if (permissions.systemPermissions.delete) {
            menuItems.push({
                menuItem: translateString("delete"),
                onClick: () => setShowDeleteDialog({ showDialog: true, systemId: row.id, systemName: row.name }),
            });
        }

        if (!systemsPlanLimitData?.limitExceeded) {
            addTaskMenuItem.addCreateTaskMenuItem(menuItems, {
                fromEntityId: row.id,
                fromEntityType: EntityType.System,
                toEntityType: EntityType.Task,
            });
        }

        return menuItems;
    };

    const getHeaders = () => {
        let headers = Array<ITableHeader<SystemListOverviewRowTableModel>>();

        headers.push({ property: "icon", text: "", align: "left", showOnMobile: false });

        headers.push({
            property: "name",
            text: translateString("name"),
            align: "left",
            showOnMobile: true,
        });

        headers.push({
            property: "noCompanies",
            text: translateString("associatedCompaniesCount"),
            align: "left",
            showOnMobile: false,
        });

        headers.push({
            property: "noProcessingActivities",
            text: translateString("processingActivities"),
            align: "left",
            showOnMobile: false,
        });

        headers.push({
            property: "noSharings",
            text: translateString("sharings"),
            align: "left",
            showOnMobile: false,
        });

        headers.push({
            property: "noDataCategories",
            text: translateString("dataCategories"),
            align: "left",
            showOnMobile: false,
        });

        if (permissions.riskPermissions.read) {
            headers.push({
                property: "riskString",
                text: translateString("risk"),
                align: "left",
                showOnMobile: false,
                width: "130",
            });
        }

        headers.push({ property: null, text: "", align: "left", showOnMobile: true });

        return headers;
    };

    const getButtons = () => {
        if (systemsPlanLimitData !== undefined) {
            return (
                <React.Fragment>
                    {permissions.systemPermissions.create && (
                        <PlanDialogButtonWrapper
                            text={translateString("systemLimitReached", { count: informationSecurityFeatures.maxNumberOfSystems })}
                            enabled={systemsPlanLimitData.limitReached}
                        >
                            <DotLegalButton
                                buttonType="primary"
                                size="medium"
                                onClick={() => setShowCreateDialog(true)}
                                disabled={systemsPlanLimitData.limitReached}
                                endIcon={systemsPlanLimitData.limitReached ? WhiteLockIcon : undefined}
                                toolTip={
                                    systemsPlanLimitData.limitReached
                                        ? translateString("systemLimitReached", { count: informationSecurityFeatures.maxNumberOfSystems })
                                        : undefined
                                }
                            >
                                {" "}
                                {translateString("createSystem")}
                            </DotLegalButton>
                        </PlanDialogButtonWrapper>
                    )}
                    {permissions.canManageSystemStandardTemplates && (
                        <PlanDialogButtonWrapper
                            text={translateString("systemLimitReached", { count: informationSecurityFeatures.maxNumberOfSystems })}
                            enabled={systemsPlanLimitData.limitReached}
                        >
                            <DotLegalButton
                                buttonType="secondary"
                                size="medium"
                                onClick={() => setShowAddSystemTemplate(true)}
                                disabled={systemsPlanLimitData.limitReached}
                                endIcon={systemsPlanLimitData.limitReached ? PurpleLockIcon : undefined}
                                toolTip={
                                    systemsPlanLimitData.limitReached
                                        ? translateString("systemLimitReached", { count: informationSecurityFeatures.maxNumberOfSystems })
                                        : undefined
                                }
                            >
                                {translateString("createFromTemplate")}
                            </DotLegalButton>
                        </PlanDialogButtonWrapper>
                    )}
                    {permissions.customListPermissions.read && <CustomListSelector type={CustomListSelectorType.System} />}
                </React.Fragment>
            );
        } else {
            return undefined;
        }
    };

    return (
        <div>
            <DotLegalPageHeader
                userContext={{
                    customerName: customerName,
                }}
                breadCrumbs={breadCrumbs}
                leftComponent={<DotLegalAllOrMySwitchBox onChange={setOnlyUserSpecific} enabled={onlyUserSpecific} hidden={!permissions.canAccessAllData} />}
            />

            <DotLegalPaper sx={styles.createPaper} background={"smallPurpleWave"} backgroundCover>
                {getButtons()}
                {showCreateDialog && (
                    <AddSystemDialog
                        onHideDialog={() => setShowCreateDialog(false)}
                        showDialog={showCreateDialog}
                        onSystemCreated={(id: string) => {
                            if (hasAccessToSystemSteps(permissions)) {
                                navigate(getSystemCreateViewUrl(id));
                            } else {
                                navigate(getSystemOverviewUrl(id));
                            }
                        }}
                        shouldDisplayCreateSystemWarning={systemsPlanLimitData?.warnLimitReachedOnTrial}
                    />
                )}
            </DotLegalPaper>

            <DotLegalPaper>
                {hasData ? (
                    <>
                        {systemsPlanLimitData?.limitExceeded && (
                            <TrialUsageWarning textKey={"systemsLockedWarningContent"} headerKey={"SystemsLockedWarningHeader"} marginBottom={3} />
                        )}
                        <DotLegalTableWithControls
                            extraControls={getSearchFields()}
                            headers={getHeaders()}
                            getUserSpecificPageLength={() => 30}
                            hideRowsPerPage
                            defaultOrderBy={"name"}
                            defaultOrder={"asc"}
                            isLoading={isLoading}
                            emptyText={translateString("noSystemsMatchesQuery")}
                            labelRowsPerPage={translateString("showEntites")}
                            labelSearch={translateString("search")}
                            noOptionsLabel={translateString("noOptions")}
                            paginationLabelOf={translateString("labelOf")}
                            clickableRows={!systemsPlanLimitData?.limitExceeded}
                            data={systems}
                            renderRow={(row, i) => (
                                <TrialExpiredRowWrapper
                                    key={i}
                                    onNonExpiredClick={() => gotoOverview(row.id)}
                                    tooltip={translateString("systemLockedMaxReached")}
                                    expired={systemsPlanLimitData !== undefined && systemsPlanLimitData?.limitExceeded}
                                    planUpgradeText={translateString("systemLimitReached", {
                                        count: informationSecurityFeatures.maxNumberOfSystems,
                                    })}
                                >
                                    <TableCell align="center">
                                        <Box sx={styles.systemIcon}>
                                            <SystemIcon systemName={row.name} size={"small"} />
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={styles.bold}>{row.name}</TableCell>
                                    {!isOnSmallScreen && (
                                        <TableCell>
                                            <Box sx={styles.imgContainer}>
                                                <AssociationSvg />
                                                <Box component={"span"} sx={styles.value}>
                                                    {row.noCompanies}
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    )}
                                    {!isOnSmallScreen && (
                                        <TableCell>
                                            <Box sx={styles.imgContainer}>
                                                <ProcessingActivitySvg />
                                                <Box component={"span"} sx={styles.value}>
                                                    {row.noProcessingActivities}
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    )}
                                    {!isOnSmallScreen && (
                                        <TableCell>
                                            <Box sx={styles.imgContainer}>
                                                <SharingSvg />
                                                <Box component={"span"} sx={styles.value}>
                                                    {row.noSharings}
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    )}
                                    {!isOnSmallScreen && (
                                        <TableCell>
                                            <Box sx={styles.imgContainer}>
                                                <DataCategorySvg />
                                                <Box component={"span"} sx={styles.value}>
                                                    {row.noDataCategories}
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    )}
                                    {!isOnSmallScreen && permissions.riskPermissions.read && (
                                        <TableCell>
                                            <Box sx={styles.imgContainer}>
                                                {!riskScoresLoading ? (
                                                    <RiskIndicator
                                                        riskAssessmentType={RiskAssessmentType.None}
                                                        riskScoreTextColor="darkblue"
                                                        removeLeftMargin
                                                        score={row.riskAssessmentTotalScore}
                                                        hideRiskType
                                                        showHighRiskWarning
                                                        linkHref={
                                                            systemsPlanLimitData?.limitExceeded
                                                                ? undefined
                                                                : getRiskAssessmentQuestionsDialog(
                                                                      row.id,
                                                                      RiskAssessmentArea.Systems,
                                                                      RiskComplianceArea.GDPR,
                                                                      row.currentRiskAssessmentVersionId ?? "null"
                                                                  )
                                                        }
                                                    />
                                                ) : (
                                                    <Skeleton width={"100%"} height={18} />
                                                )}
                                            </Box>
                                        </TableCell>
                                    )}
                                    <TableCell align={"right"}>
                                        <Box
                                            sx={{
                                                "& svg": { height: 20 },
                                                marginRight: 0.5,
                                            }}
                                        >
                                            <DotLegalOverflowMenu items={getMenuItems(row)} />
                                        </Box>
                                    </TableCell>
                                </TrialExpiredRowWrapper>
                            )}
                        />
                    </>
                ) : (
                    <DotLegalEmptyState icon={<EmptyStateSvg />} text={translateString("noSystems")} />
                )}
            </DotLegalPaper>

            {showAddSystemTemplate && (
                <SystemTemplatesDialog
                    onCloseDialog={() => {
                        refetchSystems();
                        refetchSystemsPlanLimitData();
                        setShowAddSystemTemplate(false);
                    }}
                    showCompaniesExistingDialog={(response) => {
                        refetchSystems();
                        setShowExistingCompaniesDialog({ showDialog: response.length > 0, companies: response });
                    }}
                />
            )}

            {showExistingCompaniesDialog.showDialog && (
                <DotLegalDialog
                    open
                    onDialogClose={() => setShowExistingCompaniesDialog({ showDialog: false, companies: [] })}
                    onOkClick={() => setShowExistingCompaniesDialog({ showDialog: false, companies: [] })}
                    buttonOkText={"OK"}
                    header={translateString("warning")}
                    size="sm"
                >
                    <Box sx={styles.existingCompaniesContainer}>
                        <DotLegalHeader headerStyle="small" marginBottom={1}>
                            {translateString("followingCompaniesCantBeCreated")}
                        </DotLegalHeader>
                        <div>
                            {showExistingCompaniesDialog?.companies?.map((company, index) => {
                                return <li key={index}>{company}</li>;
                            })}
                        </div>
                    </Box>
                </DotLegalDialog>
            )}

            {showDeleteDialog.showDialog && (
                <SystemDeleteDialog
                    systemId={showDeleteDialog.systemId}
                    systemName={showDeleteDialog.systemName}
                    onCloseDialog={() => setShowDeleteDialog({ showDialog: false, systemId: "", systemName: "" })}
                    onSuccessDelete={() => {
                        refetch();
                        refetchSystemsPlanLimitData();
                    }}
                />
            )}

            {copySystem && (
                <CopySystemDialog
                    onCloseDialog={() => setCopySystem(undefined)}
                    copyModel={copySystem}
                    onSave={() => {
                        setCopySystem(undefined);
                        refetch();
                        refetchSystemsPlanLimitData();
                    }}
                    shouldDisplayCreateWarning={systemsPlanLimitData?.warnLimitReachedOnTrial}
                />
            )}
        </div>
    );
}

export default Systems;
