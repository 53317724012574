import { useResponsibleQuery } from "../user/hooks/useResponsibleQuery.ts";
import { UserSelectableItem } from "../user/User.types.ts";

export function useSelectableResponsiblesAssignees(assignees: Array<string> | undefined, responsible: string | undefined) {
    let responsibleQuery = useResponsibleQuery(true);
    assignees = assignees ?? [];

    let selectableResponsibles: Array<UserSelectableItem> | undefined;
    let selectableAssignees: Array<UserSelectableItem> | undefined;
    if (responsibleQuery.userData) {
        selectableResponsibles = responsibleQuery.data(responsible)?.filter((x) => !assignees.includes(x.id) || assignees.includes(responsible ?? ""));

        selectableAssignees = responsibleQuery
            .dataForMultiSelect(assignees ?? [])
            ?.filter((x) => x.id !== responsible || assignees.includes(responsible))
            .map((d) => {
                return d;
            });
    }
    return { selectableResponsibles, selectableAssignees, responsiblesLoading: responsibleQuery.isLoading };
}
