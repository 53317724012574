import { Box, IconButton } from "@mui/material";
import DotLegalCollapse from "../../../common/components/dotLegalCollapse/DotLegalCollapse";
import { useTranslation } from "../../../localization/useTranslation";
import useTaskRelationsBoxHooks from "./TaskRelationsBox.hooks";
import {
    contractsIcon,
    internalAssessments,
    legalEntitiesIcon,
    policiesIcon,
    processingActivitiesIcon,
    removeIcon,
    riskAssessmentIcon,
    systemsMenuIcon,
    taskManagementIcon,
} from "../../../common/icons";
import { DotLegalHeader, DotLegalLink, DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import { EntityType } from "../../../common/Common.types";
import { exhaustiveGuard } from "../../../common/utilities";
import { useUrlProvider } from "../../../useUrlProvider";

export interface ITaskRelationsBox {
    taskId: string;
}

function TaskRelationsBox(props: ITaskRelationsBox) {
    const { translateString, translateEntityType } = useTranslation();
    const { expanded, setExpanded, data, removeRelation } = useTaskRelationsBoxHooks(props);
    const urlProvider = useUrlProvider();

    function getIcon(type: EntityType) {
        switch (type) {
            case EntityType.IncidentLog:
            case EntityType.Policy:
                return policiesIcon;
            case EntityType.LegalEntity:
            case EntityType.LegalEntityDocument:
                return legalEntitiesIcon;
            case EntityType.ProcessingActivity:
                return processingActivitiesIcon;
            case EntityType.Risk:
                return riskAssessmentIcon;
            case EntityType.System:
                return systemsMenuIcon;
            case EntityType.AnnualWheelActivity:
            case EntityType.Task:
                return taskManagementIcon;
            case EntityType.LegalEntityAuditGroup:
            case EntityType.LegalEntityAudit:
            case EntityType.InternalAssessment:
            case EntityType.LegalEntityClassification:
                return internalAssessments;
            case EntityType.Contract:
                return contractsIcon;
            default:
                exhaustiveGuard(type);
        }
    }

    function getLink(type: EntityType, id: string) {
        switch (type) {
            case EntityType.IncidentLog:
                return urlProvider.getIncidentLogOverviewUrl(id);
            case EntityType.Policy:
                return urlProvider.getPolicyUrl(id);
            case EntityType.LegalEntity:
                return urlProvider.getLegalEntityUrl(id);
            case EntityType.ProcessingActivity:
                return urlProvider.getProcessingActivityOverviewUrl(id);
            case EntityType.Risk:
                return ""; // ??
            case EntityType.System:
                return urlProvider.getSystemOverviewUrl(id);
            case EntityType.AnnualWheelActivity:
                return ""; // ??
            case EntityType.Task:
                return urlProvider.getTaskManagementTaskDialogUrl(id);
            case EntityType.LegalEntityAuditGroup:
                return urlProvider.getAuditGroupUrl(id);
            case EntityType.LegalEntityAudit:
            case EntityType.LegalEntityClassification:
            case EntityType.LegalEntityDocument:
                return ""; // ??
            case EntityType.InternalAssessment:
                return urlProvider.getInternalAssessmentUrl();
            case EntityType.Contract:
                return ""; // ??
            default:
                exhaustiveGuard(type);
        }
    }

    return (
        <DotLegalCollapse
            isExpanded={expanded}
            onExpandClick={() => {
                if (expanded) {
                    setExpanded(false);
                } else {
                    setExpanded(true);
                }
            }}
            collapseName={translateString("relations")}
            persistCollapseName
            leftAlign
            sx={{
                marginTop: 2,
                borderWidth: "1px",
                "&.MuiCollapse-root": {
                    backgroundColor: "white",
                },
            }}
            isCollapseNameBold
        >
            {data?.map((x) => (
                <Box
                    key={x.entityId}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        "&:hover": {
                            "& .deleteRelation": {
                                visibility: "visible",
                            },
                        },
                    }}
                >
                    <Box sx={{ display: "flex", gap: 1 }}>
                        <DotLegalTooltip text={translateEntityType(EntityType[x.entityType].toString())}>
                            <Box
                                sx={(theme) => ({
                                    "& svg": {
                                        width: theme.customSpacing.menuIconSize,
                                        height: theme.customSpacing.menuIconSize,
                                        fill: "#DAD9EF",
                                    },
                                })}
                            >
                                {getIcon(x.entityType)}
                            </Box>
                        </DotLegalTooltip>
                        <DotLegalHeader fontWeight={400} headerStyle={"small"}>
                            <DotLegalLink linkColor="primary" to={getLink(x.entityType, x.entityId)}>
                                {x.entityName}
                            </DotLegalLink>
                        </DotLegalHeader>
                    </Box>
                    <Box sx={{ marginRight: 1.5 }}>
                        <IconButton
                            className="deleteRelation"
                            sx={{
                                visibility: "hidden",
                            }}
                            size="small"
                            onClick={() => removeRelation(x.relationId)}
                        >
                            {removeIcon}
                        </IconButton>
                    </Box>
                </Box>
            ))}
        </DotLegalCollapse>
    );
}

export default TaskRelationsBox;
