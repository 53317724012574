import { DotLegalButton } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import AddTaskDialog, { AddTaskRelationData } from "../addTaskDialog/AddTaskDialog";
import React, { useState } from "react";
import EditTaskDialog from "../../editTaskDialog/EditTaskDialog.tsx";

export interface IAddTaskButton {
    relationInformation?: AddTaskRelationData;
    refetchTasks?: () => void;
    tooltip?: string;
}

function AddTaskButton(props: IAddTaskButton) {
    const { translateString } = useTranslation();
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [createdTaskId, setCreatedTaskId] = useState<string | undefined>(undefined);

    return (
        <>
            <DotLegalButton buttonType={"primary"} onClick={() => setShowAddDialog(true)} toolTip={props.tooltip} showTooltipAsInfoIcon>
                {translateString("createTask")}
            </DotLegalButton>

            {showAddDialog && (
                <AddTaskDialog
                    relationInformation={props.relationInformation}
                    onDialogClose={() => setShowAddDialog(false)}
                    onTaskCreated={(taskId) => {
                        setShowAddDialog(false);
                        setCreatedTaskId(taskId);
                    }}
                />
            )}

            {createdTaskId && (
                <EditTaskDialog
                    setShowDeleteDialog={() => {}}
                    refetchTasks={() => {
                        if (props.refetchTasks) {
                            props.refetchTasks();
                        }
                    }}
                    taskId={createdTaskId!}
                    setSelectedTask={() => setCreatedTaskId(undefined)}
                    setYearByTaskYear={() => {}}
                />
            )}
        </>
    );
}

export default AddTaskButton;
