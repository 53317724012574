import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { postGet, put } from "../../../common/api/apiShared.ts";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { LegalEntityAuditGroupAuditsTabProps } from "./LegalEntityAuditGroupAuditsTab.tsx";
import { useTranslation } from "../../../localization/useTranslation.ts";
import { DotLegalSelectOption } from "../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types.ts";
import { LegalEntityAuditInformationsDto, LegalEntityMasterType } from "../../../legalEntity/legalEntities/LegalEntities.types.ts";
import { isNullOrWhitespace, setFirstLetterToLowerCase } from "../../../common/stringOperations.ts";
import { BaseViewModel } from "../../../system/systemOverview/SystemOverview.types.ts";
import { LegalEntityAuditStatus } from "../../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types.ts";
import { RiskLevel } from "../../../legalEntity/legalEntityClassificationTab/LegalEntityClassificationTab.types.ts";
import {
    LegalEntityAuditInformation,
    LegalEntityClassificationInformationViewModel,
} from "../../legalEntityAuditGroupDraftOverview/auditGroupLegalEntitySelector/AuditGroupLegalEntitySelector.types.ts";
import { EvaluationStatus } from "../../../common/components/questionnaireComponents/evaluateQuestionnaireDialog/EvaluateQuestionnaireDialog.types.ts";
import { LegalEntityAuditGroupAuditModel } from "../LegalEntityAuditGroupOverview.types.ts";
import { useStateUrlParamsArray } from "../../../common/hooks/useStateUrlParams.ts";
import { useDownloadLegalEntityAuditReport } from "../../DowloadLegalEntityAuditReport.hooks.ts";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider.tsx";

export function useLegalEntityAuditGroupAuditsTab(props: LegalEntityAuditGroupAuditsTabProps) {
    const snackbar = useDotLegalSnackbar();
    const { translateString, translateLegalEntityAuditStatus } = useTranslation();
    const { isDownloadingManagementReport, onDownloadManagementReport, getReportPdfUrl, isDownloadingPdf, onDownloadReportAsPdf } =
        useDownloadLegalEntityAuditReport();
    const { customerName } = useUserContext();

    const [showNote, setShowNote] = useState<string | undefined>(undefined);
    const [searchedType, setSearchedType] = useStateUrlParamsArray("type", []);
    const [searchedRiskLevel, setSearchedRisklevel] = useStateUrlParamsArray("riskLevel", []);
    const [searchedClassifications, setSearchedClassifications] = useStateUrlParamsArray("classifications", []);
    const [searchedAssets, setSearchedAssets] = useStateUrlParamsArray("assets", []);
    const [searchedStatus, setSearchedStatus] = useStateUrlParamsArray("status", []);
    const [searchedEvaluations, setSearchedEvaluations] = useStateUrlParamsArray("evaluations", []);

    const auditInformationsUrl = `/legalEntityAudit/auditInformations`;
    let auditInformationsQuery = useQuery(
        auditInformationsUrl,
        () =>
            postGet<Array<LegalEntityAuditInformation>>(auditInformationsUrl, {
                legalEntityIds: props.auditsData?.audits.map((x) => x.legalEntityId),
                fetchLatestAudit: false,
            } as LegalEntityAuditInformationsDto),
        {
            enabled: props.auditsData !== undefined,
        }
    );

    if (auditInformationsQuery.data) {
        auditInformationsQuery.data.forEach((s) => {
            let entity = props.auditsData!.audits.find((x) => x.legalEntityId === s.legalEntityId);

            if (entity) {
                entity.classifications = s.classifications;
            }
        });
    }

    const saveNoteMutation = useMutation(submitSaveNoteAPI);
    const saveNote = (note: string, auditId: string) => {
        saveNoteMutation.mutateAsync(
            { note: note, auditId: auditId },
            {
                onSuccess: () => {
                    snackbar.show(translateString("noteUpdated"));
                    setShowNote(undefined);
                    props.refetchData();
                },
            }
        );
    };

    const filters = {
        typeSelectOptions: Array<DotLegalSelectOption>(),
        riskLevelOptions: Array<DotLegalSelectOption>(),
        assetsOptions: Array<DotLegalSelectOption>(),
        statusOptions: Array<DotLegalSelectOption>(),
        classificationOptions: Array<DotLegalSelectOption>(),
        evaluationOptions: Array<DotLegalSelectOption>(),
    };

    let rows: Array<LegalEntityAuditGroupAuditModel> = [];
    if (props.auditsData && props.auditsData.audits.length > 0) {
        createTypeOptions(props.auditsData.audits.flatMap((x) => x.legalEntityTypes.flatMap((x) => x)));
        createAssetOptions(props.auditsData.audits.flatMap((x) => x.assets.flatMap((x) => x)));
        createStatusOptinos(props.auditsData.audits.flatMap((x) => x.status));
        createEvalutionOptions(props.auditsData.audits.filter((x) => x.evaluationStatus).map((x) => x.evaluationStatus!));
        createAuditRiskLevelOptions(props.auditsData.audits.filter((x) => x.status === LegalEntityAuditStatus.Submitted).flatMap((x) => x.riskLevel));

        createClassificationAndRiskLevelFilterOptions(auditInformationsQuery.data ? props.auditsData.audits.flatMap((x) => x.classifications) : []);

        sortSelectOptions();
        rows = getFilteredRows(props.auditsData.audits);
    }

    const onDownloadManagementReportClick = async () => {
        await onDownloadManagementReport(props.auditsData!.id!, props.auditsData!.name, props.auditsData?.sender ?? customerName);
    };

    return {
        onDownloadManagementReportClick,
        isDownloadingManagementReport,
        rows,
        showNote,
        setShowNote,
        saveNote,
        filters,
        searchedType,
        setSearchedType,
        searchedRiskLevel,
        setSearchedRisklevel,
        searchedClassifications,
        setSearchedClassifications,
        searchedAssets,
        setSearchedAssets,
        searchedStatus,
        setSearchedStatus,
        searchedEvaluations,
        setSearchedEvaluations,
        getReportPdfUrl,
        isDownloadingPdf,
        onDownloadReportAsPdf,
        auditInformations: auditInformationsQuery.data,
        auditInformationsIsLoading: auditInformationsQuery.isLoading,
    };

    function submitSaveNoteAPI(data: { note: string; auditId: string }) {
        return put(`/legalEntityAudit/${data.auditId}/note`, {
            note: data.note,
        });
    }

    function createTypeOptions(types: Array<LegalEntityMasterType>) {
        for (let type of types) {
            if (!filters.typeSelectOptions.some((x) => x.id === type.toString())) {
                filters.typeSelectOptions.push({
                    id: type.toString(),
                    name: translateString(setFirstLetterToLowerCase(LegalEntityMasterType[type].toString())),
                });
            }
        }
    }

    function createAssetOptions(assets: Array<BaseViewModel>) {
        assets.forEach((asset) => {
            if (!filters.assetsOptions.some((x) => x.id == asset.id)) {
                filters.assetsOptions.push({ id: asset.id, name: asset.name });
            }
        });
    }

    function createStatusOptinos(allAuditStatus: Array<LegalEntityAuditStatus>) {
        allAuditStatus.forEach((status) => {
            if (!filters.statusOptions.some((x) => x.id == status.toString())) {
                filters.statusOptions.push({
                    id: status.toString(),
                    name: translateLegalEntityAuditStatus(status.toString()),
                });
            }
        });
    }

    function createAuditRiskLevelOptions(riskLevels: Array<RiskLevel | undefined>) {
        riskLevels.forEach((level) => {
            if (level && !filters.riskLevelOptions.some((x) => x.id == level.toString())) {
                filters.riskLevelOptions.push({
                    id: level.toString(),
                    name: translateString(setFirstLetterToLowerCase(RiskLevel[level])),
                });
            }
        });
    }

    function createClassificationAndRiskLevelFilterOptions(classifications: Array<LegalEntityClassificationInformationViewModel>) {
        classifications.forEach((classification) => {
            if (classification && !filters.classificationOptions.some((x) => x.id == classification.questionnaireId)) {
                filters.classificationOptions.push({ id: classification.questionnaireId, name: classification.name });
            }
        });
    }

    function createEvalutionOptions(allEvaluationStatus: Array<EvaluationStatus>) {
        allEvaluationStatus.forEach((status) => {
            if (!filters.evaluationOptions.some((x) => x.id == status.toString())) {
                filters.evaluationOptions.push({
                    id: status.toString(),
                    name: translateString(setFirstLetterToLowerCase(EvaluationStatus[status].toString())),
                });
            }
        });
    }

    function sortSelectOptions() {
        filters.typeSelectOptions.sort((a, b) => (a.id > b.id ? 1 : -1));
        filters.classificationOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
        filters.riskLevelOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    function getFilteredRows(rows: Array<LegalEntityAuditGroupAuditModel>) {
        let tempData = [...rows];

        if (hasFilterData(searchedType)) {
            tempData = tempData.filter((row) => row.legalEntityTypes.filter((type) => searchedType.indexOf(type.toString()) >= 0).length > 0);
        }

        if (hasFilterData(searchedClassifications)) {
            tempData = tempData.filter(
                (row) =>
                    row.classifications.filter(
                        (classification) => classification?.questionnaireId && searchedClassifications.indexOf(classification.questionnaireId) >= 0
                    ).length > 0
            );
        }

        if (hasFilterData(searchedRiskLevel)) {
            tempData = tempData.filter((row) => row.riskLevel && searchedRiskLevel.includes(row.riskLevel.toString()));
        }

        if (hasFilterData(searchedAssets)) {
            tempData = tempData.filter((row) => row.assets.filter((asset) => searchedAssets.indexOf(asset.id) >= 0).length > 0);
        }

        if (hasFilterData(searchedStatus)) {
            tempData = tempData.filter((row) => searchedStatus.indexOf(row.status.toString()) >= 0);
        }

        if (hasFilterData(searchedEvaluations)) {
            tempData = tempData.filter((row) => row.evaluationStatus && searchedEvaluations.includes(row.evaluationStatus.toString()));
        }

        return tempData;
    }

    function hasFilterData(filter: Array<string>) {
        return filter.length > 0 && !isNullOrWhitespace(filter[0]);
    }
}
