import { useTheme } from "@mui/material";
import { createSxStyles } from "../common/createSxStyles";

export const useContractsStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        switchBoxContainer: {
            paddingBottom: theme.spacing(2.5),
        },
        iconContainer: {
            display: "flex",
        },
        bold: {
            fontWeight: theme.typography.fontWeightBold,
        },
        tableRow: {
            "& svg": {
                height: 14 + "px",
            },
        },
        imgContainer: {
            display: "flex",
            alignItems: "center",
            "& svg": {
                width: "14px",
            },
        },
        createPaper: {
            display: "flex",
            alignItems: "center",
            height: 116 + "px",
            marginBottom: theme.spacing(2),
            position: "relative",
            "& .MuiButton-root": {
                marginRight: theme.spacing(2),
            },
        },
    });
};
