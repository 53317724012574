import { Box } from "@mui/material";
import React from "react";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { useTranslation } from "../../localization/useTranslation";
import { hasAccessToSystemSteps, useSystemOverviewHook } from "./SystemOverview.hooks";
import { useSystemOverviewStyles } from "./SystemOverview.styles";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import SystemDeleteDialog from "./systemDeleteDialog/SystemDeleteDialog";
import UpdateSystemTemplateDialog from "../updateSystemTemplateDialog/UpdateSystemTemplateDialog";
import { isTemplateCustomer } from "../../common/templateCustomerHelper";
import SystemDocumentTab from "./systemDocuments/SystemDocumentsTab";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { TabContent } from "../../common/components/dotLegalTabs/DotLegalTabs.types";
import DotLegalTabs from "../../common/components/dotLegalTabs/DotLegalTabs";
import PurpleLockSvg from "../../common/svgs/purpleLock.svg?react";
import SystemSharingTab from "./systemSharingsTab/SystemSharingsTab";
import GeneralTabContent from "./generalTabContent/GeneralTabContent";
import DataTabContent from "./dataTabContent/DataTabContent";
import SecurityTabContent from "./securityTabContent/SecurityTabContent";
import { dotLegalTabPanelNoPadding } from "../../common/components/dotLegalTabPanel/DotLegalTabPanel";
import SystemOverviewNoteDialog from "./systemOverviewNoteDialog/SystemOverviewNoteDialog";
import SystemControlProcedureDialog from "./systemControlProcedureDialog/SystemControlProcedureDialog";
import { DotLegalPageHeader } from "@dotlegal/dotlegal-ui-components";
import SystemAssociatedInternalAssessmentTab from "./systemAssociatedInternalAssessmentTab/SystemAssociatedInternalAssessmentTab";
import SystemClassificationTab from "./systemClassificationTab/SystemClassificationTab";
import { getPlanTab } from "../../plan/planTab/PlanTab";
import PageHeaderEditEntityButton, {
    PageHeaderEditEntityButtonMenuItem,
} from "../../common/components/pageHeaderEditEntityButton/PageHeaderEditEntityButton.tsx";
import RelatedTasksTab from "../../taskManagement/relatedTasksTab/RelatedTasksTab.tsx";
import { EntityType } from "../../common/Common.types.ts";
import { useAddTaskMenuItem } from "../../common/components/addTaskMenuItem/AddTaskMenuItemContext.hooks.ts";

function SystemOverview() {
    const { permissions, customerName } = useUserContext();
    const { translateString } = useTranslation();
    const { informationSecurityFeatures, hasVendorManagementAccess, vendorManagementFeatures, addonsFeatures } = usePlanContext();
    const addTaskMenuItem = useAddTaskMenuItem();

    const {
        infoData,
        getBreadCrumbs,
        editUrl,
        showDeleteSystemDialog,
        setShowDeleteSystemDialog,
        deleteSystemSuccess,
        showEditSystemTemplateInfo,
        setShowEditSystemTemplateInfo,
        id,
        selectedTab,
        setSelectedTab,
        selectedPaperTab,
        setSelectedPaperTab,
        showNoteDialog,
        setShowNoteDialog,
        showInternalControlProcedureDialog,
        setShowInternalControlProcedureDialog,
        refetchSystemInfo,
    } = useSystemOverviewHook();

    const styles = useSystemOverviewStyles();

    const getTabContent = () => {
        const content: Array<TabContent> = [
            {
                label: translateString("sharings"),
                content: getSharingsTab(),
                index: 0,
            },
        ];

        content.push({
            label: `${translateString("documents")} ${informationSecurityFeatures.systemDocuments && infoData && infoData.numberOfDocuments > 0 ? `(${infoData.numberOfDocuments})` : ""}`,
            content: getDocumentsTab(),
            index: 1,
            disabled: !informationSecurityFeatures.systemDocuments,
            icon: !informationSecurityFeatures.systemDocuments ? (
                <Box sx={{ marginLeft: 0.5, marginTop: 1 }}>
                    <PurpleLockSvg />
                </Box>
            ) : undefined,
            tooltip: !informationSecurityFeatures.systemDocuments ? translateString("upgradeInfoSecPlan") : undefined,
        });

        if (hasVendorManagementAccess && permissions.canManageInternalAssessment) {
            content.push(
                getPlanTab(
                    translateString("internalAssessments"),
                    getInternalAssesments(),
                    2,
                    vendorManagementFeatures.processingActivityAssociateInternalAssessments,
                    translateString("upgradeVendorManagementPlan")
                )
            );
        }

        if (hasVendorManagementAccess && (permissions.canManageGroupEntities || permissions.companyPermissions)) {
            content.push(
                getPlanTab(
                    translateString("classifications"),
                    <SystemClassificationTab systemId={id!} />,
                    3,
                    vendorManagementFeatures.systemClassifications,
                    translateString("upgradeVendorManagementPlan")
                )
            );
        }

        if (permissions.taskPermissions.read) {
            content.push({
                label: translateString("tasks"),
                content: <RelatedTasksTab forEntityId={id!} forEntityType={EntityType.System} forEntityName={infoData?.name ?? ""} />,
                index: 4,
            });
        }

        return content;
    };

    const getSharingsTab = () => {
        return <SystemSharingTab systemId={id} />;
    };

    const getDocumentsTab = () => {
        return <SystemDocumentTab systemId={id} refetch={() => refetchSystemInfo()} />;
    };

    const getInternalAssesments = () => {
        return <SystemAssociatedInternalAssessmentTab systemId={id!} systemName={infoData?.name ?? ""} />;
    };

    const getPaperContent = (tabIndex: number) => {
        switch (tabIndex) {
            case 0:
                return <GeneralTabContent systemId={id} systemName={infoData?.name ?? ""} />;
            case 1:
                return <DataTabContent systemId={id} />;
            case 2:
                return <SecurityTabContent systemId={id} />;
        }
    };

    const getPaperWithContent = (tabIndex: number) => {
        return (
            <Box sx={{ "& .MuiPaper-root": { borderTopLeftRadius: 0, marginTop: -dotLegalTabPanelNoPadding } }}>
                <DotLegalPaper background="purpleWave1" backgroundCover removePadding>
                    {getPaperContent(tabIndex)}
                </DotLegalPaper>
            </Box>
        );
    };

    const getEditMenuItems = () => {
        let menuItems: Array<PageHeaderEditEntityButtonMenuItem> = [];

        if (hasAccessToSystemSteps(permissions)) {
            menuItems.push({
                menuItem: translateString("editSystem"),
                onClick: () => {},
                href: editUrl,
            });
        }

        if (permissions.systemPermissions.delete) {
            menuItems.push({
                menuItem: translateString("deleteSystem"),
                onClick: () => setShowDeleteSystemDialog(true),
            });
        }

        if (permissions.canManageCustomers && isTemplateCustomer()) {
            menuItems.push({
                menuItem: translateString("editSystemTemplate"),
                onClick: () => setShowEditSystemTemplateInfo(true),
            });
        }

        if (!addonsFeatures.research) {
            menuItems.push({
                menuItem: translateString("systemInternalControlProcedureHeader"),
                onClick: () => setShowInternalControlProcedureDialog(true),
                planMenuItemInfo: {
                    hasAccess: informationSecurityFeatures.internalControlProcedures,
                    menuName: translateString("systemInternalControlProcedureHeader"),
                    tooltip: translateString("upgradeInfoSecPlan"),
                },
            });
        }

        menuItems.push({
            menuItem: translateString("note"),
            onClick: () => {
                setShowNoteDialog(true);
            },
        });

        addTaskMenuItem.addCreateTaskMenuItem(menuItems, {
            fromEntityId: id!,
            fromEntityType: EntityType.System,
            toEntityType: EntityType.Task,
        });

        return menuItems;
    };

    return (
        <Box sx={styles.container}>
            <DotLegalPageHeader
                userContext={{
                    customerName: customerName,
                }}
                breadCrumbs={getBreadCrumbs()}
                rightComponent={<PageHeaderEditEntityButton menuItems={getEditMenuItems()} />}
            />

            <DotLegalTabs
                tabStyle={"paper-tabs"}
                selectedTab={selectedPaperTab ?? 0}
                setSelectedTab={setSelectedPaperTab}
                content={[
                    {
                        label: translateString("general"),
                        content: getPaperWithContent(0),
                        index: 0,
                    },
                    {
                        label: "Data",
                        content: getPaperWithContent(1),
                        index: 1,
                    },
                    {
                        label: translateString("securityHeader"),
                        content: getPaperWithContent(2),
                        index: 2,
                    },
                ]}
                fullWidth={false}
                noPadding
            />

            <Box sx={styles.tableContainer}>
                <DotLegalTabs
                    fullWidth={false}
                    tabStyle="secondary"
                    selectedTab={selectedTab ?? 0}
                    setSelectedTab={setSelectedTab}
                    content={getTabContent()}
                    noPadding
                    ignoreTextTransformation
                />
            </Box>

            {showDeleteSystemDialog && (
                <SystemDeleteDialog
                    systemId={infoData!.id}
                    systemName={infoData!.name}
                    onCloseDialog={() => setShowDeleteSystemDialog(false)}
                    onSuccessDelete={() => deleteSystemSuccess()}
                />
            )}

            {showEditSystemTemplateInfo && <UpdateSystemTemplateDialog systemId={infoData!.id} closeDialog={() => setShowEditSystemTemplateInfo(false)} />}

            {showNoteDialog && (
                <SystemOverviewNoteDialog
                    disabled={!permissions.systemPermissions.edit}
                    systemId={infoData!.id}
                    onCloseDialog={() => setShowNoteDialog(false)}
                    onSuccess={() => setShowNoteDialog(false)}
                />
            )}

            {showInternalControlProcedureDialog && <SystemControlProcedureDialog onDialogClose={() => setShowInternalControlProcedureDialog(false)} />}
        </Box>
    );
}

export default SystemOverview;
