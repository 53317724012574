import {
    DotLegalActionBar,
    DotLegalButton,
    DotLegalEmptyState,
    DotLegalInformationTooltip,
    DotLegalPageHeader,
    DotLegalPaper,
    useDotLegalSnackbar,
} from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider";
import { useTranslation } from "../localization/useTranslation";
import { Box } from "@mui/material";
import useInternalAssessmentsHooks from "./InternalAssessments.hooks.ts";
import SendInternalAssessmentDialog from "./sendInternalAssessmentDialog/SendInternalAssessmentDialog";
import InternalAssessmentsIcon from "./internalAssessments.svg?react";
import { useUrlProvider } from "../useUrlProvider.ts";
import { useNavigate } from "react-router-dom";
import EmptyState from "../common/svgs/emptyState.svg?react";
import InternalAssessmentTable from "../common/components/questionnaireComponents/internalAssessmentTable/InternalAssessmentTable.tsx";
import { InternalAssessmentApiRow } from "./InternalAssessments.types.ts";
import { LegalEntityAuditStatus } from "../legalEntity/legalEntityAuditTab/LegalEntityAudit.types.ts";
import { getSettings } from "../common/settingsProvider.ts";
import DeleteInternalAssessmentDialog from "./deleteInternalAssessmentDialog/DeleteInternalAssessmentDialog.tsx";
import CloseInternalAssessmentDialog from "./closeInternalAssessmentDialog/CloseInternalAssessmentDialog.tsx";
import ReopenInternalAssessmentDialog from "./reopenInternalAssessmentDialog/ReopenInternalAssessmentDialog.tsx";
import ChangeAssigneeDialog from "./changeAssigneeDialog/ChangeAssigneeDialog.tsx";
import DotLegalNoteDialog from "../common/components/dotLegalNoteDialog/DotLegalNoteDialog.tsx";
import ChangeInternalAssessmentResponsibleDialog from "./changeInternalAssessmentResponsibleDialog/ChangeInternalAssessmentResponsibleDialog.tsx";
import EvaluateInternalAssessmentDialog from "./evaluateInternalAssessmentDialog/EvaluateInternalAssessmentDialog.tsx";
import SendInternalAssessmentReminderDialog from "./sendInternalAssessmentReminderDialog/SendInternalAssessmentReminderDialog.tsx";
import ToggleInternalAssessmentRemindersDialog from "./toggleInternalAssessmentRemindersDialog/ToggleInternalAssessmentRemindersDialog.tsx";
import { PurpleLockIcon, WhiteLockIcon } from "../common/icons.tsx";
import { usePlanContext } from "../auth/planProvider/PlanProvider.tsx";
import PlanDialogButtonWrapper from "../plan/planDialogButtonWrapper/planDialogButtonWrapper.tsx";
import { GetInternalAssessmentsPlanLimit } from "../auth/planProvider/PlanProvider.hooks.ts";
import { useAddTaskMenuItem } from "../common/components/addTaskMenuItem/AddTaskMenuItemContext.hooks.ts";
import { EntityType } from "../common/Common.types.ts";

function InternalAssessments() {
    const { customerName } = useUserContext();
    const { translateString } = useTranslation();
    const navigate = useNavigate();
    const urlProvider = useUrlProvider();
    const snackbar = useDotLegalSnackbar();
    const addTaskMenuItem = useAddTaskMenuItem();
    const { vendorManagementFeatures } = usePlanContext();
    const { internalAssessmentPlanLimitIsLoading, internalAssessmentPlanLimitData, refetchInternalAssessmentPlanLimit } = GetInternalAssessmentsPlanLimit();

    const {
        data,
        refetch,
        isLoading,
        setShowDialog,
        showDialog,
        onInternalAssessmentSend,
        setSelectedInternalAssessment,
        setShowDeleteDialog,
        showDeleteDialog,
        setShowCloseDialog,
        showCloseDialog,
        setShowReopenDialog,
        showReopenDialog,
        setShowNote,
        showNote,
        setShowChangeAssigneeDialog,
        showChangeAssigneeDialog,
        setShowChangeResponsibleDialog,
        showChangeResponsibleDialog,
        showReminderDialog,
        setShowReminderDialog,
        setShowEvaluationDialog,
        showEvaluationDialog,
        setShowToggleRemindersDialog,
        showToggleRemindersDialog,
        selectedInternalAssessment,
        saveNote,
        savingNote,
    } = useInternalAssessmentsHooks();

    function getMenuItems(row: InternalAssessmentApiRow) {
        let menuItems = [];

        if (row.partOfCurrentPlan) {
            menuItems.push({
                menuItem: translateString("note"),
                onClick: () => {
                    setSelectedInternalAssessment(row);
                    setShowNote(true);
                },
            });
        }

        if (row.partOfCurrentPlan) {
            if (row.isClosed || row.status === LegalEntityAuditStatus.Submitted) {
                menuItems.push({
                    menuItem: row.isClosed ? translateString("reopenInternalAssessment") : translateString("reopenCompletedInternalAssessment"),
                    onClick: () => {
                        setSelectedInternalAssessment(row);
                        setShowReopenDialog(true);
                    },
                });
            }
        }

        if (!row.isClosed) {
            menuItems.push({
                menuItem: translateString("closeInternalAssessment"),
                onClick: () => {
                    setSelectedInternalAssessment(row);
                    setShowCloseDialog(true);
                },
            });
        }

        if (row.partOfCurrentPlan) {
            if (row.status !== LegalEntityAuditStatus.Submitted && !row.isClosed) {
                menuItems.push({
                    menuItem: translateString("sendReminder"),
                    onClick: () => {
                        setSelectedInternalAssessment(row);
                        setShowReminderDialog(true);
                    },
                    disabled: row.remindersPaused,
                    tooltip: row.remindersPaused ? translateString("remindersIsPaused") : "",
                });
            }

            if (row.status !== LegalEntityAuditStatus.Submitted && !row.isClosed) {
                menuItems.push({
                    menuItem: row.remindersPaused ? translateString("resumeReminders") : translateString("pauseReminders"),
                    onClick: () => {
                        setSelectedInternalAssessment(row);
                        setShowToggleRemindersDialog(true);
                    },
                });
            }
        }

        if (row.canBeDeleted) {
            menuItems.push({
                menuItem: translateString("deleteInternalAssessment"),
                onClick: () => {
                    setSelectedInternalAssessment(row);
                    setShowDeleteDialog(true);
                },
            });
        }

        if (row.partOfCurrentPlan) {
            menuItems.push({
                menuItem: translateString("changeResponsible"),
                onClick: () => {
                    setSelectedInternalAssessment(row);
                    setShowChangeResponsibleDialog(true);
                },
            });

            menuItems.push({
                menuItem: translateString("changeAssignee"),
                onClick: () => {
                    setSelectedInternalAssessment(row);
                    setShowChangeAssigneeDialog(true);
                },
            });

            if (!row.isClosed) {
                menuItems.push({
                    menuItem: translateString("CopyLinkToAssessment"),
                    onClick: () => {
                        const url = getSettings().mainSiteBaseUrl + urlProvider.getExternalQuestionnaireResponseUrl(row.responseExternalId);
                        navigator.clipboard.writeText(url);
                        snackbar.show(translateString("linkCopied", { link: url }));
                    },
                });
            }

            if (row.status === LegalEntityAuditStatus.Submitted) {
                menuItems.push({
                    menuItem: translateString("evaluateInternalAssessmentHeader"),
                    onClick: () => {
                        setSelectedInternalAssessment(row);
                        setShowEvaluationDialog(true);
                    },
                });
            }

            addTaskMenuItem.addCreateTaskMenuItem(menuItems, {
                fromEntityId: row.id!,
                fromEntityType: EntityType.InternalAssessment,
                toEntityType: EntityType.Task,
            });
        }

        return menuItems;
    }

    return (
        <React.Fragment>
            <DotLegalPageHeader userContext={{ customerName }} breadCrumbs={[{ name: translateString("internalAssessments") }]} />
            <DotLegalActionBar
                primaryButton={
                    <PlanDialogButtonWrapper
                        enabled={internalAssessmentPlanLimitData !== undefined && internalAssessmentPlanLimitData?.limitReached}
                        text={translateString("internalAssessmentLimitReached")}
                    >
                        <DotLegalButton
                            buttonType="primary"
                            size="medium"
                            onClick={() => setShowDialog(true)}
                            disabled={internalAssessmentPlanLimitData?.limitReached}
                            endIcon={internalAssessmentPlanLimitData?.limitReached ? WhiteLockIcon : undefined}
                            isLoading={internalAssessmentPlanLimitIsLoading}
                            toolTip={internalAssessmentPlanLimitData?.limitReached ? translateString("internalAssessmentLimitReached") : undefined}
                        >
                            {translateString("sendAssessment")}
                        </DotLegalButton>
                    </PlanDialogButtonWrapper>
                }
                additionalButtons={[
                    <Box key={"publicaudit"} sx={{ display: "flex", alignItems: "center" }}>
                        <PlanDialogButtonWrapper enabled={!vendorManagementFeatures.publicAssessments} text={translateString("upgradeVendorManagementPlan")}>
                            <DotLegalButton
                                buttonType={"secondary"}
                                size={"medium"}
                                onClick={() => {
                                    navigate(urlProvider.getPublicInternalAssessmentTemplateUrl());
                                }}
                                disabled={!vendorManagementFeatures.publicAssessments}
                                endIcon={!vendorManagementFeatures.publicAssessments ? PurpleLockIcon : undefined}
                                toolTip={!vendorManagementFeatures.publicAssessments ? translateString("upgradeVendorManagementPlan") : undefined}
                            >
                                {translateString("publicInternalAssessmentCreateHeader")}
                            </DotLegalButton>
                        </PlanDialogButtonWrapper>
                        <Box sx={{ display: "flex", ml: 1 }}>
                            <DotLegalInformationTooltip text={translateString("publicInternalAssessmentTooltip")} />
                        </Box>
                    </Box>,
                ]}
                showDialog={showDialog}
                canCreate
                icon={<InternalAssessmentsIcon />}
            >
                <SendInternalAssessmentDialog
                    onInternalAssessmentSend={() => {
                        onInternalAssessmentSend();
                        refetchInternalAssessmentPlanLimit();
                    }}
                    onDialogClose={() => setShowDialog(false)}
                    planLimit={internalAssessmentPlanLimitData}
                />
            </DotLegalActionBar>

            <DotLegalPaper>
                <InternalAssessmentTable
                    emptyState={<DotLegalEmptyState icon={<EmptyState />} text={translateString("noInternalAssessments")} />}
                    internalAssessmentModel={data}
                    isLoading={isLoading}
                    refetch={() => refetch()}
                    getMenuItems={(row) => getMenuItems(row)}
                    onNoteClick={(row) => {
                        setSelectedInternalAssessment(row);
                        setShowNote(true);
                    }}
                    planLimit={internalAssessmentPlanLimitData}
                />
            </DotLegalPaper>

            {showDeleteDialog && (
                <DeleteInternalAssessmentDialog
                    onDialogClose={() => setShowDeleteDialog(false)}
                    selectedAssessment={selectedInternalAssessment!}
                    onSuccessDelete={() => {
                        setShowDeleteDialog(false);
                        refetch!();
                        refetchInternalAssessmentPlanLimit();
                    }}
                />
            )}
            {showCloseDialog && (
                <CloseInternalAssessmentDialog
                    onDialogClose={() => setShowCloseDialog(false)}
                    onInternalAssessmentClosed={() => {
                        setShowCloseDialog(false);
                        refetch!();
                    }}
                    selectedInternalAssessment={selectedInternalAssessment!}
                />
            )}
            {showReopenDialog && (
                <ReopenInternalAssessmentDialog
                    onDialogClose={() => setShowReopenDialog(false)}
                    onInternalAssessmentReopened={() => {
                        setShowReopenDialog(false);
                        refetch!();
                    }}
                    internalAssessmentId={selectedInternalAssessment!.id}
                    deadline={selectedInternalAssessment!.deadline}
                    name={selectedInternalAssessment!.name}
                    isSubmitted={selectedInternalAssessment?.status === LegalEntityAuditStatus.Submitted}
                />
            )}

            {showChangeAssigneeDialog && (
                <ChangeAssigneeDialog
                    internalAssessmentViewModel={selectedInternalAssessment!}
                    onDialogClose={() => setShowChangeAssigneeDialog(false)}
                    onChangedAssignee={() => {
                        setShowChangeAssigneeDialog(false);
                        refetch!();
                    }}
                />
            )}

            {showChangeResponsibleDialog && (
                <ChangeInternalAssessmentResponsibleDialog
                    responsible={selectedInternalAssessment!.responsible.id}
                    internalAssessmentId={selectedInternalAssessment!.id}
                    onDialogClose={() => setShowChangeResponsibleDialog(false)}
                    onChangedResponsible={() => {
                        setShowChangeResponsibleDialog(false);
                        refetch!();
                    }}
                />
            )}

            {showNote && (
                <DotLegalNoteDialog
                    showDialog={showNote}
                    noteText={selectedInternalAssessment?.note}
                    onDialogClose={() => {
                        setShowNote(false);
                    }}
                    onOkClick={(text) => {
                        saveNote(text);
                    }}
                    isSaving={savingNote}
                />
            )}

            {showReminderDialog && (
                <SendInternalAssessmentReminderDialog
                    onCloseDialog={() => setShowReminderDialog(false)}
                    onReminderSent={() => {
                        setShowReminderDialog(false);
                        refetch!();
                    }}
                    selectedInternalAssessment={selectedInternalAssessment!}
                />
            )}

            {showEvaluationDialog && (
                <EvaluateInternalAssessmentDialog
                    onEvaluated={() => {
                        setShowEvaluationDialog(false);
                        refetch!();
                    }}
                    onDialogClose={() => setShowEvaluationDialog(false)}
                    selectedInternalAssessment={selectedInternalAssessment!}
                />
            )}

            {showToggleRemindersDialog && (
                <ToggleInternalAssessmentRemindersDialog
                    onDialogClose={() => setShowToggleRemindersDialog(false)}
                    onInternalAssessmentPaused={() => {
                        setShowReminderDialog(false);
                        refetch!();
                    }}
                    internalAssessment={selectedInternalAssessment!}
                />
            )}
        </React.Fragment>
    );
}

export default InternalAssessments;
