import { Box, Collapse, Grid, lighten, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import { AnnualWheelActivityViewModel, AnnualWheelMonthEnum, TaskStateEnum } from "../AnnualWheel.types";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink";
import { DotLegalHeader, DotLegalPrettyScrollbar } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import AnnualWheelTask from "../annualWheelTask/AnnualWheelTask";
import { useTranslation } from "../../localization/useTranslation";
import { useAnnaulWheelCalendarOverviewHooks } from "./AnnualWheelCalendarOverview.hooks";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import AddIcon from "@mui/icons-material/Add";
import { useAnnualWheelCalendarOverviewStyles } from "./AnnualWheelCalendarOverview.styles";
import { columContainerMinHeight, defaultActivitiesToShow, headerBorderRadius } from "../AnnualWheel.styles";

export interface IAnnualWheelCalendarOverview {
    activities?: Array<AnnualWheelActivityViewModel>;
    isLoading: boolean;
    onMonthAddClicked: (month: AnnualWheelMonthEnum | undefined) => void;
    setSelectedActivity: (activity: AnnualWheelActivityViewModel | undefined) => void;
    setShowDeleteActivityDialog: (activity: { showDialog: boolean; activityName: string }) => void;
    setShowEditActivityDialog: () => void;
    getTaskManagementUrlForMonthAndYear: (month: AnnualWheelMonthEnum) => string;
    year: number;
    extendColumns: boolean;
}

function AnnualWheelCalendarOverview(props: IAnnualWheelCalendarOverview) {
    const styles = useAnnualWheelCalendarOverviewStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only("xs"));
    const isSm = useMediaQuery(theme.breakpoints.only("sm"));
    const isMd = useMediaQuery(theme.breakpoints.only("md"));
    const isLg = useMediaQuery(theme.breakpoints.only("lg"));

    const { translateString } = useTranslation();
    const { permissions } = useUserContext();

    const { getExpandedId, setExpandedId, hoveredMonth, setHoveredMonth } = useAnnaulWheelCalendarOverviewHooks();

    const getModulusNumber = () => {
        if (isXs) return 1;
        if (isSm) return 1;
        if (isMd) return 2;
        if (isLg) return 3;

        return 4;
    };

    const isCurrentMonth = (month: AnnualWheelMonthEnum) => {
        let date = new Date();
        return date.getFullYear() === props.year && date.getMonth() + 1 === month;
    };

    function getTask(task: AnnualWheelActivityViewModel) {
        return (
            <AnnualWheelTask
                key={task.id}
                month={task.month}
                id={task.id}
                name={task.name}
                status={task.taskStatus}
                partOfCurrentPlan={!task.notPartOfCurrentPlan}
                deleted={task.deleted}
                onEditClick={() => {
                    props.setShowEditActivityDialog();
                    props.setSelectedActivity(task);
                }}
                onDeleteClick={() => {
                    props.setShowDeleteActivityDialog({ showDialog: true, activityName: task.name });
                    props.setSelectedActivity(task);
                }}
                recurrenceInterval={task.recurringIntervalInMonths}
                areas={task.areas}
                customId={task.customId}
            />
        );
    }

    const getTasks = (tasks: AnnualWheelActivityViewModel[] | undefined) => {
        return tasks?.map((task) => {
            return getTask(task);
        });
    };

    const getColumnContent = (month: AnnualWheelMonthEnum) => {
        if (!props.isLoading) {
            const filteredTasks = props.activities?.filter((x) => {
                return x.month === month;
            });
            let IsExpanded = getExpandedId() === month.toString();
            return (
                <>
                    <Collapse
                        in={IsExpanded || props.extendColumns}
                        collapsedSize={columContainerMinHeight}
                        sx={(theme) => ({
                            "& .MuiCollapse-wrapperInner": {
                                marginTop: 1,
                                paddingLeft: 2,
                                paddingRight: 0.5,
                            },
                            "& .MuiCollapse-wrapperInner > div:first-child": {
                                padding: `0px ${theme.spacing(3.5)} 0px ${theme.spacing(2)}`,
                                maxHeight: IsExpanded ? "none" : columContainerMinHeight,
                                overflowY: month === hoveredMonth ? "auto" : "hidden",
                            },
                        })}
                    >
                        <DotLegalPrettyScrollbar width={8} showGutter>
                            {getTasks(filteredTasks)}
                        </DotLegalPrettyScrollbar>
                    </Collapse>
                    {filteredTasks && filteredTasks.length > defaultActivitiesToShow && (
                        <Box sx={styles.expandContainer}>
                            <DotLegalHeader
                                headerStyle="extraSmall"
                                marginBottom={0}
                                onClick={() => setExpandedId(getExpandedId() === month.toString() ? "0" : month.toString())}
                            >
                                {IsExpanded
                                    ? translateString("seeLess")
                                    : translateString("seeMore", { count: filteredTasks!.length - defaultActivitiesToShow })}
                            </DotLegalHeader>
                        </Box>
                    )}
                </>
            );
        } else {
            return (
                <Box sx={{ paddingLeft: 4, paddingRight: 4, paddingTop: 1 }}>
                    {[1, 2, 3].map((i) => (
                        <Skeleton variant="rectangular" key={i} width={"100%"} sx={{ borderRadius: "20px" }}>
                            <AnnualWheelTask
                                name={"Tester"}
                                month={AnnualWheelMonthEnum.April}
                                id={"id"}
                                partOfCurrentPlan
                                status={TaskStateEnum.Ready}
                                deleted
                                areas={[]}
                                onEditClick={() => {}}
                                onDeleteClick={() => {}}
                                customId={"123"}
                            />
                        </Skeleton>
                    ))}
                </Box>
            );
        }
    };

    const getColumnHeader = (month: string, monthEnum: AnnualWheelMonthEnum, isOnLeftEdge: boolean, isOnRightEdge: boolean) => {
        return (
            <Box
                onMouseEnter={() => setHoveredMonth(monthEnum)}
                onMouseLeave={() => setHoveredMonth(undefined)}
                style={{
                    borderRadius: `${isOnLeftEdge ? headerBorderRadius : "0"}px
                        ${isOnRightEdge ? headerBorderRadius : "0"}px
                        ${isOnRightEdge ? headerBorderRadius : "0"}px
                        ${isOnLeftEdge ? headerBorderRadius : "0"}px
                        `,
                }}
                sx={styles.calenderColumnHeader}
            >
                <DotLegalLink to={props.getTaskManagementUrlForMonthAndYear(monthEnum)}>
                    <DotLegalHeader headerStyle={"medium"} marginBottom={0}>
                        {translateString(month.toLowerCase())}
                    </DotLegalHeader>
                </DotLegalLink>
                {monthEnum === hoveredMonth && permissions.annualWheelPermission.create && (
                    <AddIcon
                        onClick={() => {
                            props.onMonthAddClicked(monthEnum);
                        }}
                        sx={styles.addIcon}
                        fontSize="medium"
                    />
                )}
            </Box>
        );
    };

    const getColumn = (month: AnnualWheelMonthEnum, isOnLeftEdge: boolean, isOnRightEdge: boolean) => {
        return (
            <Box
                onMouseEnter={() => setHoveredMonth(month)}
                onMouseLeave={() => setHoveredMonth(undefined)}
                sx={styles.columnContainer}
                style={{
                    borderLeft: isOnLeftEdge ? "none" : "2px solid #BDD3EC",
                    borderRight: isOnRightEdge ? "none" : "2px solid #BDD3EC",
                    backgroundColor: isCurrentMonth(month) ? lighten("#DBE0FD", 0.8) : "inherit",
                }}
            >
                {getColumnContent(month)}
            </Box>
        );
    };

    return (
        <Grid container spacing={0}>
            {Object.entries(AnnualWheelMonthEnum)
                .filter((q) => isNaN(Number(q[0])))
                .map((month, index) => {
                    const monthEnum = month[1] as AnnualWheelMonthEnum;
                    let mod = (index + 1) % getModulusNumber();
                    let onLeftEdge = mod === 1 || isSm;
                    let onRightEdge = 0 || mod === 0;
                    return (
                        <Grid key={monthEnum} item xs={12} sm={12} md={6} lg={4} xl={3} sx={{ display: "flex", flexDirection: "column" }}>
                            {getColumnHeader(month[0], monthEnum, onLeftEdge, onRightEdge)}
                            {getColumn(monthEnum, onLeftEdge, onRightEdge)}
                        </Grid>
                    );
                })}
        </Grid>
    );
}

export default AnnualWheelCalendarOverview;
