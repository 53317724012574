import { Box } from "@mui/material";
import { useTranslation } from "../../localization/useTranslation";
import { DocumentTypeUsage } from "../../masterData/documentType/documentTypes/DocumentTypes.types";
import { LegalEntityDocumentUpdateModel, SelectableDocumentTypeItem } from "./LegalEntityDocumentTab.types";
import { DotLegalCheckbox } from "@dotlegal/dotlegal-ui-components";
import { exhaustiveGuard } from "../../common/utilities";
import React from "react";

export interface IAttachDocumentToSharingsProps {
    documentTypes: Array<SelectableDocumentTypeItem>;
    document: LegalEntityDocumentUpdateModel | undefined;
    onChange: (documentUsageType: DocumentTypeUsage) => void;
}

function AttachDocumentToSharings(props: IAttachDocumentToSharingsProps) {
    const { translateString } = useTranslation();

    function renderAddToSharingsCheckboxes() {
        let documentType = props.documentTypes?.find((x) => x.id === props.document?.documentTypeId);

        return <React.Fragment>{documentType?.usages.map((x) => renderSharingCheckbox(x))}</React.Fragment>;
    }

    const renderSharingCheckbox = (documentUsageType: DocumentTypeUsage) => {
        return (
            <Box key={documentUsageType} marginTop={1} marginBottom={1}>
                <DotLegalCheckbox
                    label={getLabel(documentUsageType)}
                    checked={props.document?.addDocumentToSharings.includes(documentUsageType) ?? false}
                    onChange={() => props.onChange(documentUsageType)}
                />
            </Box>
        );
    };

    const getLabel = (documentUsageType: DocumentTypeUsage) => {
        switch (documentUsageType) {
            case DocumentTypeUsage.DataProcessor:
                return translateString("adddataProcessorAgreementOnDataProcessor");
            case DocumentTypeUsage.Tia:
                return translateString("addTransferImpactAssessmentOnDataProcessor");
            case DocumentTypeUsage.JointDataController:
                return translateString("addJointDataControllerAgreementOnJointDataControllers");
            case DocumentTypeUsage.Disclosure:
                return translateString("addDisclosureAgreementOnDisclosures");
            case DocumentTypeUsage.Source:
                return translateString("addDisclosureAgreementOnSource");
        }
    };

    return <>{renderAddToSharingsCheckboxes()}</>;
}

export default AttachDocumentToSharings;
