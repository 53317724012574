import { TagsMultiSelectProps } from "./TagsMultiSelect";
import { useState } from "react";
import { useSelectableTagsQuery } from "../../hooks/useSelectableItemQueries.ts";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider.tsx";

export function useTagsMultiSelect(props: TagsMultiSelectProps) {
    const { platformFeatures } = usePlanContext();

    const [showCreateTagDialog, setShowCreateTagDialog] = useState(false);

    let selectableTagsQuery = useSelectableTagsQuery(platformFeatures.tags);

    const onTagCreated = async (tagId: string) => {
        let tempTags = [...props.selectedTags];
        tempTags.push(tagId);

        if (props.onChange) {
            props.onChange(tempTags);
        }
        if (props.onTagAdded) {
            props.onTagAdded(tagId);
        }
        setShowCreateTagDialog(false);

        selectableTagsQuery.refetch();
    };

    const onRelationChange = (tags: Array<string>) => {
        if (props.onChange) {
            props.onChange(tags);
        }
    };

    return {
        isLoading: selectableTagsQuery.isLoading,
        selectableTags: selectableTagsQuery.data ?? [],
        showCreateTagDialog,
        setShowCreateTagDialog,
        onTagCreated,
        onRelationChange,
    };
}
