import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { LegalEntitySelectorViewModel } from "../../legalEntity/LegalEntity.types";

export function useGroupEntitiesQuery(enabled?: boolean) {
    let url = "/LegalEntity/groupentities/";
    let query = useQuery(url, () => get<Array<LegalEntitySelectorViewModel>>(url), {
        enabled: enabled === undefined ? true : enabled,
    });

    return {
        allGroupEntities: query.data,
        isLoading: query.isLoading,
    };
}
