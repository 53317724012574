import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { get } from "../../common/api/apiShared";
import { AuditGroupTabs, LegalEntityAuditGroupOverviewModel } from "./LegalEntityAuditGroupOverview.types";
import { useState } from "react";
import { useDownloadLegalEntityAuditReport } from "../DowloadLegalEntityAuditReport.hooks.ts";

export function useLegalEntityAuditGroupDialog() {
    const { id } = useParams<{ id: string }>();
    const { onDownloadReportAsPdf, isDownloadingPdf } = useDownloadLegalEntityAuditReport();

    const [selectedTab, setSelectedTab] = useState(AuditGroupTabs.Audits);

    const queryKey = `/legalEntityAuditGroups/${id}/overview`;
    let { isLoading, data, refetch } = useQuery(queryKey, () => get<LegalEntityAuditGroupOverviewModel>(queryKey));

    function onAuditGroupUpdated() {
        refetch();
    }
    return {
        auditGroupId: id!,
        isLoading,
        data,
        onAuditGroupUpdated,
        isDownloadingPdf,
        onDownloadReportAsPdf,
        refetch,
        selectedTab,
        setSelectedTab,
    };
}
