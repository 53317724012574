import { useMutation } from "react-query";
import { post, put } from "../../common/api/apiShared";
import { DeclarationSaveModel, DeclarationUpdateModel } from "./CreateEditDeclarationDialog.types";
import { useEffect, useState } from "react";
import { useValidator } from "../../common/hooks/validation";
import { useTranslation } from "../../localization/useTranslation";
import { CreateEditDeclarationDialogProps } from "./CreateEditDeclarationDialog";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { useComplianceAreaQuery } from "../../common/hooks/useSelectableItemQueries";
import { useNavigate } from "react-router-dom";
import { useUrlProvider } from "../../useUrlProvider";
import { useGroupEntitiesQuery } from "../../processingActivity/hooks/useGroupEntitiesQuery.ts";

export function useCreateEditDeclarationDialog(props: CreateEditDeclarationDialogProps) {
    const navigate = useNavigate();

    const [saveModel, setSaveModel] = useState<DeclarationSaveModel>(props.selectedDeclaration ? props.selectedDeclaration : getDefaultModel());
    const [step, setStep] = useState<"first" | "selectGroupEntity">("first");

    const groupEntitiesQuery = useGroupEntitiesQuery();
    const complianceAreasQuery = useComplianceAreaQuery(step === "first");

    const { translateString } = useTranslation();
    const { getDeclarationUrl } = useUrlProvider();

    const validation = useValidator<DeclarationSaveModel>(
        (validators) => ({
            name: validators.validateNotEmpty((item) => item.name, translateString("nameOfDeclaration")),
            auditFrequency: validators.validateNotEmpty((item) => item.auditFrequency, translateString("auditFrequency")),
            approval: validators.validateNotEmpty((item) => item.approval, translateString("approval")),
            auditDate: validators.validateNotEmpty((item) => item.auditDate, translateString("nextAuditDate")),
            groupEntityIds: (item) => {
                if (step === "selectGroupEntity") {
                    if (item.groupEntityIds.length === 0) {
                        return translateString("oneSenderIsRequired");
                    }
                }

                return undefined;
            },
        }),
        [step]
    );

    useEffect(() => {
        if (groupEntitiesQuery.allGroupEntities && groupEntitiesQuery.allGroupEntities.length === 1) {
            declarationUpdateHelper.onGroupEntityIdsChange(groupEntitiesQuery.allGroupEntities.map((x) => x.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupEntitiesQuery.allGroupEntities]);

    const saveDeclarationMutation = useMutation(createDeclarationApiUrl);
    const saveDeclaration = () => {
        saveDeclarationMutation.mutateAsync(saveModel!, {
            onSuccess: (response: any) => {
                if (response.success()) {
                    navigate(getDeclarationUrl(response.value().id));
                }
            },
        });
    };

    const editDeclarationMutation = useMutation(editDeclarationApiUrl);
    const updateDeclaration = () => {
        editDeclarationMutation.mutateAsync(convertToUpdateModel(saveModel), {
            onSuccess: () => {
                if (props.onUpdatedDeclaration) {
                    props.onUpdatedDeclaration();
                }
            },
        });
    };

    const declarationUpdateHelper = {
        onNameChange: (name: string) => {
            var temp = { ...saveModel };
            temp.name = name;
            setSaveModel(temp);
        },
        onDescriptionChange: (description: string) => {
            var temp = { ...saveModel };
            temp.description = description;
            setSaveModel(temp);
        },
        onAuditFrequncyChange: (auditFrequency: string | null) => {
            var temp = { ...saveModel };
            if (isNullOrWhitespace(auditFrequency ?? undefined)) {
                temp.auditFrequency = null;
            } else {
                temp.auditFrequency = Number(auditFrequency);
            }
            setSaveModel(temp);
        },
        onApprovalChange: (approval: string | null) => {
            var temp = { ...saveModel };
            if (isNullOrWhitespace(approval ?? undefined)) {
                temp.approval = null;
            } else {
                temp.approval = Number(approval);
            }
            setSaveModel(temp);
        },
        onComplianceAreasChange: (complianceAreas: Array<string>) => {
            var temp = { ...saveModel };
            temp.complianceAreaIds = complianceAreas;
            setSaveModel(temp);
        },
        onFirstAuditMonthChange: (date: Date | null) => {
            let newDate: Date | null = null;
            if (date === null || isNaN(date!.getTime())) newDate = null;
            else newDate = new Date(date!.getFullYear(), date!.getMonth(), 15);

            var temp = { ...saveModel };
            temp.auditDate = newDate;
            setSaveModel(temp);
        },
        onGroupEntityIdsChange: async (entities: Array<string>) => {
            var temp = { ...saveModel };
            temp.groupEntityIds = entities;
            setSaveModel(temp);
        },
    };

    const onNextOrSendClick = async () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        if (groupEntitiesQuery.allGroupEntities) {
            if (step === "selectGroupEntity") {
                if (props.selectedDeclaration) {
                    updateDeclaration();
                } else {
                    saveDeclaration();
                }
            } else {
                if (groupEntitiesQuery.allGroupEntities.length <= 1) {
                    if (props.selectedDeclaration) {
                        updateDeclaration();
                    } else {
                        saveDeclaration();
                    }
                } else {
                    setStep("selectGroupEntity");
                }
            }
        }
    };

    let onPreviousButtonClick = () => {
        setStep("first");
    };

    return {
        groupEntitiesLoading: groupEntitiesQuery.isLoading,
        groupEntities: groupEntitiesQuery.allGroupEntities,
        complianceAreas: complianceAreasQuery.data,
        complianceAreasLoading: complianceAreasQuery.isLoading,
        declarationUpdateHelper,
        validation,
        step,
        saveModel,
        onNextOrSendClick,
        onPreviousButtonClick,
        isSaving: saveDeclarationMutation.isLoading || editDeclarationMutation.isLoading,
        updateDeclaration,
    };

    function convertToUpdateModel(saveModel: DeclarationSaveModel): DeclarationUpdateModel {
        return {
            name: saveModel.name,
            approval: saveModel.approval,
            complianceAreaIds: saveModel.complianceAreaIds,
            description: saveModel.description,
            groupEntityIds: saveModel.groupEntityIds,
        };
    }

    function createDeclarationApiUrl(saveModel: DeclarationSaveModel) {
        return post<string>("/declaration", saveModel);
    }

    function editDeclarationApiUrl(updateModel: DeclarationUpdateModel) {
        return put(`/declaration/${props.selectedDeclaration!.id}`, updateModel);
    }

    function getDefaultModel(): DeclarationSaveModel {
        return {
            id: "",
            name: "",
            approval: null,
            auditFrequency: null,
            description: null,
            groupEntityIds: [],
            complianceAreaIds: [],
            auditDate: null,
        };
    }
}
