import { createElement, useEffect, useState } from "react";
import { LegalEntityAuditGroupSaveModel } from "./CreateLegalEntityAuditGroupDialog.types";
import { useTranslation } from "../../localization/useTranslation";
import { useMutation } from "react-query";
import { post, put } from "../../common/api/apiShared";
import { QuestionnaireSelectableItem } from "../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { useValidator } from "../../common/hooks/validation";
import { useResponsibleQuery } from "../../user/hooks/useResponsibleQuery";
import { useNavigate } from "react-router-dom";
import { useUrlProvider } from "../../useUrlProvider";
import { ICreateAuditGroupDialog } from "./CreateLegalEntityAuditGroupDialog";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";
import { useGroupEntitiesQuery } from "../../processingActivity/hooks/useGroupEntitiesQuery.ts";

export default function useCreateAuditGroupDialogHooks(props: ICreateAuditGroupDialog) {
    const isEdit = props.auditGroup !== undefined;

    const snackbar = useDotLegalSnackbar();
    const { getAuditGroupDraftUrl } = useUrlProvider();
    const navigate = useNavigate();
    const { gdpo } = useUserContext();
    const { translateDate, translateString } = useTranslation();
    const [saveModel, setSaveModel] = useState<LegalEntityAuditGroupSaveModel>(getDefaultSaveModel(props.auditGroup));
    const [step, setStep] = useState<"formData" | "selectGroupEntity">("formData");

    const groupEntitiesQuery = useGroupEntitiesQuery();
    let selectableGroupEntitiesData = groupEntitiesQuery.allGroupEntities;
    let selectableGroupEntitiesLoading = groupEntitiesQuery.isLoading;
    const showSelectedGroupEntityStep = selectableGroupEntitiesData && selectableGroupEntitiesData.length > 1;

    const responsibleQuery = useResponsibleQuery(true);
    let selectableResponsiblesData = responsibleQuery.data(props.auditGroup?.responsibleId);
    let selectableresponsiblesLoading = responsibleQuery.isLoading;

    useEffect(() => {
        if (selectableGroupEntitiesData && selectableGroupEntitiesData.length === 1 && !gdpo) {
            auditUpdater.onGroupEntityChanged([selectableGroupEntitiesData[0].id]);
        } else if (gdpo) {
            auditUpdater.onGroupEntityChanged([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectableGroupEntitiesData]);

    const validation = useValidator<LegalEntityAuditGroupSaveModel>(
        (validators) => ({
            name: validators.validateNotEmpty((item) => item.name, translateString("name")),
            questionnaireId: validators.validateNotEmpty((item) => item.questionnaireId, translateString("audit")),
            deadline: validators.validateTodayOrInTheFuture(
                (item) => item.deadline,
                translateString("deadline"),
                (item) =>
                    isEdit &&
                    props.auditGroup !== undefined &&
                    props.auditGroup.deadline !== undefined &&
                    item.deadline?.toDateString() === new Date(props.auditGroup.deadline).toDateString()
            ),
            responsibleId: validators.validateNotEmpty((item) => item.responsibleId, translateString("responsible")),
            groupEntityId: validators.validateNotEmpty(
                (item) => item.groupEntityId,
                "",
                () => gdpo || step !== "selectGroupEntity",
                translateString("oneSenderIsRequired")
            ),
        }),
        [step]
    );

    validation.check(saveModel);

    const auditUpdater = {
        onAssessmentNameChange: (name: string) => {
            var temp = { ...saveModel };
            temp.name = name;
            setSaveModel(temp);
        },
        onDescriptionChange: (description: string) => {
            var temp = { ...saveModel };
            temp.description = description;
            setSaveModel(temp);
        },
        onQuestionnaireChange: (questionnaire?: QuestionnaireSelectableItem) => {
            const temp = { ...saveModel! };
            temp.questionnaireId = questionnaire?.id;
            temp.questionnaireName = questionnaire?.name;
            setSaveModel(temp);
        },
        onDateChanged: (date: Date | null) => {
            const temp = { ...saveModel! };
            temp.deadline = date!;
            setSaveModel(temp);
        },
        onResponsibleChanged: (responsible: string | null) => {
            const temp = { ...saveModel! };
            temp.responsibleId = responsible ?? undefined;
            setSaveModel(temp);
        },
        onGroupEntityChanged: (groupEntityIds: Array<string>) => {
            const newModel = { ...saveModel! };

            if (groupEntityIds.length === 0) {
                newModel.groupEntityId = undefined;
            } else if (groupEntityIds.length === 1) {
                newModel.groupEntityId = groupEntityIds[0];
            } else {
                newModel.groupEntityId = groupEntityIds[1];
            }

            setSaveModel(newModel);
        },
    };

    const createMutation = useMutation(createApi);

    async function create() {
        await createMutation.mutateAsync(undefined, {
            onSuccess: (response: any) => {
                const value = response.value();
                navigate(getAuditGroupDraftUrl(value!.id));
            },
        });
    }

    const updateMutation = useMutation(updateApi);

    async function update() {
        await updateMutation.mutateAsync(undefined, {
            onSuccess: (response: any) => {
                snackbar.show(createElement(Trans, { i18nKey: "itemEditedSuccess", values: { name: saveModel?.name } }));
                if (props.onAuditGroupUpdated) {
                    props.onAuditGroupUpdated();
                }
            },
        });
    }

    const onNextOrSaveClick = async () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        if (step === "formData") {
            if (!props.auditGroupInProgress && showSelectedGroupEntityStep) {
                setStep("selectGroupEntity");
            } else {
                await save();
            }
        } else {
            await save();
        }
    };

    async function save() {
        if (isEdit) {
            await update();
        } else {
            await create();
        }
    }

    let onPreviousButtonClick = () => {
        if (step === "selectGroupEntity") {
            setStep("formData");
        }
    };

    return {
        saveModel,
        selectableGroupEntitiesData,
        auditUpdater,
        step,
        setStep,
        showSelectedGroupEntityStep,
        validation,
        selectableResponsiblesData,
        selectableresponsiblesLoading,
        disableOkButton: selectableGroupEntitiesLoading,
        onNextOrSaveClick,
        onPreviousButtonClick,
        isSaving: createMutation.isLoading || updateMutation.isLoading,
        isEdit,
    };

    function getDefaultSaveModel(auditGroup?: LegalEntityAuditGroupSaveModel) {
        return {
            name: auditGroup?.name ?? `Audits (${translateDate(new Date())})`,
            description: auditGroup?.description ?? "",
            questionnaireId: auditGroup?.questionnaireId ?? "",
            questionnaireName: auditGroup?.questionnaireName ?? "",
            questionnaireIntroductionTitle: auditGroup?.questionnaireIntroductionTitle ?? "",
            questionnaireVersion: auditGroup?.questionnaireVersion ?? undefined,
            responsibleId: auditGroup?.responsibleId ?? "",
            deadline: auditGroup?.deadline ? new Date(auditGroup.deadline) : undefined,
            groupEntityId: auditGroup?.groupEntityId ?? undefined,
        };
    }

    function createApi() {
        return post<{}>("/legalEntityAuditGroups", saveModel);
    }

    function updateApi() {
        return put<{}>(`/legalEntityAuditGroups/${props.auditGroupId!}`, saveModel);
    }
}
