import { EntityType } from "../../common/Common.types.ts";
import { useTranslation } from "../../localization/useTranslation.ts";
import { useRelatedTasksTab } from "./RelatedTasksTab.hooks.ts";
import {
    DotLegalDeleteDialog,
    DotLegalEmptyState,
    DotLegalOverflowMenu,
    DotLegalPaper,
    DotLegalTableWithControls,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import { RelatedTasksTableModel } from "./RelatedTasksTab.types.ts";
import { Badge, Box, TableCell, TableRow } from "@mui/material";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider.tsx";
import AddTaskButton from "../addTask/addTaskButton/AddTaskButton.tsx";
import React from "react";
import EditTaskDialog from "../editTaskDialog/EditTaskDialog.tsx";
import { Trans } from "react-i18next";
import { getTaskColor } from "../task/Task.styles.ts";
import { useUrlProvider } from "../../useUrlProvider.ts";

export interface RelatedTasksTabProps {
    forEntityId: string;
    forEntityType: EntityType;
    forEntityName: string;
    customApiUrl?: string;
    additionalHeaderTypeTableHeader?: string;
}

function RelatedTasksTab(props: RelatedTasksTabProps) {
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();
    const { getTaskManagementUrlForTask } = useUrlProvider();

    const { isLoading, refetchTasks, data, selectedRelationToRemove, setSelectedRelationToRemove, removeRelation, selectedTask, setSelectedTask } =
        useRelatedTasksTab(props);

    const showAdditionalColumns = props.customApiUrl && props.additionalHeaderTypeTableHeader;

    function getHeaders() {
        let headers = Array<ITableHeader<RelatedTasksTableModel>>();
        headers.push({ property: "customId", text: translateString("id") });
        if (showAdditionalColumns) {
            headers.push({ property: "entityName", text: props.additionalHeaderTypeTableHeader! });
            headers.push({ property: "entityTypeString", text: translateString("entityType") });
        }
        headers.push({ property: "title", text: translateString("task") });
        headers.push({ property: "state", text: translateString("status") });
        headers.push({ property: "responsible", text: translateString("responsible") });
        headers.push({ property: "deadline", text: translateString("deadline") });

        return headers;
    }

    const hasRelatedTasks = isLoading || data.length > 0;

    function getOverflowMenu(row: RelatedTasksTableModel) {
        return (
            <DotLegalOverflowMenu
                items={[
                    {
                        menuItem: translateString("openTask"),
                        onClick: () => {
                            setSelectedTask(row.id);
                        },
                    },
                    {
                        menuItem: translateString("viewInTaskManagement"),
                        href: getTaskManagementUrlForTask(new Date(row.deadline).getMonth() + 1, row.id),
                    },
                    {
                        menuItem: translateString("removeRelation"),
                        onClick: () => {
                            setSelectedRelationToRemove(row);
                        },
                    },
                ]}
            />
        );
    }

    return (
        <DotLegalPaper>
            {hasRelatedTasks ? (
                <DotLegalTableWithControls
                    searchFieldFullWidth
                    headers={getHeaders()}
                    getUserSpecificPageLength={() => 30}
                    hideRowsPerPage
                    clickableRows
                    defaultOrderBy={"title"}
                    defaultOrder={"asc"}
                    isLoading={isLoading}
                    data={data}
                    emptyText={translateString("noRelatedTasks")}
                    labelRowsPerPage={translateString("showEntites")}
                    labelSearch={translateString("search")}
                    noOptionsLabel={translateString("noOptions")}
                    paginationLabelOf={translateString("labelOf")}
                    renderRow={(row, i) => (
                        <TableRow key={i} onClick={() => setSelectedTask(row.id)}>
                            <TableCell>{row.customId}</TableCell>
                            {showAdditionalColumns && <TableCell>{row.additionalEntityInfo?.additionalEntityName}</TableCell>}
                            {showAdditionalColumns && <TableCell>{row.entityTypeString}</TableCell>}
                            <TableCell>{row.title}</TableCell>

                            <TableCell>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <Badge
                                        variant="dot"
                                        sx={(theme) => ({
                                            "& .MuiBadge-badge": {
                                                backgroundColor: getTaskColor(row.state, theme, row.isOverdue),
                                            },
                                        })}
                                    />
                                    <Box>{row.stateString}</Box>
                                </Box>
                            </TableCell>
                            <TableCell>{row.responsible}</TableCell>
                            <TableCell>{row.deadlineString}</TableCell>

                            <TableCell align={"right"}>{getOverflowMenu(row)}</TableCell>
                        </TableRow>
                    )}
                />
            ) : (
                <DotLegalEmptyState text={translateString("noRelatedTasks")} />
            )}

            {permissions.taskPermissions.create && (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <AddTaskButton
                        relationInformation={{
                            fromEntityId: props.forEntityId,
                            fromEntityType: props.forEntityType,
                            toEntityType: EntityType.Task,
                        }}
                        refetchTasks={refetchTasks}
                    />
                </Box>
            )}

            {selectedRelationToRemove !== undefined && (
                <DotLegalDeleteDialog
                    headerText={translateString("removeRelation")}
                    deleteBtnText={"Delete"}
                    cancelBtnText={translateString("cancel")}
                    onDialogClose={() => setSelectedRelationToRemove(undefined)}
                    onOkClick={() => removeRelation(selectedRelationToRemove.relationId)}
                    open
                    alertBodyText={<Trans i18nKey="removeRelationWarning" values={{ from: props.forEntityName, to: selectedRelationToRemove.title }} />}
                    warningTranslation={translateString("warning")}
                />
            )}

            {selectedTask && (
                <EditTaskDialog
                    setShowDeleteDialog={() => {}}
                    refetchTasks={refetchTasks}
                    taskId={selectedTask!}
                    setSelectedTask={() => setSelectedTask(undefined)}
                    setYearByTaskYear={() => {}}
                />
            )}
        </DotLegalPaper>
    );
}

export default RelatedTasksTab;
