type Defined<T> = Exclude<T, undefined | null>;
export function toArray<T extends Exclude<any, undefined | null>>(value?: T | T[] | undefined | null): Defined<T>[] {
    if (!value) return [];

    if (Array.isArray(value)) return value.filter((v) => !!v) as Defined<T>[];

    return [value as Defined<T>];
}

export function exhaustiveGuard(_value: never): never {
    throw new Error(`ERROR! Reached forbidden guard function with unexpected value: ${JSON.stringify(_value)}`);
}

/**
 * this can sort an array of strings including multiple decimal separators and letters
 *
 * ["1", "2", "10.3", "a", "b", "2.0", "1.0", "2.1", "3.5.1", "z"]
 */
export function sortIds(a: string, b: string, reverse = false) {
    const isANumeric = isNumeric(a);
    const isBNumeric = isNumeric(b);

    let result;
    if (isANumeric && isBNumeric) {
        // Both are numeric, compare as numbers
        result = compareNumericStrings(a, b);
    } else if (isANumeric) {
        // Only `a` is numeric, it comes first
        result = -1;
    } else if (isBNumeric) {
        // Only `b` is numeric, it comes first
        result = 1;
    } else {
        // Both are non-numeric, compare alphabetically
        result = a.localeCompare(b);
    }

    // Reverse the result if reverse sorting is enabled
    return reverse ? -result : result;
}

// Helper function to check if a string is numeric (including multi-part numbers)
function isNumeric(str: string) {
    return str.split(".").every((part) => !isNaN(Number(part)));
}

// Helper function to compare multi-part numeric strings
function compareNumericStrings(a: string, b: string) {
    const aParts = a.split(".").map(Number);
    const bParts = b.split(".").map(Number);
    const maxLength = Math.max(aParts.length, bParts.length);

    for (let i = 0; i < maxLength; i++) {
        const aPart = aParts[i] || 0; // Default missing parts to 0
        const bPart = bParts[i] || 0;

        if (aPart !== bPart) {
            return aPart - bPart; // Compare numerically
        }
    }
    return 0; // All parts are equal
}
