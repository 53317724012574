import { useTranslation } from "../../localization/useTranslation.ts";
import { createElement, useMemo, useState } from "react";
import { TabStep } from "../AnnualWheel.types.ts";
import {
    AnnualWheelActivityCategoryType,
    AnnualWheelActivityFramework,
    AnnualWheelActivityTemplateItem,
    AnnualWheelActivityType,
    AnnualWheelFilterOption,
    AnnualWheelPriority,
    NIS2TemplateContainer,
    StandardAnnualWheelActivityCategory,
} from "../annualWheelDialog/AnnualWheelDialog.types.ts";
import { useMutation, useQuery } from "react-query";
import { get, post } from "../../common/api/apiShared.ts";
import { ISOCategory, ISOCategoryMapper } from "../annualWheelDialog/templates/isoTemplates/IsoTemplates.types.ts";
import { ITemplateAnnualWheelActivityDialogProps } from "./TemplateAnnualWheelActivityDialog.tsx";
import { SelectedAnnualWheelActivityTemplate } from "../annualWheelAddEditDialog/AnnualWheelAddEditDialog.types.ts";
import { AnnualWheelActivityTemplateSaveModel } from "../customTemplateAddEditDialog/CustomTemplateAddEditDialog.types.ts";
import { Trans } from "react-i18next";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";

export function useTemplateAnnualWheelActivityDialog(props: ITemplateAnnualWheelActivityDialogProps) {
    const [showTemplateDialog, setShowTemplateDialog] = useState(true);
    const [templateModel, setTemplateModel] = useState<AnnualWheelActivityTemplateSaveModel | undefined>(undefined);
    const [showAddEditTemplateDialog, setShowAddEditTemplateDialog] = useState(false);
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [selectedTab, setSelectedTab] = useState<TabStep>(TabStep.GDPRTemplates);
    const [customTemplateIdForDeletion, setCustomTemplateIdForDeletion] = useState<string | undefined>(undefined);
    const [searchInput, setSearchInput] = useState("");

    const [selectedActivity, setSelectedActivity] = useState<SelectedAnnualWheelActivityTemplate | undefined>(undefined);

    const [selectedFilterOption, setSelectedFilterOption] = useState(AnnualWheelFilterOption.All.toString());

    const stdAnnualWheelActivitiesUrl = "/annualWheelActivityTemplate";
    const annualWheelActivityTemplatesQuery = useQuery(stdAnnualWheelActivitiesUrl, () =>
        get<Array<AnnualWheelActivityTemplateItem>>(stdAnnualWheelActivitiesUrl)
    );

    let stdActivitiesLoading = annualWheelActivityTemplatesQuery.isLoading;

    let allActivities: Array<AnnualWheelActivityTemplateItem> = useMemo(() => {
        return annualWheelActivityTemplatesQuery.data ?? [];
    }, [annualWheelActivityTemplatesQuery.data]);

    const copyTemplate = async (data: { id: string; name: string }) => {
        await copyMutation.mutateAsync(data);
    };

    function copy(data: { id: string; name: string }) {
        return post<{}>(`/annualWheelActivityTemplate/${data.id}/copy`, { name: translateString("copyName", { name: data.name }) });
    }

    const copyMutation = useMutation(copy, {
        onSuccess: (response: any) => {
            const value = response.value();
            setTemplateModel(value);
            onCopyComplete();
        },
    });

    function onSelectActivity(activity: AnnualWheelActivityTemplateItem) {
        setSelectedActivity({
            name: activity.name,
            description: activity.description,
            subTasks: activity.subTasks,
            type: activity.type,
            customTemplateId: activity.customTemplateId,
            interval: activity.recurringIntervalInMonths,
            notificationPeriodInDays: activity.notificationPeriodInDays ?? 14,
            framework: activity.framework,
            customId: activity.customId,
            priority: activity.priority ?? AnnualWheelPriority.Moderate,
            planNow: false,
            notPartOfCurrentPlan: activity.notPartOfCurrentPlan,
            showTrialWarning: activity.showTrialWarning,
        });
    }

    const onChangeTab = (step: TabStep) => {
        if (step !== selectedTab) {
            setSelectedActivity(undefined);
        }

        setSelectedTab(step);
    };

    function onCopyComplete() {
        setSelectedActivity(undefined);
        refetch();
    }

    if (searchInput) {
        allActivities = allActivities.filter(
            (t) => t.name.toLowerCase().indexOf(searchInput.toLowerCase()) > -1 || t.name.toLowerCase().indexOf(searchInput.toLowerCase()) > -1
        );
    }

    let gdprActivities = useMemo(() => {
        const standardActivitiesPerCategory: Array<StandardAnnualWheelActivityCategory> = [];

        allActivities
            .filter((a) => a.framework === AnnualWheelActivityFramework.GDPR && filterTemplatesByFilterOption(a))
            .forEach((activity) => {
                const categoryType = getCategory(activity.type);
                let standardActivity = standardActivitiesPerCategory.find((x) => x.categoryType === categoryType);

                if (standardActivity) {
                    standardActivity.templates.push(activity);
                } else {
                    standardActivitiesPerCategory.push({
                        categoryType: categoryType,
                        templates: new Array<AnnualWheelActivityTemplateItem>(activity),
                        framework: activity.framework!,
                    });
                }
            });
        return standardActivitiesPerCategory;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allActivities, selectedFilterOption]);

    let hasCustomActivities = allActivities.some((x) => x.isCustom);

    let customActiviites: AnnualWheelActivityTemplateItem[] = useMemo(() => {
        return allActivities.filter((x) => x.isCustom && filterTemplatesByFilterOption(x));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allActivities, selectedFilterOption]);

    let nis2Templates: NIS2TemplateContainer = useMemo(() => {
        const templates = new NIS2TemplateContainer();
        templates.setTemplates(allActivities.filter((x) => x.framework === AnnualWheelActivityFramework.NIS2 && filterTemplatesByFilterOption(x)));

        return templates;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allActivities, selectedFilterOption]);

    let isoTemplates: { iso27001Templates: ISOCategory[]; iso27002Templates: ISOCategory[]; iso27002_2013Templates: ISOCategory[] } = useMemo(() => {
        const isoTemplatemapper = new ISOCategoryMapper();
        isoTemplatemapper.setTemplates(
            allActivities.filter(
                (x) =>
                    (x.framework === AnnualWheelActivityFramework.ISO27001 || x.framework === AnnualWheelActivityFramework.ISO27002) &&
                    filterTemplatesByFilterOption(x)
            )
        );
        return {
            iso27001Templates: isoTemplatemapper.iso27001Templates,
            iso27002Templates: isoTemplatemapper.iso27002Templates,
            iso27002_2013Templates: isoTemplatemapper.iso27002_2013Templates,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allActivities, selectedFilterOption]);

    const onCustomTemplateEdit = (activity: AnnualWheelActivityTemplateItem) => {
        setSelectedActivity(undefined);
        setShowAddEditTemplateDialog(true);
        setTemplateModel({
            name: activity.name,
            description: activity.description,
            subTasks: activity.subTasks,
            planNow: true,
            id: activity.customTemplateId!,
        });
        setShowTemplateDialog(false);
    };

    const onCustomTemplateCreate = () => {
        setSelectedActivity(undefined);
        setShowAddEditTemplateDialog(true);
        setTemplateModel(undefined);
        setShowTemplateDialog(false);
    };

    const onCustomTemplateDelete = (activityId: string) => {
        setCustomTemplateIdForDeletion(activityId);
    };

    const onAddEditCanceled = () => {
        setShowAddEditTemplateDialog(false);
        setTemplateModel(undefined);
        setShowTemplateDialog(true);
    };

    const onCustomTemplateUpdated = () => {
        setShowAddEditTemplateDialog(false);
        setTemplateModel(undefined);
        setShowTemplateDialog(true);
        refetch();
    };

    const onCustomTemplateAdded = (customTemplateModel: AnnualWheelActivityTemplateSaveModel) => {
        setShowAddEditTemplateDialog(false);
        setTemplateModel(undefined);
        setShowTemplateDialog(true);
        if (customTemplateModel.planNow) {
            props.onCustomTemplateAdded(customTemplateModel);
        } else {
            refetch();
            snackbar.show(createElement(Trans, { i18nKey: "createdSuccessGeneric", values: { name: customTemplateModel.name } }));
        }
    };

    return {
        isLoading: stdActivitiesLoading,
        selectedActivity,
        searchInput,
        setSearchInput,
        gdprActivities,
        selectedTab,
        onChangeTab,
        customActiviites,
        refetch,
        hasCustomActivities,
        customTemplateIdForDeletion,
        setCustomTemplateIdForDeletion,
        copyCustomTemplate: copyTemplate,
        nis2Templates,
        isoTemplates,
        onSelectActivity,
        selectedFilterOption,
        setSelectedFilterOption,
        onCustomTemplateEdit,
        onCustomTemplateCreate,
        onCustomTemplateDelete,
        showAddEditTemplateDialog,
        onAddEditCanceled,
        onCustomTemplateAdded,
        onCustomTemplateUpdated,
        showTemplateDialog,
        templateModel,
    };

    function refetch() {
        annualWheelActivityTemplatesQuery.refetch();
    }
    function getCategory(activityType: AnnualWheelActivityType) {
        if (
            AnnualWheelActivityType.ProcessingActivityValidation === activityType ||
            AnnualWheelActivityType.ValidateRecord === activityType ||
            AnnualWheelActivityType.ValidateGroupEntityBasisForProcessing === activityType ||
            AnnualWheelActivityType.ReviewConsents === activityType ||
            AnnualWheelActivityType.SpotChecksOnConsents === activityType ||
            AnnualWheelActivityType.InspectThirdCountryTransfers === activityType
        ) {
            return AnnualWheelActivityCategoryType.ProcessingActivity;
        } else if (AnnualWheelActivityType.ReviewOfRiskAssessment === activityType || AnnualWheelActivityType.AssessImpactAssessmentsDPIA === activityType) {
            return AnnualWheelActivityCategoryType.Risk;
        } else if (
            AnnualWheelActivityType.DataProcessorAudit === activityType ||
            AnnualWheelActivityType.UpdateDataprocessorAgreements === activityType ||
            AnnualWheelActivityType.AuditSubProcessors === activityType
        ) {
            return AnnualWheelActivityCategoryType.DataProcessor;
        } else if (AnnualWheelActivityType.ReviewDeletionPolicy === activityType || AnnualWheelActivityType.CheckForDeFactoDeletion === activityType) {
            return AnnualWheelActivityCategoryType.DeletionPeriod;
        } else if (
            AnnualWheelActivityType.InspectProceduresAndPolicies === activityType ||
            AnnualWheelActivityType.UpdatePrivacyPolicyWebsite === activityType ||
            AnnualWheelActivityType.ReviewSocialMediaPrivacyPolicies === activityType ||
            AnnualWheelActivityType.CheckCookiePolicyAndBanner === activityType ||
            AnnualWheelActivityType.ReviewRequirementsForJobApplicants === activityType ||
            AnnualWheelActivityType.ReviewEmployeeDisclosure === activityType ||
            AnnualWheelActivityType.ReviewPolicyForDataSubjectsRights === activityType ||
            AnnualWheelActivityType.CheckPolicyForDataSubjectRights === activityType
        ) {
            return AnnualWheelActivityCategoryType.PolicyAndProcedure;
        } else if (
            AnnualWheelActivityType.CustomizeGDPRTemplates === activityType ||
            AnnualWheelActivityType.ExamineGuidlineDecisionsFromDDPA === activityType
        ) {
            return AnnualWheelActivityCategoryType.TemplateAndInstructions;
        } else if (AnnualWheelActivityType.FollowUpLastYearGDPRProjectsAndPlanNext === activityType) {
            return AnnualWheelActivityCategoryType.AnnualWheel;
        } else if (
            AnnualWheelActivityType.ValidateGDRPOnIntra === activityType ||
            AnnualWheelActivityType.PlanAwerenessCampaignOne === activityType ||
            AnnualWheelActivityType.PlanAwerenessCampaignTwo === activityType ||
            AnnualWheelActivityType.CompleteYearlyAudit === activityType ||
            AnnualWheelActivityType.ValidateYourGroupCompanies === activityType ||
            AnnualWheelActivityType.ReviewDisclosurRegForResignation === activityType
        ) {
            return AnnualWheelActivityCategoryType.Group;
        } else if (
            AnnualWheelActivityType.TestSecurityBreachProcedure === activityType ||
            AnnualWheelActivityType.CheckLogForSecurityBreaches === activityType ||
            AnnualWheelActivityType.TestAndFollowUpITContingencyPlan === activityType ||
            AnnualWheelActivityType.FollowUpAccessRights === activityType ||
            AnnualWheelActivityType.UpdateItSecurityPolicy === activityType
        ) {
            return AnnualWheelActivityCategoryType.ITAndSecurity;
        } else {
            return AnnualWheelActivityCategoryType.Other;
        }
    }
    function filterTemplatesByFilterOption(activtity: AnnualWheelActivityTemplateItem) {
        if (selectedFilterOption === AnnualWheelFilterOption.NotPlanned.toString()) {
            return !activtity.isScheduled;
        }

        if (selectedFilterOption === AnnualWheelFilterOption.Planned.toString()) {
            return activtity.isScheduled;
        }
        return true;
    }
}
