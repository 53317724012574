import React from "react";
import { ValidationError } from "../../../common/validationError";
import { useTranslation } from "../../../localization/useTranslation";
import { DataCategoryDataTypes, ProcessingActivityModel, Source, StepTypeEnum } from "../../ProcessingActivity.types";
import { useSourceStepStyles } from "./SourceStep.styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SegmentStep from "../../stepSegment/StepSegment";
import DotLegalStepHeader from "../../../common/components/dotLegalStepHeader/DotLegalStepHeader";
import DotLegalCollapse from "../../../common/components/dotLegalCollapse/DotLegalCollapse";
import { DotLegalButton, DotLegalCheckbox, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import DotLegalPersonalDataSelector from "../../../common/components/dotLegalPersonalDataSelector/DotLegalPersonalDataSelector";
import LegalEntityTable from "../../../legalEntity/legalEntityTable/LegalEntityTable";
import { Guid } from "../../../common/guid";
import { useSourceMapping } from "./SourceStep.hooks";
import SharingsInfoBox from "../sharingsInfoBox/SharingsInfoBox";
import SharingsLegalEntity from "../sharingsLegalEntityBox/sharingsLegalEntity";
import { rightArrowIcon } from "../../../common/icons";
import { useUrlProvider } from "../../../useUrlProvider";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { getSelectableDocumentItems, getSelectedDocuments } from "../../hooks/useDataProcessingAgreement";
import { DocumentStatus } from "../../../documents/Documents.types";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import EditLegalEntityDocumentDialog from "../../../legalEntity/legalEntityDocumentTab/editLegalEntityDocumentDIalog/EditLegalEntityDocumentDialog";
import SharingsAgreementBox from "../sharingsAgreementBox/sharingsAgreementBox";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import { Box } from "@mui/material";
import { mapToDataCategorySelectorModel } from "../sharingOfDataStepFunctions.ts";
import { DOCUMENT_TYPE_DISCLOSURE_AGREEMENT_ID, DocumentTypeUsage } from "../../../masterData/documentType/documentTypes/DocumentTypes.types.ts";

export interface ISourceStep {
    processingActivity: ProcessingActivityModel;
    sources: Array<Source>;
    dataCategories: Array<string>;
    entitesFromAssociation: Array<string>;
    validationErrors: Array<ValidationError>;
    processingActivityOwner: string | null | undefined;
    onDataControllerChange: (legalUnitId: string | null, sourceId: string) => void;
    onCreateNewDocument: (agreement: string, dataProcessorId: string) => void;
    onResponsibleChange: (responsibleId: string | null, sourceId: string) => void;
    onChosenDataCategories: (chosenCategories: Array<DataCategoryDataTypes>, sourceId: string) => void;
    onGroupEntitiesChange: (entities: Array<string>, sourceId: string) => void;
    onRemoveSourceClick: (sourceId: string) => void;
    onAddNewSourceClick: () => void;
    disableSources: boolean;
    isAddingSource: boolean;
    onSharingNameChange: (name: string, sharingId: string) => void;
    onSharingDescriptionChange: (description: string, sharingId: string) => void;
    onDisableSources: (disabled: boolean) => void;
    hasData: boolean;
    isReadOnly: boolean;
}

function SourceStep(props: ISourceStep) {
    const { getEditProcessingActivityUrl, getUserUrl } = useUrlProvider();
    const { translateString } = useTranslation();
    const styles = useSourceStepStyles();
    const dataCategoryIds = props.dataCategories.map((d) => {
        return d!;
    });

    const { addonsFeatures } = usePlanContext();
    const { permissions, gdpo } = useUserContext();
    const sourcePermissions = permissions.processingActivitySourcesPermissions;

    const {
        dataCategoriesData,
        legalEntityLoading,
        legalEntityData,
        responsibleLoading,
        groupEntityLoading,
        groupEntityData,
        documentsLoading,
        documentsData,
        getResponsibles,
        dataProcessorAgreementSaveModel,
        setDataProcessorAgreementSaveModel,
        sourceId,
        setSourceId,
        refetchDocuments,
        selectedDocument,
        setSelectedDocument,
        showEditDocumentDialog,
        setShowEditDocumentDialog,
        getExpandedId,
        setExpandedId,
        onAddAgreement,
        onDeleteAgreement,
        onHasAgreementChange,
    } = useSourceMapping(dataCategoryIds, props.processingActivity, props.sources);

    const handleAddNewSourceClick = (sourceId: string) => {
        setExpandedId(sourceId);
        props.onAddNewSourceClick();
    };

    const handleCollapseClick = (sourceId: string) => {
        if (sourceId) {
            if (getExpandedId() === sourceId) setExpandedId("");
            else setExpandedId(sourceId);
        }
    };

    const handleRemoveTransfer = (sourceId: string) => {
        if (getExpandedId() === sourceId) setExpandedId("");

        props.onRemoveSourceClick(sourceId);
    };

    const handleChosenDataCategories = (datacategories: Array<DataCategoryDataTypes> | undefined, sourceId: string) => {
        if (dataCategoriesData) {
            props.onChosenDataCategories(datacategories!, sourceId);
        } else {
            props.onChosenDataCategories([], sourceId);
        }
    };

    const getGroupEntitiesForTable = () => {
        if (groupEntityData) {
            return groupEntityData.filter((entity) => props.entitesFromAssociation.indexOf(entity.id) >= 0);
        }
        return undefined;
    };

    const getCollapseDetails = (companies: Array<string>, dataprocessor: string | null | undefined) => {
        let sender = "";
        let recipient = "";

        if (companies.length > 0 && !legalEntityLoading) {
            let entity = legalEntityData?.find((x) => x.id === companies[0]);
            if (entity) {
                recipient = `${entity!.name} ${companies.length > 1 ? " + " + (companies.length - 1).toString() : ""}`;
            }
        }

        if (dataprocessor && !legalEntityLoading) {
            let entity = legalEntityData!.find((x) => x.id === dataprocessor);
            if (entity) {
                sender = entity.name;
            }
        }

        return (
            <React.Fragment>
                <div>{sender}</div>
                <KeyboardBackspaceIcon fontSize="large" sx={styles.collapseArrow} />
                <div>{recipient}</div>
            </React.Fragment>
        );
    };

    return (
        <SegmentStep size="large">
            <DotLegalStepHeader toolTipText={translateString("typeOfSourcesReceivedHelperText")} record={false} topMargin>
                {translateString("typeOfSourcesReceived")}
            </DotLegalStepHeader>

            {props.sources.map((source, index) => {
                let readOnly = source.readOnly || source.generatedFromTransferId !== null || props.isReadOnly;
                const hasDocuments = source.agreementIds.length !== 0;
                const selectedEntity = legalEntityData?.find((x) => x.id === source.dataControllerId);

                return (
                    <DotLegalCollapse
                        id={source.id}
                        collapseName={source.name}
                        key={source.id ?? index}
                        isLoading={legalEntityLoading || groupEntityLoading}
                        sx={[styles.collapse, styles.systemImage]}
                        error={props.validationErrors.some((e) => e.field.startsWith(index.toString()))}
                        isExpanded={getExpandedId() === source.id}
                        centerContent={getCollapseDetails(source.groupEntities, source.dataControllerId)}
                        removeItemText={translateString("removeSource")}
                        onExpandClick={() => handleCollapseClick(source.id!)}
                        onRemoveClick={() => handleRemoveTransfer(source.id!)}
                        disabled={source.readOnly || source.generatedFromTransferId !== null || props.isReadOnly}
                        collapseIcon={
                            source.generatedFromTransferId !== null
                                ? {
                                      icon: rightArrowIcon,
                                      iconHoverText: translateString("sharingSourceAdded"),
                                      href: getEditProcessingActivityUrl(
                                          props.processingActivity.id,
                                          StepTypeEnum.sharings,
                                          StepTypeEnum.disclosure,
                                          source.generatedFromTransferId
                                      ),
                                  }
                                : undefined
                        }
                    >
                        <SegmentStep size="small">
                            <SharingsInfoBox
                                name={source.name}
                                description={source.description}
                                onDescriptionChange={(e) => props.onSharingDescriptionChange(e, source.id!)}
                                onNameChange={(e) => props.onSharingNameChange(e, source.id!)}
                                disabled={readOnly}
                            />

                            <DotLegalStepHeader toolTipText={translateString("whoSendsDataHelperText")} record={false} topMargin>
                                {translateString("whoSendsData")}
                            </DotLegalStepHeader>

                            <SharingsLegalEntity
                                options={legalEntityData}
                                isLoading={legalEntityLoading}
                                selectedItem={source.dataControllerId}
                                placeholder={translateString("Controller")}
                                label={translateString("Controller")}
                                errorText={props.validationErrors.find((v) => v.field === `${index}legalEntityId`)?.error}
                                disabled={readOnly}
                                onLegalEntityChange={(legalUnitId) => props.onDataControllerChange(legalUnitId, source.id!)}
                                showAddNewEntityButton={!readOnly}
                            />
                            {sourcePermissions.hasAgreementPermission && (
                                <SharingsAgreementBox
                                    options={getSelectableDocumentItems(
                                        documentsData,
                                        translateString,
                                        source.hasDataProcessingAgreement,
                                        source.agreementIds,
                                        source.containsNotAccessibleDocuments
                                    )}
                                    showAddAgreementBox={!isNullOrWhitespace(source.dataControllerId) ? true : false}
                                    getSelectedDocuments={getSelectedDocuments(
                                        source.agreementIds,
                                        source.hasDataProcessingAgreement,
                                        source.containsNotAccessibleDocuments
                                    )}
                                    saveAgreement={(agreementId) => {
                                        props.onCreateNewDocument(agreementId, sourceId);
                                        refetchDocuments();
                                    }}
                                    id={sourceId}
                                    disabled={readOnly}
                                    onYesOrNoChange={(hasAgreement) => onHasAgreementChange(hasAgreement, source.id!)}
                                    dataProcessorAgreementSaveModel={dataProcessorAgreementSaveModel}
                                    onAddNewAgreementClick={() => {
                                        setSourceId(source!.id!);
                                        setDataProcessorAgreementSaveModel({
                                            legalEntityId: source!.dataControllerId!,
                                            name: "",
                                            base64Data: "",
                                            fileName: "",
                                            link: "",
                                            isLink: false,
                                            owner: props.processingActivityOwner,
                                            status: permissions.canSetDocumentStatus ? undefined : DocumentStatus.draft,
                                            groupEntityAccessIds: [],
                                            allGroupEntities: gdpo || selectedEntity!.isCustomerOwned,
                                            isGroupEntity: selectedEntity?.isCustomerOwned,
                                            documentTypeId: addonsFeatures.research ? undefined : DOCUMENT_TYPE_DISCLOSURE_AGREEMENT_ID,
                                        });
                                    }}
                                    onChipClick={
                                        hasDocuments
                                            ? (id) => {
                                                  setSelectedDocument({
                                                      documentId: id,
                                                      legalEntityId: source.dataControllerId!,
                                                      name: "",
                                                      isGroupEntity: selectedEntity?.isCustomerOwned,
                                                      documentTypeUsage: DocumentTypeUsage.Source,
                                                  });
                                                  setShowEditDocumentDialog(true);
                                              }
                                            : undefined
                                    }
                                    singleSelect={!hasDocuments}
                                    isLoading={documentsLoading}
                                    stepType={StepTypeEnum.sources}
                                    onAddedAgreement={(agreementId: string) => onAddAgreement(agreementId, source.id!)}
                                    onDeletedAgreement={(agreementId: string) => onDeleteAgreement(agreementId, source.id!)}
                                    documentUsageType={DocumentTypeUsage.Source}
                                />
                            )}

                            {sourcePermissions.hasResponsiblePermission && (
                                <DotLegalSelect
                                    selectedItem={source.responsible}
                                    isLoading={responsibleLoading}
                                    label={translateString("responsible")}
                                    placeholder={translateString("responsible")}
                                    onChange={(responsibleId) => props.onResponsibleChange(responsibleId, source.id!)}
                                    options={getResponsibles(source.responsible)}
                                    toolTipText={translateString("sourceResponsibleHelperText")}
                                    disabled={readOnly}
                                    errorText={props.validationErrors.find((x) => x.field === `${index}inactiveUser`)?.error}
                                    link={source.responsible && permissions.canManageUsers ? getUserUrl(source.responsible!) : undefined}
                                    noOptionsLabel={translateString("noOptions")}
                                />
                            )}
                        </SegmentStep>

                        {sourcePermissions.hasDataCategoriesPermission && (
                            <React.Fragment>
                                <DotLegalStepHeader
                                    toolTipText={translateString("dataTypeSentHelperText")}
                                    record={false}
                                    subHeader={translateString("noDataCategoriesSelectedProcess")}
                                    topMargin
                                    bottomMargin
                                >
                                    {translateString("dataTypeSent")}
                                </DotLegalStepHeader>

                                {dataCategoriesData && (
                                    <DotLegalPersonalDataSelector
                                        activityDataCategories={mapToDataCategorySelectorModel(
                                            dataCategoriesData!,
                                            props.processingActivity.dataCategories,
                                            source.dataCategories
                                        )}
                                        onChange={(data) => handleChosenDataCategories(data, source.id!)}
                                        disabled={readOnly}
                                        errorText={props.validationErrors.find((v) => v.field === `${index}dataCategories`)?.error}
                                    />
                                )}
                            </React.Fragment>
                        )}

                        <SegmentStep size="large">
                            <DotLegalStepHeader record={false} subHeader={translateString("entityChosenOnAssociation")} topMargin bottomMargin>
                                {translateString("companiesAsSources")}
                            </DotLegalStepHeader>

                            <LegalEntityTable
                                disabled={source.generatedFromTransferId !== null || readOnly}
                                errorText={props.validationErrors.find((v) => v.field === `${index}companies`)?.error}
                                isLoading={groupEntityLoading}
                                legalEntities={getGroupEntitiesForTable()}
                                selectedEntities={source.groupEntities}
                                showAddNewEntityButton={false}
                                showAddAllEntityButton
                                onLegalEntitiesChange={(entities) => props.onGroupEntitiesChange(entities, source.id!)}
                            />
                        </SegmentStep>
                    </DotLegalCollapse>
                );
            })}

            <Box sx={styles.addNewSource}>
                <DotLegalButton
                    disabled={props.disableSources || props.isReadOnly}
                    buttonType="secondary"
                    isLoading={props.isAddingSource}
                    onClick={() => handleAddNewSourceClick(Guid.newGuid())}
                >
                    {props.sources.length > 0 ? translateString("addAnotherSource") : translateString("addSource")}
                </DotLegalButton>
            </Box>

            {!props.hasData && (
                <Box sx={styles.disableSources}>
                    <Box id={"disable-sources"}>
                        <DotLegalCheckbox
                            disabled={props.isReadOnly}
                            checked={props.disableSources}
                            onChange={props.onDisableSources}
                            label={translateString("disableSources")}
                        />
                        {props.validationErrors.some((e) => e.field === "noDataSharingsSources") && (
                            <Box sx={styles.error}>{props.validationErrors.find((e) => e.field === "noDataSharingsSources")?.error}</Box>
                        )}
                    </Box>
                </Box>
            )}

            {showEditDocumentDialog && (
                <EditLegalEntityDocumentDialog
                    onCloseDialog={() => {
                        setShowEditDocumentDialog(false);
                    }}
                    selectedDocument={selectedDocument!}
                    isExtended={false}
                    onSave={() => refetchDocuments()}
                    stepType={StepTypeEnum.sources}
                />
            )}
        </SegmentStep>
    );
}

export default SourceStep;
