import { Permission, PermissionLevel, PermissionType } from "../Roles.types";

//TODO: skal fjernes.
export interface RolesSaveModel {
    permissions: Array<Permission>;
}

export interface PermissionTypeContent {
    masterPermission: Permission;
    subPermissions: Array<PermissionType> | undefined;
    type: PermissionListType;
    accessLevel: Array<PermissionLevel>;
    order: number;
    associatedPermissions: Array<PermissionType> | undefined;
    disableAccessLevels: boolean;
}

export enum PermissionListType {
    ProcessingActivity,
    Systems,
    Risk,
    AnnualWheel,
    TaskManagement,
    Settings,
    Lists,
    LegalEntities,
    Record,
    Policies,
    AuditLog,
    IncidentLog,
    VendorManagement,
    Declarations,
    Other,
    Contracts,
}
