import { DotLegalCheckbox, DotLegalDialog, DotLegalHeader, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import React from "react";
import { Box, Grid } from "@mui/material";
import useTransferUserResponsibilityHooks from "./TransferUserResponsibility.hooks";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";

export interface ITransferUserResponsibility {
    onDialogClose: () => void;
}

function TransferUserResponsibility(props: ITransferUserResponsibility) {
    const { translateString } = useTranslation();
    const {
        fromUsers,
        toUsers,
        onFromUserChange,
        onToUserChange,
        transferModel,
        onTransferAnnualWheelTasksChange,
        onTransferProcessingActivitiesChange,
        onTransferSystemsChange,
        onTransferDocumentsChange,
        onTransferSharingsChange,
        onTransferLegalEntityaudits,
        transferResponsible,
        onTransferAuditGroups,
        onTransferInternalAssessments,
        onTransferPublicInternalAssessmentTemplates,
        onTransferLegalEntitiesChange,
        transferButtonEnabled,
        inProgress,
    } = useTransferUserResponsibilityHooks(props);

    return (
        <DotLegalDialog
            okButtonDisabled={!transferButtonEnabled}
            size="sm"
            header={translateString("transferReponsibility")}
            buttonOkText={translateString("transfer")}
            open
            onDialogClose={() => props.onDialogClose()}
            onOkClick={() => transferResponsible()}
            inProgress={inProgress}
            disableBackdropOnCloseIfValueChange={transferModel}
        >
            <Box sx={{ marginLeft: 2, marginRight: 2 }}>
                <DotLegalHeader center fontWeight={0} headerStyle={"small"}>
                    {translateString("transferUserResponsibilityInfo")}
                </DotLegalHeader>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box>
                        <DotLegalSelect
                            options={fromUsers}
                            onChange={(user) => onFromUserChange(user)}
                            placeholder={translateString("from")}
                            label={translateString("from")}
                            noOptionsLabel={""}
                            selectedItem={transferModel.fromUser}
                        />
                        <DotLegalSelect
                            options={toUsers}
                            onChange={(user) => onToUserChange(user)}
                            placeholder={translateString("to")}
                            label={translateString("to")}
                            noOptionsLabel={""}
                            selectedItem={transferModel.toUser}
                        />

                        <Grid container>
                            <Grid item sm={12} md={6}>
                                <DotLegalCheckbox
                                    onChange={(checked) => onTransferProcessingActivitiesChange(checked)}
                                    checked={transferModel.transferProcessingActivities}
                                    label={translateString("processingActivities")}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <DotLegalCheckbox
                                    onChange={(checked) => onTransferLegalEntitiesChange(checked)}
                                    checked={transferModel.transferLegalEntities}
                                    label={translateString("legalEntities")}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <DotLegalCheckbox
                                    onChange={(checked) => onTransferSharingsChange(checked)}
                                    checked={transferModel.transferSharings}
                                    label={translateString("sharings")}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <DotLegalCheckbox
                                    onChange={(checked) => onTransferSystemsChange(checked)}
                                    checked={transferModel.transferSystems}
                                    label={translateString("systems")}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <DotLegalCheckbox
                                    onChange={(checked) => onTransferAnnualWheelTasksChange(checked)}
                                    checked={transferModel.transferAnnualWheelTasks}
                                    label={translateString("tasks")}
                                />
                            </Grid>

                            <Grid item sm={12} md={6}>
                                <DotLegalCheckbox
                                    onChange={(checked) => onTransferDocumentsChange(checked)}
                                    checked={transferModel.transferDocuments}
                                    label={translateString("documents")}
                                />
                            </Grid>

                            <Grid item sm={12} md={6}>
                                <DotLegalCheckbox
                                    onChange={(checked) => onTransferLegalEntityaudits(checked)}
                                    checked={transferModel.transferLegalEntityAudits}
                                    label={translateString("audits")}
                                />
                            </Grid>

                            <Grid item sm={12} md={6}>
                                <DotLegalCheckbox
                                    onChange={(checked) => onTransferAuditGroups(checked)}
                                    checked={transferModel.transferAuditGroups}
                                    label={translateString("auditGroup")}
                                />
                            </Grid>

                            <Grid item sm={12} md={6}>
                                <DotLegalCheckbox
                                    onChange={(checked) => onTransferInternalAssessments(checked)}
                                    checked={transferModel.transferInternalAssessments}
                                    label={translateString("internalAssessments")}
                                />
                            </Grid>

                            <Grid item sm={12} md={6}>
                                <DotLegalCheckbox
                                    onChange={(checked) => onTransferPublicInternalAssessmentTemplates(checked)}
                                    checked={transferModel.transferPublicInternalAssessmentTemplates}
                                    label={translateString("publicInternalAssessmentHeader")}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </DotLegalDialog>
    );
}

export default TransferUserResponsibility;
