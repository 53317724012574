import {
    DotLegalActionBar,
    DotLegalButton,
    DotLegalOverflowMenu,
    DotLegalPageHeader,
    DotLegalPaper,
    DotLegalTableWithControls,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import useDocumentTypesHooks from "./DocumentTypes.hooks";
import { DocumentType } from "./DocumentTypes.types";
import { Box, TableRow, TableCell } from "@mui/material";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import CheckIcon from "@mui/icons-material/Check";
import AddEditDocumentTypeDialog from "../addEditDocumentTypeDialog/AddEditDocumentTypeDialog";
import DotLegalDeleteDialogWrapper from "../../../common/components/dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";

function DocumentTypes() {
    const {
        documentTypes,
        isLoading,
        breadCrumbs,
        getMenuItems,
        onDialogClose,
        showCreateDialog,
        onSaved,
        documentTypeForDeletion,
        setDocumentTypeForDeletion,
        onDelete,
        onCreateClick,
        onUpdateClick,
        documentTypeForUpdate,
        isDeleting,
    } = useDocumentTypesHooks();
    const { translateString } = useTranslation();
    const { customerName } = useUserContext();

    const headers = Array<ITableHeader<DocumentType>>();
    headers.push({ property: "name", text: translateString("name") });
    headers.push({ property: "isCustom", text: translateString("standardMasterData") });

    return (
        <div>
            <DotLegalPageHeader userContext={{ customerName }} breadCrumbs={breadCrumbs} />
            <DotLegalActionBar
                canCreate
                primaryButton={
                    <DotLegalButton buttonType={"primary"} onClick={() => onCreateClick()}>
                        {translateString("createDocumentType")}
                    </DotLegalButton>
                }
                showDialog={showCreateDialog || documentTypeForUpdate !== undefined}
            >
                <AddEditDocumentTypeDialog
                    onDialogClose={onDialogClose}
                    isAdd={documentTypeForUpdate === undefined}
                    documentTypeToUpdate={documentTypeForUpdate}
                    onSaved={onSaved}
                />
            </DotLegalActionBar>
            <DotLegalPaper>
                <DotLegalTableWithControls
                    headers={headers}
                    isLoading={isLoading}
                    data={documentTypes}
                    emptyText={translateString("noDataTypes")}
                    defaultOrderBy={"name"}
                    hideRowsPerPage
                    getUserSpecificPageLength={() => 100}
                    labelRowsPerPage={translateString("showEntites")}
                    labelSearch={translateString("search")}
                    noOptionsLabel={translateString("noOptions")}
                    paginationLabelOf={translateString("labelOf")}
                    clickableRows={false}
                    renderRow={(row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>
                                <Box sx={{ height: "20px !important" }}>{!row.isCustom ? <CheckIcon sx={{ height: "20px !important" }} /> : ""}</Box>
                            </TableCell>
                            <TableCell align={"right"}>{row.isCustom && <DotLegalOverflowMenu items={getMenuItems(row)} />}</TableCell>
                        </TableRow>
                    )}
                />
            </DotLegalPaper>
            {documentTypeForDeletion && (
                <DotLegalDeleteDialogWrapper
                    open
                    onDialogClose={() => setDocumentTypeForDeletion(undefined)}
                    onOkClick={onDelete}
                    itemForDeletion={documentTypeForDeletion.name}
                    inProgress={isDeleting}
                />
            )}
        </div>
    );
}

export default DocumentTypes;
