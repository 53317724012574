import React, { createContext, useState } from "react";
import AddTaskDialog, { AddTaskRelationData } from "../../../taskManagement/addTask/addTaskDialog/AddTaskDialog.tsx";
import EditTaskDialog from "../../../taskManagement/editTaskDialog/EditTaskDialog.tsx";
import { DotLegalOverflowMenuItem } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation.ts";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider.tsx";

export interface AddTaskMenItemModel {
    addCreateTaskMenuItem: (menuItems: Array<DotLegalOverflowMenuItem>, relationInformation: AddTaskRelationData) => void;
    registerRefetchFunc?: (refetchFunc: () => void) => void;
}

export const AddTaskMenuItemContext = createContext<AddTaskMenItemModel>({
    addCreateTaskMenuItem: (menuItems, relationInformation) => {},
    registerRefetchFunc: undefined,
});

export interface IAddTaskMenItemProviderProps {
    children: any;
}

export function AddTaskMenuItemProvider(props: IAddTaskMenItemProviderProps) {
    const [relatedData, setRelatedData] = useState<AddTaskRelationData | undefined>(undefined);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [createdTaskId, setCreatedTaskId] = useState<string | undefined>(undefined);
    const [refetchFunction, setRefetchFunction] = useState<() => void>();

    const { translateString } = useTranslation();
    const { permissions } = useUserContext();

    const data: AddTaskMenItemModel = {
        addCreateTaskMenuItem: (menuItems, relationInformation) => {
            if (permissions.taskPermissions.create) {
                menuItems.push({
                    menuItem: translateString("createTask"),
                    onClick: () => {
                        setShowAddDialog(true);
                        setRelatedData(relationInformation);
                    },
                });
            }
        },
        registerRefetchFunc: (refetchFunc) => {
            setRefetchFunction(() => refetchFunc);
        },
    };

    const resetDialogs = () => {
        setCreatedTaskId(undefined);
        setShowAddDialog(false);
        setRelatedData(undefined);
    };

    return (
        <AddTaskMenuItemContext.Provider value={data}>
            {props.children}

            {showAddDialog && !createdTaskId && (
                <AddTaskDialog
                    relationInformation={relatedData}
                    onDialogClose={() => {
                        resetDialogs();
                    }}
                    onTaskCreated={(taskId) => {
                        setCreatedTaskId(taskId);
                    }}
                />
            )}

            {createdTaskId && (
                <EditTaskDialog
                    setShowDeleteDialog={() => {}}
                    refetchTasks={() => {
                        if (refetchFunction) {
                            refetchFunction();
                        }
                    }}
                    taskId={createdTaskId!}
                    setSelectedTask={() => resetDialogs()}
                    setYearByTaskYear={() => {}}
                />
            )}
        </AddTaskMenuItemContext.Provider>
    );
}

export default AddTaskMenuItemProvider;
