import { Avatar, Box, IconButton } from "@mui/material";
import React, { createRef, useLayoutEffect, useState } from "react";
import { DotLegalChip, DotLegalHeader, DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import { BusinessAreaDiplayViewModel } from "../../masterData/businessArea/businessAreas/BusinessAreas.types";
import { getTaskColor, useTaskStyles } from "./Task.styles";
import { useTranslation } from "../../localization/useTranslation";
import Calendar from "./calender.svg?react";
import Unassigned from "./unassigned.svg?react";
import CheckedCheckboxIcon from "./subTaskCompleted.svg?react";
import GreenArrowRight from "../../common/svgs/green-arrow-right.svg?react";
import { AnnualWheelMonthEnum, TaskLaneStateEnum, TaskRawStateEnum } from "../../annualWheel/AnnualWheel.types";
import { highRiskIcon, lowRiskIcon, moderateRiskIcon, reallyHighRiskIcon, reallyLowRiskIcon } from "../../riskAssessment/riskIndicator/riskIcons";
import { AnnualWheelPriority } from "../../annualWheel/annualWheelDialog/AnnualWheelDialog.types";
import { useUrlProvider } from "../../useUrlProvider";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink";
import { SelectableItem } from "../../processingActivity/ProcessingActivity.types";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { TaskResponsibleViewModel } from "../TaskManagement.types";

export interface ITaskProps {
    activityName: string;
    taskName: string;
    description?: string;
    businessAreas: Array<BusinessAreaDiplayViewModel>;
    responsibles: Array<TaskResponsibleViewModel>;
    month: AnnualWheelMonthEnum;
    year: number;
    subTaskCompleted: number;
    totalSubTasks: number;
    taskStatus: TaskLaneStateEnum;
    isOverdue: boolean;
    priority: AnnualWheelPriority;
    customId?: string;
    onClick: () => void;
    processingActivtiyId: string | null;
    areas: Array<SelectableItem> | undefined;
    isReadyForApproval: boolean;
}

export const getTaskDeadLine = (styles: any, translateShortMonth: any, month: number, year: number) => {
    return (
        <Box sx={styles.gridItemsContainer}>
            <Calendar />
            <Box sx={styles.text}>
                {translateShortMonth(Number(month) - 1)} {year}
            </Box>
        </Box>
    );
};

const getReponsibleToShow = (responsibles: Array<TaskResponsibleViewModel>) => {
    return responsibles?.find((x) => x.isResponsible) ?? responsibles[0];
};

const getResponsibleName = (responsibles: Array<TaskResponsibleViewModel>, translateString: any) => {
    if (responsibles.length > 0) {
        const responsible = getReponsibleToShow(responsibles);
        let value = responsible ? `${responsible.firstName} ${responsible.lastName}` : `${responsibles[0].firstName} ${responsibles[0].lastName}`;

        return (
            <Box component={"span"}>
                {value}
                <Box component={"span"} sx={{ fontWeight: 700 }}>
                    {responsibles.length > 1 ? ` +${responsibles.length - 1}` : ""}
                </Box>
            </Box>
        );
    }

    return translateString("noResponsible");
};

export const getTaskResponsible = (responsibles: Array<TaskResponsibleViewModel>, styles: any, translateString: any) => {
    const responsible = getReponsibleToShow(responsibles);
    return (
        <Box sx={styles.gridItemsContainer}>
            {responsible ? (
                <Box sx={{ position: "relative" }}>
                    <Avatar sx={{ border: "1px solid #7284fa", backgroundColor: "#eeeef9", color: "#7284fa", height: 20, width: 20, fontSize: 11 }}>
                        {responsible.firstName.charAt(0).toUpperCase()}
                        {responsible.lastName.charAt(0).toUpperCase()}
                    </Avatar>
                </Box>
            ) : (
                <Box sx={{ display: "flex", "& svg": { height: 20, width: 20 } }}>
                    <Unassigned />
                </Box>
            )}
            <Box sx={styles.text}>{getResponsibleName(responsibles, translateString)}</Box>
        </Box>
    );
};

export const getTaskSubTasks = (styles: any, subTaskCompleted: number, totalSubTasks: number) => {
    return (
        <Box sx={styles.gridItemsContainer}>
            <CheckedCheckboxIcon />
            <Box sx={styles.text}>
                {subTaskCompleted}/{totalSubTasks}
            </Box>
        </Box>
    );
};

export const getRiskIconAndHover = (priority: AnnualWheelPriority, translateString: any) => {
    switch (priority) {
        case 1:
            return { icon: reallyLowRiskIcon, iconHoverText: translateString("veryLow") };
        case 2:
            return { icon: lowRiskIcon, iconHoverText: translateString("low") };
        case 3:
            return { icon: moderateRiskIcon, iconHoverText: translateString("moderate") };
        case 4:
            return { icon: highRiskIcon, iconHoverText: translateString("high") };
        case 5:
            return { icon: reallyHighRiskIcon, iconHoverText: translateString("veryHigh") };
        default:
            return { icon: <></>, iconHoverText: "" };
    }
};

export function getRiskIcon(priority: AnnualWheelPriority, translateString: any, styles: any) {
    const iconInformation = getRiskIconAndHover(priority, translateString);

    return (
        <DotLegalTooltip text={iconInformation.iconHoverText}>
            <Box sx={styles.priorityIcon}>{iconInformation.icon}</Box>
        </DotLegalTooltip>
    );
}

function Task(props: ITaskProps) {
    const styles = useTaskStyles(props.taskStatus, props.isOverdue);
    const ref: React.RefObject<HTMLDivElement> = createRef();
    const [showTooltip, setShowTooltip] = useState(false);
    const { translateString, translateShortMonth } = useTranslation();
    const { getProcessingActivityOverviewUrl } = useUrlProvider();
    const { platformFeatures: platform } = usePlanContext();

    useLayoutEffect(() => {
        if (ref?.current) {
            if (ref.current!.offsetHeight < ref.current!.scrollHeight) {
                setShowTooltip(true);
            } else {
                setShowTooltip(false);
            }
        }
    }, [ref]);

    return (
        <DotLegalPaper sx={styles.root}>
            <Box
                sx={(_) => ({
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    minHeight: "55px",
                })}
            >
                <Box
                    sx={(theme) => ({
                        backgroundColor: getTaskColor(props.isReadyForApproval ? TaskRawStateEnum.ReadyForApproval : props.taskStatus, theme, props.isOverdue),
                        marginRight: theme.spacing(2),
                        borderRadius: "12px",
                        paddingRight: "6px",
                    })}
                />

                <Box sx={{ display: "flex", flexDirection: "column", flex: 1, "& > .MuiBox-root": { marginTop: "2px", marginBottom: "2px" } }}>
                    <Box sx={styles.headerContainer}>
                        <Box sx={styles.statusAndHeader}>
                            {props.customId && (
                                <Box sx={{ alignSelf: "flex-start", marginRight: "10px" }}>
                                    <DotLegalHeader marginBottom={0} headerStyle={"small"}>
                                        {props.customId}&nbsp;
                                    </DotLegalHeader>
                                </Box>
                            )}

                            <DotLegalHeader onClick={props.onClick} hoverable marginBottom={0} headerStyle={"small"} fontWeight={400}>
                                {props.activityName}
                            </DotLegalHeader>
                        </Box>
                        <Box>{getRiskIcon(props.priority, translateString, styles)}</Box>
                    </Box>
                    {props.taskName && (
                        <DotLegalHeader fontWeight={700} marginBottom={0.5} color="primary" headerStyle={"extraSmall"}>
                            {props.processingActivtiyId !== null ? (
                                <DotLegalLink linkColor="primary" to={getProcessingActivityOverviewUrl(props.processingActivtiyId!)}>
                                    {props.taskName}
                                </DotLegalLink>
                            ) : (
                                <Box
                                    sx={(theme) => ({
                                        color: theme.palette.primary.main,
                                    })}
                                    id="test"
                                >
                                    {props.taskName}
                                </Box>
                            )}
                        </DotLegalHeader>
                    )}
                    {props.description && (
                        <Box>
                            <DotLegalTooltip text={props.description ?? ""} disableHoverlistener={!showTooltip}>
                                <Box ref={ref} sx={styles.description}>
                                    {props.description}
                                </Box>
                            </DotLegalTooltip>
                        </Box>
                    )}
                    {platform.annualWheelComplianceArea && props.areas && (
                        <Box sx={{ display: "flex", gap: 1 }}>
                            {props.areas?.map((x) => (
                                <Box
                                    key={x.id}
                                    sx={(theme) => ({
                                        border: 1,
                                        paddingRight: 0.5,
                                        paddingLeft: 0.5,
                                        color: theme.palette.primary.main,
                                        borderRadius: "8px",
                                        marginTop: 0.5,
                                        marginBottom: 0.5,
                                    })}
                                >
                                    <DotLegalHeader color="secondary" fontWeight={500} headerStyle={"small"} marginBottom={0}>
                                        {x.name}
                                    </DotLegalHeader>
                                </Box>
                            ))}
                        </Box>
                    )}
                    {props.businessAreas.length > 0 && (
                        <Box sx={styles.businessAreasContainer}>
                            {props.businessAreas.map((ba) => {
                                return (
                                    <Box key={ba.id} sx={{ display: "inline-block", marginRight: 0.5, marginBottom: 0.5 }}>
                                        <DotLegalChip value={ba.name} color={ba.color} size="small" />
                                    </Box>
                                );
                            })}
                        </Box>
                    )}
                    <Box sx={styles.gridContainer}>
                        <Box>{getTaskDeadLine(styles, translateShortMonth, props.month, props.year)}</Box>

                        <Box>{getTaskResponsible(props.responsibles, styles, translateString)}</Box>

                        <Box sx={styles.taskContainter}>{props.totalSubTasks > 0 && getTaskSubTasks(styles, props.subTaskCompleted, props.totalSubTasks)}</Box>

                        <Box sx={styles.greenArrowContainer}>
                            <IconButton onClick={props.onClick} sx={styles.greenArrow}>
                                <GreenArrowRight />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </DotLegalPaper>
    );
}

export default Task;
