import { EvaluationStatus } from "../../common/components/questionnaireComponents/evaluateQuestionnaireDialog/EvaluateQuestionnaireDialog.types";
import { LegalEntityMasterType } from "../../legalEntity/legalEntities/LegalEntities.types";
import { LegalEntityAuditStatus } from "../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types";
import { RiskLevel } from "../../legalEntity/legalEntityClassificationTab/LegalEntityClassificationTab.types";
import { LegalEntityContactType } from "../../legalEntity/legalEntityContactTab/LegalEntityContactTab.types";
import { BaseViewModel } from "../../system/systemOverview/SystemOverview.types";
import { LegalEntityClassificationInformationViewModel } from "../legalEntityAuditGroupDraftOverview/auditGroupLegalEntitySelector/AuditGroupLegalEntitySelector.types";

export interface LegalEntityAuditGroupOverviewModel {
    id: string;
    name: string;
    description?: string;
    sent?: Date;
    auditTemplate: string;
    auditTemplateId: string;
    auditTemplateVersion: number;
    deadline: Date;
    responsible: string;
    responsibleId: string;
    sender?: string;
    senderId?: string;
    openedAuditsCount: number;
    submittedAuditsCount: number;
    excludedAuditsCount: number;
    progress: number;
    numberOfLegalEntities: number;
    overallRiskLevel: RiskLevel | undefined;
    overallRiskLevelName: string | undefined;
    overallRiskScore: number | undefined;
    scoreEnabled: boolean;
    audits: Array<LegalEntityAuditGroupAuditModel>;
}

export interface LegalEntityAuditGroupAuditModel {
    id: string;
    legalEntityId: string;
    legalEntityName: string;
    uniqueIdentifier: string;
    legalEntityTypes: Array<LegalEntityMasterType>;
    assets: Array<BaseViewModel>;
    status: LegalEntityAuditStatus;
    scoreEnabled: boolean;
    score: number;
    riskLevel: RiskLevel | undefined;
    riskName: string | undefined;
    contactId: string;
    contact: string;
    contactTypes: Array<LegalEntityContactType>;
    contactEmail: string;
    numberOfQuestionsAnswered: number;
    totalNumberOfQuestions: number;
    lastestResponseChange: Date | undefined;
    showQuestionsStatistics: boolean;
    note: string | undefined;
    report: string;
    questionnaireName: string;
    sender: string;
    deadline: Date;
    questionnaireResponseId: string;
    questionnaireId: string;
    responseExternalId: string;
    closed: boolean;
    classifications: Array<LegalEntityClassificationInformationViewModel>;
    reminderSent: Date | null;
    remindersPaused: boolean;
    hasRecommendations: boolean;
    evaluationStatus?: EvaluationStatus;
    evaluation: string | undefined;
    partOfCurrentPlan: boolean;
}

export interface LegalEntityAuditGroupAuditTableModel extends LegalEntityAuditGroupAuditModel {
    assetsString: string;
    contactTypesString: string;
    auditStatusString: string;
    scoreString?: string;
    legalEntityTypeString?: string;
    reminderSentString?: string;
    evaluationName?: string;
}

export enum AuditGroupTabs {
    Audits = 1,
    Tasks = 2,
}
