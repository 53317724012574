import { Box, Divider, SxProps, Theme } from "@mui/material";
import { DotLegalButton, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import DotLegalPersonalDataSelector from "../../../../common/components/dotLegalPersonalDataSelector/DotLegalPersonalDataSelector";
import DotLegalStepHeader from "../../../../common/components/dotLegalStepHeader/DotLegalStepHeader";
import { ValidationError } from "../../../../common/validationError";
import { useTranslation } from "../../../../localization/useTranslation";
import {
    DataCategoryDataTypes,
    SelectableDataCategoryItem,
    SelectableDocumentItem,
    SelectableItem,
    SelectableLegalUnitsItem,
    SubProcessor,
} from "../../../ProcessingActivity.types";
import SegmentStep from "../../../stepSegment/StepSegment";
import { useSubProcessorStepStyles } from "./SubProcessor.styles";
import React, { useState } from "react";
import { dataContainedInThirdCountry } from "../../../../legalEntity/isInsecureThirdCountry";
import { DataProcessor } from "./SubProcessorComponent.types";
import SharingsLegalEntity from "../../sharingsLegalEntityBox/sharingsLegalEntity";
import { filterDocumentDataByCompanyId, getSelectableTiaDocumentItems, getSelectedDocument } from "../../../hooks/useDataProcessingAgreement";
import { useUserContext } from "../../../../auth/userContextProvider/UserContextProvider";
import { getStepPermissions } from "../DataProcessorsStep.hooks";
import { EUToUSDataPrivacyFrameworkId, filterTransferBasis } from "../../../../legalBasis/TransferBasis.Helper";
import { DotLegalSelectOption } from "../../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { dotlegalLinkIcon } from "../../../../common/icons";
import { Link } from "react-router-dom";
import { mapToDataCategorySelectorModel } from "../../sharingOfDataStepFunctions.ts";

export interface ISubProcessorComponent {
    groupEntityIsDataProcessor: boolean;
    subProcessorIndex: number;
    isReadOnly: boolean;
    subProcessor: SubProcessor;
    dataProcessor: DataProcessor;
    isLegalEntityLoading: boolean;
    documentsLoading: boolean;
    documentsData: Array<SelectableDocumentItem> | undefined;
    legalEntityData?: SelectableLegalUnitsItem[];
    isTransferBasisLoading: boolean;
    transferBasisData: SelectableItem[] | undefined;
    validationErrors: Array<ValidationError>;
    onSubProcessorTransferBasisChange?: (transferBasis: string | null, dataProcessorId: string, index: number) => void;
    onRemoveSubDataProcessor?: (dataProcessorId: string, subprocessorIndex: number) => void;
    onSubProcessorAgreementChange?: (hasAgreement: boolean, dataProcessorId: string, subprocessorInde: number) => void;
    onSubDataProcessorChange?: (
        processorId: string | null,
        dataProcessorId: string,
        subprocessorInde: number,
        legalEntity: SelectableLegalUnitsItem | undefined
    ) => void;
    isDataCategoryLoading: boolean;
    dataCategoriesData: SelectableDataCategoryItem[] | undefined;
    onSubProcessorDataCategoryChange: (chosenCategories: Array<DataCategoryDataTypes>, dataProcessorId: string, subprocessorIndex: number) => void;
    key?: React.Key | null;
    onSubProcessorTransferImpactAssessmentChange?: (tia: string | null, dataProcessorId: string, index: number) => void;
    countriesLoading?: boolean;
    countryList?: Array<DotLegalSelectOption>;
    onSubProcessorStorageLocationChange?: (
        storageLocations: Array<string>,
        supportLocations: Array<string>,
        dataProcessorId: string,
        subprocessorIndex: number,
        dataContainedInThirdCountry: boolean
    ) => void;
    thirdCountries: Array<string>;
    onLinkChange: (link: string, dataProcessorId: string, index: number) => void;
    shouldAlwaysDisableLink?: boolean;
}

function SubProcessorComponent(props: ISubProcessorComponent) {
    const { translateString } = useTranslation();
    const styles = useSubProcessorStepStyles();
    const [onHover, setOnHover] = useState(false);
    const { permissions } = useUserContext();

    const GetContainerClass = () => {
        let containerClass: SxProps<Theme> = [styles.subProcessorContainer];

        if (onHover && Array.isArray(containerClass)) containerClass.push(styles.onRemoveHover);

        return containerClass;
    };

    const isDisabled = () => {
        return props.dataProcessor.isSystemGenerated || props.isReadOnly;
    };

    const userPermissions = getStepPermissions(props.groupEntityIsDataProcessor, permissions);
    const selectedDataProcessor = props.legalEntityData?.find((x) => x.id === props.subProcessor.processorId);
    const subProcessorIsThirdCountry = dataContainedInThirdCountry(props.thirdCountries, props.subProcessor.dataStorageLocations, selectedDataProcessor);
    const showTia =
        subProcessorIsThirdCountry && selectedDataProcessor?.isTIAUnnecessary === false && props.subProcessor.transferBasisId !== EUToUSDataPrivacyFrameworkId;

    const showTransferBasis = subProcessorIsThirdCountry && selectedDataProcessor?.isTransferBasisUnnecessary === false;

    return (
        <React.Fragment>
            {props.subProcessorIndex === 0 && <Divider />}
            <Box sx={GetContainerClass()} key={props.subProcessorIndex}>
                <DotLegalStepHeader record={false}>
                    {props.subProcessor.isLink ? translateString("linkToSubDataProcessors") : translateString("whoIsSubProcessor")}
                </DotLegalStepHeader>
                <Box justifyContent="center" display="flex">
                    <SegmentStep size="small">
                        {props.subProcessor.isLink ? (
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Box sx={{ width: "100%" }}>
                                    <DotLegalTextField
                                        disabled={props.shouldAlwaysDisableLink || props.isReadOnly}
                                        label={translateString("link")}
                                        value={props.subProcessor.link ?? ""}
                                        debounce
                                        onChange={(value) => props.onLinkChange(value, props.dataProcessor.id, props.subProcessorIndex)}
                                    />
                                </Box>
                                <Box sx={{ marginLeft: "10px;", marginTop: "10px", display: "flex" }}>
                                    {props.subProcessor.link && (
                                        <Link to={props.subProcessor.link} target={"_blank"}>
                                            {dotlegalLinkIcon}
                                        </Link>
                                    )}
                                </Box>
                            </Box>
                        ) : (
                            <Box>
                                <div style={{ position: "relative" }}>
                                    <SharingsLegalEntity
                                        showAddNewEntityButton={!(props.dataProcessor.isSystemGenerated || props.isReadOnly)}
                                        errorText={
                                            props.validationErrors.find(
                                                (v) => v.field === `${props.dataProcessor.id},${props.subProcessorIndex}dataProcessorId`
                                            )?.error
                                        }
                                        selectedItem={props.subProcessor.processorId}
                                        isLoading={props.isLegalEntityLoading}
                                        disabled={isDisabled()}
                                        label={translateString("subDataProcessor")}
                                        placeholder={translateString("subDataProcessor")}
                                        onLegalEntityChange={(subProcessorId) =>
                                            props.onSubDataProcessorChange?.(
                                                subProcessorId,
                                                props.dataProcessor.id,
                                                props.subProcessorIndex,
                                                props.legalEntityData?.find((x) => x.id === subProcessorId)
                                            )
                                        }
                                        options={props.legalEntityData}
                                        toolTipText={
                                            isDisabled()
                                                ? undefined
                                                : translateString(
                                                      props.groupEntityIsDataProcessor ? "subProcessorControllerHelperText" : "subProcessorHelperText"
                                                  )
                                        }
                                        isThirdCountry={!isDisabled() && subProcessorIsThirdCountry}
                                        countriesLoading={props.countriesLoading}
                                        countryList={props.countryList}
                                        showStorageLocationLink
                                        onDataStorageLocationSave={(storageLocations: Array<string>, supportLocations: Array<string>) => {
                                            const entity = props.legalEntityData?.find((x) => x.id === props.subProcessor.processorId);

                                            props.onSubProcessorStorageLocationChange!(
                                                storageLocations,
                                                supportLocations,
                                                props.dataProcessor.id,
                                                props.subProcessorIndex,
                                                dataContainedInThirdCountry(props.thirdCountries, storageLocations, entity)
                                            );
                                        }}
                                        selectedStorageLocations={props.subProcessor.dataStorageLocations}
                                        selectedSupportLocations={props.subProcessor.supportLocations}
                                        warningText={
                                            props.subProcessor.dataStorageLocations.length > 0 ? translateString("dataStoredInThirdCountry") : undefined
                                        }
                                    />
                                </div>

                                {userPermissions.hasTiaAndTransferBasisPermission && (
                                    <React.Fragment>
                                        {showTransferBasis && (
                                            <DotLegalSelect
                                                selectedItem={props.subProcessor.transferBasisId}
                                                isLoading={props.isTransferBasisLoading}
                                                disabled={isDisabled()}
                                                label={translateString("transferBasis")}
                                                placeholder={translateString("transferBasis")}
                                                options={filterTransferBasis(props.transferBasisData, selectedDataProcessor?.countryCode)}
                                                toolTipText={isDisabled() ? undefined : translateString("subprocessorSpecifyTransferBasis")}
                                                errorText={
                                                    props.validationErrors.find(
                                                        (v) => v.field === `${props.dataProcessor.id},${props.subProcessorIndex}transferBasisId`
                                                    )?.error
                                                }
                                                onChange={(transferBasis) =>
                                                    props.onSubProcessorTransferBasisChange?.(transferBasis, props.dataProcessor.id, props.subProcessorIndex)
                                                }
                                                noOptionsLabel={translateString("noOptions")}
                                            />
                                        )}

                                        {showTia && (
                                            <DotLegalSelect
                                                disableClearable
                                                selectedItem={getSelectedDocument(
                                                    props.subProcessor.transferImpactAssessmentId,
                                                    props.subProcessor.hasTransferImpactAssessment,
                                                    props.subProcessor.containsNotAccessibleTia
                                                )}
                                                isLoading={props.documentsLoading}
                                                disabled={isDisabled()}
                                                label={translateString("tia")}
                                                placeholder={translateString("tia")}
                                                options={getSelectableTiaDocumentItems(
                                                    filterDocumentDataByCompanyId(props.documentsData, props.subProcessor.processorId),
                                                    translateString,
                                                    props.subProcessor.hasTransferImpactAssessment,
                                                    props.subProcessor.containsNotAccessibleTia
                                                )}
                                                toolTipText={translateString("tiaTooltip")}
                                                errorText={
                                                    props.validationErrors.find(
                                                        (v) => v.field === `${props.dataProcessor.id},${props.subProcessorIndex}transferImpactAssessment`
                                                    )?.error
                                                }
                                                onChange={(tia) =>
                                                    props.onSubProcessorTransferImpactAssessmentChange?.(tia, props.dataProcessor.id, props.subProcessorIndex)
                                                }
                                                noOptionsLabel={translateString("noOptions")}
                                            />
                                        )}
                                    </React.Fragment>
                                )}

                                {userPermissions.hasAgreementPermission && (
                                    <DotLegalSelect
                                        selectedItem={props.subProcessor?.subProcessHasAgreement?.toString()}
                                        label={translateString("dataProcessAgreement")}
                                        disabled={isDisabled()}
                                        placeholder={translateString("dataProcessAgreement")}
                                        disableClearable
                                        onChange={(option) =>
                                            props.onSubProcessorAgreementChange?.(option === "true", props.dataProcessor.id, props.subProcessorIndex)
                                        }
                                        options={[
                                            {
                                                name: translateString("yes"),
                                                id: "true",
                                            },
                                            { name: translateString("no"), id: "false" },
                                        ]}
                                        toolTipText={
                                            isDisabled()
                                                ? undefined
                                                : translateString(
                                                      props.groupEntityIsDataProcessor ? "sharingAgreementControllerHelperText" : "sharingAgreementHelperText"
                                                  )
                                        }
                                        noOptionsLabel={translateString("noOptions")}
                                    />
                                )}
                            </Box>
                        )}
                    </SegmentStep>
                </Box>

                {!props.subProcessor.isLink && userPermissions.hasDataCategoriesPermissions && (
                    <React.Fragment>
                        <DotLegalStepHeader
                            toolTipText={props.dataProcessor.isSystemGenerated ? "" : translateString("dataSharedHelperText")}
                            record
                            subHeader={props.dataProcessor.isSystemGenerated ? "" : translateString("noDataCategoiresOnProcess")}
                            topMargin
                            bottomMargin
                        >
                            {translateString("dataTypeSent")}
                        </DotLegalStepHeader>
                        {!props.isDataCategoryLoading && (
                            <DotLegalPersonalDataSelector
                                activityDataCategories={mapToDataCategorySelectorModel(
                                    props.dataCategoriesData!,
                                    props.dataProcessor.dataCategories,
                                    props.subProcessor.dataCategories
                                )}
                                onChange={(data) => props.onSubProcessorDataCategoryChange(data, props.dataProcessor.id, props.subProcessorIndex)}
                                disabled={props.isReadOnly}
                                errorText={
                                    props.validationErrors.find((v) => v.field === `${props.dataProcessor.id},${props.subProcessorIndex}dataCategories`)?.error
                                }
                            />
                        )}
                    </React.Fragment>
                )}

                {!props.dataProcessor.isSystemGenerated &&
                    !props.dataProcessor.readOnly &&
                    !props.isReadOnly &&
                    ((props.groupEntityIsDataProcessor && props.dataProcessor.createdAsGroupEntityIsDataProcessor) ||
                        (!props.groupEntityIsDataProcessor && !props.dataProcessor.createdAsGroupEntityIsDataProcessor)) && (
                        <Box sx={styles.removeSubproces}>
                            <DotLegalButton
                                onMouseHover={setOnHover}
                                buttonType="danger"
                                onClick={() => props.onRemoveSubDataProcessor?.(props.dataProcessor.id, props.subProcessorIndex)}
                            >
                                {translateString("removeSubProcessor")}
                            </DotLegalButton>
                        </Box>
                    )}
            </Box>
            <Divider />
        </React.Fragment>
    );
}

export default SubProcessorComponent;
