import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import { useTranslation } from "../../../localization/useTranslation";
import { SwitchNumbersCount, TasksWidgetProps } from "../../tasksWidget/TaskWidget";
import Widget from "../../Widget";
import TasksWidgetStatus from "../../tasksWidget/tasksWidgetStatus/TasksWidgetStatus";
import TasksWidgetReportTask from "./tasksWidgetReportTask/TasksWidgetReportTask";
import TaskWidgetEmptyState from "../../tasksWidget/taskWidgetEmptyState/TaskWidgetEmptyState";
import { useTasksWidget } from "../../tasksWidget/TaskWidget.hooks";

function TasksWidgetReport(props: TasksWidgetProps) {
    const { translateString } = useTranslation();

    const { tasks, progress, isEnabled, numberOfOverdueTasks, numberOfTasksThisMonth, noOverdueTaskStreakInMonths } = useTasksWidget(props);

    return (
        <Widget disabled={!isEnabled} isPdf>
            <DotLegalHeader headerStyle={"medium"} marginBottom={0}>
                {translateString("tasks").toUpperCase()}
            </DotLegalHeader>
            <DotLegalHeader headerStyle={"small"}>
                <>
                    {props.showCurrentMonth ? translateString("thisMonth") : translateString("overdue")}
                    <SwitchNumbersCount>{props.showCurrentMonth ? numberOfTasksThisMonth : numberOfOverdueTasks}</SwitchNumbersCount>
                </>
            </DotLegalHeader>
            <TasksWidgetStatus progress={progress} numberOfTasks={tasks.length} showCurrentMonth={props.showCurrentMonth ?? false} />

            {tasks.length > 0 ? (
                <Box
                    mt={2}
                    sx={{
                        marginRight: `-15px`,
                        height: "100%",
                        "& .MuiPaper-root": {
                            boxShadow: "none",
                        },
                    }}
                >
                    <Box sx={{ marginTop: 2, display: "flex", flexDirection: "column", gap: 2, pr: 2 }}>
                        {tasks.map((task) => {
                            return (
                                <TasksWidgetReportTask
                                    activityName={task.name}
                                    taskName={task.processingActivityName ?? ""}
                                    responsibles={task.responsibles}
                                    month={new Date(task.deadline).getMonth() + 1}
                                    year={new Date(task.deadline).getFullYear()}
                                    subTaskCompleted={task.completedSubTasks}
                                    totalSubTasks={task.totalSubTasks}
                                    taskStatus={task.laneState}
                                    isOverdue={task.isOverdue}
                                    priority={task.priority}
                                    customId={task.customId}
                                    isReadyForApproval={task.isReadyForApproval}
                                    key={task.id}
                                />
                            );
                        })}
                    </Box>
                </Box>
            ) : (
                <TaskWidgetEmptyState showCurrentMonth={props.showCurrentMonth ?? false} noOverdueTaskStreakInMonths={noOverdueTaskStreakInMonths} />
            )}
        </Widget>
    );
}

export default TasksWidgetReport;
