import {
    DotLegalActionBar,
    DotLegalButton,
    DotLegalCompletedPercentage,
    DotLegalEmptyState,
    DotLegalInformationTooltip,
    DotLegalMultiSelect,
    DotLegalOverflowMenu,
    DotLegalPageHeader,
    DotLegalPaper,
    DotLegalTableWithControls,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { useTranslation } from "../../localization/useTranslation";
import ExternalAuditEmptyStateIcon from "../externalAuditsEmptyState.svg?react";
import ExternalAuditCreatPaperIcon from "../externalAudits.svg?react";
import { Box, Grid, GridSize, styled, TableCell, TableRow } from "@mui/material";
import CreateLegalEntityAuditGroupDialog from "../createLegalEntityAuditGroupDialog/CreateLegalEntityAuditGroupDialog";
import { useLegalEntityAuditGroupsHooks } from "./LegalEntityAuditGroups.hooks";
import { LegalEntityAuditGroupRow, LegalEntityAuditGroupRowTableModel } from "./LegalEntityAuditGroups.types";
import { WhiteLockIcon } from "../../common/icons";
import { useNavigate } from "react-router-dom";
import { useUrlProvider } from "../../useUrlProvider";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink.tsx";
import { usePlanContext } from "../../auth/planProvider/PlanProvider.tsx";
import PlanDialogButtonWrapper from "../../plan/planDialogButtonWrapper/planDialogButtonWrapper.tsx";
import { EntityType } from "../../common/Common.types.ts";
import { useAddTaskMenuItem } from "../../common/components/addTaskMenuItem/AddTaskMenuItemContext.hooks.ts";

const NumberTableCell = styled(TableCell)(({ theme }) => ({
    fontSize: "18px !important",
    fontWeight: theme.typography.fontWeightBold,
}));

function LegalEntityAuditGroups() {
    const navigate = useNavigate();
    const addTaskMenuItem = useAddTaskMenuItem();
    const { getAuditGroupDraftUrl, getAuditGroupUrl } = useUrlProvider();
    const { customerName } = useUserContext();
    const { vendorManagementFeatures } = usePlanContext();
    const { translateString, translateDate } = useTranslation();
    const {
        setShowCreateDialog,
        showCreateDialog,
        isLoading,
        rows,
        hasData,
        searchedAuditTemplate,
        searchedDeadline,
        searchedResponsibles,
        setSearchedAuditTemplate,
        setSearchedDeadline,
        setSearchedResponsible,
        auditTemplateFilterOptions,
        deadlineFilterOptions,
        responsibleFilterOptions,
        auditGroupToEdit,
        setAuditGroupToEdit,
        refetchAuditGroups,
    } = useLegalEntityAuditGroupsHooks();

    function getHeaders() {
        let headers = Array<ITableHeader<LegalEntityAuditGroupRowTableModel>>();
        headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
        headers.push({ property: "questionnaire", text: translateString("auditTemplate"), align: "left", showOnMobile: true });
        headers.push({ property: "sent", text: translateString("sent"), align: "left", showOnMobile: true });
        headers.push({ property: "deadline", text: translateString("deadline"), align: "left", showOnMobile: true });
        headers.push({ property: "responsible", text: translateString("responsible"), align: "left", showOnMobile: true });
        headers.push({ property: "sender", text: translateString("sentFrom"), align: "left", showOnMobile: true });
        headers.push({ property: "totalCount", text: translateString("legalEntities"), align: "left", showOnMobile: true });
        headers.push({ property: "openedCount", text: translateString("opened"), align: "left", showOnMobile: true });
        headers.push({ property: "submittedCount", text: translateString("submitted"), align: "left", showOnMobile: true });
        headers.push({ property: "excludedCount", text: translateString("excluded"), align: "left", showOnMobile: true });
        headers.push({ property: "progress", text: translateString("progress"), align: "left", showOnMobile: true });
        headers.push({ property: "responsibleString", text: "", align: "left", showOnMobile: true, hideHeader: true });
        headers.push({ property: "auditTemplateString", text: "", align: "left", showOnMobile: true, hideHeader: true });

        return headers;
    }

    function getFilters() {
        const tableGridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize; xl: GridSize } = {
            item: true,
            xs: 12,
            sm: 6,
            md: 4,
            lg: 2,
            xl: 2,
        };

        return (
            <>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedAuditTemplate}
                        chosenOptions={searchedAuditTemplate}
                        label={translateString("auditTemplate")}
                        placeholder={translateString("auditTemplate")}
                        isLoading={isLoading}
                        options={auditTemplateFilterOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedDeadline}
                        chosenOptions={searchedDeadline}
                        label={translateString("deadline")}
                        placeholder={translateString("deadline")}
                        isLoading={isLoading}
                        options={deadlineFilterOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedResponsible}
                        chosenOptions={searchedResponsibles}
                        label={translateString("responsible")}
                        placeholder={translateString("responsible")}
                        isLoading={isLoading}
                        options={responsibleFilterOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
            </>
        );
    }

    const getAuditGroupUrlForRow = (auditGroupId: string, sent: Date | undefined) => {
        return sent ? getAuditGroupUrl(auditGroupId) : getAuditGroupDraftUrl(auditGroupId);
    };

    const getMenuItems = (row: LegalEntityAuditGroupRowTableModel) => {
        let menuItems = [];

        if (vendorManagementFeatures.auditGroups) {
            menuItems.push({
                menuItem: translateString("overview"),
                onClick: () => {
                    navigate(getAuditGroupUrlForRow(row.id, row.sent));
                },
            });

            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    setAuditGroupToEdit(row);
                },
            });

            menuItems.push({
                menuItem: translateString("addLegalEntity"),
                onClick: () => {
                    navigate(getAuditGroupDraftUrl(row.id!));
                },
            });

            addTaskMenuItem.addCreateTaskMenuItem(menuItems, {
                fromEntityId: row.id,
                fromEntityType: EntityType.LegalEntityAuditGroup,
                toEntityType: EntityType.Task,
            });
        }

        return menuItems;
    };

    return (
        <React.Fragment>
            <DotLegalPageHeader userContext={{ customerName }} breadCrumbs={[{ name: translateString("externalAudits") }]} />
            <DotLegalActionBar
                primaryButton={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <PlanDialogButtonWrapper enabled={!vendorManagementFeatures.auditGroups} text={translateString("upgradeVendorManagementPlan")}>
                            <DotLegalButton
                                buttonType="primary"
                                size="medium"
                                onClick={() => setShowCreateDialog(true)}
                                disabled={!vendorManagementFeatures.auditGroups}
                                endIcon={!vendorManagementFeatures.auditGroups ? WhiteLockIcon : undefined}
                                toolTip={!vendorManagementFeatures.auditGroups ? translateString("upgradeVendorManagementPlan") : undefined}
                            >
                                {translateString("createAuditGroup")}
                            </DotLegalButton>
                        </PlanDialogButtonWrapper>
                        <Box sx={{ display: "flex", ml: 1 }}>
                            <DotLegalInformationTooltip text={translateString("sendExternalAuditButtonTooltip")} />
                        </Box>
                    </Box>
                }
                showDialog={showCreateDialog}
                canCreate
                icon={<ExternalAuditCreatPaperIcon />}
            >
                <CreateLegalEntityAuditGroupDialog onDialogClose={() => setShowCreateDialog(false)} />
            </DotLegalActionBar>

            <DotLegalPaper>
                {hasData ? (
                    <DotLegalTableWithControls
                        extraControls={getFilters()}
                        headers={getHeaders()}
                        getUserSpecificPageLength={() => 30}
                        hideRowsPerPage
                        defaultOrderBy={"deadline"}
                        defaultOrder={"asc"}
                        isLoading={isLoading}
                        labelRowsPerPage={translateString("showEntites")}
                        labelSearch={translateString("search")}
                        noOptionsLabel={translateString("noOptions")}
                        paginationLabelOf={translateString("labelOf")}
                        emptyText={translateString("noData")}
                        data={mapToLegalEntityAuditGroupRowTableModel(rows)}
                        renderRow={(row) => (
                            <TableRow key={row.id} onClick={() => navigate(getAuditGroupUrlForRow(row.id, row.sent))}>
                                <TableCell>
                                    <DotLegalLink bold linkColor={"primary"} to={getAuditGroupUrlForRow(row.id, row.sent)} stopPropagation>
                                        {row.name}
                                    </DotLegalLink>
                                </TableCell>
                                <TableCell>{row.questionnaire.name}</TableCell>
                                <TableCell>{row.sentString}</TableCell>
                                <TableCell>{row.deadlineString}</TableCell>
                                <TableCell>{row.responsible.name}</TableCell>
                                <TableCell>{row.sender ? row.sender : customerName}</TableCell>
                                <NumberTableCell>{row.totalCount}</NumberTableCell>
                                <NumberTableCell>{row.openedCount}</NumberTableCell>
                                <NumberTableCell>{row.submittedCount}</NumberTableCell>
                                <NumberTableCell>{row.excludedCount}</NumberTableCell>
                                <TableCell>
                                    <Box sx={{ width: 238 }}>
                                        <DotLegalCompletedPercentage
                                            emptyText={row.isDraft ? translateString("draft") : undefined}
                                            completed={row.isDraft ? 0 : row.progress}
                                        />
                                    </Box>
                                </TableCell>
                                {vendorManagementFeatures.auditGroups && (
                                    <TableCell align={"right"}>
                                        <DotLegalOverflowMenu items={getMenuItems(row)} />
                                    </TableCell>
                                )}
                            </TableRow>
                        )}
                    />
                ) : (
                    <DotLegalEmptyState icon={<ExternalAuditEmptyStateIcon />} text={translateString("externalAuditsEmptystateText")} />
                )}
            </DotLegalPaper>

            {auditGroupToEdit && (
                <CreateLegalEntityAuditGroupDialog
                    onDialogClose={() => setAuditGroupToEdit(undefined)}
                    auditGroup={{
                        description: auditGroupToEdit.description,
                        name: auditGroupToEdit.name,
                        deadline: auditGroupToEdit.deadline,
                        groupEntityId: auditGroupToEdit.senderId,
                        questionnaireId: auditGroupToEdit.questionnaire.id,
                        questionnaireName: auditGroupToEdit.questionnaire.name,
                        questionnaireVersion: auditGroupToEdit.auditTemplateVersion,
                        responsibleId: auditGroupToEdit.responsible.id,
                    }}
                    onAuditGroupUpdated={() => {
                        setAuditGroupToEdit(undefined);
                        refetchAuditGroups();
                    }}
                    auditGroupId={auditGroupToEdit.id}
                    auditGroupInProgress={auditGroupToEdit.sent !== undefined && auditGroupToEdit.sent !== null}
                />
            )}
        </React.Fragment>
    );

    function mapToLegalEntityAuditGroupRowTableModel(rows: Array<LegalEntityAuditGroupRow>): Array<LegalEntityAuditGroupRowTableModel> {
        return rows.map((row) => {
            return {
                ...row,
                responsibleString: row.responsible.name,
                auditTemplateString: row.questionnaire.name,
                deadlineString: translateDate(row.deadline),
                sentString: row.sent ? translateDate(row.sent) : "",
            };
        });
    }
}

export default LegalEntityAuditGroups;
