import { getEnumValues } from "../../../../common/enumOperations";
import { AnnualWheelActivityTemplateItem, AnnualWheelActivityType } from "../../AnnualWheelDialog.types";

export enum ISOCategoryType {
    OrganizationsFrameworkAndConditions = 0,
    Leadership = 1,
    Planning = 2,
    Support = 3,
    Operation = 4,
    PerformanceEvaluation = 5,
    Improvement = 6,
    OrganisationalControls = 7,
    PeopleControls = 8,
    PhysicalControls = 9,
    TechnologicalControls = 10,
    InformationSecurityPolicies = 11,
    OrganisationOfInformationSecurity = 12,
    HumanResourceSecurity = 13,
    AssetManagement = 14,
    AccessControl = 15,
    Cryptography = 16,
    PhysicalAndEnvironmentalSecurity = 17,
    OperationsSecurity = 18,
    CommunicationsSecurity = 19,
    SystemAcquisitionDevelopmentAndMaintenance = 20,
    SupplierRelationships = 21,
    InformationSecurityIncidentManagement = 22,
    InformationSecurityAspectsOfBusinessContinuityManagement = 23,
    Compliance = 24,
}

export class ISOCategoryMapper {
    iso27001Templates: Array<ISOCategory> = [];
    iso27002Templates: Array<ISOCategory> = [];
    iso27002_2013Templates: Array<ISOCategory> = [];

    constructor() {
        this.onInit();
    }

    onInit() {
        getEnumValues(ISOCategoryType).forEach((type) => {
            switch (type) {
                case ISOCategoryType.OrganizationsFrameworkAndConditions:
                case ISOCategoryType.Leadership:
                case ISOCategoryType.Planning:
                case ISOCategoryType.Support:
                case ISOCategoryType.Operation:
                case ISOCategoryType.PerformanceEvaluation:
                case ISOCategoryType.Improvement:
                    this.iso27001Templates.push(new ISOCategory(type));
                    break;
                case ISOCategoryType.OrganisationalControls:
                case ISOCategoryType.PeopleControls:
                case ISOCategoryType.PhysicalControls:
                case ISOCategoryType.TechnologicalControls:
                    this.iso27002Templates.push(new ISOCategory(type));
                    break;
                default:
                    this.iso27002_2013Templates.push(new ISOCategory(type));
                    break;
            }
        });
    }

    setTemplates(templates: Array<AnnualWheelActivityTemplateItem>) {
        templates.forEach((x) => this.setTemplate(x));
    }

    setTemplate(template: AnnualWheelActivityTemplateItem) {
        switch (template.type) {
            case AnnualWheelActivityType.UnderstandingTheOrganisationAndItsContext:
            case AnnualWheelActivityType.UnderstandingTheNeedsAndExpectationsOfInterestedParties:
            case AnnualWheelActivityType.DeterminingTheScopeOfTheInformationSecurityManagementSystem:
            case AnnualWheelActivityType.InformationSecurityManagementSystem:
                this.addISO27001Templates(ISOCategoryType.OrganizationsFrameworkAndConditions, template);
                break;
            case AnnualWheelActivityType.LeadershipAndCommitment:
            case AnnualWheelActivityType.Policy:
            case AnnualWheelActivityType.OrganisationalRolesResponsibilitiesAndAuthorities:
                this.addISO27001Templates(ISOCategoryType.Leadership, template);
                break;
            case AnnualWheelActivityType.GeneralActionsToAddressRisksAndOpportunities:
            case AnnualWheelActivityType.PlanningInformationSecurityRiskAssessment:
            case AnnualWheelActivityType.PlanningInformationSecurityRiskTreatment:
            case AnnualWheelActivityType.InformationSecurityObjectivesAndPlanningToAchieveThem:
            case AnnualWheelActivityType.PlanningOfChanges:
                this.addISO27001Templates(ISOCategoryType.Planning, template);
                break;
            case AnnualWheelActivityType.Resources:
            case AnnualWheelActivityType.Competence:
            case AnnualWheelActivityType.Awareness:
            case AnnualWheelActivityType.Communication:
            case AnnualWheelActivityType.GeneralDocumentedInformation:
            case AnnualWheelActivityType.CreatingAndUpdating:
            case AnnualWheelActivityType.ControlOfDocumentedInformation:
                this.addISO27001Templates(ISOCategoryType.Support, template);
                break;
            case AnnualWheelActivityType.OperationalPlanningAndControl:
            case AnnualWheelActivityType.OperationInformationSecurityRiskAssessment:
            case AnnualWheelActivityType.OperationInformationSecurityRiskTreatment:
                this.addISO27001Templates(ISOCategoryType.Operation, template);
                break;
            case AnnualWheelActivityType.MonitoringMeasurementAnalysisAndEvaluation:
            case AnnualWheelActivityType.GeneralInternalAudit:
            case AnnualWheelActivityType.InternalAuditProgramme:
            case AnnualWheelActivityType.GeneralManagementReview:
            case AnnualWheelActivityType.ManagementReviewInputs:
            case AnnualWheelActivityType.ManagementReviewResults:
                this.addISO27001Templates(ISOCategoryType.PerformanceEvaluation, template);
                break;
            case AnnualWheelActivityType.ContinualImprovement:
            case AnnualWheelActivityType.NonconformityAndCorrectiveAction:
                this.addISO27001Templates(ISOCategoryType.Improvement, template);
                break;
            case AnnualWheelActivityType.PoliciesForInformationSecurity:
            case AnnualWheelActivityType.InformationSecurityRolesAndResponsibilities:
            case AnnualWheelActivityType.SegregationOfDuties:
            case AnnualWheelActivityType.ManagementResponsibilities:
            case AnnualWheelActivityType.ContactWithAuthorities:
            case AnnualWheelActivityType.ContactWithSpecialInterestGroups:
            case AnnualWheelActivityType.ThreatIntelligence:
            case AnnualWheelActivityType.InformationSecurityInProjectManagement:
            case AnnualWheelActivityType.InventoryOfInformationAndOtherAssociatedAssets:
            case AnnualWheelActivityType.AcceptableUseOfInformationAndOtherAssociatedAssets:
            case AnnualWheelActivityType.ReturnOfAssets:
            case AnnualWheelActivityType.ClassificationOfInformation:
            case AnnualWheelActivityType.LabellingOfInformation:
            case AnnualWheelActivityType.InformationTransfer:
            case AnnualWheelActivityType.AccessControl:
            case AnnualWheelActivityType.IdentityManagement:
            case AnnualWheelActivityType.AuthenticationInformation:
            case AnnualWheelActivityType.AccessRights:
            case AnnualWheelActivityType.InformationSecurityInSupplierRelationships:
            case AnnualWheelActivityType.AddressingInformationSecurityWithinSupplierAgreements:
            case AnnualWheelActivityType.ManagingInformationSecurityInTheICTSupplyChain:
            case AnnualWheelActivityType.MonitoringReviewAndChangeManagementOfSupplierServices:
            case AnnualWheelActivityType.InformationSecurityForUseOfCloudServices:
            case AnnualWheelActivityType.InformationSecurityIncidentManagementPlanningAndPreparation:
            case AnnualWheelActivityType.AssessmentAndDecisionOnInformationSecurityEvents:
            case AnnualWheelActivityType.ResponseToInformationSecurityIncidents:
            case AnnualWheelActivityType.LearningFromInformationSecurityIncidents:
            case AnnualWheelActivityType.CollectionOfEvidence:
            case AnnualWheelActivityType.InformationSecurityDuringDisruption:
            case AnnualWheelActivityType.ICTReadinessForBusinessContinuity:
            case AnnualWheelActivityType.LegalStatutoryRegulatoryAndContractualRequirements:
            case AnnualWheelActivityType.IntellectualPropertyRights:
            case AnnualWheelActivityType.ProtectionOfRecords:
            case AnnualWheelActivityType.PrivacyAndProtectionOfPII:
            case AnnualWheelActivityType.IndependentReviewOfInformationSecurity:
            case AnnualWheelActivityType.ComplianceWithPoliciesRulesAndStandardsForInformationSecurity:
            case AnnualWheelActivityType.DocumentedOperatingProcedures:
                this.addISO27002Templates(ISOCategoryType.OrganisationalControls, template);
                break;
            case AnnualWheelActivityType.Screening:
            case AnnualWheelActivityType.TermsAndConditionsOfEmployment:
            case AnnualWheelActivityType.InformationSecurityAwarenessEducationAndTraining:
            case AnnualWheelActivityType.DisciplinaryProcess:
            case AnnualWheelActivityType.ResponsibilitiesAfterTerminationOrChangeOfEmployment:
            case AnnualWheelActivityType.ConfidentialityOrNonDisclosureAgreements:
            case AnnualWheelActivityType.RemoteWorking:
            case AnnualWheelActivityType.InformationSecurityEventReporting:
                this.addISO27002Templates(ISOCategoryType.PeopleControls, template);
                break;
            case AnnualWheelActivityType.PhysicalSecurityPerimeters:
            case AnnualWheelActivityType.PhysicalEntry:
            case AnnualWheelActivityType.SecuringOfficesRoomsAndFacilities:
            case AnnualWheelActivityType.PhysicalSecurityMonitoring:
            case AnnualWheelActivityType.ProtectingAgainstPhysicalAndEnvironmentalThreats:
            case AnnualWheelActivityType.WorkingInSecureAreas:
            case AnnualWheelActivityType.ClearDeskAndClearScreen:
            case AnnualWheelActivityType.EquipmentSitingAndProtection:
            case AnnualWheelActivityType.SecurityOfAssetsOffPremises:
            case AnnualWheelActivityType.StorageMedia:
            case AnnualWheelActivityType.SupportingUtilities:
            case AnnualWheelActivityType.CablingSecurity:
            case AnnualWheelActivityType.EquipmentMaintenance:
            case AnnualWheelActivityType.SecureDisposalOrReuseOfEquipment:
                this.addISO27002Templates(ISOCategoryType.PhysicalControls, template);
                break;
            case AnnualWheelActivityType.UserEndpointDevices:
            case AnnualWheelActivityType.PrivilegedAccessRights:
            case AnnualWheelActivityType.InformationAccessRestriction:
            case AnnualWheelActivityType.AccessToSourceCode:
            case AnnualWheelActivityType.SecureAuthentication:
            case AnnualWheelActivityType.CapacityManagement:
            case AnnualWheelActivityType.ProtectionAgainstMalware:
            case AnnualWheelActivityType.ManagementOfTechnicalVulnerabilities:
            case AnnualWheelActivityType.ConfigurationManagement:
            case AnnualWheelActivityType.InformationDeletion:
            case AnnualWheelActivityType.DataMasking:
            case AnnualWheelActivityType.DataLeakagePrevention:
            case AnnualWheelActivityType.InformationBackup:
            case AnnualWheelActivityType.RedundancyOfInformationProcessingFacilities:
            case AnnualWheelActivityType.Logging:
            case AnnualWheelActivityType.MonitoringActivities:
            case AnnualWheelActivityType.ClockSynchronisation:
            case AnnualWheelActivityType.UseOfPrivilegedUtilityPrograms:
            case AnnualWheelActivityType.InstallationOfSoftwareOnOperationalSystems:
            case AnnualWheelActivityType.NetworksSecurity:
            case AnnualWheelActivityType.SecurityOfNetworkServices:
            case AnnualWheelActivityType.SegregationOfNetworks:
            case AnnualWheelActivityType.WebFiltering:
            case AnnualWheelActivityType.UseOfCryptography:
            case AnnualWheelActivityType.SecureDevelopmentLifeCycle:
            case AnnualWheelActivityType.ApplicationSecurityRequirements:
            case AnnualWheelActivityType.SecureSystemArchitectureAndEngineeringPrinciples:
            case AnnualWheelActivityType.SecureCoding:
            case AnnualWheelActivityType.SecurityTestingInDevelopmentAndAcceptance:
            case AnnualWheelActivityType.OutsourcedDevelopment:
            case AnnualWheelActivityType.SeparationOfDevelopmentTestAndProductionEnvironments:
            case AnnualWheelActivityType.ChangeManagement:
            case AnnualWheelActivityType.TestInformation:
            case AnnualWheelActivityType.ProtectionOfInformationSystemsDuringAuditTesting:
                this.addISO27002Templates(ISOCategoryType.TechnologicalControls, template);
                break;
            case AnnualWheelActivityType.PoliciesForInformationSecurity2013:
            case AnnualWheelActivityType.ReviewOfThePoliciesForInformationSecurity2013:
                this.addISO27002_2013Templates(ISOCategoryType.InformationSecurityPolicies, template);
                break;
            case AnnualWheelActivityType.InformationSecurityRolesAndResponsibilities2013:
            case AnnualWheelActivityType.SegregationOfDuties2013:
            case AnnualWheelActivityType.ContactWithAuthorities2013:
            case AnnualWheelActivityType.ContactWithSpecialInterestGroups2013:
            case AnnualWheelActivityType.InformationSecurityInProjectManagement2013:
            case AnnualWheelActivityType.MobileDevicePolicy2013:
            case AnnualWheelActivityType.Teleworking2013:
                this.addISO27002_2013Templates(ISOCategoryType.OrganisationOfInformationSecurity, template);
                break;
            case AnnualWheelActivityType.Screening2013:
            case AnnualWheelActivityType.TermsAndConditionsOfEmployment2013:
            case AnnualWheelActivityType.ManagementResponsibilities2013:
            case AnnualWheelActivityType.InformationSecurityAwarenessEducationAndTraining2013:
            case AnnualWheelActivityType.DisciplinaryProcess2013:
            case AnnualWheelActivityType.TerminationOrChangeOfEmploymentResponsibilities2013:
                this.addISO27002_2013Templates(ISOCategoryType.HumanResourceSecurity, template);
                break;
            case AnnualWheelActivityType.InventoryOfAssets2013:
            case AnnualWheelActivityType.OwnershipOfAssets2013:
            case AnnualWheelActivityType.AcceptableUseOfAssets2013:
            case AnnualWheelActivityType.ReturnOfAssets2013:
            case AnnualWheelActivityType.ClassificationOfInformation2013:
            case AnnualWheelActivityType.LabellingOfInformation2013:
            case AnnualWheelActivityType.HandlingOfAssets2013:
            case AnnualWheelActivityType.ManagementOfRemovableMedia2013:
            case AnnualWheelActivityType.DisposalOfMedia2013:
            case AnnualWheelActivityType.PhysicalMediaTransfer2013:
                this.addISO27002_2013Templates(ISOCategoryType.AssetManagement, template);
                break;
            case AnnualWheelActivityType.AccessControlPolicy2013:
            case AnnualWheelActivityType.AccessToNetworksAndNetworkServices2013:
            case AnnualWheelActivityType.UserRegistrationAndDeRegistration2013:
            case AnnualWheelActivityType.UserAccessProvisioning2013:
            case AnnualWheelActivityType.ManagementOfPrivilegedAccessRights2013:
            case AnnualWheelActivityType.ManagementOfSecretAuthenticationInformationOfUsers2013:
            case AnnualWheelActivityType.ReviewOfUserAccessRights2013:
            case AnnualWheelActivityType.RemovalOrAdjustmentOfAccessRights2013:
            case AnnualWheelActivityType.UseOfSecretAuthenticationInformation2013:
            case AnnualWheelActivityType.InformationAccessRestriction2013:
            case AnnualWheelActivityType.SecureLogOnProcedures2013:
            case AnnualWheelActivityType.PasswordManagementSystem2013:
            case AnnualWheelActivityType.UseOfPrivilegedUtilityPrograms2013:
            case AnnualWheelActivityType.AccessControlToProgramSourceCode2013:
                this.addISO27002_2013Templates(ISOCategoryType.AccessControl, template);
                break;
            case AnnualWheelActivityType.PolicyOnTheUseOfCryptographicControls2013:
            case AnnualWheelActivityType.KeyManagement2013:
                this.addISO27002_2013Templates(ISOCategoryType.Cryptography, template);
                break;
            case AnnualWheelActivityType.PhysicalSecurityPerimeter2013:
            case AnnualWheelActivityType.PhysicalEntryControls2013:
            case AnnualWheelActivityType.SecuringOfficesRoomsAndFacilities2013:
            case AnnualWheelActivityType.ProtectingAgainstExternalAndEnvironmentalThreats2013:
            case AnnualWheelActivityType.WorkingInSecureAreas2013:
            case AnnualWheelActivityType.DeliveryAndLoadingAreas2013:
            case AnnualWheelActivityType.EquipmentSitingAndProtection2013:
            case AnnualWheelActivityType.SupportingUtilities2013:
            case AnnualWheelActivityType.CablingSecurity2013:
            case AnnualWheelActivityType.EquipmentMaintenance2013:
            case AnnualWheelActivityType.RemovalOfAssets2013:
            case AnnualWheelActivityType.SecurityOfEquipmentAndAssetsOffPremises2013:
            case AnnualWheelActivityType.SecureDisposalOrReuseOfEquipment2013:
            case AnnualWheelActivityType.UnattendedUserEquipment2013:
            case AnnualWheelActivityType.ClearDeskAndClearScreenPolicy2013:
                this.addISO27002_2013Templates(ISOCategoryType.PhysicalAndEnvironmentalSecurity, template);
                break;
            case AnnualWheelActivityType.DocumentedOperatingProcedures2013:
            case AnnualWheelActivityType.ChangeManagement2013:
            case AnnualWheelActivityType.CapacityManagement2013:
            case AnnualWheelActivityType.SeparationOfDevelopmentTestingAndOperationalEnvironments2013:
            case AnnualWheelActivityType.ControlsAgainstMalware2013:
            case AnnualWheelActivityType.InformationBackup2013:
            case AnnualWheelActivityType.EventLogging2013:
            case AnnualWheelActivityType.ProtectionOfLogInformation2013:
            case AnnualWheelActivityType.AdministratorAndOperatorLogs2013:
            case AnnualWheelActivityType.ClockSynchronisation2013:
            case AnnualWheelActivityType.InstallationOfSoftwareOnOperationalSystems2013:
            case AnnualWheelActivityType.ManagementOfTechnicalVulnerabilities2013:
            case AnnualWheelActivityType.RestrictionsOnSoftwareInstallation2013:
            case AnnualWheelActivityType.InformationSystemsAuditControls2013:
                this.addISO27002_2013Templates(ISOCategoryType.OperationsSecurity, template);
                break;
            case AnnualWheelActivityType.NetworkControls2013:
            case AnnualWheelActivityType.SecurityOfNetworkServices2013:
            case AnnualWheelActivityType.SegregationInNetworks2013:
            case AnnualWheelActivityType.InformationTransferPoliciesAndProcedures2013:
            case AnnualWheelActivityType.AgreementsOnInformationTransfer2013:
            case AnnualWheelActivityType.ElectronicMessaging2013:
            case AnnualWheelActivityType.ConfidentialityOrNonDisclosureAgreements2013:
                this.addISO27002_2013Templates(ISOCategoryType.CommunicationsSecurity, template);
                break;
            case AnnualWheelActivityType.InformationSecurityRequirementsAnalysisAndSpecification2013:
            case AnnualWheelActivityType.SecuringApplicationServicesOnPublicNetworks2013:
            case AnnualWheelActivityType.ProtectingApplicationServicesTransactions2013:
            case AnnualWheelActivityType.SecureDevelopmentPolicy2013:
            case AnnualWheelActivityType.SystemChangeControlProcedures2013:
            case AnnualWheelActivityType.TechnicalReviewOfApplicationsAfterOperatingPlatformChanges2013:
            case AnnualWheelActivityType.RestrictionsOnChangesToSoftwarePackages2013:
            case AnnualWheelActivityType.SecureSystemEngineeringPrinciples2013:
            case AnnualWheelActivityType.SecureDevelopmentEnvironment2013:
            case AnnualWheelActivityType.OutsourcedDevelopment2013:
            case AnnualWheelActivityType.SystemSecurityTesting2013:
            case AnnualWheelActivityType.SystemAcceptanceTesting2013:
            case AnnualWheelActivityType.ProtectionOfTestData2013:
                this.addISO27002_2013Templates(ISOCategoryType.SystemAcquisitionDevelopmentAndMaintenance, template);
                break;
            case AnnualWheelActivityType.InformationSecurityPolicyForSupplierRelationships2013:
            case AnnualWheelActivityType.AddressingSecurityWithinSupplierAgreements2013:
            case AnnualWheelActivityType.InformationAndCommunicationTechnologySupplyChain2013:
            case AnnualWheelActivityType.MonitoringAndReviewOfSupplierServices2013:
            case AnnualWheelActivityType.ManagingChangesToSupplierServices2013:
                this.addISO27002_2013Templates(ISOCategoryType.SupplierRelationships, template);
                break;
            case AnnualWheelActivityType.ResponsibilitiesAndProcedures2013:
            case AnnualWheelActivityType.ReportingInformationSecurityEvents2013:
            case AnnualWheelActivityType.ReportingInformationSecurityWeaknesses2013:
            case AnnualWheelActivityType.AssessmentOfAndDecisionOnInformationSecurityEvents2013:
            case AnnualWheelActivityType.ResponseToInformationSecurityIncidents2013:
            case AnnualWheelActivityType.LearningFromInformationSecurityIncidents2013:
            case AnnualWheelActivityType.CollectionOfEvidence2013:
                this.addISO27002_2013Templates(ISOCategoryType.InformationSecurityIncidentManagement, template);
                break;
            case AnnualWheelActivityType.PlanningInformationSecurityContinuity2013:
            case AnnualWheelActivityType.ImplementingInformationSecurityContinuity2013:
            case AnnualWheelActivityType.VerifyReviewAndEvaluateInformationSecurityContinuity2013:
            case AnnualWheelActivityType.AvailabilityOfInformationProcessingFacilities2013:
                this.addISO27002_2013Templates(ISOCategoryType.InformationSecurityAspectsOfBusinessContinuityManagement, template);
                break;
            case AnnualWheelActivityType.IdentificationOfApplicableLegislationAndContractualRequirements2013:
            case AnnualWheelActivityType.IntellectualPropertyRights2013:
            case AnnualWheelActivityType.ProtectionOfRecords2013:
            case AnnualWheelActivityType.PrivacyAndProtectionOfPersonallyIdentifiableInformation2013:
            case AnnualWheelActivityType.RegulationOfCryptographicControls2013:
            case AnnualWheelActivityType.IndependentReviewOfInformationSecurity2013:
            case AnnualWheelActivityType.ComplianceWithSecurityPoliciesAndStandards2013:
            case AnnualWheelActivityType.TechnicalComplianceReview2013:
                this.addISO27002_2013Templates(ISOCategoryType.Compliance, template);
                break;
        }
    }

    addISO27001Templates(categoryType: ISOCategoryType, template: AnnualWheelActivityTemplateItem) {
        this.iso27001Templates.find((x) => x.categoryType === categoryType)?.templates.push(template);
    }

    addISO27002Templates(categoryType: ISOCategoryType, template: AnnualWheelActivityTemplateItem) {
        this.iso27002Templates.find((x) => x.categoryType === categoryType)?.templates.push(template);
    }

    addISO27002_2013Templates(categoryType: ISOCategoryType, template: AnnualWheelActivityTemplateItem) {
        this.iso27002_2013Templates.find((x) => x.categoryType === categoryType)?.templates.push(template);
    }
}

export class ISOCategory {
    categoryType: ISOCategoryType;
    templates: Array<AnnualWheelActivityTemplateItem> = [];

    constructor(categoryType: ISOCategoryType) {
        this.categoryType = categoryType;
    }

    hasTemplates() {
        return this.templates.length > 0;
    }
}
