import { createElement, useState } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { post } from "../../../common/api/apiShared";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { IAgreementDialog } from "./DataProcessorAgreementDialog";
import { DataProcessorAgreementSaveModel } from "./DataProcessorAgreementDialog.types";
import { useValidator } from "../../../common/hooks/validation";
import { useDocumentHook } from "../../../documents/Documents.hook";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { useSelectableDocumentTypesByDocumentUsageTypeQuery } from "../../../common/hooks/useSelectableItemQueries";

export function useAgreementDialogHooks(props: IAgreementDialog) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [viewModel, setViewModel] = useState<DataProcessorAgreementSaveModel | undefined>(props.saveModel);
    const [documentationOption, setDocumentationOption] = useState(1);
    const { data: documentTypes, isLoading: documentTypesIsLoading } = useSelectableDocumentTypesByDocumentUsageTypeQuery(props.documentUsageType);
    const { gdpo } = useUserContext();

    const validation = useValidator<DataProcessorAgreementSaveModel>(
        (validators) => ({
            name: validators.validateNotEmpty((item) => item.name, translateString("name")),
            documentTypeId: validators.validateNotEmpty((item) => item.documentTypeId, translateString("type")),
            fileName: documentationOption === 0 ? undefined : validators.validateNotEmpty((item) => item.fileName, translateString("file")),
            link: documentationOption === 1 ? undefined : validators.validateUrlAndNotEmpty((item) => item.link, translateString("link")),
            status: validators.validateNotEmpty((item) => item.status, translateString("status")),
            groupEntityAccessIds: validators.validateNotEmpty(
                (item) => item.groupEntityAccessIds,
                "",
                () => gdpo || props.saveModel.isGroupEntity!,
                translateString("oneGroupCompanyIsRequired")
            ),
        }),
        [documentationOption]
    );

    let documentStatus = useDocumentHook().selecableDocumentStatus;

    const saveMutation = useMutation(save, {
        onSuccess: (response: any) => {
            if (response.success()) {
                const value = response.value();
                props.onSave(value.id);
                props.onDialogClose();
                setViewModel(undefined);
                snackbar.show(createElement(Trans, { i18nKey: "dataprocessorAgreementCreated", values: { name: viewModel?.name } }));
            } else {
                snackbar.show(translateString("dataprocessorAgreementExists"), "warning");
            }
        },
    });

    const createAgreement = async () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        await saveMutation.mutateAsync();
    };

    function save() {
        return post<string>("/agreement/", viewModel);
    }

    function onDocumentationTypeChange(option: number) {
        let tempModel = { ...viewModel! };
        if (option === 0) {
            tempModel.base64Data = "";
            tempModel.fileName = "";
            setViewModel(tempModel);
            setDocumentationOption(0);
        }

        if (option === 1) {
            tempModel.link = "";
            setViewModel(tempModel);
            setDocumentationOption(1);
        }
    }

    function onGroupEntityAccessIdsChange(groupEntityIds: Array<string>) {
        let tempModel = { ...viewModel! };
        tempModel.groupEntityAccessIds = groupEntityIds;

        if (gdpo) {
            if (groupEntityIds.length === 0) {
                tempModel.allGroupEntities = true;
            } else {
                tempModel.allGroupEntities = false;
            }
        }

        setViewModel(tempModel);
    }

    return {
        inProgress: saveMutation.isLoading,
        viewModel,
        documentTypes,
        setViewModel,
        documentationOption,
        onDocumentationTypeChange,
        validation,
        createAgreement,
        documentStatus,
        onGroupEntityAccessIdsChange,
        documentTypesIsLoading,
    };
}
