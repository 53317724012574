import { Box, ClickAwayListener, Icon, IconButton, TableCell, TableRow } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { DotLegalTable, DotLegalTextField, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { DocumentModel, DocumentUpdateModel } from "../../documents/documentModel";
import { useDotLegalFileUploadDataMapping } from "./DotLegalFileUploadTable.hooks";
import DotLegalDeleteDialogWrapper from "../dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { isNullOrWhitespace } from "../../stringOperations";

export interface IDotLegalFileUploadTable {
    documents: Array<DocumentModel> | undefined;
    onRemoveDocument: (documentId: string) => void;
    disabled?: boolean;
    onEditDocument: (document: DocumentUpdateModel) => void;
    selectedDocument: DocumentModel | undefined;
    setSelectedDocument: (model: DocumentModel | undefined) => void;
}

function DotLegalFileUploadTable(props: IDotLegalFileUploadTable) {
    const [documentForDeletion, setDocumentForDeletion] = useState<{ id: string; name: string } | undefined>(undefined);
    const { translateString, translateDate } = useTranslation();
    const { downloadDocument } = useDotLegalFileUploadDataMapping();

    const textFieldRef = React.useRef<HTMLTextAreaElement>();
    useEffect(() => {
        if (props.selectedDocument) {
            textFieldRef.current?.focus();
            let endIndex = isNullOrWhitespace(props.selectedDocument.dataId) ? textFieldRef.current?.value.length : textFieldRef.current?.value.indexOf(".");
            textFieldRef.current?.setSelectionRange(0, endIndex!);
        }
    }, [props.selectedDocument]);

    const getHeaders = () => {
        let headers: Array<ITableHeader<DocumentModel>> = [];
        headers.push({ property: "fileName", text: translateString("document"), align: "left", showOnMobile: true });
        headers.push({ property: "created", text: translateString("date"), align: "left", showOnMobile: true });

        return headers;
    };

    const handleRowClick = (row: DocumentModel) => {
        if (textFieldRef.current) {
            return;
        }

        const isFileDocument = !isNullOrWhitespace(row.dataId);
        if (isFileDocument) {
            downloadDocument(row.id, row.fileName!);
        } else {
            window.open(row.link, "_blank");
        }
    };

    return (
        <Fragment>
            <DotLegalTable
                headers={getHeaders()}
                emptyText={translateString("noDocuments")}
                paginationLabelOf={translateString("labelOf")}
                defaultOrderBy={"created"}
                defaultOrder={"asc"}
                isLoading={false}
                hidePagination
                textStyle={"NoCapsHeaders"}
                data={props.documents!}
                marginTop={1}
                clickableRows={props.selectedDocument === undefined}
                rowsPerPage={1000}
                renderRow={(row) => (
                    <TableRow
                        sx={{
                            "&:hover": {
                                button: {
                                    visibility: "visible",
                                },
                            },
                        }}
                        key={row.id}
                        onClick={() => handleRowClick(row)}
                    >
                        {props.selectedDocument?.id === row.id ? (
                            <ClickAwayListener onClickAway={() => props.setSelectedDocument(undefined)}>
                                <TableCell>
                                    <Box sx={{ mt: "5px" }}>
                                        <DotLegalTextField
                                            noMargin
                                            variant="standard"
                                            label={""}
                                            value={row.fileName ?? row.link!}
                                            debounce
                                            inputRef={textFieldRef}
                                            onChange={(value) => {
                                                if (isNullOrWhitespace(row.dataId)) {
                                                    props.onEditDocument({ id: row.id, fileName: undefined, link: value });
                                                } else {
                                                    props.onEditDocument({ id: row.id, fileName: value, link: undefined });
                                                }
                                            }}
                                            onEnterPressed={() => props.setSelectedDocument(undefined)}
                                        />
                                    </Box>
                                </TableCell>
                            </ClickAwayListener>
                        ) : (
                            <TableCell>{row.fileName ?? row.link!}</TableCell>
                        )}

                        <TableCell sx={{ whiteSpace: "nowrap", width: 100 }}>{translateDate(row.created)}</TableCell>

                        <TableCell width={40}>
                            {props.disabled === false && (
                                <Box sx={{ display: "flex" }}>
                                    <IconButton
                                        sx={{ visibility: "hidden" }}
                                        size="small"
                                        onClick={(e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
                                            if (props.selectedDocument) {
                                                props.setSelectedDocument(undefined);
                                            } else {
                                                props.setSelectedDocument(row);
                                            }
                                            e.stopPropagation();
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>

                                    <IconButton
                                        sx={{ visibility: "hidden" }}
                                        size="small"
                                        onClick={(e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
                                            e.stopPropagation();
                                            setDocumentForDeletion({ id: row.id, name: row.fileName ?? row.link ?? "" });
                                        }}
                                    >
                                        <CloseIcon sx={(theme) => ({ fill: theme.palette.error.main })} />
                                    </IconButton>
                                </Box>
                            )}
                        </TableCell>
                    </TableRow>
                )}
            />

            <DotLegalDeleteDialogWrapper
                itemForDeletion={documentForDeletion ? documentForDeletion.name : ""}
                open={!!documentForDeletion}
                onDialogClose={() => setDocumentForDeletion(undefined)}
                onOkClick={() => {
                    setDocumentForDeletion(undefined);
                    props.onRemoveDocument(documentForDeletion!.id);
                }}
            />
        </Fragment>
    );
}

export default DotLegalFileUploadTable;
