import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteHttp, get, put } from "../../../common/api/apiShared";
import { LegalEntitySelectorViewModel } from "../../../legalEntity/LegalEntity.types";
import { DataProcessor, ProcessingActivityModel, SelectableLegalUnitsItem } from "../../ProcessingActivity.types";
import { ValidationError } from "../../../common/validationError";
import { useTranslation } from "../../../localization/useTranslation";
import { useSelectableLegalEntityQuery } from "../../../legalEntity/useSelectableLegalEntityQuery";
import { useSelectableDataCategoriesQuery } from "../../hooks/useSelectableDataCategoriesQuery";
import { useSelectableDocumentsForLegalEntitiesQuery, useSelectableDocumentsQuery } from "../../hooks/useSelectableDocumentQuery";
import { useResponsibleQuery } from "../../../user/hooks/useResponsibleQuery";
import { UserSelectableItem } from "../../../user/User.types";
import {
    isTransferBasisMissingForDataProcessorSharing,
    isTransferImpactAssessmentMissingForDataProcessorSharing,
} from "../../../common/dataProcessorValidation";
import { UserPermissions } from "../../../auth/userContextProvider/UserContextProvider.types";
import { useTransferBasesQuery } from "../../../common/hooks/useSelectableItemQueries";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { useState } from "react";
import { AgreementSaveModel, DataProcessorAgreementSaveModel } from "../agreementDialog/DataProcessorAgreementDialog.types";
import { SelectedLegalEntityDocument } from "../../../legalEntity/legalEntityDocumentTab/LegalEntityDocumentTab.types";
import { useExpandedId } from "../../../common/hooks/useExpandedId";
import useCountryHook from "../../../common/hooks/useCountryList";
import { useOptimisticUpdate } from "../../../common/hooks/useOptimisticUpdate";

export function useDataProcessorsStepMapping(
    processingActivity: ProcessingActivityModel,
    groupEntityIsDataProcessor: boolean,
    onCreateNewDocument: (transferImpactAssessmentId: string, dataProcessorId: string) => void,
    dataProcessors: Array<DataProcessor>
) {
    const queryClient = useQueryClient();
    const optimisticUpdate = useOptimisticUpdate();
    const { getExpandedId, setExpandedId } = useExpandedId();
    const { permissions } = useUserContext();
    const userPermissions = getUserPermissions();
    const [dataProcessorAgreementSaveModel, setDataProcessorAgreementSaveModel] = useState<DataProcessorAgreementSaveModel | undefined>(undefined);
    const [dataprocessorId, setDataprocessorId] = useState("");
    const [selectedDocument, setSelectedDocument] = useState<SelectedLegalEntityDocument>();
    const [showEditDocumentDialog, setShowEditDocumentDialog] = useState(false);
    const [showSpecificStorageLocationDialog, setShowSpecificStorageLocationDialog] = useState(false);

    const { getSelectableCountries, countriesIsLoading, thirdCountries } = useCountryHook();

    let queryKey = "processingActivity" + processingActivity.id;

    let dataCategoriesQuery = useSelectableDataCategoriesQuery();
    let legalEntitiesQuery = useSelectableLegalEntityQuery();
    let responsibleQuery = useResponsibleQuery(userPermissions.hasResponsiblePermission);
    let sendersQuery = useQuery("senders" + groupEntityIsDataProcessor, () =>
        get<Array<LegalEntitySelectorViewModel>>("/LegalEntity/senders/" + groupEntityIsDataProcessor)
    );
    let transferBasisQuery = useTransferBasesQuery(userPermissions.hasTiaAndTransferBasisPermission);

    let documentsQuery = useSelectableDocumentsQuery(
        userPermissions.hasTiaAndTransferBasisPermission || userPermissions.hasAgreementPermission,
        dataProcessors.find((x) => x.id === getExpandedId())?.dataProcessor ?? undefined,
        processingActivity.id
    );
    let subProcessorsDocumentsQuery = useSelectableDocumentsForLegalEntitiesQuery(
        userPermissions.hasTiaAndTransferBasisPermission || userPermissions.hasAgreementPermission,
        dataProcessors
            .find((x) => x.id === getExpandedId())
            ?.subProcessors?.filter((s) => s.processorId)
            .map((s) => s.processorId!) ?? [],
        processingActivity.id
    );

    let dataCategoryLoading = dataCategoriesQuery.isLoading;
    let legalEntityLoading = legalEntitiesQuery.isLoading;
    let sendersLoading = sendersQuery.isLoading;
    let responsibleLoading = responsibleQuery.isLoading;
    let transferBasisLoading = transferBasisQuery.isLoading;
    let documentsLoading = documentsQuery.isLoading;

    let legalEntityData = legalEntitiesQuery.data;
    let sendersData = sendersQuery.data;
    let transferBasisData = transferBasisQuery.data;
    let dataProcessorAgreementDocs = documentsQuery.dataProcessorDocuments;
    let transferImpactAssessmentDocs = documentsQuery.transferImpactAssessmentDocuments;

    let subProcessorsTIADocsLoaing = subProcessorsDocumentsQuery.isLoading;
    let subProcessorsTIADocs = subProcessorsDocumentsQuery.transferImpactAssessmentDocuments;

    const dataCategoriesData = dataCategoriesQuery.getDataCategoriesForProcessingActivity(processingActivity);

    const removeAgreementFromDataProcessorMutation = useMutation(removeAgreementAPI, {
        onSuccess: (data) => {
            const processingActivity = data.value();
            queryClient.setQueryData(queryKey, processingActivity);
        },
    });
    const addAgreementToDataProcessorMutation = useMutation(addAgreementAPI, {
        onSuccess: (data) => {
            const processingActivity = data.value();
            queryClient.setQueryData(queryKey, processingActivity);
        },
    });

    const onAgreementAdded = async (agreementId: string, dataProcessorId: string) => {
        const newProcessingActivityModel = { ...processingActivity };
        const dataProcessor = newProcessingActivityModel.dataProcessors.find((d) => d.id === dataProcessorId)!;

        dataProcessor.dataProcessAgreements.push(agreementId);

        await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, addAgreementToDataProcessorMutation.mutateAsync, {
            processingActivityId: processingActivity.id,
            dataProcessorId: dataProcessorId,
            saveModel: { agreementId: agreementId },
        });
    };

    const onAgreementDeleted = async (agreementId: string, dataProcessorId: string) => {
        const newProcessingActivityModel = { ...processingActivity };
        const dataProcessor = newProcessingActivityModel.dataProcessors.find((d) => d.id === dataProcessorId)!;

        dataProcessor.dataProcessAgreements = dataProcessor.dataProcessAgreements.filter((x) => x !== agreementId);

        await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, removeAgreementFromDataProcessorMutation.mutateAsync, {
            processingActivityId: processingActivity.id,
            dataProcessorId: dataProcessorId,
            saveModel: { agreementId: agreementId },
        });
    };

    const onHasAgreementChange = async (hasAgreement: boolean, dataProcessorId: string) => {
        const newProcessingActivityModel = { ...processingActivity };
        const dataProcessor = newProcessingActivityModel.dataProcessors.find((d) => d.id === dataProcessorId)!;

        dataProcessor.hasDataProcessorAgreement = hasAgreement;
        dataProcessor.dataProcessAgreements = [];

        await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, addAgreementToDataProcessorMutation.mutateAsync, {
            processingActivityId: processingActivity.id,
            dataProcessorId: dataProcessorId,
            saveModel: { hasAgreement: hasAgreement },
        });
    };

    return {
        dataCategoryLoading,
        dataCategoriesData,
        legalEntityLoading,
        legalEntityData,
        responsibleLoading,
        sendersLoading,
        sendersData,
        transferBasisesLoading: transferBasisLoading,
        transferBasisesData: transferBasisData,
        documentsLoading,
        dataProcessorAgreementDocs,
        transferImpactAssessmentDocs,
        getResponsibles: responsibleQuery.data,
        dataProcessorAgreementSaveModel,
        setDataProcessorAgreementSaveModel,
        dataprocessorId,
        setDataprocessorId,
        saveAgreement,
        selectedDocument,
        showEditDocumentDialog,
        setShowEditDocumentDialog,
        setSelectedDocument,
        refetchDocuments: documentsQuery.refetch,
        getExpandedId,
        setExpandedId,
        subProcessorsTIADocsLoaing,
        subProcessorsTIADocs,
        showSpecificStorageLocationDialog,
        setShowSpecificStorageLocationDialog,
        getSelectableCountries,
        countriesIsLoading,
        thirdCountries,
        onAgreementAdded,
        onAgreementDeleted,
        onHasAgreementChange,
    };

    function getUserPermissions() {
        if (groupEntityIsDataProcessor) {
            return {
                hasAgreementPermission: permissions.processingActivityDataControllersPermissions.hasAgreementPermission,
                hasTiaAndTransferBasisPermission: permissions.processingActivityDataControllersPermissions.hasTiaAndTransferBasisPermission,
                hasResponsiblePermission: permissions.processingActivityDataControllersPermissions.hasResponsiblePermission,
            };
        } else {
            return {
                hasAgreementPermission: permissions.processingActivityDataProcessorsPermissions.hasAgreementPermission,
                hasTiaAndTransferBasisPermission: permissions.processingActivityDataProcessorsPermissions.hasTiaAndTransferBasisPermission,
                hasResponsiblePermission: permissions.processingActivityDataProcessorsPermissions.hasResponsiblePermission,
            };
        }
    }

    function saveAgreement(agreement: string, dataprocessor: string) {
        onCreateNewDocument(agreement, dataprocessor);
        documentsQuery.refetch();
    }

    async function addAgreementAPI(data: { processingActivityId: string; dataProcessorId: string; saveModel: AgreementSaveModel }) {
        return await put("/processingactivity/" + data.processingActivityId + "/dataprocessor/" + data.dataProcessorId + "/agreement", data.saveModel);
    }

    async function removeAgreementAPI(data: { processingActivityId: string; dataProcessorId: string; saveModel: AgreementSaveModel }) {
        return await deleteHttp(
            `/processingactivity/${data.processingActivityId}/dataprocessor/${data.dataProcessorId}/agreement?agreementId=${data.saveModel.agreementId}`
        );
    }
}

export function useValidateDataProcessorsStep(userPermissions: UserPermissions, canRegisterSelfAsDataProcessor: boolean) {
    const { translateString } = useTranslation();

    return (
        model: ProcessingActivityModel,
        legalEntities: Array<SelectableLegalUnitsItem> | undefined,
        thirdCountries: Array<string>,
        groupEntityIsDataProcessor: boolean,
        responsibles: Array<UserSelectableItem> | undefined
    ): Array<ValidationError> => {
        const result: Array<ValidationError> = [];

        if (groupEntityIsDataProcessor && !canRegisterSelfAsDataProcessor) {
            return result;
        }

        var permissions = getStepPermissions(groupEntityIsDataProcessor, userPermissions);

        if (permissions.hasSharingPermission) {
            const dataProcessors = model.dataProcessors;

            if (groupEntityIsDataProcessor && !model.disableDataControllers && !model.hasDataControllers) {
                result.push(new ValidationError("noDataSharingsDataProcessorGroupEntityisDataProcessor", translateString("atLeastOneSharingRequired")));
            }

            if (!groupEntityIsDataProcessor && !model.disableDataProcessors && !model.hasDataProcessors) {
                result.push(new ValidationError("noDataSharingsDataProcessor", translateString("atLeastOneSharingRequired")));
            }

            filterDataProcessors(groupEntityIsDataProcessor, dataProcessors).forEach((dataProcessor, index) => {
                if (permissions.hasSubProcessorsPermission) {
                    dataProcessor.subProcessors
                        .filter((x) => !x.isLink)
                        .forEach((subprocessor, subprocessorIndex) => {
                            let recipient = legalEntities?.find((x) => x.id === subprocessor.processorId);

                            if (!subprocessor.processorId) {
                                result.push(
                                    new ValidationError(`${dataProcessor.id},${subprocessorIndex}dataProcessorId`, translateString("dataProcessorRequired"))
                                );
                            }
                            if (subprocessor.processorId === dataProcessor.dataProcessor && dataProcessor.dataProcessor) {
                                result.push(
                                    new ValidationError(
                                        `${dataProcessor.id},${subprocessorIndex}dataProcessorId`,
                                        dataProcessor.isSystemGenerated
                                            ? translateString("subprocessorUsedAsProcessorChangeOnSystem")
                                            : translateString("subprocessorUsedAsProcessor")
                                    )
                                );
                            }
                            if (
                                dataProcessor.subProcessors.filter((entry) => entry.processorId === subprocessor.processorId).length > 1 &&
                                subprocessor.processorId
                            ) {
                                result.push(
                                    new ValidationError(
                                        `${dataProcessor.id},${subprocessorIndex}dataProcessorId`,
                                        dataProcessor.isSystemGenerated
                                            ? translateString("duplicateSubprocessorChangeOnSystem")
                                            : translateString("duplicateSubprocessor")
                                    )
                                );
                            }
                            if (
                                isTransferBasisMissingForDataProcessorSharing(
                                    recipient,
                                    subprocessor.transferBasisId,
                                    permissions.hasTiaAndTransferBasisPermission,
                                    thirdCountries,
                                    subprocessor.dataStorageLocations
                                )
                            ) {
                                result.push(
                                    new ValidationError(
                                        `${dataProcessor.id},${subprocessorIndex}transferBasisId`,
                                        dataProcessor.isSystemGenerated
                                            ? translateString("transferBasisRequiredChangeOnSystem")
                                            : translateString("transferBasisRequired")
                                    )
                                );
                            }
                            if (permissions.hasDataCategoriesPermissions && subprocessor.dataCategories.length === 0) {
                                result.push(
                                    new ValidationError(`${dataProcessor.id},${subprocessorIndex}dataCategories`, translateString("dataCategoriesRequired"))
                                );
                            }

                            if (dataProcessor.legalEntities.some((l) => l === subprocessor.processorId)) {
                                result.push(
                                    new ValidationError(
                                        `${dataProcessor.id},${subprocessorIndex}dataProcessorId`,
                                        dataProcessor.isSystemGenerated
                                            ? translateString("dataProcessorCantBeInCompaniesChangeOnSystem")
                                            : translateString("dataProcessorCantBeInCompanies")
                                    )
                                );
                            }

                            if (
                                isTransferImpactAssessmentMissingForDataProcessorSharing(
                                    recipient,
                                    subprocessor.hasTransferImpactAssessment,
                                    subprocessor.transferImpactAssessmentId,
                                    permissions.hasTiaAndTransferBasisPermission,
                                    subprocessor.transferBasisId,
                                    thirdCountries,
                                    subprocessor.dataStorageLocations,
                                    subprocessor.containsNotAccessibleTia
                                )
                            ) {
                                result.push(
                                    new ValidationError(
                                        `${dataProcessor.id},${subprocessorIndex}transferImpactAssessment`,
                                        dataProcessor.isSystemGenerated ? translateString("tiaRequiredChangeOnSystem") : translateString("tiaRequired")
                                    )
                                );
                            }
                        });
                }

                let recipient = legalEntities?.find((x) => x.id === dataProcessor.dataProcessor);

                if (!dataProcessor.dataProcessor) {
                    result.push(new ValidationError(`${dataProcessor.id}dataProcessorId`, translateString("dataProcessorRequired")));
                }
                if (dataProcessor.legalEntities.length === 0) {
                    result.push(new ValidationError(`${dataProcessor.id}legalEntities`, translateString("companiesRequired")));
                }

                if (!groupEntityIsDataProcessor) {
                    if (
                        isTransferImpactAssessmentMissingForDataProcessorSharing(
                            recipient,
                            dataProcessor.hasTransferImpactAssessment,
                            dataProcessor.transferImpactAssessmentId,
                            permissions.hasTiaAndTransferBasisPermission,
                            dataProcessor.transferBasisId,
                            thirdCountries,
                            dataProcessor.dataStorageLocations,
                            dataProcessor.containsNotAccessibleTia
                        )
                    ) {
                        result.push(
                            new ValidationError(
                                `${dataProcessor.id}transferImpactAssessment`,
                                dataProcessor.isSystemGenerated ? translateString("tiaRequiredChangeOnSystem") : translateString("tiaRequired")
                            )
                        );
                    }

                    if (
                        isTransferBasisMissingForDataProcessorSharing(
                            recipient,
                            dataProcessor.transferBasisId,
                            permissions.hasTiaAndTransferBasisPermission,
                            thirdCountries,
                            dataProcessor.dataStorageLocations
                        )
                    ) {
                        result.push(
                            new ValidationError(
                                `${dataProcessor.id}transferBasisId`,
                                dataProcessor.isSystemGenerated
                                    ? translateString("transferBasisRequiredChangeOnSystem")
                                    : translateString("transferBasisRequired")
                            )
                        );
                    }
                }

                if (permissions.hasDataCategoriesPermissions && dataProcessor.dataCategories.length === 0) {
                    result.push(new ValidationError(`${dataProcessor.id}dataCategories`, translateString("dataCategoriesRequired")));
                }
                if (dataProcessor.legalEntities.some((l) => l === dataProcessor.dataProcessor)) {
                    result.push(
                        new ValidationError(
                            `${dataProcessor.id}dataProcessorId`,
                            dataProcessor.isSystemGenerated
                                ? translateString("dataProcessorCantBeInCompaniesChangeOnSystem")
                                : translateString("dataProcessorCantBeInCompanies")
                        )
                    );
                }

                let user = responsibles?.find((x) => x.id === dataProcessor.responsible);
                if (user && !user.active) {
                    result.push(new ValidationError(`${dataProcessor.id}inactiveUser`, translateString("userDeactivated")));
                }
            });
        }
        return result;
    };
}

export function getStepPermissions(groupEntityIsDataProcessor: boolean, userPermissions: UserPermissions) {
    return {
        hasTiaAndTransferBasisPermission: groupEntityIsDataProcessor
            ? userPermissions.processingActivityDataControllersPermissions?.hasTiaAndTransferBasisPermission
            : userPermissions.processingActivityDataProcessorsPermissions?.hasTiaAndTransferBasisPermission,
        hasSharingPermission: groupEntityIsDataProcessor
            ? userPermissions.processingActivityDataControllersPermissions !== undefined
            : userPermissions.processingActivityDataProcessorsPermissions !== undefined,
        hasSubProcessorsPermission: groupEntityIsDataProcessor
            ? userPermissions.processingActivityDataControllersPermissions?.hasSubProcessorPermission
            : userPermissions.processingActivityDataProcessorsPermissions?.hasSubProcessorPermission,
        hasResponsiblePermission: groupEntityIsDataProcessor
            ? userPermissions.processingActivityDataControllersPermissions?.hasResponsiblePermission
            : userPermissions.processingActivityDataProcessorsPermissions?.hasResponsiblePermission,
        hasAgreementPermission: groupEntityIsDataProcessor
            ? userPermissions.processingActivityDataControllersPermissions?.hasAgreementPermission
            : userPermissions.processingActivityDataProcessorsPermissions?.hasAgreementPermission,
        hasDataCategoriesPermissions: groupEntityIsDataProcessor
            ? userPermissions.processingActivityDataControllersPermissions?.hasDataCategoriesPermission
            : userPermissions.processingActivityDataProcessorsPermissions?.hasDataCategoriesPermission,
    };
}

export function filterDataProcessors(groupEntityIsDataProcessor: boolean, dataProcessors: Array<DataProcessor>) {
    if (groupEntityIsDataProcessor) return dataProcessors.filter((x) => x.hasDataControllerAccess);
    else return dataProcessors.filter((x) => x.hasDataProcessorAccess);
}

export function isDataProcessorReadOnly(dataProcessor: DataProcessor, isGroupEntityDataProcessor: boolean, isValidated: boolean) {
    return (
        dataProcessor.readOnly ||
        (isGroupEntityDataProcessor && !dataProcessor.createdAsGroupEntityIsDataProcessor) ||
        (!isGroupEntityDataProcessor && dataProcessor.createdAsGroupEntityIsDataProcessor) ||
        isValidated
    );
}

export function canAddNewSubProcessor(dataProcessor: DataProcessor, isGroupEntityDataProcessor: boolean, isValidated: boolean) {
    return (
        !dataProcessor.isSystemGenerated &&
        ((isGroupEntityDataProcessor && dataProcessor.createdAsGroupEntityIsDataProcessor) ||
            (!isGroupEntityDataProcessor && !dataProcessor.createdAsGroupEntityIsDataProcessor) ||
            isValidated)
    );
}
