import { ICreatePublicInternalAssessmentTemplateDialog } from "./CreatePublicInternalAssessmentTemplateDialog.tsx";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { createElement, useState } from "react";
import { useMutation } from "react-query";
import { useTranslation } from "../../../localization/useTranslation.ts";
import { PublicInternalAssessmentTemplateSaveModel, PublicInternalAssessmentTemplateSaveViewModel } from "../PublicInternalAssessmentTemplates.types.ts";
import { useResponsibleQuery } from "../../../user/hooks/useResponsibleQuery.ts";
import { post } from "../../../common/api/apiShared.ts";
import { useValidator } from "../../../common/hooks/validation";
import { QuestionnaireSelectableItem } from "../../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types.ts";
import { Trans } from "react-i18next";
import { useGroupEntitiesQuery } from "../../../processingActivity/hooks/useGroupEntitiesQuery.ts";

export default function useCreatePublicInternalAssessmentTemplateDialogHooks(props: ICreatePublicInternalAssessmentTemplateDialog) {
    const snackBar = useDotLegalSnackbar();
    const { translateString } = useTranslation();

    const [model, setModel] = useState<PublicInternalAssessmentTemplateSaveViewModel>(getDefaultModel());
    const [step, setStep] = useState<"first" | "selectGroupEntity">("first");

    const groupEntitiesQuery = useGroupEntitiesQuery();
    const responsibleQuery = useResponsibleQuery(true);

    let selectableGroupEntitiesData = groupEntitiesQuery.allGroupEntities;
    let selectableGroupEntitiesLoading = groupEntitiesQuery.isLoading;

    let selectableResponsiblesData = responsibleQuery.data(undefined) ?? [];
    let selectableResponsiblesLoading = responsibleQuery.isLoading;

    const validation = useValidator<PublicInternalAssessmentTemplateSaveViewModel>(
        (validators) => ({
            name: validators.validateNotEmpty((item) => item.name, translateString("assessmentName")),
            questionnaireId: validators.validateNotEmpty((item) => item.questionnaireId, translateString("audit")),
            responsibleId: validators.validateNotEmpty((item) => item.responsibleId, translateString("responsible")),
            groupEntityId: validators.validateNotEmpty(
                (item) => item.groupEntityId,
                "",
                () => step !== "selectGroupEntity",
                translateString("oneSenderIsRequired")
            ),
        }),
        [step]
    );

    const updater = {
        onAssessmentNameChange: (name: string) => {
            var temp = { ...model };
            temp.name = name;
            setModel(temp);
        },
        onDescriptionChange: (description: string) => {
            var temp = { ...model };
            temp.description = description;
            setModel(temp);
        },
        onQuestionnaireChange: (questionnaire?: QuestionnaireSelectableItem) => {
            const temp = { ...model! };
            temp.questionnaireId = questionnaire?.id;
            temp.questionnaireName = questionnaire?.name;
            setModel(temp);
        },
        onResponsibleChanged: (responsible: string | null) => {
            const temp = { ...model! };
            temp.responsibleId = responsible ?? undefined;
            setModel(temp);
        },
        onGroupEntityChanged: (groupEntityIds: Array<string>) => {
            const newModel = { ...model! };

            if (groupEntityIds.length === 0) {
                newModel.groupEntityId = undefined;
            } else if (groupEntityIds.length === 1) {
                newModel.groupEntityId = groupEntityIds[0];
            } else {
                newModel.groupEntityId = groupEntityIds[1];
            }

            setModel(newModel);
        },
    };

    const sendMutation = useMutation(sendApi);
    const isSending = sendMutation.isLoading;

    async function send() {
        await sendMutation.mutateAsync(undefined, {
            onSuccess: () => {
                snackBar.show(createElement(Trans, { i18nKey: "itemCreatedSuccess", values: { name: model?.name } }));
                props.onCreate();
            },
        });
    }

    const onNextOrSendClick = async () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        if (step === "first") {
            setStep("selectGroupEntity");
        } else if (step === "selectGroupEntity") {
            await send();
        }
    };

    let onPreviousButtonClick = () => {
        if (step === "selectGroupEntity") {
            setStep("first");
        }
    };

    function sendApi() {
        const saveModel: PublicInternalAssessmentTemplateSaveModel = {
            questionnaireId: model.questionnaireId!,
            questionnaireName: model.questionnaireName!,
            questionnaireIntroductionTitle: model.questionnaireIntroductionTitle!,
            groupEntityId: model.groupEntityId!,
            responsibleId: model.responsibleId!,
            description: model.description,
            name: model.name,
        };

        return post<{}>("/publicInternalAssessmentTemplates", saveModel);
    }

    function getDefaultModel() {
        return {
            name: "",
            description: "",
            questionnaireId: "",
            questionnaireName: "",
            questionnaireIntroductionTitle: "",
            responsibleId: "",
            groupEntityId: "",
        };
    }

    return {
        model,
        validation,
        updater,
        onNextOrSendClick,
        onPreviousButtonClick,
        step,
        selectableGroupEntitiesData,
        selectableGroupEntitiesLoading,
        selectableResponsiblesData,
        selectableResponsiblesLoading,
        isSending,
    };
}
