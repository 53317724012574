export interface AuditLogViewModel {
    entries: Array<AuditLogEntryViewModel>;
}

export interface AuditLogEntryViewModel {
    id: string;
    auditType: AuditTypeView;
    timeStamp: Date;
    name: string | null;
    entityType: string;
    properties: Array<AuditLogPropertyViewModel>;
}

export interface AuditLogPropertyViewModel {
    propertyName: string;
    from: string | null;
    to: string | null;
    type: AuditLogPropertyType;
}

export enum AuditTypeView {
    Created = 1,
    Updated = 2,
    Deleted = 3,
    Added = 4,
    Removed = 5,
}

export enum AuditLogPropertyType {
    Enum = 1,
    Date = 2,
    String = 3,
}
