import { useQuery } from "react-query";
import { AnnualWheelResponsibleFormProps } from "./AnnualWheelResponsibleForm";
import { get } from "../../../common/api/apiShared";
import { SelectableColoredItem } from "../../../processingActivity/ProcessingActivity.types";
import { AnnualWheelResponsibleFormModel, GroupCompanyResponsible } from "./AnnualWheelResponsibleForm.types";
import { ApprovalOption } from "../../AnnualWheel.types";
import { isNullOrWhitespace } from "../../../common/stringOperations.ts";
import { useSelectableResponsiblesAssignees } from "../../useSelectableResponsiblesAssignees.ts";

export function useAnnualWheelResponsibleForm(props: AnnualWheelResponsibleFormProps) {
    let selectableBusinessAreaQuery = useQuery("selectableBusinessAreas", () => get<Array<SelectableColoredItem>>("/BusinessAreas/businessareas"));
    const responsiblesAssignees = useSelectableResponsiblesAssignees(props.saveModel?.assignees, props.saveModel?.responsible);

    const onBusinessAreaChange = (businessAreas: Array<string>) => {
        props.onChange(
            new AnnualWheelResponsibleFormModel(
                businessAreas,
                props.saveModel!.responsible,
                props.saveModel?.groupCompanies,
                props.saveModel?.approval,
                props.saveModel?.assignees
            )
        );
    };

    const onResponsibleChange = (responsible: string | null) => {
        let approval = props.saveModel?.allCompaniesHasUsers(props.saveModel.groupCompanies) ? props.saveModel.approval : ApprovalOption.ApprovalNotRequired;

        props.onChange(
            new AnnualWheelResponsibleFormModel(
                props.saveModel?.businessAreas,
                responsible,
                props.saveModel?.groupCompanies,
                approval,
                props.saveModel?.assignees
            )
        );
    };

    const onAssigneeChange = (assignees: Array<string>) => {
        props.onChange(
            new AnnualWheelResponsibleFormModel(
                props.saveModel!.businessAreas,
                props.saveModel!.responsible,
                props.saveModel?.groupCompanies,
                props.saveModel?.approval,
                assignees
            )
        );
    };
    const onApprovalChange = (approvalOption: string) => {
        props.onChange(
            new AnnualWheelResponsibleFormModel(
                props.saveModel?.businessAreas,
                props.saveModel?.responsible,
                props.saveModel?.groupCompanies,
                Number(approvalOption),
                props.saveModel?.assignees
            )
        );
    };

    const onGroupCompanyResponsibleChange = (userId: string | null, groupCompanyId: string) => {
        let userGroupCompanies = props.saveModel?.groupCompanies ?? [];
        userGroupCompanies.find((x) => x.groupCompanyId === groupCompanyId)!.userId = userId;
        const approvaal =
            isNullOrWhitespace(userId ?? undefined) && isNullOrWhitespace(props.saveModel?.responsible)
                ? ApprovalOption.ApprovalNotRequired
                : props.saveModel?.approval;

        props.onChange(
            new AnnualWheelResponsibleFormModel(
                props.saveModel?.businessAreas,
                props.saveModel?.responsible,
                userGroupCompanies,
                approvaal,
                props.saveModel?.assignees
            )
        );
    };

    const onGroupCompaniesChange = (groupCompanies: Array<string>) => {
        let groupCompaniesTest: Array<GroupCompanyResponsible> = [];
        let approval = props.saveModel?.approval;
        if (groupCompanies.length === 0) {
            approval = isNullOrWhitespace(props.saveModel?.responsible) ? ApprovalOption.ApprovalNotRequired : props.saveModel?.approval;
            groupCompaniesTest = [];
        } else {
            groupCompanies.forEach((companyId) => {
                const foundMatch = props.saveModel?.groupCompanies.find((x) => x.groupCompanyId === companyId);
                if (foundMatch) {
                    groupCompaniesTest.push({ userId: foundMatch.userId, groupCompanyId: companyId });
                } else {
                    approval = isNullOrWhitespace(props.saveModel?.responsible) ? ApprovalOption.ApprovalNotRequired : props.saveModel?.approval;
                    groupCompaniesTest.push({ userId: null, groupCompanyId: companyId });
                }
            });
        }

        props.onChange(
            new AnnualWheelResponsibleFormModel(
                props.saveModel?.businessAreas,
                props.saveModel?.responsible,
                groupCompaniesTest,
                approval,
                props.saveModel?.assignees
            )
        );
    };

    return {
        selectableBusinessAreaLoading: selectableBusinessAreaQuery.isLoading,
        selectableBusinessAreaData: selectableBusinessAreaQuery.data,
        usersIsLoading: responsiblesAssignees.responsiblesLoading,
        selectableResponsibles: responsiblesAssignees.selectableResponsibles,
        selectableAssignees: responsiblesAssignees.selectableAssignees,
        onBusinessAreaChange,
        onResponsibleChange,
        onApprovalChange,
        onAssigneeChange,
        onGroupCompaniesChange,
        onGroupCompanyResponsibleChange,
    };
}
