import DeclarationControlDialogContent from "../declarationControlDialogContent/DeclarationControlDialogContent";
import { IDeclarationControlDialogWrapper } from "../DeclarationControlDialogWrapper";
import useAddDeclarationControlDialogHooks from "./AddDeclarationControlDialog.hooks";

function AddDeclarationControlDialog(props: IDeclarationControlDialogWrapper) {
    const { modelUpdater, onSaveClick, saveModel, isSaving, validation } = useAddDeclarationControlDialogHooks(props);

    return (
        <DeclarationControlDialogContent
            {...props}
            isEdit={false}
            modelUpdater={modelUpdater}
            onOkClick={onSaveClick}
            saveModel={saveModel}
            isLoading={false}
            isSaving={isSaving}
            validation={validation}
        />
    );
}

export default AddDeclarationControlDialog;
