import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { get, put } from "../../../common/api/apiShared";
import { Result } from "../../../common/api/result";
import { useAllSelectableUsersQuery, useSelectableDocumentTypesByDocumentUsageTypeQuery } from "../../../common/hooks/useSelectableItemQueries";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useValidator } from "../../../common/hooks/validation";
import { useTranslation } from "../../../localization/useTranslation";
import { LegalEntityDocumentUpdateModel } from "../LegalEntityDocumentTab.types";
import { useDocumentHook } from "../../../documents/Documents.hook";
import { IEditLegalEntityDocumentDialogProps } from "./EditLegalEntityDocumentDialog";
import { LegalEntityDocumentViewModel } from "./EditLegalEntityDocumentDialog.types";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";

export function useEditLegalEntityDocumentHook(props: IEditLegalEntityDocumentDialogProps) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const { gdpo } = useUserContext();

    const [document, setDocument] = useState<LegalEntityDocumentUpdateModel>();
    const [documentsUploading, setDocumentsUploading] = useState(false);

    const { data, isLoading } = useAllSelectableUsersQuery(props.isExtended);
    const url = `/legalEntity/${props.selectedDocument.legalEntityId}/document/${props.selectedDocument.documentId}`;
    const legalEntityDocumentQuery = useQuery(url, () => get<LegalEntityDocumentViewModel>(url));

    var legalEntityDocumentData = legalEntityDocumentQuery.data;
    const { data: documentTypes, isLoading: documentTypesIsLoading } = useSelectableDocumentTypesByDocumentUsageTypeQuery(
        props.selectedDocument.documentTypeUsage
    );

    useEffect(() => {
        if (legalEntityDocumentData) {
            setDocument({
                documentId: legalEntityDocumentData!.documentId,
                name: legalEntityDocumentData!.name,
                ownerId: legalEntityDocumentData!.owner,
                documentTypeId: legalEntityDocumentData!.documentTypeId,
                addDocumentToSharings: [],
                base64Data: "",
                fileName: "",
                isLink: false,
                link: "",
                status: legalEntityDocumentData.status,
                groupEntityAccessIds: legalEntityDocumentData.groupEntityAccessIds,
                allGroupEntities: legalEntityDocumentData.allGroupEntities,
            });
        }
    }, [legalEntityDocumentData]);

    const validation = useValidator<LegalEntityDocumentUpdateModel>(
        (validators) => ({
            name: validators.validateNotEmpty((item) => item.name, translateString("name")),
            groupEntityAccessIds: validators.validateNotEmpty(
                (item) => item.groupEntityAccessIds,
                "",
                (item) => item.allGroupEntities,
                translateString("oneGroupCompanyIsRequired")
            ),
        }),
        []
    );

    let documentStatus = useDocumentHook().selecableDocumentStatus;

    const editMutation = useMutation(getEditMutation);

    function getEditMutation(doc: LegalEntityDocumentUpdateModel) {
        return put<string>(`/LegalEntity/${doc.documentId}/Documents`, doc);
    }

    const editDocument = () => {
        editMutation.mutateAsync(document!, {
            onSuccess: (response: Result<string>) => {
                snackbar.show(translateString("documentWasUpdated"));

                if (props.onSave) {
                    props.onSave();
                }

                props.onCloseDialog();
            },
        });
    };

    function onGroupEntityAccessIdsChange(groupEntityIds: Array<string>) {
        let tempModel = { ...document! };
        tempModel.groupEntityAccessIds = groupEntityIds;

        if (gdpo) {
            if (groupEntityIds.length === 0) {
                tempModel.allGroupEntities = true;
            } else {
                tempModel.allGroupEntities = false;
            }
        }

        setDocument(tempModel);
    }

    return {
        document,
        setDocument,
        documentTypes,
        inProgress: editMutation.isLoading,
        validation,
        validateDocumentForm,
        userOptions: data,
        userOptionsIsLoading: isLoading,
        isLoading: legalEntityDocumentQuery.isLoading,
        documentsUploading,
        setDocumentsUploading,
        documentStatus,
        onGroupEntityAccessIdsChange,
        documentTypesIsLoading,
    };

    function validateDocumentForm() {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }
        editDocument();
    }
}
