import { useState } from "react";
import { useQuery } from "react-query";
import { get, postGet } from "../../common/api/apiShared";
import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useHistoryWithReferer } from "@dotlegal/dotlegal-ui-components";
import { useStateUrlParamsArray } from "../../common/hooks/useStateUrlParams";
import { useTranslation } from "../../localization/useTranslation";
import { useUrlProvider } from "../../useUrlProvider";
import { ILegalEntitiesProps } from "./LegalEntities";
import {
    LegalEntitiesRowViewModel,
    LegalEntitiesViewModel,
    LegalEntityAuditInformationsDto,
    LegalEntityPageEnum,
    LegalEntityRowModel,
} from "./LegalEntities.types";
import { RiskLevel } from "../legalEntityClassificationTab/LegalEntityClassificationTab.types";
import { setFirstLetterToLowerCase } from "../../common/stringOperations";
import { SelectableColoredItem, SelectableItem } from "../../processingActivity/ProcessingActivity.types";
import { LegalEntityAuditStatus } from "../legalEntityAuditTab/LegalEntityAudit.types";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import {
    LegalEntityAuditInformation,
    LegalEntityAuditInformationViewModel,
    LegalEntityClassificationInformationViewModel,
} from "../../legalEntityAuditGroup/legalEntityAuditGroupDraftOverview/auditGroupLegalEntitySelector/AuditGroupLegalEntitySelector.types";

export function useLegalEntitiesHook(props: ILegalEntitiesProps) {
    const { translateString, translateCountry, translateLegalEntityAuditStatus } = useTranslation();
    const { getLegalEntityUrl, getGroupEntityUrl, getCustomerLegalEntityUrl, getOtherLegalEntityUrl } = useUrlProvider();
    const historyWithReferer = useHistoryWithReferer();
    const { hasVendorManagementAccess } = usePlanContext();

    const [searchString, setSearchString] = useState("");
    const [searchedLegalEntityStatus, setSearchedLegalEntityStatus] = useStateUrlParamsArray("legalEntityStatus", []);
    const [searchedCountry, setSearchedCountry] = useStateUrlParamsArray("countryCodes", []);
    const [searchedRiskLevel, setSearchedRisklevel] = useStateUrlParamsArray("riskLevel", []);
    const [searchedClassifications, setSearchedClassifications] = useStateUrlParamsArray("classifications", []);
    const [searchedComplianceAreas, setSearchedComplianceAreas] = useStateUrlParamsArray("complianceAreas", []);
    const [searchedResponsibles, setSearchedResponsibles] = useStateUrlParamsArray("responsibles", []);
    const [searchedLatestAudits, setSearchedLatestAudits] = useStateUrlParamsArray("latestAudits", []);
    const [searchedBusinessAreas, setSearchedBusinessAreas] = useStateUrlParamsArray("businessAreas", []);
    const [searchedLatestAuditStatus, setSearchedLatestAuditStatus] = useStateUrlParamsArray("latestAuditStatus", []);
    const [showAddLegalEntityDialog, setShowAddLegalEntityDialog] = useState(false);

    const url = `/LegalEntities?page=${props.legalEntityPage}`;
    let { isLoading, data, refetch } = useQuery(url, () => get<LegalEntitiesViewModel>(url));

    const auditUrl = `/legalEntityAudit/auditInformations`;
    let auditQuery = useQuery(
        auditUrl,
        () =>
            postGet<Array<LegalEntityAuditInformation>>(auditUrl, {
                legalEntityIds: data?.legalEntityRows.map((x) => x.legalEntityId),
                fetchLatestAudit: props.legalEntityPage !== LegalEntityPageEnum.GroupEntity,
            } as LegalEntityAuditInformationsDto),
        {
            enabled: hasVendorManagementAccess && !isLoading,
        }
    );

    if (auditQuery.data) {
        auditQuery.data.forEach((s) => {
            let entity = data?.legalEntityRows.find((x) => x.legalEntityId === s.legalEntityId);

            if (entity) {
                entity.classifications = s.classifications;
                entity.latestAudit = s.latestAudit;
            }
        });
    }

    const getFilteredRows = (overviewRows: Array<LegalEntitiesRowViewModel>) => {
        let tempData = [...overviewRows];
        if (searchedCountry.length > 0 && searchedCountry[0] !== "") {
            tempData = tempData.filter((row) => searchedCountry.indexOf(row.countryCode.toLowerCase()) >= 0);
        }

        if (searchedRiskLevel.length > 0 && searchedRiskLevel[0] !== "") {
            tempData = tempData.filter(
                (row) =>
                    row.classifications.filter(
                        (classification) =>
                            (classification && searchedRiskLevel.indexOf(classification.riskLevel?.toString() ?? "") >= 0) ||
                            (searchedRiskLevel.includes("null") && classification.riskLevel === null)
                    ).length > 0
            );
        }

        if (searchedClassifications.length > 0 && searchedClassifications[0] !== "") {
            tempData = tempData.filter(
                (row) => row.classifications.filter((classification) => searchedClassifications.indexOf(classification.questionnaireId) >= 0).length > 0
            );
        }

        if (searchedComplianceAreas.length > 0 && searchedComplianceAreas[0] !== "") {
            tempData = tempData.filter((row) => row.complianceAreas.filter((area) => searchedComplianceAreas.indexOf(area.id) >= 0).length > 0);
        }

        if (searchedResponsibles.length > 0 && searchedResponsibles[0] !== "") {
            tempData = tempData.filter((row) => searchedResponsibles.indexOf(row.responsible?.id ?? "") >= 0);
        }

        if (searchedLatestAudits.length > 0 && searchedLatestAudits[0] !== "") {
            tempData = tempData.filter((row) => searchedLatestAudits.indexOf(row.latestAudit?.questionnaireId ?? "") >= 0);
        }

        if (searchedBusinessAreas.length > 0 && searchedBusinessAreas[0] !== "") {
            tempData = tempData.filter(
                (row) => row.businessAreas.filter((businessArea) => searchedBusinessAreas.indexOf(businessArea.id ?? "") >= 0).length > 0
            );
        }

        if (searchedLatestAuditStatus.length > 0 && searchedLatestAuditStatus[0] !== "") {
            tempData = tempData.filter((row) => row.latestAudit && searchedLatestAuditStatus.indexOf(row.latestAudit.status.toString()) >= 0);
        }

        if (searchedLegalEntityStatus.length > 0 && searchedLegalEntityStatus[0] !== "") {
            tempData = tempData.filter(
                (row) =>
                    (row.isActive && searchedLegalEntityStatus.indexOf("active") >= 0) ||
                    (!row.isActive && searchedLegalEntityStatus.indexOf("deactivated") >= 0)
            );
        }

        return tempData;
    };

    let countrySelectOptions: Array<DotLegalSelectOption> = [];
    let levelOfProtectionOptions: Array<DotLegalSelectOption> = [];
    let riskLevelSelectOptions: Array<DotLegalSelectOption> = [];
    let businessAreaSelectOptions: Array<DotLegalSelectOption> = [];
    let responsibleSelectOptions: Array<DotLegalSelectOption> = [];
    let complianceAreaSelectOptions: Array<DotLegalSelectOption> = [];
    let latestAuditFilterOptions: Array<DotLegalSelectOption> = [];
    let classificationFilterOptions: Array<DotLegalSelectOption> = [];
    let latestAuditStatusFilterOptions: Array<DotLegalSelectOption> = [];

    const hasCompanies = isLoading || data!.legalEntityRows.length > 0;

    let legalEntitiesData;
    if (data) {
        legalEntitiesData = { ...data };

        data.legalEntityRows.forEach((x) => {
            createCountrySelectOptions(x.countryCode);
            createBusinessAreaFilterOptions(x.businessAreas);
            createResponsibleUserFilterOptions(x.responsible);
            createComplianceAreaFilterOptions(x.complianceAreas);
            createClassificationFilterOptions(x.classifications);
            createLatestAuditFilterOptions(x.latestAudit);
        });

        createLatestAuditStatusFilterOption(data.legalEntityRows.flatMap((x) => x.latestAudit?.status));
        createRiskLevelSelectOptions(data?.legalEntityRows.flatMap((x) => x.classifications.flatMap((x) => x.riskLevel)));
        sortSelectOptions();
        legalEntitiesData.legalEntityRows = getFilteredRows(data.legalEntityRows);
    }

    const onGoToLegalEntityClick = (legalEntityId: string) => {
        switch (props.legalEntityPage) {
            case LegalEntityPageEnum.Vendor:
                historyWithReferer.push(getLegalEntityUrl(legalEntityId));
                break;
            case LegalEntityPageEnum.GroupEntity:
                historyWithReferer.push(getGroupEntityUrl(legalEntityId));
                break;
            case LegalEntityPageEnum.Customer:
                historyWithReferer.push(getCustomerLegalEntityUrl(legalEntityId));
                break;
            case LegalEntityPageEnum.Other:
                historyWithReferer.push(getOtherLegalEntityUrl(legalEntityId));
                break;
            default: {
                throw new Error(`The given page '${props.legalEntityPage}' for legal entity was not found`);
            }
        }
    };

    const getBreadCrumbs = () => {
        switch (props.legalEntityPage) {
            case LegalEntityPageEnum.Vendor:
                return [{ name: translateString("vendors") }];
            case LegalEntityPageEnum.GroupEntity:
                return [{ name: translateString("groupCompanies") }];
            case LegalEntityPageEnum.Customer:
                return [{ name: translateString("customers") }];
            case LegalEntityPageEnum.Other:
                return [{ name: translateString("other") }];
            default: {
                throw new Error(`The given page '${props.legalEntityPage}' for legal entity was not found`);
            }
        }
    };

    const rows: undefined | Array<LegalEntityRowModel> = legalEntitiesData?.legalEntityRows?.map((a) => {
        return {
            ...a,
            countryName: translateCountry(a.countryCode),
            businessAreaNames: a.businessAreas.map((b) => b.name).join(", "),
            responsibleName: a.responsible?.name,
            complianceAreaNames: a.complianceAreas.map((b) => b.name).join(", "),
            certificationNames: a.certifications.map((b) => b.name).join(", "),
            assetNames: a.assets.map((b) => b.name).join(", "),
            statusString: a.latestAudit ? translateLegalEntityAuditStatus(a.latestAudit.status.toString()) : "",
            latestAuditName: a.latestAudit ? a.latestAudit.name : "",
        };
    });

    const refetchLegalEntities = () => {
        refetch();
    };

    return {
        isLoading,
        auditIsLoading: auditQuery.isLoading,
        searchedCountry,
        setSearchedCountry,
        countrySelectOptions,
        levelOfProtectionOptions,
        showAddLegalEntityDialog,
        setShowAddLegalEntityDialog,
        onGoToLegalEntityClick,
        getBreadCrumbs,
        hasCompanies,
        searchedRiskLevel,
        setSearchedRisklevel,
        riskLevelSelectOptions,
        searchedClassifications,
        setSearchedClassifications,
        searchedComplianceAreas,
        setSearchedComplianceAreas,
        searchedResponsibles,
        setSearchedResponsibles,
        searchedLatestAudits,
        setSearchedLatestAudits,
        searchedBusinessAreas,
        setSearchedBusinessAreas,
        searchedLatestAuditStatus,
        setSearchedLatestAuditStatus,
        businessAreaSelectOptions,
        responsibleSelectOptions,
        complianceAreaSelectOptions,
        latestAuditFilterOptions,
        classificationFilterOptions,
        latestAuditStatusFilterOptions,
        rows,
        searchedLegalEntityStatus,
        setSearchedLegalEntityStatus,
        searchString,
        setSearchString,
        refetchLegalEntities,
    };

    function createCountrySelectOptions(countryCode: string) {
        if (!countrySelectOptions.some((x) => x.id === countryCode.toLowerCase())) {
            if (countryCode === "generic") countrySelectOptions.push({ name: translateString(countryCode), id: countryCode });
            else countrySelectOptions.push({ name: translateCountry(countryCode), id: countryCode.toLowerCase() });
        }
    }

    function createRiskLevelSelectOptions(riskLevels: Array<RiskLevel | null>) {
        riskLevels.forEach((level) => {
            if (level && !riskLevelSelectOptions.some((x) => x.id === level?.toString())) {
                riskLevelSelectOptions.push({ id: level.toString(), name: translateString(setFirstLetterToLowerCase(RiskLevel[level])) });
            }
        });

        if (riskLevels.some((x) => x === null)) {
            riskLevelSelectOptions.push({ id: "null", name: translateString("notAnswered") });
        }
    }

    function createResponsibleUserFilterOptions(responsible: SelectableItem | null) {
        if (!responsibleSelectOptions.some((x) => x.id === responsible?.id) && responsible !== null) {
            responsibleSelectOptions.push({ id: responsible.id, name: responsible.name });
        }
    }

    function createBusinessAreaFilterOptions(businessAreas: Array<SelectableColoredItem>) {
        for (let businessArea of businessAreas) {
            if (!businessAreaSelectOptions.some((x) => x.id === businessArea.id)) {
                businessAreaSelectOptions.push({ id: businessArea.id, name: businessArea.name, color: businessArea.color });
            }
        }
    }

    function createComplianceAreaFilterOptions(complianceAreas: Array<SelectableItem>) {
        for (let complianceArea of complianceAreas) {
            if (!complianceAreaSelectOptions.some((x) => x.id === complianceArea.id)) {
                complianceAreaSelectOptions.push({ id: complianceArea.id, name: complianceArea.name });
            }
        }
    }

    function createLatestAuditFilterOptions(legalEntitiesAuditViewModel: LegalEntityAuditInformationViewModel | null) {
        if (legalEntitiesAuditViewModel !== null && !latestAuditFilterOptions.some((x) => x.id === legalEntitiesAuditViewModel.questionnaireId)) {
            latestAuditFilterOptions.push({ id: legalEntitiesAuditViewModel.questionnaireId, name: legalEntitiesAuditViewModel.name });
        }
    }

    function createClassificationFilterOptions(classifications: Array<LegalEntityClassificationInformationViewModel>) {
        classifications.forEach((classification) => {
            if (!classificationFilterOptions.some((x) => x.id == classification.questionnaireId)) {
                classificationFilterOptions.push({ id: classification.questionnaireId, name: classification.name });
            }
        });
    }

    function createLatestAuditStatusFilterOption(legalEntitiesStatus: Array<LegalEntityAuditStatus | undefined>) {
        legalEntitiesStatus.forEach((status) => {
            if (status !== undefined && !latestAuditStatusFilterOptions.some((x) => x.id === status.toString())) {
                latestAuditStatusFilterOptions.push({ id: status.toString(), name: translateLegalEntityAuditStatus(status.toString()) });
            }
        });
    }

    function sortSelectOptions() {
        countrySelectOptions.sort((a, b) => (a.id > b.id ? 1 : -1));
        latestAuditStatusFilterOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
        classificationFilterOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
        businessAreaSelectOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
        complianceAreaSelectOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
        responsibleSelectOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
        latestAuditFilterOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
    }
}
