import { DotLegalDialog, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import TrialUsageWarning from "../../common/components/TrialUsageWarning.tsx";
import { CopySystem } from "./CopySystemDialog.types.ts";
import { useCopySystem } from "./CopySystemDialog.hooks.ts";

export interface ICopySystemDialogProps {
    onCloseDialog: () => void;
    copyModel: CopySystem;
    onSave: () => void;
    shouldDisplayCreateWarning?: boolean;
}

function CopySystemDialog(props: ICopySystemDialogProps) {
    const { copySystem, inProgress, validation, copyModel, setCopyModel } = useCopySystem(props);
    const { translateString } = useTranslation();

    return (
        <DotLegalDialog
            inProgress={inProgress}
            size="sm"
            buttonOkText={translateString("copy")}
            header={translateString("copySystem")}
            onOkClick={async () => {
                copySystem();
            }}
            onDialogClose={props.onCloseDialog}
            open
        >
            <DotLegalTextField
                debounce={false}
                size="small"
                label={translateString("system")}
                value={copyModel.name}
                onChange={(x) => {
                    let tempModel = { ...copyModel };
                    tempModel.name = x;
                    setCopyModel(tempModel);
                }}
                errorText={validation.getErrorsForDisplay("name")}
            />
            {props.shouldDisplayCreateWarning && <TrialUsageWarning textKey={"createExceedingNumberOfSystemWarning"} marginTop={2} />}
        </DotLegalDialog>
    );
}

export default CopySystemDialog;
