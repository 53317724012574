import { Trans } from "react-i18next";
import { TaskRawStateEnum } from "../../../annualWheel/AnnualWheel.types";
import { AnnualWheelActivityType, AnnualWheelPriority } from "../../../annualWheel/annualWheelDialog/AnnualWheelDialog.types";
import { DotLegalSelectOption } from "../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { getEnumValues } from "../../../common/enumOperations";
import { setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { useTranslation } from "../../../localization/useTranslation";
import { MasterDataSectionProps } from "./MasterDataSection";
import { createElement, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { get, put } from "../../../common/api/apiShared";
import { SelectableColoredItem } from "../../../processingActivity/ProcessingActivity.types";
import { useResponsibleQuery } from "../../../user/hooks/useResponsibleQuery";
import { TaskMasterDataSaveModel, TaskMasterDataViewModel } from "./MasterDataSection.types";
import { useOptimisticUpdate } from "../../../common/hooks/useOptimisticUpdate";
import { TrackingEvent, useTrackAIEvent } from "../../../processingActivity/hooks/useTracking";
import { isApprovalRequired } from "../../../annualWheel/ApprovalOptions";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { useComplianceAreaQuery, useGroupEntitiesForUserQuery } from "../../../common/hooks/useSelectableItemQueries";
import { useLocation } from "react-router-dom";
import { getTaskRelationUrl } from "../taskRelations/TaskRelations.hooks";
import { exhaustiveGuard } from "../../../common/utilities.ts";

export function useMasterDataSection(props: MasterDataSectionProps) {
    const isParentFinishedLoading = !props.isLoading;
    const optimisticUpdate = useOptimisticUpdate();
    const trackEvent = useTrackAIEvent();
    const { translateString } = useTranslation();
    const { userProfileId } = useUserContext();
    const [expandDetails, setExpandDetails] = useState(true);
    const location = useLocation();
    const taskUnderCreation = location.search.includes("create=true");
    const queryClient = useQueryClient();

    const taskMasterDataUrl = `/task/${props.taskId}/masterdata`;
    const { isLoading, data, isSuccess } = useQuery(taskMasterDataUrl, () => get<TaskMasterDataViewModel>(taskMasterDataUrl), {
        enabled: isParentFinishedLoading,
    });
    let businessAreaQuery = useQuery("selectableBusinessAreas", () => get<Array<SelectableColoredItem>>("/BusinessAreas/businessareas"), {
        enabled: isParentFinishedLoading,
    });

    let responsibleQuery = useResponsibleQuery(isParentFinishedLoading);
    let complianceAreaQuery = useComplianceAreaQuery(isParentFinishedLoading);
    let groupEntityQuery = useGroupEntitiesForUserQuery(isParentFinishedLoading && props.masterData?.isAdHocTask);

    let completedAllowed: string | JSX.Element | undefined = undefined;
    let options = new Array<DotLegalSelectOption>();

    useEffect(() => {
        if (props.passIsDoneLoading && isSuccess) {
            props.passIsDoneLoading(true);
        }
    }, [props, isSuccess]);

    if (data) {
        options.push({ name: getRawStateString(TaskRawStateEnum.Ready, translateString), id: TaskRawStateEnum.Ready.toString() });
        options.push({ name: getRawStateString(TaskRawStateEnum.InProgress, translateString), id: TaskRawStateEnum.InProgress.toString() });

        if (
            isApprovalRequired(data.approval) &&
            (data.responsible !== userProfileId || data!.taskState === TaskRawStateEnum.ReadyForApproval) &&
            data!.taskState !== TaskRawStateEnum.Completed
        ) {
            options.push({ name: getRawStateString(TaskRawStateEnum.ReadyForApproval, translateString), id: TaskRawStateEnum.ReadyForApproval.toString() });
        }

        if (canShowCompletedForProcessingActivityTask() && canCompleteTaskWithRequiredDocumentation() && canCompleteTaskWithRequiredWithResponsible()) {
            options.push({ name: getRawStateString(TaskRawStateEnum.Completed, translateString), id: TaskRawStateEnum.Completed.toString() });
        }

        if (props.masterData!.type === AnnualWheelActivityType.ProcessingActivityValidation && data!.taskState !== TaskRawStateEnum.Completed)
            completedAllowed = createElement(Trans, { i18nKey: "processingActivityTaskValidationFailed" });
        else if (isApprovalRequired(data.approval) && data!.taskState !== TaskRawStateEnum.Completed) {
            completedAllowed = createElement(Trans, { i18nKey: "approvalRequiredByResponsibleBeforeCompletion", values: { taskName: props.masterData?.name } });
        }
    }

    let responsiblesData;
    let assigneesData;
    if (responsibleQuery.userData && data) {
        responsiblesData = responsibleQuery
            .data(data.responsible)
            ?.filter((x) => !data.assignees.includes(x.id) || data.assignees.includes(data.responsible))
            .map((d) => {
                return d;
            });

        assigneesData = responsibleQuery
            .dataForMultiSelect(data.assignees)
            ?.filter((x) => x.id !== data.responsible || data.assignees.includes(data.responsible))
            .map((d) => {
                return d;
            });
    }

    const priorityOptions = () => {
        return getEnumValues(AnnualWheelPriority).map((x) => {
            var name = translateString(setFirstLetterToLowerCase(AnnualWheelPriority[x].toString()));
            return { name: name, id: x.toString() };
        });
    };

    const groupEntityOptions = () => {
        let groupEntities = groupEntityQuery.data ?? [];

        if (!groupEntities.some((x) => x.id === "all")) {
            groupEntities.push({ id: "all", name: translateString("entireGroup") });
        }

        return groupEntities;
    };

    function showAddToCalenderLink() {
        return props.masterData!.isOverdue === false && data!.taskState !== TaskRawStateEnum.Completed;
    }

    const masterDataUpdater = {
        onResponsibleChange: async (responsible: string | null) => {
            let model = { ...data! };
            model.responsible = responsible ?? "";
            await onTaskChange(model);
        },
        onAssigneesChange: async (assignees: Array<string>) => {
            let model = { ...data! };
            model.assignees = assignees;
            await onTaskChange(model);
        },
        onBusinessAreaChange: async (businessAreas: Array<string>) => {
            let model = { ...data! };
            model.businessAreas = businessAreas;
            await onTaskChange(model);
        },
        onComplianceAreaChange: async (complianceAreas: Array<string>) => {
            let model = { ...data! };
            model.complianceAreas = complianceAreas;
            await onTaskChange(model);
        },
        onPriorityChange: async (priority: string | null) => {
            let model = { ...data! };
            model.priority = Number(priority);
            await onTaskChange(model);
        },
        onProgressChange: async (state: string | null) => {
            let model = { ...data! };
            model.taskState = Number(state);
            await onTaskChange(model, false);
        },
        onDeadlineChange: async (date: Date | null) => {
            let model = { ...data! };

            if (date === null || isNaN(date!.getTime())) {
                model.deadline = null;
            } else {
                model.deadline = new Date(date!.getFullYear(), date!.getMonth(), 15);
            }

            onTaskChange(model);
        },
        onCustomIdChange: async (customId: string) => {
            let model = { ...data! };
            model.customId = customId;
            await onTaskChange(model);

            props.refetchTask();
        },
        onDocumentationRequiredChange: async (chosenItem: string | null) => {
            let model = { ...data! };
            model.documentationRequired = Number(chosenItem) === 1;
            await onTaskChange(model);
        },
        onApprovalChange: async (approvalOption: string) => {
            let model = { ...data! };
            model.approval = Number(approvalOption);
            await onTaskChange(model);
        },
        onNotificationDaysChange: async (value: string) => {
            let model = { ...data! };

            if (value) {
                model.notificationDays = Number(value);
            } else {
                model.notificationDays = null;
            }

            await onTaskChange(model);
        },
        onGroupEntityChange: async (value: string) => {
            let model = { ...data! };

            if (value === "all") {
                model.groupEntityId = undefined;
            } else {
                model.groupEntityId = value;
            }

            await onTaskChange(model, false, true);
        },
    };

    async function onTaskChange(viewModel: TaskMasterDataViewModel, updateTaskState: boolean = true, refetchTaskRelations: boolean = false) {
        await optimisticUpdate.putOnQueueAndSetQueryData(
            viewModel,
            taskMasterDataUrl,
            updateTask,
            mapToSaveModel(viewModel!, props.masterData!.isAdHocTask, updateTaskState)
        );

        if (refetchTaskRelations) {
            await queryClient.refetchQueries(getTaskRelationUrl(props.taskId!, props.masterData!.isAdHocTask));
        }

        trackEvent(TrackingEvent.AnnualWheelActivityTaskUpdated, { taskId: data?.id });
    }

    return {
        options,
        completedAllowed,
        data,
        responsiblesData,
        assigneesData,
        businessAreaData: businessAreaQuery.data,
        complianceAreas: complianceAreaQuery.data,
        isSectionLoading: isLoading || businessAreaQuery.isLoading || responsibleQuery.isLoading || props.isLoading,
        showAddToCalenderLink,
        priorityOptions,
        masterDataUpdater,
        groupEntities: groupEntityOptions(),
        expandDetails,
        setExpandDetails,
    };

    function mapToSaveModel(viewModel: TaskMasterDataViewModel, isAdHocTask: boolean, updateState: boolean) {
        const currentState = viewModel.taskState;

        return {
            businessAreas: viewModel.businessAreas,
            priority: viewModel.priority,
            responsible: viewModel.responsible,
            state:
                !props.masterData?.isAdHocTask && updateState
                    ? viewModel.taskState === TaskRawStateEnum.Ready
                        ? TaskRawStateEnum.InProgress
                        : currentState
                    : currentState,
            isAdHocTask: isAdHocTask,
            assignees: viewModel.assignees,
            deadline: viewModel.deadline,
            complianceAreas: viewModel.complianceAreas,
            customId: viewModel.customId,
            notificationDays: viewModel.notificationDays,
            groupEntityId: viewModel.groupEntityId,
            approval: viewModel.approval,
            documentationRequired: viewModel.documentationRequired,
            isInCreation: taskUnderCreation,
        };
    }

    async function updateTask(saveModel: TaskMasterDataSaveModel) {
        return await put<TaskMasterDataSaveModel>(`/Task/${props.taskId}/masterdata`, saveModel);
    }

    function canShowCompletedForProcessingActivityTask() {
        return (
            props.masterData!.type !== AnnualWheelActivityType.ProcessingActivityValidation ||
            (props.masterData!.type === AnnualWheelActivityType.ProcessingActivityValidation && data!.taskState === TaskRawStateEnum.Completed)
        );
    }

    function canCompleteTaskWithRequiredDocumentation() {
        return props.masterData!.documentationRequired === false || (props.masterData!.documentationRequired && props.masterData!.hasDocument);
    }

    function canCompleteTaskWithRequiredWithResponsible() {
        return (
            !isApprovalRequired(data?.approval) ||
            (isApprovalRequired(data?.approval) && data?.responsible === userProfileId) ||
            data?.taskState === TaskRawStateEnum.Completed
        );
    }
}

export function getRawStateString(state: TaskRawStateEnum, translateString: (key: string) => string) {
    switch (state) {
        case TaskRawStateEnum.Ready:
            return translateString("ready");
        case TaskRawStateEnum.InProgress:
            return translateString("inProgress");
        case TaskRawStateEnum.ReadyForApproval:
            return translateString("readyForApproval");
        case TaskRawStateEnum.Completed:
            return translateString("completed");
        default:
            exhaustiveGuard(state);
    }
}
