import { useQuery } from "react-query";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import { get } from "../../../common/api/apiShared";
import { getEnumValues } from "../../../common/enumOperations";
import { isNullOrWhitespace, setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { useTranslation } from "../../../localization/useTranslation";
import { AnnualWheelPriority } from "../AnnualWheelDialog.types";
import { SelectableAreaItem } from "../annualWheelResponsibleForm/AnnualWheelResponsibleForm.types";
import { AnnaulWheelBaseInformationProps } from "./AnnualWheelBaseInformationForm";
import { AnnualWheelBaseInformationFormModel } from "./AnnualWheelBaseInformationForm.types";
import { useEffect } from "react";

export function useAnnualWheelBaseInformation(props: AnnaulWheelBaseInformationProps) {
    const { translateString } = useTranslation();
    const { platformFeatures } = usePlanContext();
    let areasQuery = useQuery("selectableAreas", () => get<Array<SelectableAreaItem>>("/areas/selectableitems"));
    let activityQuery = useQuery("selectableAnnualWheelActivities", () => get<Array<SelectableAreaItem>>("/annualWheelActivity/selectableItems"), {
        enabled: props.isProcessingActivityValidationActivity === false,
    });

    useEffect(() => {
        if (areasQuery.data && platformFeatures.annualWheelComplianceArea) {
            var area = areasQuery.data.find((x) => x.framework === props.saveModel?.frameWork);

            if (area) {
                props.onChange(
                    new AnnualWheelBaseInformationFormModel(
                        props.saveModel?.priority,
                        props.saveModel?.customId,
                        props.saveModel?.documentationRequired,
                        [area.id],
                        props.saveModel?.relatedActivities,
                        props.saveModel?.tags ?? []
                    )
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areasQuery.data, props.saveModel?.frameWork]);

    const onCustomIdChange = (id: string | null) => {
        props.onChange(
            new AnnualWheelBaseInformationFormModel(
                props.saveModel?.priority,
                id,
                props.saveModel?.documentationRequired,
                props.saveModel?.areas,
                props.saveModel?.relatedActivities,
                props.saveModel?.tags ?? []
            )
        );
    };

    const onDocumentationRequiredChange = (required: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(
            new AnnualWheelBaseInformationFormModel(
                props.saveModel?.priority,
                props.saveModel?.customId,
                Number((required.target as HTMLInputElement).value) === 1,
                props.saveModel?.areas,
                props.saveModel?.relatedActivities,
                props.saveModel?.tags ?? []
            )
        );
    };

    const onAreaChange = (areas: Array<string>) => {
        props.onChange(
            new AnnualWheelBaseInformationFormModel(
                props.saveModel!.priority,
                props.saveModel!.customId,
                props.saveModel?.documentationRequired,
                areas,
                props.saveModel?.relatedActivities,
                props.saveModel?.tags ?? []
            )
        );
    };

    const onRelatedActivitiesChange = (activities: Array<string>) => {
        props.onChange(
            new AnnualWheelBaseInformationFormModel(
                props.saveModel!.priority,
                props.saveModel!.customId,
                props.saveModel?.documentationRequired,
                props.saveModel?.areas,
                activities,
                props.saveModel?.tags ?? []
            )
        );
    };

    const onPriorityChange = (priority: string | null) => {
        props.onChange(
            new AnnualWheelBaseInformationFormModel(
                isNullOrWhitespace(priority!) ? undefined : Number(priority),
                props.saveModel?.customId ?? null,
                props.saveModel?.documentationRequired,
                props.saveModel?.areas,
                props.saveModel?.relatedActivities,
                props.saveModel?.tags ?? []
            )
        );
    };

    const onTagsChange = (tags: Array<string>) => {
        props.onChange(
            new AnnualWheelBaseInformationFormModel(
                props.saveModel?.priority,
                props.saveModel?.customId ?? null,
                props.saveModel?.documentationRequired,
                props.saveModel?.areas,
                props.saveModel?.relatedActivities,
                tags
            )
        );
    };

    const priorityOptions = () => {
        return getEnumValues(AnnualWheelPriority).map((x) => {
            var name = translateString(setFirstLetterToLowerCase(AnnualWheelPriority[x].toString()));
            return { name: name, id: x.toString() };
        });
    };

    return {
        onPriorityChange,
        onCustomIdChange,
        onDocumentationRequiredChange,
        onAreaChange,
        onRelatedActivitiesChange,
        onTagsChange,
        priorityOptions,
        selectableAreas: areasQuery.data,
        selectableAreasLoading: areasQuery.isLoading,
        selectableActivities: activityQuery.data?.filter((x) => x.id !== props.activityId),
        selectableActivitiesLoading: activityQuery.isLoading,
    };
}
