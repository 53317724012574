import { useMutation } from "react-query";
import { DeclarationControlSaveModel } from "../declarationControlDialogContent/DeclarationControlDialogContent.types";
import { createElement, useState } from "react";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";
import { post } from "../../../../common/api/apiShared";
import { SelectableItem } from "../../../../processingActivity/ProcessingActivity.types";
import { useValidator } from "../../../../common/hooks/validation";
import { useTranslation } from "../../../../localization/useTranslation";
import { IDeclarationControlDialogWrapper } from "../DeclarationControlDialogWrapper";

export default function useAddDeclarationControlDialogHooks(props: IDeclarationControlDialogWrapper) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [saveModel, setSaveModel] = useState<DeclarationControlSaveModel>(getSaveModel());

    const validation = useValidator<DeclarationControlSaveModel>(
        (validators) => ({
            name: validators.validateNotEmpty((item) => item.name, translateString("name")),
            responsibleId: validators.validateNotEmpty((item) => item.responsibleId, translateString("responsible")),
        }),
        []
    );

    const saveDeclarationControlMutation = useMutation(createDeclarationControlApiUrl);

    const saveDeclarationControl = () => {
        saveDeclarationControlMutation.mutateAsync(saveModel!, {
            onSuccess: (response: any) => {
                snackbar.show(createElement(Trans, { i18nKey: "itemCreatedSuccess", values: { name: saveModel.name } }));
                props.onControlSaved();
            },
        });
    };

    function createDeclarationControlApiUrl(saveModel: DeclarationControlSaveModel) {
        return post<string>(`/declarationControl/${props.declarationId}`, saveModel);
    }

    const modelUpdater = {
        onNameChange: (name: string) => {
            var temp = { ...saveModel };
            temp.name = name;
            setSaveModel(temp);
        },
        onIDChange: (id: string) => {
            var temp = { ...saveModel };
            temp.customId = id;
            setSaveModel(temp);
        },
        onDescriptionChange: (description: string) => {
            var temp = { ...saveModel };
            temp.description = description;
            setSaveModel(temp);
        },
        onResponsibleChanged: (responsible: string | null) => {
            const temp = { ...saveModel! };
            temp.responsibleId = responsible ?? undefined;
            setSaveModel(temp);
        },
        onCategoryChanged: (category: SelectableItem | undefined) => {
            const temp = { ...saveModel! };
            temp.categoryId = category?.id ?? undefined;
            setSaveModel(temp);
        },
        onAddTask: (taskId: string) => {
            const temp = { ...saveModel! };
            temp.taskIds.push(taskId);
            setSaveModel(temp);
        },
        onRemoveTask: (taskId: string) => {
            const temp = { ...saveModel! };
            temp.taskIds = temp.taskIds.filter((id) => id !== taskId);
            setSaveModel(temp);
        },
    };

    function onSaveClick() {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        saveDeclarationControl();
    }

    return {
        saveModel,
        modelUpdater,
        isSaving: saveDeclarationControlMutation.isLoading,
        validation,
        onSaveClick,
    };

    function getSaveModel(): DeclarationControlSaveModel {
        return {
            categoryId: undefined,
            customId: "",
            description: "",
            name: "",
            responsibleId: undefined,
            taskIds: [],
        };
    }
}
