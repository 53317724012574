import { useTranslation } from "../localization/useTranslation.ts";
import { useState } from "react";
import { useStateSessionStorage } from "@dotlegal/dotlegal-ui-components";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider.tsx";

export const onlyUserSpecificContractsStorageKey = "contractsUserSpecific";
export function useContracts() {
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();
    const [onlyUserSpecific, setOnlyUserSpecific] = useStateSessionStorage(onlyUserSpecificContractsStorageKey, !permissions.canAccessAllData);
    const [showCreateDialog, setShowCreateDialog] = useState(false);

    const breadCrumbs = [{ name: translateString("contracts") }];
    return { breadCrumbs, onlyUserSpecific, setOnlyUserSpecific, showCreateDialog, setShowCreateDialog };
}
