import { Box, Divider, Grid } from "@mui/material";
import { AnnualWheelActivityTemplateItem } from "../../AnnualWheelDialog.types";
import { SelectableActivityTemplate } from "../selectableActivityTemplate/SelectableActivityTemplate";
import React from "react";
import { DotLegalPrettyScrollbar } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../../localization/useTranslation";
import { ISOCategory } from "./IsoTemplates.types";
import { SelectedAnnualWheelActivityTemplate } from "../../../annualWheelAddEditDialog/AnnualWheelAddEditDialog.types.ts";

export interface IISOTemplates {
    templates: Array<ISOCategory>;
    onSelectActivity: (activity: AnnualWheelActivityTemplateItem) => void;
    selectedActivity?: SelectedAnnualWheelActivityTemplate;
    searchField: JSX.Element;
    additionalFilterField?: JSX.Element;
}

function ISOTemplates(props: IISOTemplates) {
    const { translateISO27001CategoryTypes } = useTranslation();

    const getSelectableActivity = (activity: AnnualWheelActivityTemplateItem) => {
        return (
            <SelectableActivityTemplate
                key={activity.type}
                activity={activity}
                selectedActivity={props.selectedActivity}
                onClick={(activity: AnnualWheelActivityTemplateItem) => props.onSelectActivity(activity)}
                addCustomIdToHeader
            />
        );
    };

    return (
        <React.Fragment>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {props.additionalFilterField}
                {props.searchField}
            </Box>
            <DotLegalPrettyScrollbar>
                <Box sx={{ p: "2px" }}>
                    {props.templates
                        .filter((x) => x.hasTemplates())
                        .sort((a, b) => (a!.categoryType > b!.categoryType ? 1 : -1))
                        .map((x, index) => {
                            return (
                                <React.Fragment key={x.categoryType}>
                                    <Box
                                        key={x.categoryType}
                                        sx={(theme) => ({
                                            marginTop: theme.spacing(2),
                                            marginLeft: theme.spacing(1),
                                            fontWeight: theme.typography.fontWeightBold,
                                            color: theme.palette.grey[500],
                                        })}
                                    >
                                        {translateISO27001CategoryTypes(x.categoryType.toString())}
                                    </Box>
                                    <Grid container columnSpacing={1}>
                                        {x.templates.map((activity) => {
                                            return getSelectableActivity(activity);
                                        })}
                                    </Grid>
                                    {++index !== props.templates.length && <Divider sx={{ marginTop: 1, marginBottom: 3, color: "#D9DAEE" }} />}
                                </React.Fragment>
                            );
                        })}
                </Box>
            </DotLegalPrettyScrollbar>
        </React.Fragment>
    );
}

export default ISOTemplates;
