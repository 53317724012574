import { createElement, useEffect, useState } from "react";
import { QuestionnaireSelectableItem } from "../LegalEntityAudit.types";
import { SelectableItem } from "../../../processingActivity/ProcessingActivity.types";
import { LegalEntityContactRow } from "../../legalEntityContactTab/LegalEntityContactTab.types";
import { post } from "../../../common/api/apiShared";
import { useMutation } from "react-query";
import { LegalEntityAuditSaveModel, SendAuditViewModel } from "./SendAuditDialog.types";
import { ISendAuditDialogProps } from "./SendAuditDialog";
import { Trans } from "react-i18next";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useValidator } from "../../../common/hooks/validation";
import { useTranslation } from "../../../localization/useTranslation";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { useResponsibleQuery } from "../../../user/hooks/useResponsibleQuery";
import { UserSelectableItem } from "../../../user/User.types";
import { useGroupEntitiesQuery } from "../../../processingActivity/hooks/useGroupEntitiesQuery.ts";

export function useSendAuditDialog(props: ISendAuditDialogProps) {
    const { translateString } = useTranslation();
    const snackBar = useDotLegalSnackbar();
    const isCopy = props.questionnaire !== undefined;

    const { gdpo, customerName } = useUserContext();

    const groupEntitiesQuery = useGroupEntitiesQuery();
    let responsibleQuery = useResponsibleQuery(true);

    let selectableGroupEntitiesData = groupEntitiesQuery.allGroupEntities;
    let selectableGroupEntitiesLoading = groupEntitiesQuery.isLoading;

    let selectableResponsiblesData: Array<UserSelectableItem> = [];
    if (selectableGroupEntitiesData) {
        selectableResponsiblesData = responsibleQuery.data(undefined) ?? [];
    }

    let selectableresponsiblesLoading = responsibleQuery.isLoading;

    useEffect(() => {
        if (selectableGroupEntitiesData && selectableGroupEntitiesData.length === 1 && !gdpo) {
            onGroupEntityChanged([selectableGroupEntitiesData[0].id]);
        } else if (gdpo) {
            onGroupEntityChanged([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectableGroupEntitiesData]);

    const [step, setStep] = useState<"first" | "selectGroupEntity" | "send">("first");

    const [model, setModel] = useState<SendAuditViewModel>({
        legalEntityId: props.legalEntityId,
        legalEntityName: props.legalEntityName,
        systemIds: [],
        systemNames: [],
        deadline: null,
        groupEntityId: undefined,
        groupEntityName: "",
        questionnaireId: props.questionnaire?.id,
        questionnaireName: props.questionnaire?.name,
    });

    const validation = useValidator<SendAuditViewModel>(
        (validators) => ({
            questionnaireId: validators.validateNotEmpty((item) => item.questionnaireId, translateString("audit")),
            contactId: validators.validateNotEmpty((item) => item.contactId, translateString("contact")),
            deadline: validators.validateTodayOrInTheFuture((item) => item.deadline, translateString("deadline")),
            groupEntityId: validators.validateNotEmpty(
                (item) => item.groupEntityId,
                "",
                () => gdpo || step !== "selectGroupEntity",
                translateString("oneSenderIsRequired")
            ),
            responsibleId: validators.validateNotEmpty((item) => item.responsibleId, translateString("responsible")),
        }),
        [step]
    );

    const sendMutation = useMutation(sendUrl, {
        onSuccess: (response: any) => {
            snackBar.show(
                createElement(Trans, {
                    i18nKey: "legalEntityAuditSentMessage",
                    values: { questionnaireName: model.questionnaireName, contactName: model.contactName },
                })
            );
            props.onAuditSent();
        },
    });

    const copyMutation = useMutation(copyUrl, {
        onSuccess: (response: any) => {
            if (response.success()) {
                snackBar.show(
                    createElement(Trans, {
                        i18nKey: "legalEntityAuditSentMessage",
                        values: { questionnaireName: model.questionnaireName, contactName: model.contactName },
                    })
                );
            } else {
                snackBar.show(
                    createElement(Trans, {
                        i18nKey: "legalEntityAuditCopyFailed",
                        values: { questionnaireName: model.questionnaireName, contactName: model.contactName },
                    }),
                    "warning"
                );
            }
            props.onAuditSent();
        },
    });

    const onAuditChanged = (questionnaire: QuestionnaireSelectableItem | undefined) => {
        const newModel = { ...model! };

        newModel.questionnaireId = questionnaire?.id;
        newModel.questionnaireName = questionnaire?.name;
        setModel(newModel);
    };

    const onContactChanged = (contact?: LegalEntityContactRow) => {
        const newModel = { ...model! };
        newModel.contactId = contact?.id;
        newModel.contactName = contact?.name;
        newModel.contactEmail = contact?.email;
        setModel(newModel);
    };
    const onDateChanged = (date: Date | null) => {
        const newModel = { ...model! };
        newModel.deadline = date!;
        setModel(newModel);
    };

    const onSystemsChanged = (systems: Array<SelectableItem>) => {
        const newModel = { ...model! };
        newModel.systemIds = systems.map((s) => s.id);
        newModel.systemNames = systems.map((s) => s.name);
        setModel(newModel);
    };

    const onResponsibleChanged = (responsible: string | null) => {
        const newModel = { ...model! };
        newModel.responsibleId = responsible ?? undefined;
        setModel(newModel);
    };

    const onGroupEntityChanged = (groupEntityIds: Array<string>) => {
        const newModel = { ...model! };

        if (groupEntityIds.length === 0) {
            newModel.groupEntityId = undefined;
            newModel.groupEntityName = customerName;
        } else if (groupEntityIds.length === 1) {
            newModel.groupEntityId = groupEntityIds[0];
            newModel.groupEntityName = selectableGroupEntitiesData!.find((x) => x.id === groupEntityIds[0])!.name;
        } else {
            newModel.groupEntityId = groupEntityIds[1];
            newModel.groupEntityName = selectableGroupEntitiesData!.find((x) => x.id === groupEntityIds[1])!.name;
        }

        setModel(newModel);
    };

    async function send() {
        if (isCopy) {
            await copyMutation.mutateAsync();
        } else {
            await sendMutation.mutateAsync();
        }
    }

    function copyUrl() {
        return post<{}>(`/legalEntityAudit/${props.responseId}/copy`, getSaveModel());
    }

    function sendUrl() {
        return post<{}>("/legalEntityAudit", getSaveModel());
    }

    function getSaveModel() {
        const saveModel: LegalEntityAuditSaveModel = {
            legalEntityId: model.legalEntityId!,
            questionnaireId: model.questionnaireId!,
            deadline: model.deadline!,
            contactId: model.contactId!,
            systemIds: model.systemIds,
            systemNames: model.systemNames,
            groupEntityId: model.groupEntityId,
            groupEntityName: model.groupEntityName,
            responsibleId: model.responsibleId!,
        };

        return saveModel;
    }

    const showSelectedGroupEntityStep = selectableGroupEntitiesData && selectableGroupEntitiesData.length > 1;

    const onNextOrSendClick = async () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        if (step === "first") {
            if (showSelectedGroupEntityStep) {
                setStep("selectGroupEntity");
            } else {
                setStep("send");
            }
        } else if (step === "selectGroupEntity") {
            setStep("send");
        } else {
            await send();
        }
    };

    let onPreviousButtonClick = () => {
        if (step === "send") {
            if (showSelectedGroupEntityStep) {
                setStep("selectGroupEntity");
            } else {
                setStep("first");
            }
        } else {
            setStep("first");
        }
    };

    validation.check(model);

    return {
        onAuditChanged,
        model,
        onContactChanged,
        onDateChanged,
        onSystemsChanged,
        onGroupEntityChanged,
        onResponsibleChanged,
        onNextOrSendClick,
        step,
        onPreviousButtonClick,
        isSending: sendMutation.isLoading || copyMutation.isLoading,
        validation,
        selectableGroupEntitiesLoading,
        selectableGroupEntitiesData,
        selectableResponsiblesData,
        selectableresponsiblesLoading,
    };
}
